import styled from "styled-components";
import { breakpoints } from "../../../styles/responsive";
import { colors, fontSizes } from "../../../styles/globalStyled";

import bgC1d from "../../../images/home/P1-1920-1080.png";
import bgC2d from "../../../images/home/P2-1920-1080.png";
import bgC3d from "../../../images/home/P3-1920-1080.png";

import bgC1t from "../../../images/home/P1-1440-1080.png";
import bgC2t from "../../../images/home/P2-1440-1080.png";
import bgC3t from "../../../images/home/P3-1440-1080.png";

import bgC1m from "../../../images/home/P1-390-844.png";
import bgC2m from "../../../images/home/P2-390-844.png";
import bgC3m from "../../../images/home/P3-390-844.png";

export const CarouselWrapper = styled.div`
  height: var(--height-under-navbar);
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-x: hidden;
  scroll-behavior: smooth;

  @media (max-width: ${breakpoints.mobile}) {
    height: calc(100vh - var(--navbar-height-mobile));
    overflow-x: hidden;
  }
`;

const getCarouselItem = (desktop: string, tablet: string, mobile: string) => styled.div`
  background-image: url(${desktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left 0 bottom -70px;

  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.tablet}) {
    background-image: url(${tablet});
  }

  @media (max-width: ${breakpoints.mobile}) {
    background-color: ${colors.biegeLight};
    background-image: url(${mobile});
    background-position: left 0 bottom 14vw;
  }

  @media (max-width: ${breakpoints.iPhone12}){
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      background-position-y: -35vh;
      background-size: 100vw auto;
      /* position: absolute;
      right: 25vw;
      top: 25vh; */
    }
  }
`;

export const LeftCarouselItem = getCarouselItem(bgC1d, bgC1t, bgC1m);
export const MiddleCarouselItem = getCarouselItem(bgC2d, bgC2t, bgC2m);
export const RightCarouselItem = getCarouselItem(bgC3d, bgC3t, bgC3m);

export const DescContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: -2vw;
  transform: translateY(-55vh);
  /* left: 5vw; */
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.mobile}) {
    /* position: relative; */
    margin: auto;
    /* left: auto; */
    left: 5vw;
    right: 5vw;
    transform: translateY(-35vh);

    @supports (-webkit-touch-callout: none) {
      transform: translateY(-45vh);
    }
  }

  h2 {
    font-size: ${fontSizes.huge};
    text-align: center;
    line-height: 1;
    font-weight: 450;
    letter-spacing: -0.25rem;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.hsDescription};
      letter-spacing: -0.2rem;
    }
  }

  p {
    font-size: ${fontSizes.larger};
    text-align: center;
    line-height: 1;
    margin: 1% 15% 0;
    padding: 0 5%;

    @media (max-width: ${breakpoints.mobile}) {
      margin: 2% 2% 0;
      font-size: ${fontSizes.mediumLarger};
    }
  }

  a {
    position: relative;
    margin: 3vh auto;
    padding: 1.5% 5%;
    
    border-style: none;
    border-radius: 5px;
    background-color: ${colors.green};
    text-transform: uppercase;
    color: white;
    font-size: ${fontSizes.larger};
    font-weight: 600;
    cursor: pointer;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mediumLarger};
    }
  }
`;

export const Arrow = styled.div`
  margin: auto auto 5px;
  color: ${colors.greyMedium};
  -webkit-transform: scale(1, 1.3);
  -moz-transform: scale(1, 1.3);
  -o-transform: scale(1, 1.3);
  transform: scale(1, 1.3);

  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;
