import { css } from "@emotion/react";
import { Dispatch, FC, InputHTMLAttributes, SetStateAction, useEffect, useState } from "react";

import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";
import { ceilTo10, distinct, normalizePriceLabel, pluralise } from "../../utils/helpers";
import { DimensionLabel, FilterSection, RangeSlider } from "./BrowsePageStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { sortBy } from "lodash";
import { colourNameToHexMap } from "../FilterPage/utils/filterAssets";

import Icon from '@mdi/react'
import { 
  mdiCheck, 
  mdiCheckboxBlank, 
  mdiCheckboxBlankOutline} from "@mdi/js";
import { FilterLabel } from "./BrowsePageStyles";
import { useSearchContext } from "../../store/searchContext";

import transparent from "../../images/transparent.png";
import multicolour from "../../images/multicolour.png";
import { useShopContext } from "../../store/shopContext";

const endButtonStyle = css`
  background: white;
  width: 50%;
  padding: 10px 0;
  border: 1px solid ${colors.orange};
  font-size: ${fontSizes.medium};
  color: ${colors.orange};
  text-transform: uppercase;
  cursor: pointer;
`;

const CheckBox: FC<InputHTMLAttributes<HTMLInputElement> & { color?: string, isTransparent?: boolean, isMulticolour?: boolean }> =
  ({ checked, color, isTransparent, isMulticolour, disabled, ...props }) => {
    const getCheckerColor = (boxColor: string | undefined) => {
      //TODO: Make a function to check if the color dark or light
      if (boxColor && ["#E9CABA", "#FFFFFF", "#FB9E02"].includes(boxColor))
        return colors.greyDark;
        
      return "white";
    }

    const background = 
      isTransparent 
        ? `url(${transparent})`
        : isMulticolour
          ? `url(${multicolour})`
          : "none";
    
    return (
      <span
        css={css`
          cursor: pointer;
          margin-right: 5px;
          position: relative;

          // &:focus-within {
          //   outline: 5px auto Highlight;
          //   outline: 5px auto -webkit-focus-ring-color;
          // }
        `}
      >
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          css={css`
            cursor: pointer;
            position: absolute;
            left: 0px;
            opacity: 0;
          `}
          {...props}
        />
        <div css={css`
          display: grid; 
          grid-template-columns: 22px 1fr; 
          grid-template-rows: 22px 1fr;
          margin-top: -18%;
          `}>
          <Icon 
            css={css`
              grid-area: 1 / 1 / 2 / 2;
              outline: ${!checked ? "none" : `1px solid ${getCheckerColor(color)}`};
              outline-offset: -6px;
              border-radius: 8px;
              transition: outline .65s;
              background-image: ${background};
              background-repeat: no-repeat;
              background-position: center;
              background-size: 60%;
            `}
            path= {!color && !checked ? mdiCheckboxBlankOutline : mdiCheckboxBlank} 
            size={1.8}
            color={color ?? (checked ? colors.greyDark : colors.greyLight)}
          />
          <Icon 
            css={css`
              opacity: ${checked ? .6 : 0};
              grid-area: 1 / 1 / 2 / 2;
              padding-left: 5px;
              padding-top: 5px;
              transition: opacity .4s;
            `}
            path={mdiCheck} 
            size={1.4}
            color={getCheckerColor(color)}
          />
        </div>
      </span>
    );
  };

interface BrowseFilterPanel {
  //items: IFurnitureItem[];
  state: BrowseState;
  isOpen: boolean;
  close: VoidFunction;
  numberLeft: number;
  browseMaxVals: BrowseMaxVals | undefined;
  filters: BrowseFilterOptions;
  resetFilters: VoidFunction;
  setRange: (rangeLabel: keyof Ranges, range: NumRange) => void;
  dispatch: Dispatch<Action>;
  options: FilterOptions;
  selectedCustomFilters: string[];
  selectCustomFilters: DispatchSetter<string[]>;
  includeIS: boolean;
  includeOOS: boolean;
  setIncludeIS: (value: boolean) => void;
  setIncludeOOS: (value: boolean) => void;
  soldByFlitch: boolean;
  soldBy3rdParty: boolean;
  setSoldByFlitch: (value: boolean) => void;
  setSoldBy3rdParty: (value: boolean) => void;
  sale: SaleFilter;
  setSale: (value: SaleFilter) => void;
  selectedTags: [Tag | undefined, Tag | undefined];
  filterTags: Tag[];
  setFilterTags: DispatchSetter<Tag[]>;
}

export const BrowseFilterPanel: FC<BrowseFilterPanel> = ({
  state,
  isOpen,
  close,
  numberLeft,
  browseMaxVals,
  filters,
  resetFilters,
  setRange,
  dispatch,
  options,
  selectedCustomFilters,
  selectCustomFilters,
  includeIS,
  includeOOS,
  setIncludeIS,
  setIncludeOOS,
  soldByFlitch,
  soldBy3rdParty,
  sale,
  setSale,
  setSoldByFlitch,
  setSoldBy3rdParty,
  selectedTags,
  filterTags,
  setFilterTags
}) => {
  const {
    budget,
    height,
    width,
    depth,
    coloursPreference,
    materialsPreference,
    retailers,
  } = filters;

  const swapCustomFilterSelected = (name: string) => {
    const selected = selectedCustomFilters.includes(name);

    const filters = selected
      ? selectedCustomFilters.filter(x => x != name)
      : [...selectedCustomFilters, name];

    selectCustomFilters(filters);
  }

  const { browseMetadata } = useShopContext();

  const { appliedSearch, keywords } = useSearchContext();
  const { materials: searchMaterials, colors: searchColors, retailers: searchRetailers } = keywords;

  const getLabelColor = <T extends string>(keywords: T[], keyword: T) => {
    if (!appliedSearch) return colors.greyDark;
    return keywords.includes(keyword)
      ? colors.greyDark
      : colors.greyMediumLighter;
  };

  const [foundRetailers, setFoundRetailers] = useState<RetailerUnion[]>([]);
  const [foundColors, setFoundColors] = useState<ColourName[]>([]);
  const [foundMaterials, setFoundMaterials] = useState<Material[]>([]);

  const { 
    items, 
    allColors: availableColors, 
    allMats: availableMaterials, 
    allRetailers: availableRetailers 
  } = state;

  const setFoundKeywords = <T extends string>(
    recognized: T[],
    found: T[],
    set: Dispatch<SetStateAction<T[]>>
  ) => 
    set(appliedSearch ? [...recognized, ...found].filter(distinct) : []);

  useEffect(() => {
    setFoundKeywords(searchRetailers, availableRetailers, setFoundRetailers);
  }, [searchRetailers, items]);

  useEffect(() => {
    setFoundKeywords(searchColors, availableColors, setFoundColors);
  }, [searchRetailers, items]);

  useEffect(() => {
    setFoundKeywords(searchMaterials, availableMaterials, setFoundMaterials);
  }, [searchRetailers, items]);

  const toggleSale = (newVal:  SaleFilter) => 
    setSale(sale === newVal ? undefined : newVal);

  const limit = (source: number) =>
    ceilTo10(source > 5000 ? 5000 : source);

  const tagFilters = () => {
    if (!browseMetadata) return [];

    const [parent, child] = selectedTags;
    const parentGroup = browseMetadata.tags.find(t => t.name === parent);

    const labelColour = (t: TagBase) =>
      child 
        ? child === t.name ? colors.greyDark : colors.greyMediumLighter
        : colors.greyDark;

    const updateSingleTag = (tag: Tag) => {
      if (filterTags.includes(tag)) {
        setFilterTags(filterTags.filter(t => t !== tag));
        return;
      }

      setFilterTags([...filterTags, tag]);
    };

    const updateTags = (parent: TagGroup) => {
      const children = parent.children.map(c => c.name);

      if (filterTags.includes(parent.name)) {
        setFilterTags(filterTags.filter(t => t != parent.name && !children.includes(t)));
        return;
      };

      setFilterTags([...filterTags, parent.name, ...children]);
    };

    return parentGroup
      ? (
        <FilterSection label="Categories" filtersActive={filterTags && filterTags.length > 0}>
          {parentGroup.children.map(c =>
            <div key={`${c.name}_${Math.random()*100}`}>
              <label
                css={css`
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  font-size: ${fontSizes.smaller};
                  color: ${labelColour(c)};
                  text-transform: capitalize;

                  @media (min-width: ${breakpoints.mobile}) {
                    font-size: ${fontSizes.medium};
                  }
                `}
              >
                <CheckBox
                  name={c.name}
                  checked={child === c.name || filterTags.includes(c.name)}
                  disabled={!!child}
                  onChange={() => updateSingleTag(c.name) }
                />
                <span>
                  {` ${c.displayName ?? c.name}`}
                </span>
              </label>
            </div>  
          )}
        </FilterSection>)
      : (
        <FilterSection label="Categories" filtersActive={filterTags && filterTags.length > 0}>
        {browseMetadata.tags.map(t =>
          <div key={`${t.name}_${Math.random()*100}`}>
            <label
              css={css`
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: ${fontSizes.smaller};
                color: ${colors.greyDark};
                text-transform: capitalize;

                @media (min-width: ${breakpoints.mobile}) {
                  font-size: ${fontSizes.medium};
                }
              `}
            >
              <CheckBox
                name={t.name}
                checked={filterTags.includes(t.name)}
                disabled={false}
                onChange={() => updateTags(t)}
              />
              <span>
                {` ${t.displayName ?? t.name}`}
              </span>
            </label>
          </div>  
        )}
      </FilterSection>);
  };

  const limitHeight = () => {
    if (!browseMaxVals?.maxHeight) return 0;
    return browseMaxVals.maxHeight > 1500 ? 1500 : browseMaxVals.maxHeight;
  }

  return (
    <div
      css={css`
        position: fixed;
        width: 75vw;
        right: 0;
        top: var(--navbar-height);
        bottom: 0;
        transform: translateX(
          ${isOpen ? "0" : "105%"}
        ); // 105% so its box-shadow doesn't fall on the rest of the page because of being too close if it would be exactly 100%
        background-color: white;
        box-shadow: var(--box-shadow);
        z-index: 4;
        transition: transform 0.5s;
        display: flex;
        flex-direction: column;
        padding: 25px;
        overflow: auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: ${breakpoints.mobile}) {
          width: 500px;
        }
      `}
    >
      <div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            padding-bottom: 15px;
            border-bottom: solid 1px ${colors.greyLight};
          `}
        >
          <h2
            css={css`
              font-size: ${fontSizes.large};
            `}
          >
            Filters
          </h2>
          <button
            onClick={close}
            css={css`
              border: none;
              cursor: pointer;
              background-color: white;
            `}
          >
            <FontAwesomeIcon icon={faTimes} size="2x" color={colors.greyDarker} />
          </button>
        </div>
        {tagFilters()}
        {browseMaxVals?.maxPrice && (
          <FilterSection label="Price" filtersActive={!!budget}>
            <RangeSlider
              onChange={(range) => setRange("budget", range)}
              values={budget ?? [0, limit(browseMaxVals.maxPrice)]}
              minValue={0}
              maxValue={limit(browseMaxVals.maxPrice)}
              step={5}
              unit="£"
              unitIsPrefixed={true}
            />
          </FilterSection>
        )}
        <FilterSection label="On Sale" filtersActive={sale!==undefined}>
          <div>
            <FilterLabel>
              <CheckBox
                name="onSale"
                checked={sale==="Sale"}
                onChange={() => toggleSale("Sale")}
              />
              <span>{" On Sale"}</span>
            </FilterLabel>
            <FilterLabel>
              <CheckBox
                name="off10"
                checked={sale==="10"}
                onChange={() => toggleSale("10")}
              />
              <span>{" 10% off or more"}</span>
            </FilterLabel>
            <FilterLabel>
              <CheckBox
                name="off20"
                checked={sale==="20"}
                onChange={() => toggleSale("20")}
              />
              <span>{" 20% off or more"}</span>
            </FilterLabel>
            <FilterLabel>
              <CheckBox
                name="off50"
                checked={sale==="50"}
                onChange={() => toggleSale("50")}
              />
              <span>{" 50% off or more"}</span>
            </FilterLabel>
          </div>
        </FilterSection>
        {options.add && options.add.map(customFilter => (
          <FilterSection 
            key={customFilter.name} 
            label={customFilter.name}
            filtersActive={selectedCustomFilters && selectedCustomFilters.length > 0}
          >
            {customFilter.categories.map(cat => (
              <div key={cat.name}>
              <label
                css={css`
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  font-size: ${fontSizes.smaller};
                  color: ${colors.greyDark};

                  @media (min-width: ${breakpoints.mobile}) {
                    font-size: ${fontSizes.medium};
                  }
                `}
              >
                <CheckBox
                  name={cat.name}
                  checked={selectedCustomFilters.includes(cat.name)}
                  onChange={() =>
                    swapCustomFilterSelected(cat.name)
                  }
                />
                <span>
                  {" "}
                  {cat.displayName}
                </span>
              </label>
            </div>
            ))}
          </FilterSection>
        ))}
        {browseMaxVals?.colours && !options.omit.includes("colour") && (
          <FilterSection
            label="Colour"
            filtersActive={coloursPreference.length > 0}
          >
            {browseMaxVals.colours.sort().map((colour) => {
              const hex = colourNameToHexMap[colour];
              const isWhite = (colour === "White") || (availableColors.length > 0 && !availableColors.includes(colour));

              return (
                <div key={colour}>
                  <label
                    css={css`
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                      font-size: ${fontSizes.smaller};
                      color: ${getLabelColor(foundColors, colour)};

                      @media (min-width: ${breakpoints.mobile}) {
                        font-size: ${fontSizes.medium};
                      }
                    `}
                  >
                    <CheckBox
                      name={colour}
                      checked={coloursPreference.includes(colour) || searchColors.includes(colour)}
                      isTransparent={colour === "NoColour"}
                      isMulticolour={colour === "Multicolour"}
                      color={isWhite ? undefined : hex}
                      disabled={!!appliedSearch && !foundColors.includes(colour)}
                      onChange={() =>
                        dispatch({
                          type: "toggleColourFilter",
                          colour,
                        })
                      }
                    />
                    <span css={css`padding-left: 5px;`}>
                      {" "}
                      {colour === "NoColour" ? "Transparent" : colour} {/*count*/}
                    </span>
                  </label>
                </div>
              );
            })}
          </FilterSection>
        )}
        {browseMaxVals?.materials && !options.omit.includes("material") && (
          <FilterSection
            label="Material"
            filtersActive={materialsPreference.length > 0}
          >
            {browseMaxVals.materials.filter(m => !["Material irrelevant", "Other"].includes(m)).sort().map((material) => (
              <div key={material}>
                <label
                  css={css`
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-size: ${fontSizes.smaller};
                    color: ${getLabelColor(foundMaterials, material)};

                    @media (min-width: ${breakpoints.mobile}) {
                      font-size: ${fontSizes.medium};
                    }
                  `}
                >
                  <CheckBox
                    name={material}
                    checked={materialsPreference.includes(material) || searchMaterials.includes(material)}
                    disabled={!!appliedSearch && !foundMaterials.includes(material)}
                    onChange={() =>
                      dispatch({ type: "toggleMaterialFilter", material })
                    }
                  />
                  <span>
                    {" "}
                    {/*capitalise*/(material.replace(/([a-z])([A-Z])/g, '$1 $2'))} {/*count*/}
                  </span>
                </label>
              </div>
            ))}
          </FilterSection>
        )}
        {browseMaxVals &&
          (browseMaxVals.maxHeight ||
            browseMaxVals.maxWidth ||
            browseMaxVals.maxDepth) && (
            <FilterSection
              label="Dimensions"
              filtersActive={!!(height ?? width ?? depth)}
            >
              {browseMaxVals.maxHeight && !options.omit.includes("height") && (
                <>
                  <DimensionLabel label="Height" />
                  <RangeSlider
                    onChange={(range) => setRange("height", range)}
                    values={height ?? [0, limitHeight()]} // TODO: investigate where does browseMaxVals.maxHeight = 18 kms(!!!) come from
                    minValue={0}
                    maxValue={limitHeight()}
                    step={5}
                    unit="cm"
                  />
                </>
              )}
              {browseMaxVals.maxWidth && !options.omit.includes("width") && (
                <>
                  <DimensionLabel label="Width" />
                  <RangeSlider
                    onChange={(range) => setRange("width", range)}
                    values={width ?? [0, ceilTo10(browseMaxVals.maxWidth)]}
                    minValue={0}
                    maxValue={ceilTo10(browseMaxVals.maxWidth)}
                    step={5}
                    unit="cm"
                  />
                </>
              )}
              {browseMaxVals.maxDepth && !options.omit.includes("depth") && (
                <>
                  <DimensionLabel label="Depth" />
                  <RangeSlider
                    onChange={(range) => setRange("depth", range)}
                    values={depth ?? [0, ceilTo10(browseMaxVals.maxDepth)]}
                    minValue={0}
                    maxValue={ceilTo10(browseMaxVals.maxDepth)}
                    step={5}
                    unit="cm"
                  />
                </>
              )}
            </FilterSection>
          )}

        <FilterSection label="Retailer" filtersActive={retailers.length > 0}>
          {browseMaxVals &&
            sortBy(browseMaxVals.retailers).map(
              (retailer) => (
                <div key={retailer}>
                  <label
                    css={css`
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                      font-size: ${fontSizes.moreSmall};
                      color: ${getLabelColor(foundRetailers as Retailer[], retailer)};
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      @media (min-width: ${breakpoints.mobile}) {
                        font-size: ${fontSizes.medium};
                      }

                      @media (max-width: ${breakpoints.mobileS}) {
                        font-size: 1.2rem;
                      }
                    `}
                  >
                    <CheckBox
                      name={retailer}
                      checked={retailers.includes(retailer) || (foundRetailers.length > 0 && searchRetailers.includes(retailer as RetailerUnion))}
                      disabled={!!appliedSearch && !foundRetailers.includes(retailer as RetailerUnion)}
                      onChange={() =>
                        dispatch({ type: "toggleRetailerFilter", retailer })
                      }
                    />
                    <span>
                      {" "}
                      {retailer} {/*count*/}
                    </span>
                  </label>
                </div>
              )
            )}
        </FilterSection>
        <FilterSection label="Sold By" filtersActive={false}>
          <div>
            <FilterLabel>
              <CheckBox 
                name="byFlitch"
                checked={soldByFlitch}
                onChange={() => setSoldByFlitch(!soldByFlitch)}
                />
              <span>
                {" Flitch"}
              </span>
            </FilterLabel>
            <FilterLabel>
              <CheckBox 
                name="by3rdParty"
                checked={soldBy3rdParty}
                onChange={() => setSoldBy3rdParty(!soldBy3rdParty)}
                />
              <span>
                {" 3rd Party Retailers"}
              </span>
            </FilterLabel>
          </div>
        </FilterSection>
        <FilterSection label="Stock" filtersActive={false}>
          <div>
            <FilterLabel>
              <CheckBox
                name="includeIS"
                checked={includeIS}
                onChange={() => setIncludeIS(!includeIS)}
              />
              <span>
                {" Show items in stock"}
              </span>
            </FilterLabel>
            <FilterLabel>
              <CheckBox
                name="includeOOS"
                checked={includeOOS}
                onChange={() => setIncludeOOS(!includeOOS)}
              />
              <span>
                {" Show items out of stock"}
              </span>
            </FilterLabel>
          </div>
        </FilterSection>
        
      </div>
      {/* Bottom button row */}
      <div
        css={css`
          display: flex;
          gap: 10px;
        `}
      >
        <button
          onClick={() => {
            selectCustomFilters([]);
            resetFilters();
            close();
          }}
          css={endButtonStyle}
        >
          Clear all
        </button>
        <button
          onClick={close}
          css={css`
            ${endButtonStyle};

            color: white;
            background-color: ${colors.orange};
          `}
        >
          View{" "}
          <span
            css={css`
              text-transform: initial;
            `}
          >
            ({numberLeft.toLocaleString()} item{pluralise(numberLeft)})
          </span>
        </button>
      </div>
    </div>
  );
};
