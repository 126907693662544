import styled from "styled-components";
import { colors, fontSizes } from "../../../styles/globalStyled";

export const CouponBarSpan = styled.span`
    font-size: ${fontSizes.moreSmall};
    color: ${colors.greyMediumDarker};
`;

export const CouponCodeText = styled.text`
    font-size: ${fontSizes.moreSmall};
    color: ${colors.red};
    border-color: ${colors.red};
    border-style: solid;
    border-width: 1px;
    padding: 2px;
`;