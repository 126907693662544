import styled, { keyframes } from "styled-components";
import Spacer from "react-spacer";

import { colors, fontSizes } from "../../styles/globalStyled";
import logosMobile from "../../images/logos-mobile.jpg";
import logosDesktop from "../../images/logos-desktop.jpg";
import { breakpoints } from "../../styles/responsive";


export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: var(--navbar-height);

  @media (max-width: ${breakpoints.mobile}) {
    top: var(--navbar-height-mobile);
  }
`;

export const MethodTitle = styled.h2`
  font-weight: 450;
  font-size: ${fontSizes.hsDescTitle};
  margin-bottom: 0;
  word-wrap: break-word;
  color: ${colors.greyDark};
  margin: 0 20vw 3%;
  text-align: center;
  line-height: 1.2;
  
  @media (max-width: ${breakpoints.mobile}) {
    /*height: var(--height-under-navbar-mobile);*/
    font-size: ${fontSizes.larger};
    letter-spacing: -0.1rem;
    line-height: 1em;
    margin: 20px 1% 0;
    padding: 0 4%;
  }
`;

export const MethodSection = styled.div`
  background: white;
  position: relative; // For box shadow to work https://stackoverflow.com/a/31276836/3229534
  /* box-shadow: var(--box-shadow); */
  box-shadow: 0px 2px 7px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 4% 0;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    justify-content: center;
  }
`;

export const MethodCardsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: minmax(100px, 350px);
  justify-items: space-around;
  padding: 10px 0;

  @media (min-width: ${breakpoints.mobile}) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    width: min(100%, var(--max-content-width));
    justify-content: space-around;
  }
`;

export const MethodCardStyled = styled.div`
  margin: 10px 0; // bigger here so that margin around card and between cards is the same
  padding: var(--card-padding);

  align-self: end;
  justify-self: center;

  @media (min-width: ${breakpoints.mobile}) {
    width: min(25vw, 350px);
  }
  
`;

export const MethodCardTitle = styled.p`
  font-weight: 400;
  font-size: ${fontSizes.larger};
  text-align: center;
  color: ${colors.greyDark};
  line-height: 1;
  margin: 3% 0;
  
  b {
    font-weight: 750;
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.mediumLarger};
  }
`;

export const MethodCardDescription = styled.p`
  font-size: ${fontSizes.medium};
  text-align: center;
  color: ${colors.greyDark};

  @media (max-width: ${breakpoints.mobile}) {
    line-height: 1.1;
    padding: 1%;
  }
  
  @media (min-width: ${breakpoints.mobile}) {
    margin: 5% 5% 7%;
    font-size: ${fontSizes.mediumSmaller};
  }
`;

export const MethodCardImage = styled.img`
  width: 100%;
  margin-top: 10px;
  margin-bottom: var(--card-padding);
  border-radius: 20px;
  box-shadow: var(--box-shadow);

  &:focus {
    outline: none;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 70%;
    margin-left: 15%;
    margin-bottom: 2%;
  }
`;

export const StylistBanner = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background-color: ${colors.green};
  text-align: left;
  font-size: ${fontSizes.hsDescTitle};
  font-weight: 400;
  padding: 1.5em 16.5vw 2em 16.5vw;

  h2 {
    font-size: ${fontSizes.hsDescTitle};
    font-weight: 500;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.larger};
    }
  }

  a {
    background-color: #ffffff;
    color: ${colors.green};
    padding: 0.4em 1.7em;
    border-radius: 5px;
    font-size: ${fontSizes.hsDescription};
    width: fit-content;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.larger};
    }

  }

  p {
    margin-bottom: 1em;
    margin-top: 0.5em;
    font-size: ${fontSizes.larger};

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mediumLarger};
    }

  }
  
  @media (max-width: ${breakpoints.mobile}) {
    outline: 4px solid ${colors.green};
    outline-offset: -0.5em;
    line-height: 1.2;
    padding: 1.5em 8.3vw 2em 8.3vw;
  }
`;

export const InfoLabel = styled.div`
  font-size: ${fontSizes.larger};
  text-align: center;
  color: ${colors.greyDark};
  padding: 5vh 15vw;
  z-index: 3;
  line-height: 1.2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.mediumLarger};
    padding: 4vh 5vw;
    line-height: 1;
  }

  h2 {
    font-size: ${fontSizes.hsDescTitle};
    font-weight: 450;
    margin-bottom: 2vh;
    
    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.larger};
    }
  }

  g {
    color: ${colors.green};
  }

  hr {
    height: 0;
    margin: 0.2em 0;

    @media (max-width: ${breakpoints.mobile}) {
      margin: 0.4em 0;
    }
  }

`;
