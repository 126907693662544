import { css } from "@emotion/react";
import Icon from "@mdi/react";
import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";

const StyledButton = styled.button`
  width: var(--nav-button-side);
  height: var(--nav-button-side);
  border-radius: 4rem;
  border-style: none;
  cursor: pointer;
  background-color: white;
  color: ${colors.greyMediumDarker};
  margin: auto;

  @media (max-width: ${breakpoints.mobile}) {
    width: var(--nav-button-side-mobile);
    height: var(--nav-button-side-mobile);
    margin: 0 5px;
  }
`;

const IconContainerCss = css`
  position: relative;
  margin-top: 10%;
`;

const getIconCss = (
  desktopSize: number | undefined,
  mobileSize: number | undefined
) => css`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: ${desktopSize ? `${desktopSize}px` : "var(--nav-button-icon-size)"};
  height: ${desktopSize ? `${desktopSize}px` : "var(--nav-button-icon-size)"};

  @media (max-width: ${breakpoints.mobile}) {
    width: ${mobileSize ? `${mobileSize}px` : "var(--nav-button-icon-size-mobile)"};
    height: ${mobileSize ? `${mobileSize}px` : "var(--nav-button-icon-size-mobile)"};
  }`;

interface NavButtonProps {
  onClick: () => void;
  iconPath: string;
  iconSizeDesktop? : number;
  iconSizeMobile? : number;
}

export const NavButton: FC<NavButtonProps> = (
  {onClick, iconPath, iconSizeDesktop, iconSizeMobile}
) => (
  <StyledButton onClick={onClick}>
    <div css={IconContainerCss}>
      <Icon css={getIconCss(iconSizeDesktop, iconSizeMobile)} path={iconPath}/>
    </div>
  </StyledButton>
)
