import { useEffect, EffectCallback, useRef, DependencyList } from "react";

/**
 * `useEffect` that only fires on non-initial renders, so not on the initial
 * component mount. Useful for when you want to call a reset function if some
 * state changes, but not the first time the effect runs – unlike the default
 * `useEffect` behaviour.
 */
export const useNonInitialEffect = (
  callback: EffectCallback,
  deps?: DependencyList
) => {
  const renderCount = useRef(0);

  useEffect(() => {
    if (renderCount.current > 0) {
      return callback();
    } else {
      renderCount.current++;
    }
  }, deps);
};
