import React from "react";

import { SQFooterWrapper, NextPageButton } from "./SQFooterWrapper";

interface FooterProps {
  isMinSelected: boolean;
  nextPage: string;
  onClick?: VoidFunction;
}

export const SQFooter = ({ isMinSelected, nextPage, onClick }: FooterProps) => {
  return (
    <SQFooterWrapper>
      <NextPageButton
        to={nextPage}
        className={`button ${isMinSelected ? "" : "disabled"}`}
        onClick={onClick}
      >
        Next Page
      </NextPageButton>
    </SQFooterWrapper>
  );
};
