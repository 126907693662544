import { FC } from "react";
import { CartEntry } from "../../store/shopContext";
import { getProductUrl, normalizePriceLabel } from "../../utils/helpers";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { 
  CartItemContainer, 
  CartItemLeftArea, 
  CartItemPriceCss, 
  CartItemQntButton, 
  CartItemQntContainer,
  CartItemQntCss, 
  CartItemRetailerCss, 
  CartItemRightArea, 
  RemoveFromCartButton, 
  RemoveFromCartButtonContainer } from "./ShopCartStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spacer from "react-spacer";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

interface ShopCartItemProps {
  entry: CartEntry;
  removeFromCart: (item: IFurnitureItem) => void;
  increaseInCart: (item: IFurnitureItem) => void;
  decreaseInCart: (item: IFurnitureItem) => void;
  closeCart: () => void;
}

export const ShopCartItem: FC<ShopCartItemProps> = (
  {entry, removeFromCart, increaseInCart, decreaseInCart, closeCart}
) => {
  const {item, quantity} = entry;

  const closeIfMobile = () => {
    if (isMobile) closeCart();
  };

  return (
    <>
      <Spacer height="5px" />
      <CartItemContainer>
        <CartItemLeftArea
          image={item.images.thumbnail.mainImageGb ?? item.images.thumbnail.mainImage}
        >
          <RemoveFromCartButtonContainer>
            <RemoveFromCartButton onClick={() => removeFromCart(item)}>
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </RemoveFromCartButton>
          </RemoveFromCartButtonContainer>
        </CartItemLeftArea>
        <div/>
        <CartItemRightArea>
          <Link to={getProductUrl(item)} onClick={closeIfMobile}><b>{item.name}</b></Link>
          <p className={CartItemPriceCss}>{normalizePriceLabel(item.stockData?.price, false)}</p>
          <CartItemQntContainer>
            <CartItemQntButton onClick={() => decreaseInCart(item)}> - </CartItemQntButton>
            <span className={CartItemQntCss}>{quantity}</span>
            <CartItemQntButton onClick={() => increaseInCart(item)}> + </CartItemQntButton>
          </CartItemQntContainer>
          <p className={CartItemRetailerCss}>{`Supplied by ${item.retailer}`}</p>
        </CartItemRightArea>
      </CartItemContainer>
    </>
  );
}