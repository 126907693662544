export const defaultCommonOpts: ICommonFilterOptions = {
  haveSomeIdeas: undefined,
  colourSchemes: [],
  materials: [],
  tone: undefined,

  minBudget: undefined,
  maxBudget: undefined,
  minHeight: undefined,
  maxHeight: undefined,
  minWidth: undefined,
  maxWidth: undefined,
  minDepth: undefined,
  maxDepth: undefined,
};

export const diningchairDefaultValues: IDiningChairFilterOptions = {
  ...defaultCommonOpts,
};
export const diningtableDefaultValues: IDiningTableFilterOptions = {
  ...defaultCommonOpts,
};
export const barstoolDefaultValues: IBarstoolFilterOptions = {
  ...defaultCommonOpts,
};
export const coffeeTableDefaultValues: ICoffeeTableFilterOptions = {
  ...defaultCommonOpts,
};
export const consoleTableDefaultValues: IConsoleTableFilterOptions = {
  ...defaultCommonOpts,
};
export const sideboardDefaultValues: ISideboardFilterOptions = {
  ...defaultCommonOpts,
};
export const sidetableDefaultValues: ISidetableFilterOptions = {
  ...defaultCommonOpts,
};
export const bedsidetableDefaultValues: ISidetableFilterOptions = {
  ...defaultCommonOpts,
};
export const chestofdrawersDefaultValues: IChestofdrawersFilterOptions = {
  ...defaultCommonOpts,
};
export const displaycabinetDefaultValues: IDisplaycabinetFilterOptions = {
  ...defaultCommonOpts,
};
export const shelvingDefaultValues: IShelvingFilterOptions = {
  ...defaultCommonOpts,
};
export const tvStandDefaultValues: ITvStandFilterOptions = {
  ...defaultCommonOpts,
};
export const wardrobeDefaultValues: IWardrobeFilterOptions = {
  ...defaultCommonOpts,
};
export const sofaDefaultValues: ISofaFilterOptions = {
  ...defaultCommonOpts,
};
export const armchairDefaultValues: IArmchairFilterOptions = {
  ...defaultCommonOpts,
};
export const bedDefaultValues: IBedFilterOptions = {
  ...defaultCommonOpts,
};
export const shopDefaultValues: IShopFilterOptions = {
  ...defaultCommonOpts,
}
