import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { BottomFooter } from "../../shared/Footer/BottomFooter";
import { Footer } from "../../shared/Footer/Footer";
import NavBar from "../../shared/NavBar/NavBar";
import { SectionHeader, Space } from "./TermPageComponents";
import { FaqsPageContainer, FaqsPageWrapper, TermsOfServiceWrapper } from "./TermsWrapper";

export const TosPage = () => (
  <FaqsPageWrapper>
    <FlitchHelmet title="General Terms of Sale" />
    <NavBar />
    <FaqsPageContainer>
      <TermsOfServiceWrapper>
        <>
          <SectionHeader label="General Terms of Sale" />
          <Space />
          <p className="c5">
            <span className="c0">
              These terms of sale (“Terms of Sale”) set out the terms and conditions 
              on which we supply any of the products (“Product” or “Products”) listed 
              on our website www.withflitch.com (“our site”) to you.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              Please read these Terms of Sale carefully before ordering any Products 
              from our site. These Terms of Sale tell you who we are, how we will provide 
              Products to you, how you and we may end the contract, what to do if there is 
              a problem and other important information. If you do not agree to these Terms 
              of Sale you must not order any Product through our site.
            </span>
          </p>
          <Space />
          <SectionHeader label="1. Information about us" />
          <Space />
          <p className="c5">
            <span className="c0">
              1.1 www.withflitch.com is a website operated by Azara Ltd 
              (“we”, “our”, “us”). We are registered in the U.K. under 
              company number 11059322. Our registered office is at Suite 
              2 1st Floor Sutherland House, 70-78 West Hendon Broadway, 
              London NW9 7BT. Our VAT number is 372329395.
            </span>
          </p>
          <Space />
          <SectionHeader label="2. Ordering Products" />
          <Space />
          <p className="c5">
            <span className="c0">
              2.1 You can place orders for Products with us by following the process outlined on our site. 
              You acknowledge that by placing an order with us you will be under an obligation to pay for the 
              Products in that order if we accept your order.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              2.2 Our order process allows you to check and amend any errors before submitting your order to us. 
              Please take the time to read and check your order at each stage of the order process.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              2.3 All orders are subject to acceptance by us. Upon receiving an order, we will send you an email 
              acknowledging your order and this will not constitute acceptance by us. Once the necessary checks 
              with our suppliers have been completed, we will send you a confirmation e-mail and this will constitute 
              acceptance by us. The contract between you and us will only be made when we send you this email. 
              We will charge your chosen payment method after we accept your order. We will send a further email 
              when your order has been dispatched.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              2.4 Unfortunately we can&rsquo;t stop an order once it has been dispatched. If you change your 
              mind about your order after this point you can reject delivery or return the Products to 
              us in accordance with the returns policy below at clause 9.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              2.5 Before ordering from us, it is your responsibility to check and determine your full ability 
              to receive the Products ordered. This includes ensuring that the Products ordered will pass 
              freely into your room of choice, they fit in that room, can be transported through the door of 
              your flat or room, stairs and doorways, and there are no other issues that could make delivery 
              more complicated or impossible.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              2.6 You must keep the contact details we hold for you up-to-date so that we can contact you, 
              if necessary, about your order or the delivery of the Products.
            </span>
          </p>
          <Space />
          <SectionHeader label="3. Availability" />
          <Space />
          <p className="c5">
            <span className="c0">
              3.1 All orders for Products are subject to the availability of those Products and the materials for 
              making the Products. We will inform you as soon as possible after receiving your order if, for any 
              reason, the Products you have ordered are not available or are subject to any delay.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              3.2 If we are unable to supply you with a Product, for example, because the supplier is out of stock or 
              because of an error in the price (see clauses 5.5 and 5.6 below) we will inform you of this by email and 
              we will not process your order. If you have already paid for the Product we will refund you the full amount 
              as soon as possible.
            </span>
          </p>
          <Space />
          <SectionHeader label="4. Images and sizing of products" />
          <Space />
          <p className="c5">
            <span className="c0">
              4.1 The images of the Products on our site are for illustrative purposes only. Although we have made every 
              effort to display the colours accurately, we cannot guarantee that your computer will display the colours 
              accurately or reflect the colour of the Products. The Products that are delivered to you may vary slightly 
              from those images.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              4.2 Although we have made every effort to be as accurate as possible, the measurements indicated including weights, 
              dimensions and capacities shown on our site are approximate only.
            </span>
          </p>
          <Space />
          <SectionHeader label="5. Prices of products" />
          <Space />
          <p className="c5">
            <span className="c0">
              5.1 The price of any Product will be as quoted on our site, except in cases of obvious error.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              5.2 The price of any Product may change from time to time, but changes will not affect any order we have accepted.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              5.3 All prices on the Site are shown in GBP.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              5.4 The price of a Product includes VAT (where it applies) but excludes any delivery costs, which where 
              applicable will be added to the price of the Products and set out as part of the total amount due during 
              the order process. 
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              5.5 Due to the large number of Products on our site, it is always possible that some of the Products listed 
              on our site may be incorrectly priced, despite our reasonable efforts. We will normally verify prices as part 
              of our order-handling procedure so that, where a Product&rsquo;s correct price is less than our stated price, we 
              will charge the lower amount when dispatching the Product to you. If the Product&rsquo;s correct price is higher 
              than the price stated on our site, we will normally, at our discretion, either contact you for instructions 
              before dispatching the Product, or reject your order and notify you of the rejection.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              5.6 We are under no obligation to provide the Product to you at the incorrect (lower) price, even after
              we have sent you an order acceptance, if the pricing error is obvious and unmistakable and could have
              reasonably been recognised by you as a mis-pricing.
            </span>
          </p>
          <Space />
          <SectionHeader label="6. How to pay" />
          <Space />
          <p className="c5">
            <span className="c0">
              6.1 We accept payment with the payment methods listed on our site. You must pay for the Products and any applicable 
              delivery charges in advance of delivery of the Products.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              6.2 By submitting an order to us through our site, you are confirming that the payment details provided on your 
              order are valid and correct.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              6.3 We are not responsible for any processing or administrative charges that may be levied by your bank or card 
              issuer as a result of payments made to us. It is your responsibility to contact your bank or card issuer to check 
              any conditions of payment.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              6.4 In cooperation with Klarna Bank AB (publ), Sveavägen 46, 111 34 Stockholm, Sweden, we offer you the Pay Later 
              payment option. Payment is to be made to Klarna. Only available to UK users. You can find further information and 
              Klarna&rsquo;s user terms <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/user">here</a>, 
              and you can find general information on Klarna <a href="https://www.klarna.com/uk/">here</a>. Your personal data 
              is handled in accordance with applicable data protection laws and in accordance with the information in 
              <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/privacy"> Klarna&rsquo;s privacy statement.</a>
            </span>
          </p>
          <Space />
          <SectionHeader label="7. Delivery" />
          <Space />
          <p className="c5">
            <span className="c0">
              7.1 Our suppliers offer a delivery service in accordance with the timescales shown against each Product on our site. 
              Please note that delivery timescales are estimates. 
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              7.2 Please note that our suppliers only deliver to addresses in the United Kingdom, excluding Northern Ireland.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              7.3 Your estimated dispatch date will be as set out in a dispatch confirmation sent by email. This dispatch date 
              indicates when we expect our suppliers to send the Products to you. Although our suppliers will make every reasonable 
              effort to ensure your Products are dispatched and delivered within the estimated timescales, unfortunately we cannot 
              guarantee that they will not be affected by unforeseen issues affecting the suppliers of the Product or their delivery 
              partners. If they are unable to meet the estimated dispatch or delivery date, we will contact you with a revised 
              estimated date.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              7.4 Your delivery will be completed when our suppliers deliver the Products to the address you gave us. If no one is 
              available at your address to take delivery, they will leave you a note with information about how to rearrange delivery. 
              If you have not accepted delivery after three attempts, we may treat the contract as having been cancelled by you in 
              accordance with the terms of clause 9 below.
            </span>
          </p>
          <Space />
          <SectionHeader label="8. Risk and ownership" />
          <Space />
          <p className="c5">
            <span className="c0">
              8.1 Ownership of the Products will pass to you on delivery, provided that we have received full payment of all sums due 
              in respect of the Products, including delivery charges.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              8.2 The Products will be your responsibility from the time that you (or a third party other than the carrier and indicated 
              by you) take physical possession of the Products.
            </span>
          </p>
          <Space />
          <SectionHeader label="9. Cancellation, returns and refund policy" />
          <Space />
          <p className="c5">
            <span className="c0">
              9.1 We hope you will be pleased with everything you have bought from us but if you are unhappy with 
              your Products, you can return them to us either in accordance with the terms set out below or as 
              otherwise in accordance with your legal rights.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.2 You have a legal right to change your mind and cancel the contract between you and us within 14 days 
              (unless stated otherwise) of delivery of your Products without giving a reason.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.3 To return a Product it must be in a saleable condition and in its original undamaged packaging, make sure 
              it is packed up securely with the returns form and we will refund you for the product on receipt.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.4 The cancellation period will expire 14 days (unless stated otherwise) from the day on which you acquire, or a 
              third party other than the carrier and indicated by you acquires, physical possession of the Products. Where you 
              order Products in one order from multiple suppliers, the cancellation period will, for each individual Product, 
              expire 14 days (unless stated otherwise) from the day on which you acquire, or a third party other than the carrier 
              and indicated by you acquires, physical possession of that individual Product.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.5 To exercise the right to cancel, you must inform us of your decision to cancel your contract with us by making a 
              clear statement (e.g. a letter sent by post or email). The easiest way to do this is to contact our Customer Services 
              team, their contact details can be found in clause 22 
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.6 To meet the cancellation deadline, it is sufficient for you to send your communication concerning the exercise of 
              your right to cancel before the cancellation period has expired
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.7 If you cancel your contract with us, we will reimburse you all payments received from you, which may not 
              include the cost of delivery.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
            9.8 We will make the reimbursement without undue delay, and not later than: <br/>
            (a) 30 days after the day our suppliers receive back from you any Products supplied, or<br/>
            (b) if there were no goods supplied, 30 days after the day on which we are informed about your 
            decision to cancel this contract.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.9 We will make the reimbursement using the same means of payment as you used for the initial 
              transaction unless you have expressly agreed otherwise; in any event, you will not incur any 
              fees as a result of the reimbursement.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.10 We will withhold reimbursement until our suppliers have received the Products back.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.11 We may make a deduction from the reimbursement for loss in value of any Products supplied, if the 
              loss is the result of unnecessary handling by you. You are only liable for any diminished value of the 
              Products resulting from the unfair and/or unreasonable wear and tear of the Product other than what is 
              necessary to establish the nature, characteristics and functioning of the Products. We may make a deduction 
              from any reimbursement (you are entitled to from us) for such loss in value of any Products we supply.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.12 As a consumer, you will always have legal rights in relation to Products that are faulty or not as 
              described. These legal rights are not affected by the returns policy in this clause 9 or any of the other 
              general Terms of Sale set out.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.13 If you change your mind and exercise your right to cancel under clause 9.2 above after delivery, 
              then the Products must be returned to our suppliers. In this case, this may be done by emailing us 
              at <a href="mailto:support@withflitch.com">support@withflitch.com</a> to receive further instructions. You will 
              incur the delivery costs to return the Products, unless otherwise stated.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.14 If you exercise your right to return the Products under clause 9.11, because the Products are faulty, 
              were not delivered as described or you otherwise have a legal right to return the Products as a result of 
              something we have done wrong after dispatch of the Products, the Products must be returned to our suppliers. 
              In this case, this may be done by emailing us at <a href="mailto:support@withflitch.com">support@withflitch.com</a> to 
              receive further instructions. However, in this case, the return or collection will bear no cost to you.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.15 Please contact Customer Services using the contact information below at clause 25 to arrange a collection of 
              the Products.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              9.16 You must take care when opening packaging that the Products were delivered in and carefully re-pack the Products 
              in the original (or similar) packaging prior to returning the Products to our suppliers.
            </span>
          </p>
          <Space />
          <SectionHeader label="10. Sales promotions" />
          <Space />
          <p className="c5">
            <span className="c0">
              10.1 From time to time, we may run sales promotions or offer other incentives (“Promotions”) to purchase particular 
              Products from us. When we are running a Sitewide Promotion, this discount cannot be combined with other discounts, 
              including Welcome Gift Cards or Referral Discounts.
            </span>
          </p>
          <Space />
          <SectionHeader label="11. Vouchers, gift vouchers and discount codes" />
          <Space />
          <p className="c5">
            <span className="c0">
              11.1 Gift vouchers are not currently available for purchase, but vouchers may be offered or provided by Flitch 
              as prizes, through promotions, or in other circumstances.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.2 The voucher may be: <br/>
              (a) emailed to the recipient; or <br/>
              (b) printed.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.3 Unless otherwise stated, vouchers are valid for one year from the date of issue and cannot be extended.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.4 The value of any voucher you use will be deducted from the total cost of your order. The voucher can only 
              be redeemed once and you will not be able to use any unused balance on a future order. There is no minimum spend 
              required and the voucher can be redeemed against any purchase including delivery charges.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.5 Vouchers may be provided in any denomination.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.6 Any costs incurred on the order (including delivery, return, and collection costs) will be calculated based 
              on the full costs of the order, before the voucher or discount code is applied.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.7 Vouchers and/or discount codes must not be posted on consumer forums and we may invalidate vouchers or discount 
              codes posted in such a way.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.8 Only one discount code can be applied to any one order, this includes any promotional codes or offers 
              (but you may also use any voucher that you have been given).
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.9 Unless otherwise stated, discount codes may only be used once and they cannot be used in conjunction with 
              any other voucher codes or offers (e.g. cashback).
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.10 If a discount code requires a minimum spend and you return some of the Products within your order such that 
              your order falls below the minimum spend, the discount will no longer apply to the remainder of your order.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.11 When you use a voucher and/or discount code you warrant to us that you are the duly authorised recipient of the 
              voucher or discount code (as applicable) and that you are using it in accordance with these Terms of Sale, lawfully 
              and in good faith. If we believe that a voucher and/or discount code is being used in breach of these Terms of Sale, 
              unlawfully or in bad faith, we may reject or cancel the voucher or discount code (as applicable).
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.12 If you have any difficulty redeeming your voucher or discount code or have any questions then please contact us for 
              further assistance.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              11.13 Any order for Products made using a voucher or discount code will be governed by these Terms of Sale.
            </span>
          </p>
          <Space />
          <SectionHeader label="12. Your information" />
          <Space />
          <p className="c5">
            <span className="c0">
              12.1 We process information about you in accordance with our Privacy Policy.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              12.2 In order to make purchases on Flitch you will be required to provide your personal details. 
              You&lsquo;ll need to provide your real name, phone number, e-mail address and other requested information 
              as well as your payment details.
            </span>
          </p>
          <Space />
          <SectionHeader label="13. Our liability to you" />
          <Space />
          <p className="c5">
            <span className="c0">
              13.1 We are under a legal duty to supply Products that are in conformity with our contract with you.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              13.2 Our Products are designed for residential use and we do not make any guarantees or warranties about 
              their performance in a commercial environment. We will have no liability to you for any loss of profit, 
              loss of business, business interruption or loss of business opportunity.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              13.3 If we fail to comply with a contract between you and us, we will be responsible for loss or damage you 
              suffer that is a foreseeable result of our breach of these Terms of Sale or our negligence, but we will not 
              be responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if the loss or 
              damage was an obvious consequence of our breach or if the loss or damage was contemplated by you and us at the 
              time we entered into the contract.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              13.4 The maximum loss or damage we will be responsible for under clause 13.3 is limited to the price you have 
              paid us for the Product to which the loss or damage you suffer relates.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              13.5 We do not in any way exclude or limit our liability for: <br/>
              (a) death or personal injury caused by our negligence;<br/>
              (b) fraud or fraudulent misrepresentation;<br/>
              (c) any liability imposed under the Consumer Rights Act 2015 that is not permitted to be 
              excluded or restricted under section 31 of the Consumer Rights Act 2015; and <br/>
              (d) defective products under the Consumer Protection Act 1987.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              13.6 This does not affect your statutory rights. Advice about your statutory rights is available from your 
              local Citizens' Advice Bureau or Trading Standards office.
            </span>
          </p>
          <Space />
          <SectionHeader label="14. Events outside our control" />
          <Space />
          <p className="c5">
            <span className="c0">
              14.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our 
              obligations under a contract that is caused by events outside our reasonable control including acts of god, 
              fire, flood, severe weather, explosions, war (whether declared or not), acts of terrorism or acts of local or 
              central Government or of any other competent authorities provided that: <br/>
              (a) we will take reasonable steps to prevent or minimise the failure or delay; <br/>
              (b) in the event of failure to perform, we will refund you all amounts paid under the affected contract; and <br/>
              (c) in the event of substantial delay, you may cancel your order by notifying us and we will refund you all <br/>
              amounts paid under the affected contract.
            </span>
          </p>
          <Space />
          <SectionHeader label="15. Resolving disputes" />
          <Space />
          <p className="c5">
            <span className="c0">
              15.1 If you have a dispute with us relating to our contract with you, in the first instance please contact us 
              at <a href="mailto:support@withflitch.com">support@withflitch.com</a> and attempt to resolve the dispute with us informally. 
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              15.2 In the unlikely event that we are not able to resolve the dispute informally, please note that the Furniture & Home 
              Improvement Ombudsman operates an online dispute resolution platform for UK consumers to submit disputes arising from 
              online purchases, which can be accessed at <a href="https://www.fhio.org/dispute">www.fhio.org/dispute</a>. You will also 
              always have the option of resolving the dispute using court action.
            </span>
          </p>
          <Space />
          <SectionHeader label="16. Each of these terms operates independently" />
          <Space />
          <p className="c5">
            <span className="c0">
              16.1 Each of the provisions of these Terms of Sale operates separately. If any court or any other relevant 
              authority decides that any of these paragraphs are unlawful or unenforceable, the remaining paragraphs will 
              remain in full force and effect.
            </span>
          </p>
          <Space />
          <SectionHeader label="17. Updating these terms of sale" />
          <Space />
          <p className="c5">
            <span className="c0">
              17.1 We may modify or update these Terms of Sale from time to time for reasons including: <br/>
              (a) changes in how our business operates; <br/>
              (b) changes in the legal or regulatory requirements that we must comply with; or  <br/>
              (c) changes in how we accept payment from you. However, any order of Products by you will be governed by 
              the terms and conditions available on our site at the time you place the order.
            </span>
          </p>
          <Space />
          <SectionHeader label="18. Rights of third parties" />
          <Space />
          <p className="c5">
            <span className="c0">
              18.1 Any Contract made between you and us is only made between you and us. No third party will have any 
              rights to enforce any of its terms.
            </span>
          </p>
          <Space />
          <SectionHeader label="19. Law and Jurisdiction" />
          <Space />
          <p className="c5">
            <span className="c0">
              19.1 These Terms of Sale are governed by English law. This means that a contract for the purchase 
              of Products made through our site and any dispute or claim arising out of or in connection with it 
              will be governed by English law.
            </span>
          </p>
          <Space />
          <p className="c5">
            <span className="c0">
              19.2 You can bring legal proceedings in respect of this contract in the English courts. However, 
              if you are a resident of Scotland, you may also bring proceedings in Scotland and if you are a 
              resident in Northern Ireland, you may also bring proceedings in Northern Ireland.
            </span>
          </p>
          <Space />
          <SectionHeader label="20. Contacting you" />
          <Space />
          <p className="c5">
            <span className="c0">
              20.1 If we have to contact you or give you notice in writing, we will do so by e-mail or by pre-paid 
              post to the address you provide to us in your order.
            </span>
          </p>
          <Space />
          <SectionHeader label="21. Contact us" />
          <Space />
          <p className="c5">
            <span className="c0">
              21.1 If you have any queries about these Terms of Sale, please send contact our Customer Services team. 
              We&lsquo;ll be here to help.
            </span>
          </p>
          <Space />
        </>
      </TermsOfServiceWrapper>
    </FaqsPageContainer>
    <Footer />
    <BottomFooter />
  </FaqsPageWrapper>
);

export default TosPage;

