import React, { createContext, useContext, FC } from "react";
import { Category } from "./categoryData";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { isSetStateActionSetter } from "../utils/helpers";

import * as defaults from "./filterDefaultValues";
import { useState } from "react";

interface FilterContextProps {
  dontApplyFiltersToRecs: boolean;
  makeFiltersNotApplyToRecs: VoidFunction;
  filterOptions: CategoryToFilterOpts;

  /**
   * Welp, this is the one we're keeping. Anything else loses the correlation between C and N.
   */
  setProp: <C extends CategoryUnion, N extends keyof CategoryToFilterOpts[C]>(
    cat: C,
    name: N
  ) => (
    setStateAction: React.SetStateAction<CategoryToFilterOpts[C][N]>
  ) => void;

  resetFilters: VoidFunction;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

const defaultFilterOpts: CategoryToFilterOpts = {
  [Category.diningChair]: defaults.diningchairDefaultValues,
  [Category.diningTable]: defaults.diningtableDefaultValues,
  [Category.barstool]: defaults.barstoolDefaultValues,
  [Category.coffeeTable]: defaults.coffeeTableDefaultValues,
  [Category.consoleTable]: defaults.consoleTableDefaultValues,
  [Category.sideboard]: defaults.sideboardDefaultValues,
  [Category.sidetable]: defaults.sidetableDefaultValues,
  [Category.bedsideTable]: defaults.bedsidetableDefaultValues,
  [Category.chestofdrawers]: defaults.chestofdrawersDefaultValues,
  [Category.displaycabinet]: defaults.displaycabinetDefaultValues,
  [Category.shelving]: defaults.shelvingDefaultValues,
  [Category.tvstand]: defaults.tvStandDefaultValues,
  [Category.wardrobe]: defaults.wardrobeDefaultValues,
  [Category.sofa]: defaults.sofaDefaultValues,
  [Category.armchair]: defaults.armchairDefaultValues,
  [Category.bed]: defaults.bedDefaultValues,
  [Category.shop]: defaults.shopDefaultValues,
};

const getDefaultFilterValue = (): FilterContextProps => {
  /**
   * This is used when in SQ we can see that for the selected filters there will
   * be no matching Recs items. The user has the option to ignore the filters
   * for Recs.
   * The logic being that since filters are still applied to SQ, they will only
   * be able to select items that do match their chosen filters. So that there
   * is an implicit filtering rather than an explicit one. Speak to @Uriel for
   * more information, he's the one who made me do this.
   */
  const [dontApplyFiltersToRecs, dontApplyFiltersToRecsSet] = useState(false);
  const makeFiltersNotApplyToRecs = () => dontApplyFiltersToRecsSet(true);

  const [filterOptions, setFilterOptions] =
    useLocalStorage<CategoryToFilterOpts>(
      "filterOptions",
      defaultFilterOpts,
      "4"
    );

  const setPropDirectly = <
    C extends CategoryUnion,
    N extends keyof CategoryToFilterOpts[C]
  >(
    cat: C,
    name: N,
    setStateAction: React.SetStateAction<CategoryToFilterOpts[C][N]>
  ): void => {
    setFilterOptions((opts) => ({
      ...opts,
      [cat]: {
        ...opts[cat ?? "shop"],
        [name]: isSetStateActionSetter(setStateAction)
          ? setStateAction(opts[cat ?? "shop"][name])
          : setStateAction,
      },
    }));

    // Every change to a filter should reset this to false
    dontApplyFiltersToRecsSet(false);
  };

  const setProp =
    <C extends CategoryUnion, N extends keyof CategoryToFilterOpts[C]>(
      cat: C,
      name: N
    ) =>
    (setStateAction: React.SetStateAction<CategoryToFilterOpts[C][N]>) =>
      setPropDirectly(cat, name, setStateAction);

  const resetFilters = () => {
    dontApplyFiltersToRecsSet(false);
    setFilterOptions(defaultFilterOpts);
  };

  return {
    dontApplyFiltersToRecs,
    makeFiltersNotApplyToRecs,
    filterOptions,
    setProp,
    resetFilters,
  };
};

const defaultCtx = {
  dontApplyFiltersToRecs: true,
  makeFiltersNotApplyToRecs: () => {},
  filterOptions: defaultFilterOpts,
  resetFilters: () => {}
} as FilterContextProps;

export const useFilterCtx = () => {
  const ctx = useContext(FilterContext);
  if (ctx === undefined) {
    //throw new Error(`useFilterCtx must be within FilterProvider`);
    return defaultCtx;
  } else {
    return ctx;
  }
};

export const FilterProvider: FC<{children?: React.ReactNode}> = ({ children }) => {
  const filterCtxValue = getDefaultFilterValue();

  return (
    <FilterContext.Provider value={filterCtxValue}>
      {children}
    </FilterContext.Provider>
  );
};
