export class CategoryInfo {
  constructor(
    public name: CategoryUnion,
    public displayName: string = name,
    private displayPlural?: string
  ) {}

  get plural() {
    return this.displayPlural ?? this.displayName + "s";
  }
}

export const categoryData: AllCatMap<CategoryInfo> = {
  diningchair: new CategoryInfo("diningchair", "dining chair"),
  diningtable: new CategoryInfo("diningtable", "dining table"),
  barstool: new CategoryInfo("barstool"),
  coffeetable: new CategoryInfo("coffeetable", "coffee table"),
  consoletable: new CategoryInfo("consoletable", "console table"),
  sideboard: new CategoryInfo("sideboard"),
  sidetable: new CategoryInfo("sidetable"),
  bedsidetable: new CategoryInfo("bedsidetable", "bedside table"),
  chestofdrawers: new CategoryInfo(
    "chestofdrawers",
    "chest of drawers",
    "chests of drawers"
  ),
  displaycabinet: new CategoryInfo("displaycabinet", "display cabinet"),
  shelving: new CategoryInfo("shelving", "shelf", "shelves"),
  tvstand: new CategoryInfo("tvstand", "TV stand"),
  wardrobe: new CategoryInfo("wardrobe"),
  sofa: new CategoryInfo("sofa", "sofa"),
  armchair: new CategoryInfo("armchair"),
  bed: new CategoryInfo("bed"),
  shop: new CategoryInfo("shop"),
};

export enum Category {
  diningChair = "diningchair",
  diningTable = "diningtable",
  barstool = "barstool",
  coffeeTable = "coffeetable",
  consoleTable = "consoletable",
  sideboard = "sideboard",
  sidetable = "sidetable",
  bedsideTable = "bedsidetable",
  chestofdrawers = "chestofdrawers",
  displaycabinet = "displaycabinet",
  shelving = "shelving",
  tvstand = "tvstand",
  wardrobe = "wardrobe",
  sofa = "sofa",
  armchair = "armchair",
  bed = "bed",
  shop = "shop"
}
