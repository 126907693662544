import React, { FC, RefObject, useEffect } from "react";
import styled, { css } from "styled-components";
import { PieChart } from "react-minimal-pie-chart";

import { colors, fontSizes } from "../../styles/globalStyled";
import {
  categoryReadableNames,
  getGreyBgOrFbThumbnail,
  getProductUrl,
  normalizePriceLabel,
  forcedPluralize,
  toFixedRange,
  toFixedRangeIfNotInteger,
} from "../../utils/helpers";
import { useInView } from "react-intersection-observer";
import { initial, range } from "lodash";
import { breakpoints } from "../../styles/responsive";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-regular-svg-icons";

type RecsOrProductPage = "RecsPage" | "ProductPage";

interface RecsOrProductPageProps {
  recsOrProductPage: RecsOrProductPage;
}

export const customVerticalScrollbar = css`
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.greyMedium};
    border-radius: 10px;
  }
`;

export const RecommendationsPageWrapperNew = styled.div`
  display: flex;
  scroll-snap-type: x mandatory;
  padding-top: var(--navbar-height-mobile);
  overflow-x: hidden;
  position: relative;

  /* @media (max-width: ${breakpoints.mobile}) {
    padding-top: calc(var(--navbar-height-mobile) * 2);
  } */

  @media (min-width: ${breakpoints.mobile}) {
    padding-top: var(--navbar-height);
    &::-webkit-scrollbar {
      height: 0; // height for horizontal scrollbars!
    }
  }
`;

export const recItemClassName = "rec-item";

export const RecItemStyled = styled.div`
  scroll-snap-align: start;
  min-width: 100%; // No idea why a simple 'width' won't do it 🤷‍♀️

  display: flex;
  position: relative;
  flex-direction: column;

  opacity: 1;

  transition-property: opacity;
  transition-duration: 1s;

  /**
   * Don't set the height on iOS because it fucks up scrolling for some reason.
   * It makes the page very sensitive to the slightest horizontal movement in a
   * regular vertical scroll.
   */
  @supports not (-webkit-touch-callout: none) {
    /* CSS specific to non-iOS devices */
    height: var(--height-under-navbar);
  }

  @media (max-width: ${breakpoints.mobile}) {
    @supports not (-webkit-touch-callout: none) {
      height: var(--height-under-navbar-mobile);
    }
  }

  @media (min-width: ${breakpoints.mobile}) {
    height: initial;
    flex-direction: row;
  }
  
  // Class name used for React exit transition
  &.${recItemClassName}-exit-active {
    opacity: 0;
  }
`;

export const ItemImagesContainerStyled = styled.div`
  scroll-snap-type: y mandatory;
  min-height: 100vw;
  height: 100vw;
  width: 100vw;
  overflow-y: auto;
  position: relative;
  /* height: var(--height-under-navbar-mobile); */

  @media (min-width: ${breakpoints.mobile}) {
    width: 50%;
    min-height: initial;
    height: var(--height-under-navbar);

    scroll-snap-type: initial;

    ${customVerticalScrollbar}
  }
`;

interface Img {
  src: string;
}

export const ItemImageStyled = styled.div<Img>`
  scroll-snap-align: start;
  width: 100%;
  height: 100vw;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${({ src }) => src});

  @media (min-width: ${breakpoints.mobile}) {
    height: initial;
    background-size: cover;
    padding-top: 100%;
  }
`;

interface ItemImageProps extends Img {
  rootRef: RefObject<HTMLElement>;
  onVisibleImage: VoidFunction;
}

export const ItemImage: FC<ItemImageProps> = ({
  src,
  rootRef,
  onVisibleImage
}) => {
  const { ref, inView } = useInView({ root: rootRef.current, threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      onVisibleImage();
    }
  }, [inView]);

  return <ItemImageStyled ref={ref} src={src}/>;
};

/**
 * On mobile, ImagesScrollDotsStyled is inside ContentOverlay because if it's
 * inside ItemImagesContainer it can't display on top of ContentOverlay.
 * Hence the styling for mobile renders it inside ContentOverlay but on desktop
 * it renders inside ItemImagesContainer.
 */
const ImagesScrollDotsStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 10px;

  // Have to position it from inside the ContentOverlay (on mobile) because too hard to position it fixedly inside images container
  top: 0;
  transform: translateY(calc((50vw + 50%) * -1));
  width: 10px;

  @media (min-width: ${breakpoints.mobile}) {
    position: sticky;
    top: initial;
    bottom: 50%;
    left: 20px;
    transform: translateY(50%);
  }

  --dot-size: 9px;
  --dot-size-max: 12px;

  @media (min-width: ${breakpoints.mobile}) {
    --dot-size: 10px;
    --dot-size-max: 13px;
  }

  .scroll-dot {
    height: var(--dot-size);
    width: var(--dot-size);
    background-color: ${colors.greyDark};
    box-shadow: var(--box-shadow);
    border-radius: 100%;
    border: 2px solid white;
    margin: calc(var(--dot-size-max) - var(--dot-size));

    transition: height 0.5s, width 0.5s, margin 0.5s;

    &.scroll-dot--active {
      height: var(--dot-size-max);
      width: var(--dot-size-max);
    }
  }
`;

interface ImagesScrollDotsProps {
  dotsCount: number;
  activeDotIndex: number;
}

export const ImagesScrollDots: FC<ImagesScrollDotsProps> = ({
  dotsCount,
  activeDotIndex,
}) => (
  <ImagesScrollDotsStyled>
    {range(0, dotsCount).map((i) => (
      <div
        key={i}
        className={`scroll-dot ${
          i === activeDotIndex ? "scroll-dot--active" : ""
        }`}
      />
    ))}
  </ImagesScrollDotsStyled>
);

export const basicButtonStyle = css`
  cursor: pointer;
  border: none;
  text-align: center;
  display: block;
  color: white;

  &:hover {
    color: white;
  }
`;

export const FilterButton = styled.button`
  ${basicButtonStyle}

  background-color: white;
  box-shadow: var(--box-shadow);
  position: fixed;
  top: calc(var(--navbar-height) + 10px);
  right: 0;
  width: 50px;
  height: 50px;
  z-index: 1;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;

  @media (max-width: ${breakpoints.mobile}) {
    /* z-index: 2;
    -webkit-overflow-scrolling: auto !important; */
    top: calc(var(--navbar-height) * 2 - 5px);
  }
`;

export const ContentOverlay = styled.div`
  font-size: 3rem;
  position: relative;
  box-shadow: var(--box-shadow);
  /* height: var(--height-under-navbar-mobile); */

  @media (min-width: ${breakpoints.mobile}) {
    height: var(--height-under-navbar);
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    justify-content: space-between;

    #last {
	    margin-top: auto;
    }

    ${customVerticalScrollbar}
  }
`;

export const ContentOverlayTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
`;

/**
 * On mobile, ActionPanelContainer is inside ContentOverlay because if it's
 * inside ItemImagesContainer it can't display on top of ContentOverlay.
 * Hence the styling for mobile renders it inside ContentOverlay but on desktop
 * it renders inside ItemImagesContainer.
 */
export const ActionPanelContainer = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;

  @media (min-width: ${breakpoints.mobile}) {
    top: initial;
    transform: none;

    position: sticky;
    bottom: 50px;
  }
`;

export const ActionPanel = styled.div`
  border-radius: 100px;
  padding: 5px;
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
  background: white;
`;

const sizes = { sm: 40, lg: 50 };

interface ActionButtonProps {
  color: string;
  size: "sm" | "lg";
  direction?: "left" | "right";
  disabled?: boolean;
}

export const ActionLeftIconCss = css`
  position: relative;
  top: -20%;
  left: -15%;
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
`;

export const ActionRightIconCss = css`
  position: relative;
  top: -20%;
  left: -25%;
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
`;

export const ActionButton = styled.button<ActionButtonProps>`
  ${basicButtonStyle}

  margin: 5px;
  border-radius: 100%;
  width: ${({ size }) => sizes[size]}px;
  height: ${({ size }) => sizes[size]}px;
  background-color: ${({ color, disabled }) =>
    disabled ? colors.greyPale : color};

  & > svg {
    // Child will be a font awesome icon
    transform: translate(
      ${({ direction }) => (direction ? (direction === "left" ? -4 : 4) : 0)}px,
      0
    );
  }
`;

const contentSectionStyle = css`
  padding: 0 15px;

  @media (min-width: ${breakpoints.mobile}) {
    max-width: 700px;
  }
`;

const ItemContentHeaderStyled = styled.section`
  font-size: ${fontSizes.medium};
  text-align: center;
  margin: 25px 0 3px;

  ${contentSectionStyle}

  .brand-header {
    font-family: "Avenir";
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 25px;

    @media (min-width: ${breakpoints.mobile}) {
      padding-top: 0;
    }
  }

  .item-name {
    font-family: "Avenir";
  }

  .single-price {
    font-weight: bold;
  }

  .single-rrp {
    font-weight: bold;
    color: ${colors.greyMedium};
    text-decoration: line-through;
  }

  .out-of-stock {
    color: ${colors.red};
    padding: 5px;
    border: 2px solid ${colors.red};
    margin: 10px 0;
    display: inline-block;
    line-height: 1;
  }
`;

interface ItemContentHeaderProps {
  item: IFurnitureItem;
}

interface ItemDescriptionProps {
  item: IFurnitureItem;
  isFolded: boolean;
  switchFolded: () => void;
}

export const ItemContentHeader: FC<ItemContentHeaderProps> = ({ item }) => (
  <ItemContentHeaderStyled>
    <h2 className="brand-header">{item.brand}</h2>
    <h1 className="item-name">
      {item.name}
      {item.numberInSet > 1 ? `, Set of ${item.numberInSet}` : ""}
    </h1>
    {item.stockData ? (
      <p>
        {item.stockData.rrp && item.stockData.price < item.stockData.rrp && (
          <span className="single-rrp">
            {normalizePriceLabel(item.stockData.rrp) + " "}
          </span>
        )}
        <span className="single-price">
          {normalizePriceLabel(item.stockData.price)}
        </span>
        {item.numberInSet > 1
          ? ` / ${normalizePriceLabel(
              item.stockData.pricePerItem
            )} per item`
          : ""}
      </p>
    ) : (
      <p className="out-of-stock">Out of Stock</p>
    )}
  </ItemContentHeaderStyled>
);

interface ColourVariantPieProps {
  variant: IdAndColours;
  isSelected: boolean;
  onClick: Setter<ItemId>;
}

const ColourVariantPieContainer = styled.div`
  margin: 5px;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
`;

const ColourVariantPie: FC<ColourVariantPieProps> = ({
  variant,
  isSelected,
  onClick,
}) => (
  <ColourVariantPieContainer onClick={() => onClick(variant.id)}>
    <PieChart
      data={variant.colours.map((c) => ({ value: 1, color: c }))}
      style={{
        height: 35,
        width: 35,
        borderRadius: "100%",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: isSelected ? colors.greyMedium : colors.greyPale,
      }}
    />
  </ColourVariantPieContainer>
);

const ColourVariantsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

interface ColourVariantsProps {
  itemOwnColours: IdAndColours;
  variants: IdAndColours[];
  selectedVariantId: ItemId;
  onClick: Setter<ItemId>;
}

export const ColourVariants: FC<ColourVariantsProps> = ({
  itemOwnColours,
  variants,
  selectedVariantId,
  onClick,
}) => {
  //console.log([itemOwnColours, ...variants].map(i => i.colours))
  
  return (
  <ColourVariantsStyled>
    {[itemOwnColours, ...variants].sort().map((availClr) => (
      <ColourVariantPie
        key={availClr.id}
        variant={availClr}
        isSelected={availClr.id === selectedVariantId}
        onClick={onClick}
      />
    ))}
  </ColourVariantsStyled>
)};

export const MoreColourVariantsAvailable = styled.p`
  font-size: ${fontSizes.verySmall};
  text-align: center;
  max-width: 200px;
`;

export const Line = styled.hr`
  height: 1px;
  background-color: ${colors.greyDark};
  width: 250px;
  margin: 15px;

  ${contentSectionStyle}
`;

export const WideLine = styled.hr`
  height: 1px;
  background-color: ${colors.greyPale};
  width: auto;
  
  ${contentSectionStyle}
`;

const DescriptionContentStyled = styled.section<{folded: boolean}>`
  ${contentSectionStyle}

  margin: 25px 0;

  span {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .brand-says {
    font-size: ${fontSizes.medium};
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: ${({folded}) => folded ? "0" : "12px"};
  }

  .fold-button {
    position: relative;
    top: ${({folded}) => +!folded * -6}px;
    left: 15px;
    border: none;
    background: none;
    color: ${colors.greyDark};
    cursor: pointer;
    font-size: ${fontSizes.smaller}
  }

  .description-body {
    font-size: ${fontSizes.small};
    display: ${({ folded }) => folded ? "none" : "block"};
  }
`;


export const DescriptionContent: FC<ItemDescriptionProps> = ({ item, isFolded, switchFolded }) => (
  <DescriptionContentStyled folded={isFolded}>
    <span>
      <h4 className="brand-says">
        {item.brand} says
      </h4>
      <button className="fold-button" onClick={switchFolded}>
        <FontAwesomeIcon icon={isFolded ? faPlusSquare : faMinusSquare} size="1x" />
      </button>
    </span>
    <p className="description-body">{item.description}</p>
  </DescriptionContentStyled>
);

const ShopDescriptionStyled = styled.div`
  padding: 10%;
  padding-top: 50%;

  @media (max-width: ${breakpoints.mobile}) {
    padding-top: 20%;
  }

  .description-body {
    font-size: ${fontSizes.large};
    text-align: center;
    position: absolute;
    top: 40%;
    left:10%;
    right: 10%;

    @media (max-width: ${breakpoints.mobile}) {
      position: static;
      top: auto;
      left: auto;
      right: auto;
      font-size: ${fontSizes.medium};
      margin-bottom: 50%;
    }
  }
`;

export const ShopDescription: FC<{category: string}> = ({category}) => {
  const name = forcedPluralize(categoryReadableNames[category])
    .toLowerCase()
    .replace("tv", "TV")
    .replace("shelvings", "shelving units");

  const caption = `Want to see more ${name}?`;
  const description = `Head over to our shop section to browse thousands of ${name} from 75+ retailers`;

  return (
    <ShopDescriptionStyled>
      <p className="description-body">
        {caption}
        <br/>
        {description}
      </p>
    </ShopDescriptionStyled>);
}

const MoreInfoStyled = styled.section`
  margin: 25px 0;

  ${contentSectionStyle}

  .product-info {
    font-size: ${fontSizes.medium};
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .product-info-item {
    font-size: ${fontSizes.small};
    text-align: center;
  }

  .dimension-label {
    font-weight: bold;
  }
`;

export const MoreInfo: FC<ItemContentHeaderProps> = ({ item }) => (
  <MoreInfoStyled>
    <h4 className="product-info">Product info</h4>
    {item.height && (
      <p className="product-info-item">
        <span className="dimension-label">Height:</span>{" "}
        {toFixedRange(item.height, 1)}cm
      </p>
    )}
    {item.width && (
      <p className="product-info-item">
        <span className="dimension-label">Width:</span>{" "}
        {toFixedRange(item.width, 1)}
        cm
      </p>
    )}
    {item.depth && (
      <p className="product-info-item">
        <span className="dimension-label">Depth:</span>{" "}
        {toFixedRange(item.depth, 1)}
        cm
      </p>
    )}
    {item.weight && (
      <p className="product-info-item">
        <span className="dimension-label">Weight:</span> {item.weight}kg
      </p>
    )}
  </MoreInfoStyled>
);

const YouMayAlsoLikeStyled = styled.section`
  width: 100%;
  margin: 25px 0;

  ${contentSectionStyle}

  .you-may-also-like-title {
    font-size: ${fontSizes.medium};
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  .you-may-also-like-item-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}
`;

const youMayAlsoLikeItemsToShow = 4;

const YouMayAlsoLikeItem = styled(Link)<{ item: IFurnitureItem }>`
  --size: calc((100% / ${youMayAlsoLikeItemsToShow}) - 20px);

  width: var(--size);
  padding-top: var(--size);
  box-shadow: var(--box-shadow);
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ item }) => getGreyBgOrFbThumbnail(item)});
  background-size: cover;


  ${basicButtonStyle}
`;

interface YouMayAlsoLikeProps {
  items: IFurnitureItem[];
  category: CategoryUnion;
}

export const YouMayAlsoLike: FC<YouMayAlsoLikeProps> = ({
  items,
  category,
}) => {
  const navigate = useNavigate();

  return (
    <YouMayAlsoLikeStyled>
      <h4 className="you-may-also-like-title">You may also like</h4>
      <div className="you-may-also-like-item-container">
        {items.slice(0, youMayAlsoLikeItemsToShow).map((item) => (
          <YouMayAlsoLikeItem
            key={item.id}
            item={item}
            to={getProductUrl(item)}
          />
        ))}
      </div>
    </YouMayAlsoLikeStyled>
  );
};

export const BuyNowButtonContainer = styled.div<RecsOrProductPageProps>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  padding: 10px;
  margin-top: 25px;
  box-shadow: var(--box-shadow);
  background-color: white;
  display: flex;
  
  @media (min-width: ${breakpoints.mobile}) {
    width: 100%;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    display: none;
  }
`;

const buttonRowFontSizeRem = 1.75;

export const sharedLinkStyle = css`
  font-size: ${buttonRowFontSizeRem}rem;
  line-height: ${buttonRowFontSizeRem}rem;

  padding: 10px;
  color: white;
  box-shadow: var(--box-shadow);

  @supports (-webkit-touch-callout: none) {
    border-radius: 5px;
  }
`;

export const BuyNowLinkStyled = styled.a<{isActive: boolean}>`
  ${basicButtonStyle}
  ${sharedLinkStyle}
  
  background-color: ${({isActive}) => isActive ? colors.green : colors.greenDisabled};
  
  flex-basis: 20px;
  flex-grow: 2;

  pointer-events: ${({isActive}) => isActive ? "initial" : "none"};

  span.buy-now-text {
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.mobile}) {
    -webkit-padding-before: 2%;
  }
`;

export const ShareLinkStyled = styled.a`
  ${basicButtonStyle}
  ${sharedLinkStyle}

  margin-right:10px;

  justify-content: center;
  align-items: center;

  background-color: ${colors.greyDark};
  flex-basis: 38px;
  .showX {
    opacity: 1;
  }
`;

export const ShareIcon = styled.img`
  position: relative;
  padding:10px;
`;

export const IOSBuyNowButtonContainer = styled.div<RecsOrProductPageProps>`
  display: none;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    display: flex;
    margin-bottom: 25px;
    -webkit-padding-before: 5%;
  }
`;

export const SaveButtonStyled = styled.button<{ isSaved: boolean, padding: number }>`
  ${basicButtonStyle}

  font-size: ${buttonRowFontSizeRem}rem;
  line-height: ${buttonRowFontSizeRem}rem;
  background-color: ${({ isSaved }) =>
    isSaved ? colors.greyDark : colors.orange};
  padding: ${({padding}) => padding}px;
  color: white;
  font-weight: bold;
  margin-right: 10px; // for gap between it and buynow button
  box-shadow: var(--box-shadow);

  transition: all 0.35s;

  flex-basis: auto;
  //flex-grow: 1;

  span.more-text {
    // Hide more text on mobile
    display: none;

    @media (min-width: ${breakpoints.tablet}) {
      display: initial;
    }
  }

  @media (min-width: ${breakpoints.mobile}) {
    -webkit-padding-before: 2%;
  }

  @supports (-webkit-touch-callout: none) {
    border-radius: 5px;
  }
`;

export const SaveButtonTextStyled = styled.span`
  font-size: ${fontSizes.small};
  font-weight: normal;
`;
