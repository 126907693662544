import { FC } from "react";
import { seoficateTag } from "../../../../utils/helpers";
import { SubmenuContainer, TabContent } from "../MobileHamburgerStyles";
import { MenuGoBack, MenuOption } from "./MenuOption";

interface Props {
  items: string[];
  itemDecorator?: (item: string) => string;
  onItemClick: () => void;
  onGoBack: () => void;
  goBackLabel: string;
  visible: boolean;
  urlPrefix: string;
  urlPostfix: string | undefined;
}

export const Submenu: FC<Props> = ({
  items, itemDecorator, onGoBack, goBackLabel, onItemClick, visible, urlPrefix, urlPostfix
}) => (
  <SubmenuContainer isOpen={visible}>
    <TabContent>
      <MenuGoBack label={goBackLabel} onClick={onGoBack}/>
      {items.map(item => 
        <MenuOption 
          key={item}
          label={itemDecorator ? itemDecorator(item) : item}
          to={urlPostfix ? `/${urlPrefix}/${seoficateTag(item)}/${urlPostfix}` : `/${urlPrefix}/${seoficateTag(item)}`}
          onClick={onItemClick}
        />)}
    </TabContent>
  </SubmenuContainer>
);