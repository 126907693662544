import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { categoryReadableNames } from "../../../../utils/helpers";
import { MenuProps } from "../MobileHamburger";
import { TabContent } from "../MobileHamburgerStyles";
import { MenuExpansion, MenuOption } from "./MenuOption";
import { Submenu } from "./Submenu";

const groupedCats: [string, CategoryUnion[]][] = [
  ["Sofas and Armchairs", ["armchair", "sofa"]],
  ["Seating", ["armchair", "sofa", "barstool", "diningchair"]],
  ["Tables", ["bedsidetable", "coffeetable", "consoletable", "diningtable", "sidetable"]],
  ["Storage", ["bedsidetable", "chestofdrawers", "displaycabinet", "sideboard", "shelving", "tvstand", "wardrobe"]]
];

export const SqTab: FC<MenuProps> = ({closeMenu, isOpen}) => {
  const [ activeGroup, setActiveGroup ] = useState<[string, CategoryUnion[]]|undefined>(undefined);
  const navigate = useNavigate();

  const goToSq = (cat: string) => {
    closeMenu();
    navigate(`/style-quiz/${cat}/filter`)
  };

  return (
    <TabContent>
      {groupedCats.map(([name, group]) => (
        <MenuExpansion 
          key={name}
          label={name}
          onClick={() => setActiveGroup([name, group])}
        />
      ))}
      <MenuOption 
        key="Beds"
        label="Beds"
        onClick={closeMenu}
        to={"/style-quiz/bed/filter"}
      />
      <Submenu 
        visible={!!activeGroup && isOpen}
        items={activeGroup?.[1] ?? []}
        urlPrefix="style-quiz"
        urlPostfix="filter"
        onItemClick={closeMenu}
        onGoBack={() => setActiveGroup(undefined)}
        goBackLabel={activeGroup?.[0] ?? ""}
        itemDecorator={i => categoryReadableNames[i] ?? i}
      />
    </TabContent>
  );
};