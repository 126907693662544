import React, { useState } from "react";
import { default as ProfileMenuModal } from "react-modal";

import { AuthModal } from "../../AuthModal/AuthModal";
import {
  ProfileIconWrapper,
  PopupWrapper,
  PopupItem,
} from "./ProfileIconWrapper";
import { Modal as NewModal } from "../../../Modal";
import { useUserContext } from "../../../../store/userContext";
import { useNavigate, useResolvedPath } from "react-router-dom";
import {
  mdiAccount, 
  mdiAccountOutline} from "@mdi/js"
import { NavButton } from "../NavButton";

const popupStyle = {
  content: {
    top: "6.5rem",
    right: "2.5rem",
    left: "auto",
    padding: "0",
    borderBottomLeftRadius: "1rem",
    borderTopLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    overflow: "hidden",
    height: "15rem",
    maxWidth: "15rem",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.01)",
    zIndex: 4,
  },
};

ProfileMenuModal.setAppElement("#root");

export const ProfileIcon = () => {
  const navigate = useNavigate();
  const path = useResolvedPath("").pathname;

  const { user, signOut, authModalState, openAuthModal, closeAuthModal } =
    useUserContext();

  const [isPopupOpen, setIsPopUpOpen] = useState(false);
  const openPopup = () => setIsPopUpOpen(true);
  const closePopup = () => setIsPopUpOpen(false);

  const handleProfileIconClick = () => {
    if (user) {
      openPopup();
    } else {
      openAuthModal();
    }
  };

  const isInRecs = path.includes("/recommendations");
  const isInCats = path.includes("/category");

  const icon = user ? mdiAccount : mdiAccountOutline;

  return (
    <ProfileIconWrapper>
        <NavButton 
          onClick={handleProfileIconClick}
          iconPath={icon}
          iconSizeMobile={28}
        />
      {/*@ts-ignore*/}
      <ProfileMenuModal
        style={popupStyle}
        isOpen={isPopupOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closePopup}
      >
        <PopupWrapper>
          <PopupItem
            to="/activity"
            active={path === "/activity"}
          >
            Activity
          </PopupItem>
          <PopupItem 
            to="/profile"
            active={path === "/profile"}
          >
            Profile
          </PopupItem>
          <PopupItem 
            to="/"
            onClick={signOut}
          >
            Sign Out
          </PopupItem>
        </PopupWrapper>
      </ProfileMenuModal>
      <NewModal
        isOpen={authModalState.isOpen}
        onRequestClose={closeAuthModal}
        shouldCloseOnEsc={!isInRecs && !isInCats}
        shouldCloseOnOverlayClick={!isInRecs && !isInCats}
      >
        <AuthModal onRequestClose={isInRecs ? undefined : closeAuthModal} />
      </NewModal>
    </ProfileIconWrapper>
  );
};
