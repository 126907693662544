import React, {
  createContext,
  useContext,
  FC,
  useEffect,
  useState,
} from "react";
import { v4 } from "uuid";
import {
  setAuthorizationHeader,
  unsetAuthorizationHeader,
} from "../utils/setAuthorizationHeader";
import { auth } from "../firebase/firebase";
import { apiUrl } from "../config";
import Axios from "axios";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useRef } from "react";

export enum Reason {
  Login = "Login",
  RecsInteraction = "RecsInteraction",
  PersonalShopperFlow = "PersonalShopperFlow",
  RegisterBeforeViewingRecs = "RegisterBeforeViewingRecs",
  RegisterBeforeSQ = "RegisterBeforeSQ",
  RegisterBeforeCheckout = "RegisterBeforeCheckout",
  RegisterBeforeUpgrade = "RegisterBeforeUpgrade"
}

type AuthModalState =
  | { isOpen: true; reason: Reason }
  | { isOpen: false; reason: null };

/**
 * Request is idempotent.
 */
const saveNewUserInDbIdempotent = (customerId: string, firstName?: string) =>
  Axios.post<void>(`${apiUrl}/account`, { firstName, customerId });

interface UserContext {
  authFlowStatus: AuthFlowStatus;
  user: LocalUser | null;
  customerId: string;
  continueWithFirebaseSSO: (
    provider:
      | firebase.auth.FacebookAuthProvider
      | firebase.auth.GoogleAuthProvider
  ) => Promise<void>;
  registerWithFirebaseEmail: (
    email: string,
    pw: string,
    firstName: string
  ) => Promise<void>;
  loginWithFirebaseEmail: (email: string, pw: string) => Promise<void>;
  signOut: () => Promise<void>;
  deleteAccount: () => Promise<void>;
  setReferrerIfNotSet: StrSetter;
  authModalState: AuthModalState;
  openAuthModal: (reason?: Reason) => void;
  closeAuthModal: VoidFunction;
}

const UserContext = createContext({} as UserContext);

export const useUserContext = () => useContext(UserContext);

const customerIdKey = "customerId";

export const UserContextProvider: FC<{children?: React.ReactNode}> = ({ children }) => {
  const [authFlowStatus, setAuthFlowStatus] =
    useState<AuthFlowStatus>("NotStartedYet");
  const [user, setUser] = useState<LocalUser | null>(null);

  /**
   * This is a ref and not state because:
   * - It's not used in a view anywhere
   * - It needs to be updated synchronously without waiting for a rerender
   *    because it's set and then used in an API request immediately
   */
  const firstNameRef = useRef<string | null>(null);
  const resetFirstName = () => (firstNameRef.current = null);

  const [customerId] = useLocalStorage<string>(customerIdKey, v4(), "1");
  const [referrerId, setReferrerId] = useState<string | null>(null);

  const [authModalState, authModalStateSet] = useState<AuthModalState>({
    isOpen: false,
    reason: null,
  });

  const openAuthModal = (reason: Reason = Reason.Login) =>
    authModalStateSet({ isOpen: true, reason });

  const closeAuthModal = () =>
    authModalStateSet({ isOpen: false, reason: null });

  /**
   * `firstNameRef` should be set before this gets called (in non-SSO auths), so
   * that `saveNewUserInDbIdempotent` has a non-null `firstName` parameter to
   * send to the API.
   */
  const setUserAndOtherStuff = async (user: firebase.User) => {
    auth().setPersistence(auth.Auth.Persistence.LOCAL);

    const idToken = await user.getIdToken(true);
    const { email, photoURL, displayName, uid } = user;

    const localUser: LocalUser = { idToken, uid, email, displayName, photoURL };

    setAuthorizationHeader(idToken);
    setUser(localUser);
    await saveNewUserInDbIdempotent(customerId, firstNameRef.current ?? undefined);
    setAuthFlowStatus("Complete");
  };

  const unsetEverything = () => {
    setUser(null);
    unsetAuthorizationHeader();
    setAuthFlowStatus("Complete");
  };

  useEffect(() => {
    /**
     * This fires every time the user logs in/out to Firebase, so we don't need
     * to manually get the `.user` field returned by whatever login method we
     * used.
     */
    const unsub = auth().onIdTokenChanged((user) => {
      if (user) {
        setUserAndOtherStuff(user);
      } else {
        unsetEverything();
      }
    });

    return unsub;
  }, []);

  const continueWithFirebaseSSO = async (
    provider:
      | firebase.auth.FacebookAuthProvider
      | firebase.auth.GoogleAuthProvider
  ) => {
    resetFirstName();

    await auth().signInWithPopup(provider);
  };

  async function continueWithFirebaseEmail(
    email: string,
    pw: string,
    authType: "register",
    firstName: string
  ): Promise<void>;
  async function continueWithFirebaseEmail(
    email: string,
    pw: string,
    authType: "login"
  ): Promise<void>;

  async function continueWithFirebaseEmail(
    email: string,
    pw: string,
    authType: "register" | "login" = "login",
    firstName?: string
  ) {
    const authMethod =
      authType === "login"
        ? "signInWithEmailAndPassword"
        : "createUserWithEmailAndPassword";

    // Set and/or override old firstName
    firstNameRef.current = firstName ?? null;

    await auth()[authMethod](email, pw);
  }

  const registerWithFirebaseEmail = async (
    email: string,
    pw: string,
    firstName: string
  ) => continueWithFirebaseEmail(email, pw, "register", firstName);

  const loginWithFirebaseEmail = async (email: string, pw: string) =>
    continueWithFirebaseEmail(email, pw, "login");

  const signOut = async () => {
    await auth().signOut();
    unsetEverything();
  };

  const deleteAccount = async () => {
    await Axios.delete(`${apiUrl}/account`);
    await signOut();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (customerId && referrerId) {
        // Should be idempotent
        Axios.post(`${apiUrl}/referrals/link/${referrerId}`, { customerId });
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [customerId, referrerId, user]);

  // Don't override existing referrers
  const setReferrerIfNotSet = (referrer: string) => {
    if (!referrerId) {
      setReferrerId(referrer);
    }
  };

  return (
    <UserContext.Provider
      value={{
        authFlowStatus,
        user,
        customerId,
        continueWithFirebaseSSO,
        registerWithFirebaseEmail,
        loginWithFirebaseEmail,
        signOut,
        deleteAccount,
        setReferrerIfNotSet,
        authModalState,
        openAuthModal,
        closeAuthModal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
