import { FC } from "react"
import { useSavedState } from "../../../store/savedsContext"
import { normalizePriceLabel } from "../../../utils/helpers"
import { DisplayMobileOnly, DisplayWiderThanMobileOnly } from "../../../utils/reactHelpers"
import { 
  BrandNameAndPriceCss, 
  getBrowseItemCss, 
  DesktopLeftSectionCss, 
  getItemImageCss, 
  ItemTextCss, 
  ItemTextBoldCss, 
  UnderImageSectionCss, 
  OtherColorsLineCss,
  ItemTextStrokedCss,
  DiscountLabelCss,
  PriceLabelsCss} from "./BrowsePageComponentStyles"
import { LikeAndDiscardButtons } from "./LikeAndDiscardButtons"
import { ProductLink } from "./ProductLink"

export const BrowseItem: FC<{item: IFurnitureItem}> = ({item}) => {
  const { state } = useSavedState(item, "shop");
  //const [isDiscarded, isDiscardedSet] = useState(false);

  const getPriceLabel = () => {
    const stock = item.stockData;
    const hasDiscount = stock?.rrp && (stock?.rrp > stock?.price);

    const rrpLabel = hasDiscount
      ? <label className={ItemTextStrokedCss}>
          {normalizePriceLabel(stock.rrp)}
        </label>
      : undefined

    return (
      <div className={PriceLabelsCss}>
        {rrpLabel}
        <label className={ItemTextCss}>
          {normalizePriceLabel(stock?.price)}
        </label>
      </div>
    )
  }

  const calculateDiscountPerCent = () => {
    const stock = item.stockData;
    if (!stock?.rrp || !stock?.price) return 0;
    if (stock.rrp <= stock.price) return 0;

    let delta = stock.rrp - stock.price;
    return (delta / stock.rrp) * 100;
  }
  const perCent = calculateDiscountPerCent();

  const getDiscountLabel = () => {
    //console.log(perCent);
    return perCent > 0
      ? <div className={DiscountLabelCss}>
            {Math.ceil(perCent)}% OFF
        </div>
      : undefined
  }

  return <div className={getBrowseItemCss(state === "IsSaved")}>
  {/* Image */}
  <ProductLink item={item}>
    <div className={getItemImageCss(item, state === "IsDiscarded")}>
      {getDiscountLabel()}
    </div>
  </ProductLink>

  {/* Under image section */}
  <div className={UnderImageSectionCss}>
    <div className={DesktopLeftSectionCss}>
      <div className={BrandNameAndPriceCss}>
        {/* Retailer */}
        <ProductLink item={item}>
          <p className={ItemTextBoldCss}>
            {item.brand}
          </p>
        </ProductLink>

        {/* Name */}
        <ProductLink item={item}>
          <h2 className={ItemTextCss} title={item.name}>
            {item.name}
          </h2>
        </ProductLink>

        {/* Price */}
        {item.stockData && (
          <ProductLink item={item}>
            {getPriceLabel()}
          </ProductLink>
        )}
      </div>
      <DisplayMobileOnly>
        <LikeAndDiscardButtons item={item} />
      </DisplayMobileOnly>

      {/* "Available in other colours" line */}
      {/* <div
        className={OtherColorsLineCss}
      >
        {item.availableColours.length > 0 &&
          "Available in other colours"}
      </div> */}
    </div>

    <DisplayWiderThanMobileOnly>
      <LikeAndDiscardButtons item={item} />
    </DisplayWiderThanMobileOnly>
  </div>
</div>
}
