import styled, { css } from "styled-components"
import { colors, fontSizes } from "../../../styles/globalStyled"
import { breakpoints } from "../../../styles/responsive"

import backDesktop from "../../../images/recsPage/stylist/Desktop.jpg";
import backMobile from "../../../images/recsPage/stylist/Mobile.jpg";

export const PropmptWrapper = styled.div`
  scroll-snap-align: start;
  min-width: 100%;

  position: relative;
  background-image: url(${backDesktop});
  background-size: cover;
  background-repeat: no-repeat;
  transition-property: opacity;
  transition-duration: 1s;

  /* display: grid;
  grid-template-columns: 1fr 750px; */
  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: 2;

  @supports not (-webkit-touch-callout: none) {
    /* CSS specific to non-iOS devices */
    height: var(--height-under-navbar);
  }

  @media (max-width: ${breakpoints.mobile}) {
    /* top: var(--navbar-height-mobile); */
    background-image: url(${backMobile});
    background-size: fixed; 
    
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 4.5fr;

    @media (max-width: ${breakpoints.mobile}) {
      @supports not (-webkit-touch-callout: none) {
        display: flex;
        flex-direction: column;
        justify-content: start;
      }
    }
  }
`;

export const PropmptBox = styled.div`
  padding: 2%;

  @media (min-width: ${breakpoints.mobile}) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const PropmptContainer = styled.div`
  background-color: white;
  /* position: relative; */
  /* margin: auto; */
  margin-right: 5vw;
  width: 622px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  letter-spacing: -1px;
  height: auto;
  justify-content: center;

  @media (max-width: ${breakpoints.mobile}) {
    margin: auto;
    width: 95vw;
  }

  @media (min-width: ${breakpoints.mobile}) {
    margin: auto 5vw auto auto;
    height: 75vh;
  }
`;

export const PromptHeader = styled.div`
  text-align: right;
  margin: 1vh 2vw;

  button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: ${fontSizes.hsDescTitle};
    color: ${colors.greyMediumLighter};
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 2vw;
    font-size: ${fontSizes.hsDescription};
  }
`;

export const PromptBody = styled.div`
  text-align: center;
  padding: 5% 15% 10%;
  color: ${colors.greyDark};
  font-size: ${fontSizes.larger};
  margin: 7% 0;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;
    padding: 0 7vw;
    font-size: ${fontSizes.medium};
  }
  
  h1 {
    font-size: ${fontSizes.hsDescTitle};
    margin-bottom: 5%;
    font-weight: 900;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.hsDescription};
    }
  }

  p {
    line-height: 1.2;
    @media (max-width: ${breakpoints.mobile}) {
      line-height: 1.4;
    }
  }

  a {
    text-decoration: underline;
  }

  button {
    border: none;
    background-color: ${colors.green};
    cursor: pointer;
    color: white;
    font-size: ${fontSizes.hsDescription};
    font-weight: 600;
    padding: 2%;
    width: 100%;
    margin: 10% 0 5%;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.medium};
      text-transform: uppercase;
      /* margin: 0 5%; */
      padding: 3%;
      width: 80%;
      margin-bottom: 10%;
    }
  }
`;

export const UpgradeModalWrapper = styled.div<{visible: boolean}>`
  display: ${({visible}) => visible ? "flex" : "none"};
  background-color: white;
  justify-content: center;
  position: fixed;
  top: calc(var(--navbar-height-mobile) * 2);
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
  background-image: url(${backMobile});
  background-size: cover; 

  @media (min-width: ${breakpoints.mobile}) {
    background-image: url(${backDesktop});
    top: var(--navbar-height);
    flex-direction: row;
    justify-content: end;
  }
`;
