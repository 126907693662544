import React, { FC } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../styles/globalStyled";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { breakpoints } from "../../styles/responsive";

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    position: fixed;
    top: calc(var(--navbar-height-mobile) * 2);
  }
`;

export const SQLoading = () => (
  <LoadingContainer>
    <FontAwesomeIcon
      icon={faCircleNotch}
      spin={true}
      color={colors.greyDark}
      size="5x"
    />
  </LoadingContainer>
);

const NoResultsContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoResultsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  justify-content: center;
  max-width: 700px;
`;

const NoResultsHeader = styled.h2`
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const NoResultsBody = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 1rem;
`;

interface NoResultsButtonProps {
  $bgColor?: string;
}

const buttonStyle = css<NoResultsButtonProps>`
  border: none;
  background-color: ${({ $bgColor }) => $bgColor};
  text-transform: uppercase;
  padding: 10px;
  margin-right: 10px;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;

const NoResultsButtonLink = styled(Link)<NoResultsButtonProps>`
  ${buttonStyle}
`;

const NoResultsButton = styled.button<NoResultsButtonProps>`
  ${buttonStyle}
`;

const NoResultsButtonContainer = styled.div`
  display: flex;
`;

interface NoResultsFoundButtonProps {
  path?: string;
  onClick?: VoidFunction;
  label: string;
}

interface NoResultsFoundPageProps {
  headerText: string;
  bodyText: string;
  firstButtonProps?: NoResultsFoundButtonProps;
  sndButtonProps?: NoResultsFoundButtonProps;
  effectOnMount?: VoidFunction;
}

export const NoResultsFoundPage: FC<NoResultsFoundPageProps> = ({
  headerText,
  bodyText,
  firstButtonProps,
  sndButtonProps,
  effectOnMount,
}) => {
  useEffect(effectOnMount ?? (() => {}), []);

  return (
    <NoResultsContainer>
      <NoResultsInnerContainer>
        <NoResultsHeader>{headerText}</NoResultsHeader>
        <NoResultsBody>{bodyText}</NoResultsBody>
        <NoResultsButtonContainer>
          {firstButtonProps &&
            (firstButtonProps.path ? (
              <NoResultsButtonLink
                to={firstButtonProps.path}
                onClick={firstButtonProps.onClick}
                $bgColor={colors.orange}
              >
                {firstButtonProps.label}
              </NoResultsButtonLink>
            ) : (
              <NoResultsButton
                onClick={firstButtonProps.onClick}
                $bgColor={colors.orange}
              >
                {firstButtonProps.label}
              </NoResultsButton>
            ))}
          {sndButtonProps &&
            (sndButtonProps.path ? (
              <NoResultsButtonLink
                to={sndButtonProps.path}
                onClick={sndButtonProps.onClick}
                $bgColor={colors.green}
              >
                {sndButtonProps.label}
              </NoResultsButtonLink>
            ) : (
              <NoResultsButton
                onClick={sndButtonProps.onClick}
                $bgColor={colors.green}
              >
                {sndButtonProps.label}
              </NoResultsButton>
            ))}
        </NoResultsButtonContainer>
      </NoResultsInnerContainer>
    </NoResultsContainer>
  );
};
