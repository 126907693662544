import { useEffect, useState, useMemo, RefObject, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  RecommendationsPageWrapperNew,
  recItemClassName,
  FilterButton,
} from "./RecommendationsPageStyled";
import NavBar from "../../shared/NavBar/NavBar";
import {
  InitialLoadingPage,
  RecsNoItemsFound,
} from "../LoadingPage/LoadingPage";
import { apiUrl } from "../../config";
import Axios from "axios";
import { getId, getChair, toggle, concatArray, shareItem, findBestCoupon, distinct, descBy } from "../../utils/helpers";
import { Reason, useUserContext } from "../../store/userContext";
import { useCategory } from "../../hooks/useCategory";
import {
  useFetchRecsWhenNecessary,
  useSqFlowContext,
} from "../../store/SqFlowProvider";
import { useFilterCtx } from "../../store/filterContext";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { colors } from "../../styles/globalStyled";
import { useRefsList } from "../../hooks/useRefsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FilterPanelBackdrop, FilterPanel } from "./FilterPanel";
import { RecommendationItem } from "./RecommendationItem";
import { useAnalyticsContext } from "../../store/analyticsContext";
import { triggerNoRecsItems, triggerRecsLoadedEvent } from "../../dataLayer";
import { SQLoading } from "../StyleQuizPage/SQComponents";
import { checkIfAccIsPaid, /*createDealInACForUser,*/ fetchNextRecs, getCoupons, getPriceWatches } from "../../services/api.service";
//import { Redirect } from "react-router";
import { getSessionId } from "../../services/global.service";
import { MobileRoadmap } from "../../shared/NavBar/RoadMap/MobileRoadmap";
import { v4 } from "uuid";
import { UpgradePage } from "./UpgradePage/UpgradePage";
import { Navigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";


export const RecommendationsPage = () => {
  const { recsState, filteredRecItems, recsPageNeedsReload, regenerateRecs } =
    useSqFlowContext();
  const { onlyFiltersApplied } = recsState;
  const { addDiscardAnalytics } = useAnalyticsContext();
  const { user, openAuthModal, closeAuthModal } = useUserContext();
  const category = useCategory();

  const { filterOptions, setProp, resetFilters } = useFilterCtx();
  const filterOption = filterOptions[category];

  /**
   * This is a simple flag for whether or not we're currently still within the
   * fixed minimum period for displaying the green loading screen.
   */
  const [stillInMinLoadingPeriod, stillInMinLoadingPeriodSet] = useState(true);

  /**
   * Flag for whether or not we should be displaying the loading screen.
   */
  const [showLoadingScreen, showLoadingScreenSet] = useState(
    recsPageNeedsReload ? stillInMinLoadingPeriod : false
  );

  /**
   * Whether the 'reset filters' button has been clicked. Relevant for when
   * there are no results.
   */
  const [filtersResetClicked, setFiltersResetClicked] = useState(false);

  /**
   * Whether the app should redirect to product page if there is hash in URL.
   * it becomes true once user opened recommendations URL with no hash
   * (i.e. get recommendations by filtering)
   * so when user gets recommendations usual way redirections would be blocket
   * if the user opened the URL with hash (ie direct URL) they will be redirected
   * to the product page for the item.
   */
  const [blockRedirection, blockRedirectionSet] = useState(false);

  const [priceWatches, setPriceWatches] = useState([] as string[]);

  const [coupons, setCoupons] = useState([] as Coupon[])

  const pressResetFilters = () => {
    resetFilters();
    setFiltersResetClicked(true);
  };

  // In its own function so we can use proper if statements instead of a ternary mess
  const getRecsPageState = (): RecsPageState => {
    if (showLoadingScreen) {
      return "Loading";
    } else if (recsState.items === null) {
      return "LoadingButBecauseOfFilterChange";
    } else if (recsState.items.length < 1) {
      if (filtersResetClicked) {
        return "NoItemsFoundEvenAfterFiltersReset";
      } else {
        return "NoItemsFound";
      }
    } else {
      return "Loaded";
    }
  };

  const loadingPageState = getRecsPageState();

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { useTriggerFetchRecsWhenNecessary } = useFetchRecsWhenNecessary();

  useTriggerFetchRecsWhenNecessary();

  //const [watchesInitiated, setWatchesInitiated] = useState(false);

  const [replacements, setReplacements] = useState<IScoredFurnitureItem[]>([]);

  const [ isAccPaid, setIsAccPaid ] = useState(false);



  // Make hardcoded loading state disappear after 6s at the earliest
  useEffect(() => {
    const timeout = setTimeout(() => stillInMinLoadingPeriodSet(false), 6000);
    return () => clearTimeout(timeout);
  }, []);

  /**
   * When minimum loading period expires check whether items have loaded yet to
   * decide whether or not to hide loading screen.
   */
  useEffect(() => {
    if (
      !stillInMinLoadingPeriod &&
      recsState.items !== null &&
      showLoadingScreen
    ) {
      showLoadingScreenSet(false);
    }
  }, [
    stillInMinLoadingPeriod,
    concatArray(recsState.items?.map(getChair).map(getId)),
    showLoadingScreen,
  ]);

  useEffect(() => {
    if (loadingPageState === "Loaded") {
      // Reset the "already pressed the reset button" state
      setFiltersResetClicked(false);
      getCoupons().then(
        data => setCoupons(data),
        err => console.error(err)
      )
    }
  }, [loadingPageState]);

  useEffect(() => {
    if (loadingPageState === "Loaded" && recsState.items) {
      const recommendedIds = recsState.items.map(getChair).map(getId);

      triggerRecsLoadedEvent(recommendedIds);

      if (user) {
        // Axios.post(`${apiUrl}/viewedrecs`, undefined, {
        //   params: { productCategory: category },
        //   headers: { "Access-Control-Allow-Origin": "*" }
        // }).catch(console.error);

        checkIfAccIsPaid()
          .then(setIsAccPaid)
          .catch(console.error);

        //createDealInACForUser();
      }
    }
  }, [
    loadingPageState,
    user,
    concatArray(recsState.items?.map(getChair).map(getId)),
  ]);

  // When either no Recs altogether or when `onlyFiltersApplied` flag is set,
  // meaning that although there are Recs here, the BE sent them despite scoring
  // algorithm not returning any (so it just picked some random items as long as
  // they matched the filters).
  useEffect(() => {
    if (
      onlyFiltersApplied ||
      loadingPageState === "NoItemsFound" ||
      loadingPageState === "NoItemsFoundEvenAfterFiltersReset"
    ) {
      triggerNoRecsItems(category);
    }
  }, [onlyFiltersApplied]);

  /**
   * Users have to be logged in to view this page
   */
  useEffect(() => {
    if (user) {
      closeAuthModal();
    } else if (loadingPageState === "Loaded") {
      openAuthModal(Reason.RegisterBeforeViewingRecs);
    }

    return closeAuthModal; // Close when leaving this page, e.g. when going back to SQ3
  }, [user, loadingPageState]);

  const shopDummy =  {
    score: -1,
    chair: {
      id: "shop_rec" as ItemId,
      guid: v4(),
      name: "",
      retailer: "",
      retailerId: undefined,
      isRetailerOperating: true,
      referralLink: "",
      retailerLink: "",
      description: null,
      colours: [],
      availableColours: [],
      stockData: null,
      returnsAllowed: null,
      brand: "",
      height: null,
      width: null,
      depth: null,
      weight: null,
      images: {} as ItemImages,
      isChildFriendly: false,
      numberInSet: 0,
      category: category,
      humanColour: undefined,
      materials: []
    }
  } as IScoredFurnitureItem;

  const itemsToDisplay = [...[...filteredRecItems, ...replacements].slice(0, isAccPaid ? 10 : 3), shopDummy];

  const recItems = useMemo(
    () => {
      const res = itemsToDisplay
        .map(({ chair, score }) => ({ ...chair, score }))
        .sort(descBy(a => a.score));
      //console.log(res.map(x => `${x.id} -- ${x.score}`));
      return res;
    },
    [itemsToDisplay]
  );
  //console.log(recItems);

  const { makeNewRef } = useRefsList<HTMLDivElement>();
  const pageRef = useRef<HTMLDivElement>(null);

  //const navigate = useNavigate();

  const setHash = (id: undefined|string) => {
    const [sessionId, _] = splitLocationHash();

    if (!sessionId || sessionId === getSessionId())
      window.history.pushState(null, "", `${window.location.pathname}#${getSessionId()}&${id}`);
  }

  const scrollTo = (ref: RefObject<HTMLElement>, newId: undefined|string) => {
    setHash(newId);
    pageRef.current?.scrollTo({
      top: 0,
      left: ref.current?.offsetLeft,
      behavior: "smooth",
    });
  }

  const getNeighbourId = (step: number) =>
    step < 0 || step >= recItems.length
      ? undefined
      : recItems[step]?.id.toString();

  const splitLocationHash = () => {
    if (!window.location.hash)
      return [undefined, undefined] as const;

    const result = window.location.hash.replace("#", "").split("&");
    if (result.length < 2)
      return [undefined, result[0]] as const;

    return [result[0], result[1]] as const;
  }

  const getRedirectData = (): readonly [boolean, string] => {
    const [sessionId, itemId] = splitLocationHash();

    return sessionId !== getSessionId() && itemId && !blockRedirection
      ? [true, itemId] as const
      : [false, ""] as const;
  }

  // const triggerWatchChange = () =>
  //   getPriceWatches().then(
  //     res => setPriceWatches(res),
  //     err => console.error(err)
  //   )

  const discard = (id: ItemId, cat: CategoryUnion) => {
    addDiscardAnalytics(id, cat);
    const discardingReplacement = replacements.some(x => x.chair.id === id);
    if (discardingReplacement)
      setReplacements(replacements.filter(x => x.chair.id !== id));
  }

  const replaceDiscardedRec = (id: ItemId) => {
    regenerateRecs(id).then(
      newRec => {
        if (newRec) {
          console.info(newRec);
          setReplacements([...replacements, newRec].filter(distinct));
        }
      },
      err => console.error(err)
    )
  }

  const [mustRedirect, redirectId] = getRedirectData();

  if (mustRedirect)
    //navigate(redirectId === "shop_rec" ? "../../category" : `/product/a/${redirectId}`);
    return (<Navigate replace to={redirectId === "shop_rec" ? "../../category" : `/product/a/${redirectId}`} />);

  if (!blockRedirection)
    blockRedirectionSet(true);

  setHash(recItems[0]?.id);

  const [ isUpgradeVisible, setIsUpgradeVisible ] = useState(false);

  return (
    <>
      {/*@ts-ignore*/}
      <FlitchHelmet title="Recommendations"/>
      <NavBar />
      <MobileRoadmap />
      
      <RecommendationsPageWrapperNew ref={pageRef}>
        {loadingPageState !== "Loading" && (
          <FilterButton onClick={() => setIsFilterOpen(toggle)}>
            <FontAwesomeIcon
              icon={faFilter}
              color={colors.greyDark}
              size="2x"
            />
          </FilterButton>
        )}
        <FilterPanelBackdrop
          isOpen={isFilterOpen}
          onClick={() => setIsFilterOpen(false)}
        />
        <FilterPanel
          isOpen={isFilterOpen}
          category={category}
          filterOption={filterOption}
          closePanel={() => setIsFilterOpen(false)}
          setProp={setProp}
        />
        {loadingPageState === "Loaded" ? (
          /*@ts-ignore*/
          <TransitionGroup component={null}>
            {recItems.map((item, i) => (
              /*@ts-ignore*/
              <CSSTransition
                key={item.id}
                timeout={1000}
                classNames={recItemClassName}
              >
                {item.id === "shop_rec" && !isAccPaid
                  ? <UpgradePage 
                      visible={isUpgradeVisible}
                      close={() => setIsUpgradeVisible(false)} />
                  : <RecommendationItem
                      mode="RecsPage"
                      item={item}
                      prevId={getNeighbourId(i-1)}
                      nextId={getNeighbourId(i+1)}
                      isLastItem={i === 2}
                      openModal={!isAccPaid && i === 2 
                        ? () => setIsUpgradeVisible(true)
                        : undefined}
                      itemScore={item.score}
                      removeRec={discard}
                      replaceDiscardedRec={() => replaceDiscardedRec(item.id)}
                      category={category}
                      index={i}
                      itemsLen={recItems.length}
                      itemRef={makeNewRef()}
                      goToElem={scrollTo}
                      share={shareItem}
                      coupon={findBestCoupon(item, coupons)}/>}
              </CSSTransition>
            ))}
          </TransitionGroup>
        ) : loadingPageState === "Loading" ? (
          <InitialLoadingPage category={category}/>
        ) : loadingPageState === "LoadingButBecauseOfFilterChange" ? (
          <SQLoading />
        ) : (
          <RecsNoItemsFound
            showResetFiltersBtn={
              loadingPageState !== "NoItemsFoundEvenAfterFiltersReset"
            }
            category={category}
            resetFilters={pressResetFilters}
          />
        )}
      </RecommendationsPageWrapperNew>
    </>
  );
};
