import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/globalStyled";

const BottomFooterWrapper = styled.div`
  background-color: ${colors.greyishGreen};
  height: 6rem;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;

  .bottom-footer-container {
    width: var(--regular-content-width);
  }

  p {
    width: 100%;
    padding-right: 15px;
    font-size: 1.5rem;
    color: white;
    text-align: right;
  }
`;

export const BottomFooter = () => (
  <BottomFooterWrapper>
    <div className="bottom-footer-container">
      <p>© 2023 Azara Ltd &ndash; All Rights Reserved</p>
    </div>
  </BottomFooterWrapper>
);
