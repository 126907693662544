// @ts-ignore
import { useMediaQuery } from "react-responsive";

export const breakpoints = {
  mobileS: "320px",
  mobile: "768px",
  iPhone12: "428px",
  desktop: "1028px",
  tablet: "1023px",
  bigDesktop: "1216px",
  netbook: "1366px",
  twoK: "2560px"
} as const;

// export const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
// export const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 });
export const isTabletOrMobileQuery = () => useMediaQuery({ maxWidth: 1224 });
// export const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 });
export const isPortraitQuery = () => useMediaQuery({ orientation: "portrait" });
// export const isRetina = useMediaQuery({ minResolution: "2dppx" });
