import React from "react";
import {
  FooterColumnsContainer,
  FooterContainerWrapper,
  TopLogoImgContainer,
  LogoImg,
  FooterColumn,
  FooterHeader,
  FooterLink,
  FooterText,
  FooterExternalLink,
  WholeFooterContainer,
  SideLogoImgContainer,
} from "./FooterStyled";

export const Footer = () => (
  <FooterContainerWrapper>
    <WholeFooterContainer>
      <TopLogoImgContainer>
        <LogoImg />
      </TopLogoImgContainer>
      <FooterColumnsContainer>
        <SideLogoImgContainer>
          <FooterColumn>
            <LogoImg />
          </FooterColumn>
        </SideLogoImgContainer>
        <FooterColumn>
          <FooterHeader>Company</FooterHeader>
          <FooterLink to="/about">About Us</FooterLink>
          <FooterLink to="/pricing">Pricing</FooterLink>
          <FooterExternalLink
            href="https://blog.withflitch.com"
            target="_blank"
            rel="noopener"
          >
            Blog
          </FooterExternalLink>
          <FooterLink to="/faqs">FAQs</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeader>Legal</FooterHeader>
          <FooterLink to="/terms">Terms and Conditions</FooterLink>
          {/* <FooterLink to="/terms-of-sale">General Terms of Sale</FooterLink> */}
          <FooterLink to="/privacy">Privacy Policy</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeader>Contact us</FooterHeader>
          <FooterExternalLink
            href="mailto:hello@withflitch.com"
            target="_blank"
            rel="noreferrer"
          >
            hello@withflitch.com
          </FooterExternalLink>
          <FooterText>
            For media enquiries please reach out to{" "}
            <FooterExternalLink
              href="mailto:media@withflitch.com"
              target="_blank"
              rel="noreferrer"
            >
              media@withflitch.com
            </FooterExternalLink>
          </FooterText>
        </FooterColumn>
        <FooterColumn>
          <FooterHeader>Follow us</FooterHeader>
          <FooterExternalLink
            href="https://www.facebook.com/withflitch"
            target="_blank"
            rel="noopener"
          >
            Facebook
          </FooterExternalLink>
          <FooterExternalLink
            href="https://www.instagram.com/withflitch"
            target="_blank"
            rel="noopener"
          >
            Instagram
          </FooterExternalLink>
          <FooterExternalLink
            href="https://www.pinterest.co.uk/withflitch/"
            target="_blank"
            rel="noopener"
          >
            Pinterest
          </FooterExternalLink>
          {/* <FooterExternalLink
            href="https://www.twitter.com/furnishdotapp"
            target="_blank"
            rel="noopener"
          >
            Twitter
          </FooterExternalLink> */}
        </FooterColumn>
      </FooterColumnsContainer>
    </WholeFooterContainer>
  </FooterContainerWrapper>
);
