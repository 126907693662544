import React, {
  createContext,
  useContext,
  FC,
  useMemo,
  useState,
  useEffect,
} from "react";
import Axios from "axios";
import { apiUrl } from "../config";
import {
  toggleFromArraySetter,
  makeSubStateSetter,
  hashRequestPayload,
  getId,
  getPromiseResult,
  concatArray,
} from "../utils/helpers";
import { v4 } from "uuid";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useCategory } from "../hooks/useCategory";
import { useFilterCtx } from "./filterContext";
import { getFilterPayload } from "../pages/FilterPage/utils/getFilterPayloads";
import { useNonInitialEffect } from "../hooks/useNonInitialEffect";
import { useUserContext } from "./userContext";

// @TODO: similarly to how we use loadedRequestHash to check whether page needs
// a refresh, we should have a check in this provider for whether a given page
// is 'correct' or whether we need to go back to a previous page. E.g. if the
// loaded category is wrong or insufficient items have been selected on a
// previous page.

interface SQPageState {
  items: IFurnitureItem[] | null;
  selectedItems: ItemId[];

  /**
   * to know which is the latest request. Possibly replaceable with requestHash, if passed to BE as nonce.
   */
  latestNonce: string;

  /**
   * to know if currently loaded data is correct for this route.
   */
  loadedCategory: CategoryUnion | null;

  loadedRequestHash: RequestInputHash | null;

  /**
   * Whether or not the API has told us that the currently selected filters
   * will exclude any item from appearing in Recs.
   * That way we can shorcut the process and not make them go through the whole
   * SQ before disappointing them with too strict filters.
   */
  filtersExcludeAllRecs?: boolean;
}

interface RecsPageState {
  items: IScoredFurnitureItem[] | null;
  removedRecs: ItemId[];

  latestNonce: string;
  loadedCategory: CategoryUnion | null;

  loadedRequestHash: RequestInputHash | null;
  onlyFiltersApplied: boolean | null;

  requestStartTime: DateNum;
  requestEndTime: DateNum;
}

interface StyleQuizState {
  page1And2State: SQPageState;
  page3State: SQPageState;
  recsState: RecsPageState;
}

interface StyleQuizStateSetters {
  fetchSqItems: () => Promise<void>;
  toggleSq1Sq2SelectedItem: Setter<ItemId>;

  fetchRefineItems: () => Promise<void>;
  toggleSq3Item: Setter<ItemId>;

  fetchRecommendations: () => Promise<void>;
  regenerateRecs: (id: ItemId) => Promise<IScoredFurnitureItem|undefined>;

  removeRec: (id: ItemId) => void;
  undoRemoveRec: VoidFunction;

  resetStates: VoidFunction;
  resetPage3StateOnwards: VoidFunction;

  /**
   * Computed states
   */
  sq1Items: IFurnitureItem[] | null;
  sq2Items: IFurnitureItem[] | null;
  maxOnSinglePage: number;

  // These are not in state objects because we don't want them to be persisted in localStorage
  isSq1And2Loading: boolean;
  isRefineLoading: boolean;
  isRecsLoading: boolean;

  page1And2NeedReload: boolean;
  page3NeedsReload: boolean;
  recsPageNeedsReload: boolean;

  likedItems: IFurnitureItem[];
  filteredRecItems: IScoredFurnitureItem[];
}

const newPageState: SQPageState = {
  items: null,
  selectedItems: [],
  latestNonce: "",
  loadedCategory: null,
  loadedRequestHash: null,
};

const newRecsState: RecsPageState = {
  ...newPageState,
  items: null, // Need to overwrite `items` because it's a different type of array
  removedRecs: [],
  onlyFiltersApplied: null,

  requestStartTime: +new Date() as DateNum,
  requestEndTime: +new Date() as DateNum,
};

/**
 * Helper function that takes the initial or blank state as input and returns
 * a setter that resets everything to that initial state except for the
 * `latestNonce`.
 */
const makeResetExceptNonce =
  <T extends { latestNonce: string }>(newState: T) =>
  (state: T) => ({ ...newState, latestNonce: state.latestNonce });

const maxForSingleSqPage = 60;

const splitIntoTwo = <T,>(itemsOpt: T[] | null): [T[] | null, T[] | null] => {
  if (itemsOpt === null) {
    return [null, null];
  } else {
    if (itemsOpt.length > maxForSingleSqPage) {
      const halfwayPoint = Math.ceil(itemsOpt.length / 2);

      const first = itemsOpt.slice(0, halfwayPoint);
      const snd = itemsOpt.slice(halfwayPoint);

      return [first, snd];
    } else {
      return [itemsOpt, null];
    }
  }
};

const SqFlowContext = createContext(
  {} as StyleQuizState & StyleQuizStateSetters
);

export const useSqFlowContext = () => useContext(SqFlowContext);

export const SqFlowProvider: FC<{children?: React.ReactNode}> = ({ children }) => {
  const category = useCategory();

  const [page1And2State, setPage1And2State] = useLocalStorage<SQPageState>(
    "page1And2State",
    newPageState,
    "2"
  );

  const { filterOptions, dontApplyFiltersToRecs } = useFilterCtx();
  const filterOption = filterOptions[category];

  const [isSq1And2Loading, setIsSq1And2Loading] = useState(false);

  const selectedSetter = makeSubStateSetter(setPage1And2State, "selectedItems");

  const [sq1Items, sq2Items] = useMemo(
    () => splitIntoTwo(page1And2State.items),
    [concatArray(page1And2State.items?.map(getId))]
  );

  const maxOnSinglePage = Math.max(
    sq1Items?.length ?? 0,
    sq2Items?.length ?? 0
  );

  const [page3State, page3StateSet] = useLocalStorage<SQPageState>(
    "page3State",
    newPageState,
    "2"
  );

  const [isRefineLoading, setIsRefineLoading] = useState(false);

  const [recsState, recsStateSet] = useLocalStorage<RecsPageState>(
    "recsState",
    newRecsState,
    "4"
  );

  const [isRecsLoading, setIsRecsLoading] = useState(false);

  const resetRecs = () => recsStateSet(makeResetExceptNonce(newRecsState));

  const toggleSq1Sq2SelectedItem = (id: ItemId) => {
    toggleFromArraySetter(selectedSetter)(id);
    resetRecs();
  };

  const likedItems = useMemo(() => {
    const selectedSet = new Set([
      ...page1And2State.selectedItems,
      ...page3State.selectedItems,
    ]);

    return [
      ...(page1And2State.items ?? []),
      ...(page3State.items ?? []),
    ].filter((item) => selectedSet.has(item.id));
  }, [page1And2State, page3State]);

  const removedRecs = new Set(recsState.removedRecs);
  const filteredRecItems = useMemo(
    () =>
      recsState.items?.filter((item) => !removedRecs.has(item.chair.id)) ?? [],
    [recsState.items, removedRecs]
  );

  const removeRec = (id: ItemId) =>
    recsStateSet((state) => ({
      ...state,
      // Putting this at start so it can easily be sliced back off
      removedRecs: [id, ...state.removedRecs],
    }));

  const undoRemoveRec = () =>
    recsStateSet(({ removedRecs, ...state }) => ({
      ...state,
      removedRecs: removedRecs.length > 0 ? removedRecs.slice(1) : removedRecs,
    }));

  const page1And2CurrentRequestHash = hashRequestPayload(
    category,
    [],
    filterOption,
    dontApplyFiltersToRecs
  );

  const page1And2NeedReload =
    page1And2State.loadedRequestHash !== page1And2CurrentRequestHash;

  const fetchSqItems = async () => {
    const nonce = v4();

    setIsSq1And2Loading(true);
    setPage1And2State({ ...newPageState, latestNonce: nonce });

    const getUrl = (category: CategoryUnion) => `${apiUrl}/sq/filtered/${category}`;

    const sqFilterPayload = {
      filterPayload: getFilterPayload(filterOption, "sq"),
    };
    const recsFilterPayload = {
      filterPayload: getFilterPayload(filterOption, "recs"),
    };

    const payload: DynamicSqPayload = {
      nonce,
      sqFilterPayload,
      recsFilterPayload,
    };

    //console.group(payload);
    const result = await getPromiseResult(() =>
      Axios.post<DynamicSqResponse>(getUrl(category), payload/*, {
        params: { productCategory: category },
      }*/)
    );

    //console.log(result)
    if (result.success) {
      const { data } = result.result;
      setPage1And2State((state) => {
        if (data.nonce === state.latestNonce) {
          setIsSq1And2Loading(false); // not ideal, since the setter should be pure, but don't think there's an alternative

          return {
            items: data.items,
            selectedItems: [],
            latestNonce: data.nonce,
            loadedCategory: category,
            loadedRequestHash: page1And2CurrentRequestHash,
            filtersExcludeAllRecs: data.filtersExcludeAllRecs,
          };
        } else {
          return state;
        }
      });
    } else {
      setIsSq1And2Loading(false);
    }
  };

  const pageReq3Ids: SelectedsAndDisplayeds = {
    selectedIds: page1And2State.selectedItems,
    displayedIds: page1And2State.items?.map(getId) ?? [],
  };

  const page3CurrentRequestHash = hashRequestPayload(
    category,
    [pageReq3Ids],
    filterOption
  );

  const page3NeedsReload =
    page3State.loadedRequestHash !== page3CurrentRequestHash;

  const fetchRefineItems = async () => {
    const nonce = v4();
    setIsRefineLoading(true);
    page3StateSet({ ...newPageState, latestNonce: nonce });

    const url = `${apiUrl}/sq/refine/${category}`;

    const filterPayload = getFilterPayload(filterOption, "sq");
    const payload: IRefinePayload = {
      ...pageReq3Ids,
      maxOnSinglePage,
      nonce,
      filterPayload,
    };

    const result = await getPromiseResult(() =>
      Axios.post<IRefineResponse>(url, payload)
    );

    if (result.success) {
      const {
        data: { chairs, nonce: responseNonce },
      } = result.result;

      page3StateSet((state) => {
        if (responseNonce === state.latestNonce) {
          setIsRefineLoading(false);
          return {
            items: chairs,
            selectedItems: [],
            loadedCategory: category,
            latestNonce: state.latestNonce,
            loadedRequestHash: page3CurrentRequestHash,
          };
        } else {
          return state;
        }
      });
    } else {
      setIsRefineLoading(false);
    }
  };

  const selectedIdsForRecs = useMemo(
    () => [...page1And2State.selectedItems, ...page3State.selectedItems],
    [page1And2State.selectedItems, page3State.selectedItems]
  );

  const displayedIdsForRecs = useMemo(
    () =>
      [...(page1And2State.items ?? []), ...(page3State.items ?? [])].map(getId),
    [page1And2State.items ?? [], page3State.items]
  );

  const recsPageReqIds: SelectedsAndDisplayeds = {
    selectedIds: selectedIdsForRecs,
    displayedIds: displayedIdsForRecs,
  };

  const recsStateCurrentReqHash = hashRequestPayload(
    category,
    [recsPageReqIds],
    filterOption,
    dontApplyFiltersToRecs
  );

  const recsPageNeedsReload =
    recsState.loadedRequestHash !== recsStateCurrentReqHash;

  const {customerId} = useUserContext();

  const regenerateRecs = async (id: ItemId) => {
    const nonce = v4();
    const filterPayload = getFilterPayload(
      filterOption,
      "recs",
      dontApplyFiltersToRecs );

    const url = `${apiUrl}/sq/replace/${category}/${id}`;
    const recsPayload: SuggestionsPayload = {
      ...recsPageReqIds,
      filterPayload,
      nonce,
      customerId };

    return Axios.post<IScoredFurnitureItem|undefined>(url, recsPayload)
      .then(r => r.data)
      .catch(err => {
        console.error(err);
        return undefined});
  };

  const fetchRecommendations = async () => {
    const nonce = v4();
    const requestStartTime = +new Date() as DateNum;

    setIsRecsLoading(true);

    recsStateSet({ ...newRecsState, latestNonce: nonce, requestStartTime });

    const filterPayload = getFilterPayload(
      filterOption,
      "recs",
      dontApplyFiltersToRecs
    );

    const url = `${apiUrl}/sq/suggestions/${category}`;

    const recsPayload: SuggestionsPayload = {
      ...recsPageReqIds,
      filterPayload,
      nonce,
      customerId
    };

    const result = await getPromiseResult(() =>
      Axios.post<ISuggestionsResponse>(url, recsPayload)
    );
    //console.log(result)

    if (result.success) {
      const { data } = result.result;

      recsStateSet((state) => {
        const requestEndTime = +new Date() as DateNum;

        if (state.latestNonce === data.nonce) {
          setIsRecsLoading(false);

          return {
            ...state,
            items: data.items,
            loadedCategory: category,
            loadedRequestHash: recsStateCurrentReqHash,
            requestEndTime,
            onlyFiltersApplied: data.onlyFiltersApplied,
          };
        } else {
          return state;
        }
      });
    } else {
      setIsRecsLoading(false);
    }
  };

  const sq3SelectedSetter = makeSubStateSetter(page3StateSet, "selectedItems");
  const toggleSq3Item = (id: ItemId) => {
    toggleFromArraySetter(sq3SelectedSetter)(id);
    resetRecs();
  };

  const resetPage3StateOnwards = () => {
    page3StateSet(makeResetExceptNonce(newPageState));
    resetRecs();
  };

  const resetStates = () => {
    setPage1And2State(makeResetExceptNonce(newPageState));
    resetPage3StateOnwards();
  };

  // When selection or filters are changed reset Recs
  useNonInitialEffect(resetRecs, [
    concatArray(page1And2State.selectedItems),
    concatArray(page3State.selectedItems),
    filterOption,
  ]);

  useNonInitialEffect(resetStates, [category]);

  return (
    <SqFlowContext.Provider
      value={{
        page1And2State,

        page3State,
        recsState,

        fetchSqItems,
        toggleSq1Sq2SelectedItem,

        fetchRefineItems,
        toggleSq3Item,

        regenerateRecs,
        fetchRecommendations,
        removeRec,
        undoRemoveRec,

        resetStates,
        resetPage3StateOnwards,

        sq1Items,
        sq2Items,
        maxOnSinglePage,

        isSq1And2Loading,
        isRefineLoading,
        isRecsLoading,

        page1And2NeedReload,
        page3NeedsReload,
        recsPageNeedsReload,

        likedItems,
        filteredRecItems,
      }}
    >
      {children}
    </SqFlowContext.Provider>
  );
};

export const useFetchRecsWhenNecessary = () => {
  const { fetchRecommendations, isRecsLoading, recsPageNeedsReload } =
    useSqFlowContext();

  return {
    triggerFetchRecsIfNecessary: () => {
      if (!isRecsLoading && recsPageNeedsReload) {
        fetchRecommendations();
      }
    },
    useTriggerFetchRecsWhenNecessary: () =>
      useEffect(() => {
        if (!isRecsLoading && recsPageNeedsReload) {
          fetchRecommendations();
        }
      }, [isRecsLoading, recsPageNeedsReload]),
  };
};
