import styled from "styled-components";

import { fontSizes } from "../../styles/globalStyled";

export const HeadingsWrapper = styled.div`
  padding: 40px 5px;
`;

export const HeadingPrimary = styled.h3`
  font-size: ${fontSizes.medium};
  font-weight: bolder;
  color: #404040;
  text-align: center;
`;

export const HeadingSecondary = styled.h4`
  font-size: ${fontSizes.small};
  text-align: center;
`;

export const HeadingTertiary = styled.h3`
  font-size: ${fontSizes.small};
  text-align: center;
`;
