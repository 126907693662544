import styled from "styled-components";
import { fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";

export const NotFoundPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: var(--navbar-height);
  width: 100vw;
  height: calc(100vh - var(--navbar-height));
  padding: 15vw;

  h1{
    font-size: ${fontSizes.huge};
    font-weight: 450;

    @media (max-width: ${breakpoints.mobile}) {
      line-height: 1.2;
      font-size: ${fontSizes.larger};
      margin-bottom: 2vh;
    }
  }

  p{
    font-size: ${fontSizes.veryLarge};
    line-height: 1.2;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mediumLarger};
    }
  }

  a{
    text-decoration: underline;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 10vw;
    top: var(--navbar-height-mobile);
    height: calc(100vh - var(--navbar-height-mobile));
  }
`;