import React, {
  useEffect,
  useState,
  useMemo,
  FC,
  RefObject,
  useRef,
} from "react";
import {
  BuyNowButtonContainer,
  ContentOverlay,
  ItemImagesContainerStyled,
  RecItemStyled,
  ActionPanel,
  ActionButton,
  ActionPanelContainer,
  ItemContentHeader,
  Line,
  DescriptionContent,
  ColourVariants,
  MoreInfo,
  ImagesScrollDots,
  ItemImage,
  ContentOverlayTextContent,
  YouMayAlsoLike,
  MoreColourVariantsAvailable,
  IOSBuyNowButtonContainer,
  ShopDescription,
  ActionLeftIconCss,
  ActionRightIconCss,
} from "./RecommendationsPageStyled";
import { mdiMenuLeft, mdiChevronDoubleLeft, mdiMenuRight } from "@mdi/js";
import Icon from '@mdi/react';
import { arrayToObj, isNotFalsy } from "../../utils/helpers";
import { useUserContext } from "../../store/userContext";

import { fetchColourVariants, fetchSingleItem } from "./recsUtils";
import { BuyNowButton } from "./buttons/BuyNowButton";
import { colors } from "../../styles/globalStyled";
import { RefContainer } from "../../hooks/useRefsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { SaveForLaterButtonNew } from "./SaveActionButton";
import { SaveForLaterButton } from "./SaveForLaterButton";
import ItemStatus from "./ItemStatus/ItemStatus";
import {
  DisplayMobileOnly,
  DisplayWiderThanMobileOnly,
} from "../../utils/reactHelpers";
import { ShareButton } from "./buttons/ShareButton";
import { GoToShopButton } from "./buttons/GoToShopButton";
import { PriceChart } from "./PriceChart/PriceChart";
import { deletePriceWatch, postPriceWatch, saveSingleAction } from "../../services/api.service";
import { CouponBar } from "./couponBar/CouponBar";
import Spacer from "react-spacer";
import { getRecsToShopImage, ImageSizes } from "./recsToShopImages";
import { AddToCartButton } from "./buttons/AddToCartButton";
import { useShopContext } from "../../store/shopContext";
import { triggerNextItemEvent, triggerPrevItemEvent } from "../../dataLayer";

const getImageSize = () => {
  const width = window.innerWidth;
  if (width <= 720) return ImageSizes.Small;
  if (width <= 1080) return ImageSizes.Medium;
  return ImageSizes.Big;
}

const maxColoursToShow = 6;

const getImagesToShow = (item: IFurnitureItem) =>
  item.id === "shop_rec"
  ? [ getRecsToShopImage(item.category ?? "armchair", getImageSize()) ]
  : [
      item.images.full.mainImage,
      item.images.full.backImage,
      ...item.images.full.otherImages,
    ].filter(isNotFalsy);

interface ItemImagesContainerProps {
  item: IFurnitureItem;
  children?: React.ReactNode 
  onVisibleImageChange: (visibleImgIndex: number) => void;
}

const ItemImagesContainer: FC<ItemImagesContainerProps> = ({
  item,
  onVisibleImageChange,
  children,
}) => {
  const images = getImagesToShow(item);
  const imageRootref = useRef<HTMLDivElement>(null);

  return (
    <ItemImagesContainerStyled ref={imageRootref}>
      {images.map((img, i) => (
        <ItemImage
          key={img}
          src={img}
          rootRef={imageRootref}
          onVisibleImage={() => onVisibleImageChange(i)}
        />
      ))}
      {children}
    </ItemImagesContainerStyled>
  );
};

type RecItemProps = {
  mode: "RecsPage";
  item: IFurnitureItem;
  prevId: undefined | string;
  nextId: undefined | string;
  itemScore: number;
  removeRec: (id: ItemId, cat: CategoryUnion) => void;
  replaceDiscardedRec: (cat: CategoryUnion) => void;
  category: CategoryUnion;
  index: number;
  itemsLen: number;
  itemRef: RefContainer<HTMLDivElement>;
  coupon: Coupon | undefined;
  goToElem: (ref: RefObject<HTMLElement>, newId: string|undefined) => void;
  share: (item: IFurnitureItem) => void;
  openModal?: () => void;
  isLastItem: boolean;
};

type ProductPageProps = {
  mode: "ProductPage";
  item: IFurnitureItem;
  category: CategoryUnion;
  similarItems: IFurnitureItem[]; // @TODO: put this in page
  coupon: Coupon | undefined;
  share: (item: IFurnitureItem) => void;
  openModal?: () => void;
};

type RecommendationItemProps = RecItemProps | ProductPageProps;

export const RecommendationItem: FC<RecommendationItemProps> = ({
  item,
  coupon,
  category,
  share,
  openModal,
  //initialHistory,
  // isWatched,
  // triggerWatchChange,
  ...props
}) => {
  const { customerId, user, openAuthModal } = useUserContext();

  const [selectedVariantId, setSelectedVariantId] = useState(item.id);

  const [colourVariantsMap, colourVariantsMapSet] = useState<
    Record<string, IFurnitureItem> // Keys are the ItemIDs of the variants
  >({});

  const { partnerData } = useShopContext();

  /**
   * @TODO
   * Ideally have some visual indication that we aren't able to show the
   * selected colour yet. Either a spinner on the colour pie or a loading icon
   * on the entire item column to show that this variant is still loading.
   */
  //const shownVariant = colourVariantsMap[selectedVariantId] ?? item;
  const[shownVariant, setShownVariant] = useState(item);


  useEffect(() => {
    if (selectedVariantId === item?.id) {
      setShownVariant(item);
      //updateHistory(item, historyPeriod);
      return;
    }
    
    fetchSingleItem(selectedVariantId)
      .then(v => {
        setShownVariant(v ?? item);
        ///updateHistory(v, historyPeriod);})
      })
      .catch(console.error);
  }, [item, selectedVariantId]);

  // const variantsTrimmed = useMemo(
  //   () => item.availableColours.slice(0, maxColoursToShow - 1), // keep one for item itself
  //   [item]
  // );

  const [variantsTrimmed, setVariantsTrimmed] = useState([] as IdAndColours[]);
  const [loadedItem, setLoadedItem] = useState(undefined as ItemId | undefined)

  const isProductMode = () => props.mode === "ProductPage";

  useEffect(() => {
    if (loadedItem) return;
    if (item && !loadedItem) setLoadedItem(item.id);
    
    setVariantsTrimmed(item.availableColours.slice(0, maxColoursToShow - 1)); // keep one for item itself
    
    if (!colourVariantsMap[selectedVariantId]) {
      
      fetchColourVariants(variantsTrimmed).then((variants) => {
        const loadedVariantsMap = arrayToObj(variants, (variant) => variant.id);
        colourVariantsMapSet((state) => ({ ...state, ...loadedVariantsMap }));
      })
    }
  }, [item]);

  const [descFolded, setDescFolded] = useState(true);
  const switchDescFolded = () => {
    setDescFolded(!descFolded);
    if (descFolded)
      saveSingleAction("description", item.category ?? "all", item.id);
  };

  const [activeDotIndex, setActiveDotIndex] = useState(0);
  const [scrollActionSent, setScrollActionSent] = useState(false);

  useEffect(() => {
    if (activeDotIndex > 0 && !scrollActionSent) {
      saveSingleAction("imageScroll", item.category ?? "all", item.id);
      setScrollActionSent(true);
    }
  }, [activeDotIndex]);


  const createWatch = async () => {
    await postPriceWatch(shownVariant);
  }

  const deleteWatch = async () => {
    await deletePriceWatch(shownVariant);
  }

  const imageScrollDotsJsx = (
    <ImagesScrollDots
      dotsCount={getImagesToShow(shownVariant).length}
      activeDotIndex={activeDotIndex}
    />
  );

  const isDirectSelling = partnerData
    .filter(x => x.status === "Active")
    .find(x => x.retailer === item.retailer)
    ?.businessModel === "Direct Selling";
    
    
    
    /**
     * This is a simple function and not a component because otherwise React
     * handles mounting and unmounting weirdly.
     * Reason it's a function in the first place is so that we can have
     * conditionals with variable assignments inside it.
    */
   const actionPanelWithContents = (isShopRec: boolean) => {
     if (props.mode === "RecsPage") {
       const { removeRec, replaceDiscardedRec, index, itemsLen, itemRef, goToElem, prevId, nextId } = props;
       const { prev, next } = itemRef;

       const nextClick = () => {
        const action = openModal
          ? openModal
          : next ? () => goToElem(next, nextId) : undefined;

        triggerNextItemEvent();
         
        if (action) action();
      };

      const prevClick = () => {
        if (prev) {
          goToElem(prev, prevId);
          triggerPrevItemEvent();
        }
      };

      return (
        <ActionPanelContainer>
          <ActionPanel>
            <ActionButton
              color={colors.orange}
              size="lg"
              direction="left"
              onClick={prevClick}
              disabled={index === 0}
            >
              {/* <FontAwesomeIcon icon={faCaretLeft} color="white" size="3x" /> */}
              <Icon path={mdiMenuLeft} size={4.5} css={ActionLeftIconCss}/>
              {/* <Icon path={mdiChevronDoubleLeft} size={3}/> */}
            </ActionButton>
            <ActionButton
              color={colors.red}
              size="sm"
              onClick={() => {
                if (user) {
                  deletePriceWatch(shownVariant)
                  removeRec(shownVariant.id, category);
                  replaceDiscardedRec(category);
                } else {
                  // @TODO: Ideally remember to discard item right after login.
                  openAuthModal();
                }
              }}
              disabled={isShopRec}
            >
              <FontAwesomeIcon icon={faTimes} color="white" size="2x" />
            </ActionButton>
            <SaveForLaterButtonNew
              item={shownVariant}
              affiliateId={shownVariant.affiliateId??""}
              category={category}
              disabled={isShopRec}
            />
            <ActionButton
              color={colors.orange}
              size="lg"
              direction="right"
              onClick={nextClick}
              disabled={index >= itemsLen - 1}
            >
              {/* <FontAwesomeIcon icon={faCaretRight} color="white" size="3x" /> */}
              <Icon path={mdiMenuRight} size={4.5} css={ActionRightIconCss}/>
            </ActionButton>
          </ActionPanel>
        </ActionPanelContainer>
      );
    } else {
      return null;
    }
  };

  const isShopRec = item.id === "shop_rec";
  const buyButton = isDirectSelling && item.stockData?.isInStock
    ? <AddToCartButton item={shownVariant} variantId={selectedVariantId}/>
    : <BuyNowButton
        category={category}
        isOutOfStock={!shownVariant.stockData}
        customerId={customerId}
        item={shownVariant}
        score={props.mode === "RecsPage" ? props.itemScore : undefined}
        userId={user?.uid}
      />

  const checkBounds = () => {
    if (!openModal) return;
    
    const el = props.mode === "RecsPage" && props.isLastItem ? document.getElementById("itm") : undefined;
    const bounds = el?.getBoundingClientRect();
    console.log(window.innerWidth, bounds);

    if ((bounds?.left ?? -1) >= 0) openModal(); 
  }

  return (
    <RecItemStyled id={"itm"} ref={props.mode === "RecsPage" ? props.itemRef : undefined} onTouchEnd={checkBounds}>
      <ItemImagesContainer
        item={shownVariant}
        onVisibleImageChange={setActiveDotIndex}
      >
        <DisplayWiderThanMobileOnly>
          {imageScrollDotsJsx}
          {actionPanelWithContents(isShopRec)}
        </DisplayWiderThanMobileOnly>
      </ItemImagesContainer>
      <ContentOverlay>
        <DisplayMobileOnly>
          {imageScrollDotsJsx}
          {actionPanelWithContents(isShopRec)}
        </DisplayMobileOnly>
        <ContentOverlayTextContent>
          {props.mode === "RecsPage" && !isShopRec && (
            <DisplayWiderThanMobileOnly>
              <ItemStatus item={shownVariant} />
              <Line />
            </DisplayWiderThanMobileOnly>
          )}
          {!isShopRec && (<ItemContentHeader item={shownVariant} />)}
          {coupon && !isDirectSelling && (
            <CouponBar 
              type={coupon.couponType}
              code={coupon.code}
              discount={coupon.discount}
              isUpTo={coupon.isUpTo}
            />
          )}
          <Spacer height="25px" />
          <IOSBuyNowButtonContainer recsOrProductPage={props.mode}>
            {!isShopRec && (<ShareButton item={shownVariant} share={share} />)}
            {isProductMode() && (
              <SaveForLaterButton 
                item={shownVariant} 
                category={category} 
                isActive={item.isRetailerOperating} 
            />)}
            {isShopRec
              ? <GoToShopButton category={item.category!}/>
              : buyButton}
          </IOSBuyNowButtonContainer>
          {variantsTrimmed.length > 0 && (
            <>
              <ColourVariants
                itemOwnColours={item}
                variants={variantsTrimmed}
                selectedVariantId={selectedVariantId}
                onClick={setSelectedVariantId}
              />
              <Spacer height="25px" />
            </>
          )}
          {item.availableColours.length + 1 > maxColoursToShow && (
            <MoreColourVariantsAvailable>
              additional colours and finishes available at {item.brand}
            </MoreColourVariantsAvailable>
          )}
          {!isShopRec && (
            <>
              <Line/>
              <Spacer height="25px" />
              <PriceChart 
                item={shownVariant} 
                //history={history} 
                //historyPeriod={historyPeriod} 
                //updateHistory={updateHistory}
                //isWatchCreated={isWatched}
                createWatch={createWatch}
                deleteWatch={deleteWatch}
              />
              <Spacer height="25px" />
            </>
          )}
          {(shownVariant.height ?? shownVariant.width ?? shownVariant.depth ?? shownVariant.weight) && (
            <>
              <Line />
              <MoreInfo item={shownVariant} />
            </>
          )}
          {shownVariant.description && (
            <>
              <Line />
              <DescriptionContent 
                item={shownVariant} 
                switchFolded={switchDescFolded}
                isFolded={descFolded}
              />
              { !isProductMode && (<Line />) }
            </>
          )}
          {
            isShopRec && (
              <ShopDescription category={category} />
            )
          }
          {props.mode === "ProductPage" && props.similarItems.length > 0 &&(
            <>
              <Line />
              <YouMayAlsoLike items={props.similarItems} category={category} />
            </>
          )}
        </ContentOverlayTextContent>
        <BuyNowButtonContainer recsOrProductPage={props.mode} id="last">
          {!isShopRec && <ShareButton item={shownVariant} share={share} />}
          {isProductMode() && ( 
            <SaveForLaterButton 
              item={shownVariant} 
              category={category} 
              isActive={item.isRetailerOperating}
          /> )}
          {isShopRec
            ? <GoToShopButton category={item.category!}/>
            : buyButton}
        </BuyNowButtonContainer>
      </ContentOverlay>
    </RecItemStyled>
      //<div>{item.id === "shop_rec" ? shopRecommendationPage(item.category!) : recommendationPage()}</div>
  );
};
