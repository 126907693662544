import styled, { css } from "styled-components";

import { fontSizes } from "../../styles/globalStyled";

export const PrivacyPolicyPageWrapper = styled.div`
  width: 100vw;
  background: white;
  overflow: auto;
  font-size: 1.5rem;
`;

export const PrivacyPolicyPageContainer = styled.div`
  width: 100vw;
  background: white;
  overflow: auto;
`;

const privacyPolicyCSS = css`
  .lst-kix_lwiqqo810fp6-7 > li:before {
    content: "\0025cb  ";
  }
  ul.lst-kix_lwiqqo810fp6-8 {
    list-style-type: none;
  }
  ul.lst-kix_lwiqqo810fp6-7 {
    list-style-type: none;
  }
  .lst-kix_f0koknxpuvp1-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_f0koknxpuvp1-1 > li:before {
    content: "\0025cb  ";
  }
  ul.lst-kix_lwiqqo810fp6-4 {
    list-style-type: none;
  }
  ul.lst-kix_lwiqqo810fp6-3 {
    list-style-type: none;
  }
  .lst-kix_lwiqqo810fp6-6 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_lwiqqo810fp6-6 {
    list-style-type: none;
  }
  ul.lst-kix_lwiqqo810fp6-5 {
    list-style-type: none;
  }
  ul.lst-kix_lwiqqo810fp6-0 {
    list-style-type: none;
  }
  .lst-kix_lwiqqo810fp6-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_f0koknxpuvp1-5 > li:before {
    content: "\0025a0  ";
  }
  ul.lst-kix_lwiqqo810fp6-2 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-4 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-4;
  }
  ul.lst-kix_lwiqqo810fp6-1 {
    list-style-type: none;
  }
  .lst-kix_lwiqqo810fp6-5 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_f0koknxpuvp1-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_f0koknxpuvp1-7 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_lwiqqo810fp6-4 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_f0koknxpuvp1-8 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_lwiqqo810fp6-2 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_q59b3dopztiv-8 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_q59b3dopztiv-7 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_lwiqqo810fp6-1 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_lwiqqo810fp6-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_q59b3dopztiv-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_bt4ntfjj7a2-5 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-5;
  }
  .lst-kix_q59b3dopztiv-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_nzmhc09lzxbp-2 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_nzmhc09lzxbp-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_q59b3dopztiv-1 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_q59b3dopztiv-5 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_nzmhc09lzxbp-1 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_nzmhc09lzxbp-5 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_q59b3dopztiv-0 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_q59b3dopztiv-4 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_nzmhc09lzxbp-4 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_q59b3dopztiv-2 > li:before {
    content: "\0025a0  ";
  }
  ol.lst-kix_bt4ntfjj7a2-8.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-8 0;
  }
  ol.lst-kix_bt4ntfjj7a2-2.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-2 0;
  }
  .lst-kix_bt4ntfjj7a2-3 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-3;
  }
  .lst-kix_nzmhc09lzxbp-6 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_nzmhc09lzxbp-7 > li:before {
    content: "\0025cb  ";
  }
  .lst-kix_f0koknxpuvp1-4 > li:before {
    content: "\0025cb  ";
  }
  ol.lst-kix_bt4ntfjj7a2-5.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-5 0;
  }
  .lst-kix_nzmhc09lzxbp-8 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_f0koknxpuvp1-2 > li:before {
    content: "\0025a0  ";
  }
  .lst-kix_f0koknxpuvp1-3 > li:before {
    content: "\0025cf  ";
  }
  .lst-kix_bt4ntfjj7a2-5 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-5, lower-roman) ". ";
  }
  ul.lst-kix_f0koknxpuvp1-8 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-6.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-6 0;
  }
  ul.lst-kix_f0koknxpuvp1-7 {
    list-style-type: none;
  }
  ul.lst-kix_f0koknxpuvp1-6 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-3 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-3, decimal) ". ";
  }
  .lst-kix_bt4ntfjj7a2-2 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-2, lower-roman) ". ";
  }
  .lst-kix_bt4ntfjj7a2-6 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-6, decimal) ". ";
  }
  .lst-kix_bt4ntfjj7a2-6 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-6;
  }
  .lst-kix_bt4ntfjj7a2-4 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-4, lower-latin) ". ";
  }
  .lst-kix_bt4ntfjj7a2-1 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-1, lower-latin) ". ";
  }
  ul.lst-kix_f0koknxpuvp1-1 {
    list-style-type: none;
  }
  ul.lst-kix_f0koknxpuvp1-0 {
    list-style-type: none;
  }
  ul.lst-kix_f0koknxpuvp1-5 {
    list-style-type: none;
  }
  .lst-kix_nzmhc09lzxbp-0 > li:before {
    content: "\0025cf  ";
  }
  ul.lst-kix_f0koknxpuvp1-4 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-0 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-0, upper-latin) ". ";
  }
  ul.lst-kix_f0koknxpuvp1-3 {
    list-style-type: none;
  }
  ul.lst-kix_f0koknxpuvp1-2 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-8 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-7 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-6 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-2 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-2;
  }
  ol.lst-kix_bt4ntfjj7a2-5 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-4 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-0.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-0 0;
  }
  ol.lst-kix_bt4ntfjj7a2-3 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-2 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-1 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-0 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-7.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-7 0;
  }
  .lst-kix_bt4ntfjj7a2-7 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-7, lower-latin) ". ";
  }
  .lst-kix_bt4ntfjj7a2-8 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-8, lower-roman) ". ";
  }
  ol.lst-kix_bt4ntfjj7a2-4.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-4 0;
  }
  ul.lst-kix_nzmhc09lzxbp-0 {
    list-style-type: none;
  }
  ul.lst-kix_nzmhc09lzxbp-1 {
    list-style-type: none;
  }
  ul.lst-kix_nzmhc09lzxbp-4 {
    list-style-type: none;
  }
  ul.lst-kix_nzmhc09lzxbp-5 {
    list-style-type: none;
  }
  ul.lst-kix_nzmhc09lzxbp-2 {
    list-style-type: none;
  }
  ul.lst-kix_nzmhc09lzxbp-3 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-1.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-1 0;
  }
  ul.lst-kix_nzmhc09lzxbp-8 {
    list-style-type: none;
  }
  ul.lst-kix_nzmhc09lzxbp-6 {
    list-style-type: none;
  }
  ul.lst-kix_nzmhc09lzxbp-7 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-8 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-8;
  }
  ul.lst-kix_q59b3dopztiv-8 {
    list-style-type: none;
  }
  ul.lst-kix_q59b3dopztiv-7 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-0 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-0;
  }
  ul.lst-kix_q59b3dopztiv-4 {
    list-style-type: none;
  }
  ul.lst-kix_q59b3dopztiv-3 {
    list-style-type: none;
  }
  ul.lst-kix_q59b3dopztiv-6 {
    list-style-type: none;
  }
  ul.lst-kix_q59b3dopztiv-5 {
    list-style-type: none;
  }
  ul.lst-kix_q59b3dopztiv-0 {
    list-style-type: none;
  }
  ul.lst-kix_q59b3dopztiv-2 {
    list-style-type: none;
  }
  ul.lst-kix_q59b3dopztiv-1 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-1 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-1;
  }
  ol.lst-kix_bt4ntfjj7a2-3.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-3 0;
  }
  .lst-kix_bt4ntfjj7a2-7 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-7;
  }
  .lst-kix_lwiqqo810fp6-8 > li:before {
    content: "\0025a0  ";
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c7 {
    padding-top: 12pt;
    padding-bottom: 2pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c3 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
  }
  .c12 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c13 {
    padding-top: 14pt;
    padding-bottom: 4pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c19 {
    padding-top: 0pt;
    padding-bottom: 12pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c0 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c8 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
  }
  .c21 {
    background-color: #ffffff;
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
  }
  .c14 {
    background-color: #ffffff;
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #007fad;
    text-decoration: underline;
  }
  .c16 {
    font-weight: 400;
    vertical-align: baseline;
    font-style: normal;
  }
  .c20 {
    background-color: #ffffff;
    max-width: 451.4pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c23 {
    color: #666666;
    text-decoration: none;
    font-size: 12pt;
  }
  .c2 {
    background-color: #ffffff;
    color: #37454d;
  }
  .c10 {
    padding: 0;
    margin: 0;
  }
  .c5 {
    color: inherit;
    text-decoration: inherit;
  }
  .c15 {
    background-color: #ffffff;
    color: #007fad;
  }
  .c1 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c11 {
    font-size: 11pt;
  }
  .c17 {
    color: #007fad;
  }
  .c9 {
    color: #37454d;
  }
  .c22 {
    height: 12pt;
  }
  .c18 {
    height: 11pt;
  }
  .c6 {
    color: #000000;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
  }
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
`;

interface IMiddleSection {
  background: string;
  height: string;
  maxHeight: string;
}

export const Section = styled.div<IMiddleSection>`
  ${({ background, height, maxHeight }) => css`
    background: ${background};
    height: ${height};
    max-height: ${maxHeight};
  `}
  display: flex;
`;

export const PrivacyPolicyTextWrapper = styled.div`
  /* background: white; */
  width: 95vw;
  max-width: 100rem;
  margin: auto;
  padding-top: 9rem;
  padding-bottom: 9rem;
  /* margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  h1 {
    font-size: ${fontSizes.large};
    font-weight: bolder;
    text-align: left;
    padding: 1rem;
  }
  h2 {
    font-size: ${fontSizes.medium};
    font-weight: bolder;
    text-align: left;
    padding: 1rem;
  }
  p {
    font-size: ${fontSizes.small};
    text-align: left;
    padding: 1rem;
  } */
  ${privacyPolicyCSS}
`;

export const PrivacyPageTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
`;

export const PrivacySectionHeader = styled.h2`
  margin-top: 1rem;
  font-weight: bold;
`;

export const PrivacySectionBody = styled.p`
  margin-top: 1rem;
`;
