import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShopContext } from "../../../../store/shopContext";
import { capitalise, seoficateTag } from "../../../../utils/helpers";
import { MenuProps } from "../MobileHamburger";
import { TabContent } from "../MobileHamburgerStyles";
import { MenuExpansion, MenuOption } from "./MenuOption";
import { Submenu } from "./Submenu";


export const BrowseTab: FC<MenuProps> = ({closeMenu, isOpen}) => {
  const { browseMetadata } = useShopContext();
  const [ group, setGroup ] = useState<TagGroup|undefined>(undefined);

  const navigate = useNavigate();
  const capitalizeNouns = (group?: TagGroup) =>
    (group?.displayName ?? group?.name ?? "")
    .split(" ")
    .map(x => x === "and" ? x : capitalise(x))
    .join(" ");

  return (
    <TabContent>
      {browseMetadata?.tags.map(tag => (
        <MenuExpansion 
          key={tag.name}
          label={capitalizeNouns(tag)} 
          onClick={() => setGroup(tag)}
        />
      ))}
      <Submenu
        visible={!!group && isOpen}
        items={group?.children.map(c => c.name) ?? []}
        onItemClick={closeMenu}
        onGoBack={() => setGroup(undefined)}
        goBackLabel={capitalizeNouns(group)}
        urlPrefix={group ? `shop/${seoficateTag(group.name)}` : "shop"}
        urlPostfix={undefined}
      />
    </TabContent>
  );
};