import { FC } from "react";
import { TabContentCloseLine, TabContentLine, TabContentLineP, TabContentLineText, TabContentLineWrapper } from "../MobileHamburgerStyles";
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
import { Icon } from "@mdi/react";
import { WideLine } from "../../../../pages/RecommendationsPage/RecommendationsPageStyled";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

interface Props {
  label: string;
  onClick: () => void;
}

interface OptionProps extends Props{
  to: string;
}

const getMenuOption = (
  isCloseLine: boolean,
  onClick: () => void, 
  element: EmotionJSX.Element
) => {
  const content = 
      isCloseLine
          ?  
            <TabContentCloseLine>
              {element}
            </TabContentCloseLine>
          :  
            <TabContentLine>
              {element}
            </TabContentLine>

  return (
  <TabContentLineWrapper onClick={onClick}>
    {content}
    <WideLine />
  </TabContentLineWrapper>
)};

export const MenuOption: FC<OptionProps> = ({label, to, onClick}) => 
  getMenuOption(false, onClick, (<>
    <TabContentLineText to={to}>
      {label}
    </TabContentLineText>
    <Icon path={mdiChevronRight} size={2}/>
  </>));

export const MenuOptionExternal: FC<OptionProps> = ({label, to, onClick}) => 
  getMenuOption(false, onClick, (<>
    <a href={to}>{label}</a>
    <Icon path={mdiChevronRight} size={2}/>
  </>));

export const MenuExpansion: FC<Props> = ({label, onClick}) => 
    getMenuOption(false, onClick, (<>
      <TabContentLineP>
        <b>{label}</b>
      </TabContentLineP>
      <Icon path={mdiChevronRight} size={2}/>
    </>));

export const MenuGoBack: FC<Props>  = ({label, onClick}) => 
  getMenuOption(true, onClick, (<>
    <Icon path={mdiChevronLeft} size={2}/>
    <TabContentLineP>
      <b>{label}</b>
    </TabContentLineP>
  </>));
