import { FC } from "react";
import { TabContent } from "../MobileHamburgerStyles";
import { MenuOption, MenuOptionExternal } from "./MenuOption";

interface Props {
  closeMenu: () => void;
}

export const AboutTab : FC<Props> = ({closeMenu}) => (
  <TabContent>
    <MenuOption 
      label="About Us" 
      to="about"
      onClick={closeMenu}/>
    <MenuOption 
      label="Pricing" 
      to="pricing"
      onClick={closeMenu}/>
    <MenuOption 
      label="FAQs" 
      to="faqs"
      onClick={closeMenu}/>
    <MenuOptionExternal
      label="Blog" 
      to="https://blog.withflitch.com/?_ga=2.117174468.353551237.1656932161-1680926085.1641537073"
      onClick={closeMenu}/> 
  </TabContent>
);
