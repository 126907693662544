import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { triggerAccUpgradeEvent, triggerUpgradeEvent } from "../../dataLayer";
import { getUserGuid, upgradeAccount } from "../../services/api.service";
import { useUserContext } from "../../store/userContext";

export const CheckoutStylistResultPage = () => {
  const getParamByName = (name: string, search: string[]) => 
    search.find(x => x.startsWith(name))?.split("=")[1];

    const getParams = () => {
      let search = window.location.search.replace("?", "").split("&");
      let result = getParamByName("result", search);
      let redirect = getParamByName("redirect", search);
  
        if (!result || !redirect)
          console.error(`Wrong checkout result query: ${window.location.search}`, search);
  
        return [result, redirect] as const;
    };

    const { user } = useUserContext();

    const [result, redirect] = getParams();
    const navigate = useNavigate();

    const goBack = () => navigate(redirect ?? "/");

    const detailsToGtm = () => 
      getUserGuid()
        .then(guid => triggerAccUpgradeEvent(guid, user?.email ?? ""))
        .catch(console.error)

    const finalizeOrder = async () => {
      detailsToGtm();
      triggerUpgradeEvent();
      return await upgradeAccount();
    };

    useEffect(() => {
      if (!user) return;
  
      switch (result?.toLowerCase()) {
        case undefined: return;
        case "cancel": 
          goBack();
          return;
        case "success": 
          finalizeOrder()
            .then(() => {navigate("/got-stylist")})
            .catch(err => {
              console.error(err);
              goBack();
            });
          return;
        default:
          console.warn("Invalid checkout session result", result);
          goBack();
          return;
      }
    }, [result, user]);

    return <>
      {result === "success"
        ? "Processing the subscription..."
        : "We're getting you back..."}
      </>;
}