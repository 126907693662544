import styled, { keyframes } from "styled-components";
import { colors, fontSizes } from "../../styles/globalStyled";
import { Link } from "react-router-dom";
import { breakpoints } from "../../styles/responsive";

export const LoadingPageWrapper = styled.div`
  width: 100vw;
  background-color: #008c8d;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: var(--navbar-height);

  @media (max-width: ${breakpoints.mobile}) {
    top: var(--navbar-height-mobile);
  }
`;

export const LoadingPageContainer = styled.div`
  height: 100%;
  width: 70rem;
  max-width: 95vw;
  margin: auto;
  display: grid;
  justify-content: center;
  align-content: center;
  overflow: hidden;

  img {
    height: 20rem;
    margin-bottom: -4rem;
    justify-self: center;
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  @media (orientation: landscape) {
    grid-template-columns: 30rem 30rem;
    justify-content: space-evenly;
  }
  @media (orientation: portrait) {
    grid-template-rows: 4rem 4rem;
    grid-template-columns: 30rem;
    justify-content: center;
    grid-gap: 2rem;
  }
`;

export const Button = styled.div<{ active?: boolean }>`
  background: white;
  border-radius: 3rem;
  height: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: ${fontSizes.small};
  font-weight: bolder;
  cursor: pointer;
  ${({ active }) =>
    !active &&
    `
    background: #FFA500;
    color: white;
  `}
`;

export const LoadingText = styled.p`
  max-width: 95vw;
  width: 70rem;
  margin-bottom: 5rem;
  font-size: ${fontSizes.medium};
  color: white;
  text-align: center;
`;

export const AfterLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const AfterLoadingButton = styled.div`
  cursor: pointer;
  background: white;
  height: 8rem;
  width: 40rem;
  border-radius: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes.medium};
  font-weight: bolder;
  text-decoration: none;
  span {
    color: inherit;
  }
`;

export const NoItemsWrapper = styled.div`
  display: grid;
`;
export const GoBackButton = styled(Link)`
  background: white;
  width: 30rem;
  height: 5rem;
  border-radius: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  justify-content: center;
  align-content: center;
  justify-self: center;
  align-self: center;
  font-size: ${fontSizes.medium};
  font-weight: bolder;
  text-decoration: none;
  span {
    /* color: white; */
  }
`;

const ellipsis1 = keyframes`
0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ellipsis3 = keyframes` 
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;
export const LoadingCircle = styled.div`
  justify-self: center;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 6px;
    animation: ${ellipsis1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;
