import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, fontSizes } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";

const getColour = (p : {type: BrowsePromptType}) => {
  switch (p.type) {
    case "sq": return colors.green;
    case "stylist": return colors.orange;
  }
}

export const BrowsePromptBox = styled.div<{type: BrowsePromptType}>`
  display: grid;
  /* flex-direction: column; */
  grid-template-rows: auto 1fr 10%;
  padding-bottom: 10px;
  background-color: ${getColour};
  padding: 5%;
  line-height: 1.1;

  p {
    color: white;
    font-size: 1.5vw;
    margin-bottom: 5%;
    margin-right: 15%;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: 2vh;
    }
  }

  h3 {
    color: white;
    font-size: 2vw;
    font-family: "BuenosAires";
    font-weight: 600;
    margin-right: 15%;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: 2.5vh;
    }
  }
`;

export const BrowsePromptButton = styled(Link)<{type: BrowsePromptType}>`
  color: ${getColour};
  background-color: white;
  cursor: pointer;
  border-radius: 7px;
  text-align: center;
  /* padding: 5%; */
  vertical-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes.large};
  font-weight: 600;

  @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.verySmall};
    }
`;
