import React, { useEffect } from "react";
import NavBar from "../../shared/NavBar/NavBar";
import { categoryData } from "../../store/categoryData";
import { Headings } from "../../shared/Headings/Headings";
import { minSelectedItems } from "../../config";
import { SQFooter } from "./SQFooter";
import { useCategory } from "../../hooks/useCategory";
import { StyleQuizPageWrapper } from "./StyleQuizPageStyled";
import { Listing } from "./Listing/Listing";
import { useSqFlowContext } from "../../store/SqFlowProvider";
import { isShortSq } from "../../metadata";
import {
  getPrefiltersUrl,
  getRecsUrl,
  getSingleSqSelecteds,
} from "../../utils/helpers";
import { NoResultsFoundPage, SQLoading } from "./SQComponents";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { SQNoItemsFound } from "../LoadingPage/LoadingPage";
import { useFilterCtx } from "../../store/filterContext";
import {
  triggerNoRecsItemsFromSQ,
  triggerNoSqItemsLoaded,
} from "../../dataLayer";
import { MobileRoadmap } from "../../shared/NavBar/RoadMap/MobileRoadmap";

const maxForGoingDirectlyToRefine = 20;
const maxForSingleSqPage = 60; // @TODO: DRYify with one in dynamicSQContext

export const SQ1 = () => {
  const category = useCategory();
  const { makeFiltersNotApplyToRecs, dontApplyFiltersToRecs } = useFilterCtx();

  const recsUrl = getRecsUrl(category);
  const prefiltersUrl = getPrefiltersUrl(category);

  const {
    fetchSqItems,
    sq1Items,
    page1And2State,
    toggleSq1Sq2SelectedItem,
    isSq1And2Loading,
    page1And2NeedReload,
    resetPage3StateOnwards,
  } = useSqFlowContext();
  const { selectedItems, items, filtersExcludeAllRecs } = page1And2State;

  // @TODO: make this throttled
  useEffect(() => {
    if (!isSq1And2Loading && page1And2NeedReload) {
      fetchSqItems();
    }
  }, [isSq1And2Loading, page1And2NeedReload]);

  useEffect(() => {
    if (isShortSq(category)) {
      // At least needed when user is using the app in two pages at once, maybe
      // even required normally also.
      resetPage3StateOnwards();
    }
  }, [category]);

  const selectedsFromThisPage = getSingleSqSelecteds(selectedItems, sq1Items);

  const isMinSelected = selectedsFromThisPage.length >= minSelectedItems;

  return (
    <>
      <FlitchHelmet title="Preferences — Style Quiz" />
      <NavBar />
      <MobileRoadmap />
      <StyleQuizPageWrapper>
        {sq1Items === null ? (
          <SQLoading />
        ) : sq1Items.length < maxForGoingDirectlyToRefine ? (
          <NoResultsFoundPage
            headerText="Uh oh!"
            bodyText={`It seems your preferences are quite specific; you can either go back and adjust what you've already told us, or you can continue to see what we do have that fits the bill`}
            firstButtonProps={{ label: "Amend", path: prefiltersUrl }}
            sndButtonProps={{ label: "Continue", path: recsUrl }}
            effectOnMount={() => triggerNoSqItemsLoaded(category)}
          />
        ) : filtersExcludeAllRecs && !dontApplyFiltersToRecs ? (
          <SQNoItemsFound
            makeFiltersNotApplyToRecs={makeFiltersNotApplyToRecs}
            category={category}
            effectOnMount={() => triggerNoRecsItemsFromSQ(category)}
          />
        ) : (
          <>
            <Headings
              primary={`Pick the ${categoryData[category].plural} you like best`}
              sqPageNumber={1}
            />
            <Listing
              category={category}
              items={sq1Items}
              selected={selectedItems}
              toggleSelectItem={toggleSq1Sq2SelectedItem}
            />
            <SQFooter
              isMinSelected={isMinSelected}
              nextPage={
                isShortSq(category)
                  ? recsUrl // directly to recs
                  : `/style-quiz/${category}/3` // to refine page
              }
            />
          </>
        )}
      </StyleQuizPageWrapper>
    </>
  );
};
