import { FC } from "react";
import { categoryReadableNames, forcedPluralize, seoficateTag } from "../../../utils/helpers";
import { BuyNowLinkStyled } from "../RecommendationsPageStyled";

interface ButtonProps {
  category: CategoryUnion
}

export const GoToShopButton: FC<ButtonProps> = ({category}) => {
  const name = categoryReadableNames[category];
  const pluralName = forcedPluralize(name);

  // const getHash = () => {
  //   switch (name) {
  //     case "Shelving": return "free-Standing-Shelving-and-Bookcases";
  //     case "TV Stand": return "Media-Units-and-TV-Stands";
  //     case "Bar Stool": return "Barstools";
  //     case "Bed": return "Bed-Frames";
  //     default: return pluralName;
  //   }
  // }

  const postfix = () => {
    switch (category) {
      case "armchair": return "chairs-seating/armchairs";
      case "barstool": return "chairs-seating/bar-stools";
      case "bed": return "beds";
      case "bedsidetable": return "tables/bedside-tables";
      case "chestofdrawers": return "home-storage-and-organisation/chest-of-drawers";
      case "coffeetable": return "tables/coffee-tables";
      case "consoletable": return "tables/console-tables";
      case "diningchair": return "chairs-seating/dining-chairs";
      case "diningtable": return "tables/dining-tables";
      case "displaycabinet": return "home-storage-and-organisation/display-cabinets";
      case "shelving": return "home-storage-and-organisation/free-standing-shelves";
      case "sideboard": return "home-storage-and-organisation/sideboards";
      case "sidetable": return "tables/side-tables";
      case "sofa": return "sofas-and-armchairs/sofas";
      case "tvstand": return "home-storage-and-organisation/tv-stands-media-unit";
      case "wardrobe": return "home-storage-and-organisation/wardrobes";
    }
  }
  
  const url = `${window.location.origin}/shop/${postfix()}`
  
  return (
    <BuyNowLinkStyled isActive={true} href={url} target="_blank" rel="noopener">
      {`View More ${pluralName}`}
    </BuyNowLinkStyled>
  )
}
