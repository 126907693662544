import { Md5 } from "ts-md5";
import { isNonStaff } from "./config";

interface DataLayerEvent {
  event: string;
  [others: string]: any;
}
declare global {
  interface Window {
    dataLayer?: DataLayerEvent[];
  }
}

interface GoogleRetailItem {
  id: ItemId;
  google_business_vertical: "retail";
}

interface GoogleRemarketingEvent {
  event: "recommendations-loaded";
  remarketingItemsList: GoogleRetailItem[];
  fbItemIds: ItemId[];
}

const pushToDataLayer = (obj: DataLayerEvent) => {
  window.dataLayer && isNonStaff && window.dataLayer.push(obj);
};

const makeCategoryEvent = (
  event: string,
  category: CategoryUnion
): DataLayerEvent => ({ event, category });

export const triggerSignupEvent = () => pushToDataLayer({ event: "signup" });
export const triggerUpgradeEvent = () => pushToDataLayer({ event: "lead" });
export const triggerNoteSentEvent = () => pushToDataLayer({ event: "submit-application" });
export const triggerBuyNowEvent = () =>
  pushToDataLayer({ event: "click-buynow" });
export const triggerSaveItemEvent = () =>
  pushToDataLayer({ event: "click-save-item" });
export const triggerDiscardEvent = () =>
  pushToDataLayer({ event: "click-discard-item" });
export const triggerNextItemEvent = () =>
  pushToDataLayer({ event: "click-next-item" });
export const triggerPrevItemEvent = () =>
  pushToDataLayer({ event: "click-previous-item" });

export const triggerRecsLoadedEvent = (itemIds: ItemId[]) => {
  const remarketingEvent: GoogleRemarketingEvent = {
    event: "recommendations-loaded",
    remarketingItemsList: itemIds.map((id) => ({
      id,
      google_business_vertical: "retail",
    })),
    fbItemIds: itemIds,
  };

  pushToDataLayer(remarketingEvent);
};

export const triggerAccUpgradeEvent = (
  userGuid: string, email: string
) => {
  let hash = Md5.hashStr(email);

  pushToDataLayer({
    event: "acc-upgrade",
    transactionId: userGuid,
    transactionTotal: 20.83,
    transactionPromoCode: undefined,
    md5: hash });
};

/**
 * When there aren't enough items to show in the SQ.
 */
export const triggerNoSqItemsLoaded = (category: CategoryUnion) =>
  pushToDataLayer(makeCategoryEvent("PostPFnotEnoughSQItems", category));

/**
 * When the SQ req tells us there won't be enough items in Recs for the filters
 * the user has selected.
 * It tells us this via the `DynamicSqResponse.filtersExcludeAllRecs` flag.
 */
export const triggerNoRecsItemsFromSQ = (category: CategoryUnion) =>
  pushToDataLayer(makeCategoryEvent("PostPFnotEnoughRecsItems", category));

/**
 * When there aren't enough items to return with the regular scoring logic so we
 * ignore the user's selected items and just return random items that match the
 * user's selected filters.
 */
export const triggerNoRecsItems = (category: CategoryUnion) =>
  pushToDataLayer(makeCategoryEvent("PostSQNotEnoughRecsItems", category));
