import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { FC, Fragment } from "react";
import { useNavigate, useResolvedPath } from "react-router";
import { SearchShopCategory, useSearchContext } from "../../../../store/searchContext";
import { categoryReadableNames, forcedPluralize } from "../../../../utils/helpers";
import { 
  LineItemContainer, 
  MagnifyStyle, 
  SearchOption, 
  SearchOptionsList, 
  Separator 
} from "./SearchStyles";

interface SearchOptionProps {
  label: any;
  clearSearch: boolean;
  onClick:() => void;
}

const Option: FC<SearchOptionProps> = ({label, clearSearch, onClick}) => {
  const {applySearch} = useSearchContext();

  const handleClick = () => {
    if (clearSearch) applySearch("", false, true);
    onClick();
  };

  return (
    <SearchOption onMouseDown={handleClick}>
      <LineItemContainer>
        <Icon path={mdiMagnify} size={1.5} css={MagnifyStyle}/>
      </LineItemContainer>
      <p>{label}</p>
    </SearchOption>
  );
};

interface SearchOptionsProps {
  searchInput: HTMLInputElement;
  visible: boolean;
  allCategoriesFirst? : boolean;
  onSelect?: () => void;
}

export const SearchOptions: FC<SearchOptionsProps> = ({searchInput, visible, allCategoriesFirst, onSelect}) => {
  const navigate = useNavigate();
    
  const { keywords, search, applySearch, selectShopCat } = useSearchContext();
  const { recCats, shopCats } = keywords;

  const url = useResolvedPath("").pathname;
  const isOnShopPage = url.includes("/shop");

  const goToSQ = (cat: CategoryUnion) => {
    searchInput.blur();

    applySearch(searchInput?.value ?? "", true, true).then(() => {
      navigate(`/style-quiz/${cat}/filter`);
    });
  
    if (onSelect) onSelect();
  };
  
  const getLabelSQ = (cat: CategoryUnion) => forcedPluralize(categoryReadableNames[cat]);

  const goToShop = (catToSelect: SearchShopCategory|undefined = undefined) => {
    searchInput.blur();

    applySearch(searchInput?.value ?? "", false, !isOnShopPage).then(() => {
        selectShopCat(catToSelect);
        if (!isOnShopPage) navigate("/shop");
    });

    if (onSelect) onSelect();
  };

  const allCategoriesOption = (
    <Option 
      key={`optSCall`}
      clearSearch={false}
      label={<Fragment>Browse <b>{search}</b> in all categories</Fragment>}
      onClick={() => goToShop()}
    />
  );

  return (
    <SearchOptionsList visible={visible}>
      <Separator />
      {recCats.map((cat, i) => (
        <Option 
          key={`optRC${i}`}
          clearSearch={true}
          label={<Fragment>Get recommendations for <b>{getLabelSQ(cat)}</b></Fragment>}
          onClick={() => goToSQ(cat)}
        />
      ))}
      {allCategoriesFirst && allCategoriesOption}
      {shopCats.map((cat, i) => (
        <Option 
          key={`optSC${i}`}
          clearSearch={false}
          label={<Fragment>Browse <b>{search}</b> in <b>{cat}</b></Fragment>}
          onClick={() => goToShop(cat)}
        />
      ))}
      {!allCategoriesFirst && allCategoriesOption}
     </SearchOptionsList>
  )
}