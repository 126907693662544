import { FC, useEffect, useState } from "react";
import Icon from "@mdi/react";
import { 
  mdiLoading, 
  mdiCheckCircleOutline  } from "@mdi/js";
import { 
  ButtonIcon,
  CheckMark,
  CostContainer,
  CostLabel,
  CostLabelYear,
  FeatureContainer,
  FeatureTitle,
  OffLabel,
  VariantButton,
  VariantButtonDisabled,
  VariantContainer, 
  VariantStripe, 
  VariantWrapper} from "./AboutStylistStyles";
import { useUserContext } from "../../store/userContext";
import { checkIfAccIsPaid } from "../../services/api.service";

interface VariantProps {
  title: string;
  description: string;
  cost?: number;
  featuresTitle: string;
  features: string[];
  buttonLabel: string;
  buttonAction: () => void;
  freeLabel?: string;
  isLoading?: boolean;
}

const getCostLabel = (cost?: number, freeLabel?: string) => 
  cost === undefined
    ? (<CostLabel>{freeLabel ?? "Free"}</CostLabel>)
    : (<CostContainer>
        <CostLabel striked={true}>£{cost*2}</CostLabel>
        <CostLabel>£{cost}</CostLabel>
        <CostLabelYear/>
        <OffLabel>50% OFF</OffLabel>
      </CostContainer>);

const Feature: FC<{isFree: boolean, label: string}> = ({
  isFree, label
}) => (
  <FeatureContainer>
    <CheckMark isFree={isFree} />
    <p>{label}</p>
  </FeatureContainer>
);

const YourPlan = (
  <VariantButtonDisabled disabled={true}>
    Your plan
    <Icon path={mdiCheckCircleOutline} size={2} css={ButtonIcon}/>
  </VariantButtonDisabled>
);

export const Variant: FC<VariantProps> = ({
  title, description, cost, featuresTitle, features, buttonLabel, buttonAction, freeLabel, isLoading
}) => {
  const isFree = cost === undefined;

  const { user } = useUserContext();

  const [ alreadyPaid, setAlreadyPaid ] = useState(false);
  useEffect(() => {
    if (isFree) return;
    if (!user) return;

    checkIfAccIsPaid()
      .then(setAlreadyPaid)
      .catch(console.error);
  }, [user]);

  return (
    <VariantContainer>
      <VariantStripe isFree={isFree}/>
      <VariantWrapper>
        <h2>{title}</h2>
        <p>{description}</p>
        {getCostLabel(cost, freeLabel)}
        <FeatureTitle>{featuresTitle}</FeatureTitle>
        {features.map((feature, i) => (
          <Feature key={i} label={feature} isFree={isFree}/>
        ))}
        {alreadyPaid
          ? YourPlan
          : (
            <VariantButton isFree={isFree} onClick={buttonAction}>
              {isLoading
                ? (<Icon path={mdiLoading} spin={1} size={1.5}/>)
                : (<>{buttonLabel}</>)
              }
            </VariantButton>
          )}
      </VariantWrapper>
    </VariantContainer>
)};