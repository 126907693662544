import MicroSpellingCorrecter from "micro-spelling-correcter";
import { ThemeProvider } from "styled-components";
import { getSpellingDics } from "./api.service";

export class SpellingService {
  private spelling = new MicroSpellingCorrecter([]);
  private keywords = [] as string[];

  propositions: string[] = [];
  doNotSpell: string[] = [];
  retailerKeywords: string[] = [];

  public constructor(dictionary: string[]){
    getSpellingDics().then(dics =>{
      const initialDic = (dictionary ?? []).map(x => x.toLowerCase());

      this.propositions = dics.ignore;
      this.doNotSpell = dics.doNotCorrect;
      this.retailerKeywords = dics.retailerNames;

      this.keywords = [...initialDic, ...dics.doNotCorrect, ...dics.ignore, ...dics.retailerNames];
      this.spelling = new MicroSpellingCorrecter(this.keywords);
    });
  };

  public correctWord = (word: string) => {
    const res = this.keywords.includes(word) 
      ? word 
      : this.spelling.correct(word, 2) ?? word;
    //console.log("spelling:", `${word} -> ${res}`);
    return res;
  };

  public correctText = (text: string | undefined) => {
    const words = (text ?? "")
      .toLowerCase()
      .replace(/\,|\-/gi, " ")
      .split(" ")
      .filter(x => x);

      return words.map(w => this.correctWord(w));
  };
};

  