import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from "react";
import { Colors, Materials, RecCategories, ShopCategories, /*Prepositions,*/ Retailers, CategoryMap } from "../utils/constants";
import { distinct, intersect, toRecord, flatten } from "../utils/helpers";
import { SpellingService } from "../services/spelling.service";
import { patchSeacrh, postSearch } from "../services/api.service";
import { useUserContext } from "./userContext";

export type SearchMaterial = (typeof Materials)[number];
export type SearchColor = (typeof Colors)[number];
export type SearchRecCategory = (typeof RecCategories)[number];
export type SearchShopCategory = (typeof ShopCategories)[number];
export type SearchRetailers = (typeof Retailers)[number];

export interface ParsedSearch {
  nameWords: string[];
  materials: Material[];
  colors: ColourName[];
  retailers: RetailerUnion[];
  recCats: CategoryUnion[];
  shopCats: SearchShopCategory[];
};

interface SearchState {
  search: string;
  fixedSearch: string;
  appliedSearch: string;
  keywords: ParsedSearch;
  applySearch: (search: string, toRecs: boolean, unselectCat: boolean) => Promise<void>;
  clearSearch: () => Promise<void>;
  selectShopCat: Dispatch<SetStateAction<SearchShopCategory|undefined>>;
  selectedShopCat: SearchShopCategory|undefined;
  parse: (search: string|undefined) => void;
  //updateLatestSearch: (resultCount: number) => void;
};

const spelling = new SpellingService([
  ...Materials, 
  ...Colors, 
  ...flatten(ShopCategories.map(x => x.toLowerCase().split(" "))),
  // ...exceptions,
  // ...Prepositions
  ].filter(distinct));

const ItemSearchContext = createContext<SearchState>({} as SearchState);
export const useSearchContext = () => useContext(ItemSearchContext);

export const SearchProvider: FC<{children?: React.ReactNode}> = ({children}) => {
  const [search, setSearch] = useState("");
  const [fixedSearch, setFixedSearch] = useState("");
  const [appliedSearch, setAppliedSearch] = useState("");

  const [nameWords, setNameWords] = useState<string[]>([]);
  const [materials, setMaterials] = useState<SearchMaterial[]>([]);
  const [colors, setColors] = useState<SearchColor[]>([]);
  const [retailers, setRetailers] = useState<RetailerUnion[]>([]);
  const [recCats, setRecCats] = useState<SearchRecCategory[]>([]);
  const [shopCats, setShopCats] = useState<SearchShopCategory[]>([]);

  const [selectedShopCat, selectShopCat] = useState<SearchShopCategory|undefined>(undefined);

  const applySearch = async (searchToApply: string, toRecs: boolean, unselectCat: boolean) => {
    if (!parse) return;

    try {
      if (unselectCat) selectShopCat(undefined);

      setAppliedSearch(searchToApply);
      if (!searchToApply) {
        parse(undefined);
        return;
      }
    
      // let id = await postSearch(search, fixedSearch, customerId, 0, toRecs);
      // setLatestSearchId(id);
    }
    catch (err) {
      console.error("Error applying search", err);
      setAppliedSearch("");
      selectShopCat(undefined);
    }
  };

  const clearSearch = () => applySearch("", false, true);

  const {customerId} = useUserContext();
  //const [latestSearchId, setLatestSearchId] = useState(0);

  // const updateLatestSearch = (resultCount: number) =>
  //   setTimeout(() => {
  //       patchSeacrh(latestSearchId, resultCount).catch(console.error)
  //     }, 1000);

  /** Associates each search word with a set of keywords. Returns only non-empty pairs.*/ 
  const associate = <T extends string>(keywords: readonly T[], searchWords: string[]) => {
    const arr = searchWords
      .map(word => [word, keywords.filter(k => k.toLowerCase().includes(word))] as [string, T[]])
      .filter(([_, v]) => v.length > 0);

    return toRecord(arr);
  };

  const recognizeKeywords = <T extends string>(allKeywords: readonly T[], searchWords: string[]) => 
    associate(
      allKeywords.filter(k => searchWords.includes(k.toLowerCase())),
      searchWords
    );
      
  const getMaterials = (words: string[]) => recognizeKeywords(Materials, words);
  const getColors = (words: string[]) => recognizeKeywords(Colors, words);

  const recognizeCategories = <T extends string>(categories: readonly T[], searchWords: string[]) => {
    const toUse = (searchWords ?? [])
      .filter(c => c && c.length >= 3)
      .filter(c => !spelling.propositions.includes(c))
      .filter(c => !spelling.doNotSpell.includes(c));

    return associate(
      categories.filter(c => toUse.some(w => c.toLowerCase().includes(w))),
      toUse
    );
  };

  //const getRecCategories = (words: string[]) => recognizeCategories(RecCategories, words);
  const getShopCategories = (words: string[]) => recognizeCategories(ShopCategories, words);

  const getRecCategories = (shopCats: SearchShopCategory[]) => 
    CategoryMap
      .filter(([_, s]) => intersect([s, shopCats]).length > 0)
      .map(([c, _]) => c);

  const getRetailers = (words: string[]) => {
    //console.log(Retailers, words);
    //return recognizeCategories(Retailers, words)
    const lower = words.map(w => w.toLowerCase());
    return Retailers.filter(r => lower.includes(r.toLowerCase()));
  };

  const flatten = <T extends string>(groups: Record<string, T[]>) =>
    Object.values(groups).reduce((acc, x) => [...acc, ...x], []).filter(distinct);

  const parse = (rawSearch: string | undefined) => {
    const search = rawSearch?.toLowerCase();

    setSearch(search ?? "");
    const speeledWords = spelling.correctText(search); 
    setFixedSearch(speeledWords.join(" "));

    const words = search?.split(" ") ?? [];

    const recognizedMaterials = getMaterials(words);
    const recognizedColors = getColors(words);
    //const recognizedRecCats = getRecCategories(words);
    const recognizedShopCats = getShopCategories(words);

    const retailerSource = words
      .filter(w => !Object.keys(recognizedColors).includes(w))
      .filter(w => !Object.keys(recognizedMaterials).includes(w));

    const recognizedRetailers = getRetailers(retailerSource);

    setMaterials(flatten(recognizedMaterials));
    setColors(flatten(recognizedColors));

    //const recs = intersect(Object.values(recognizedRecCats));
    const shops = intersect(Object.values(recognizedShopCats));
    const rets = intersect(Object.values(recognizedRetailers));

    const shopCategoryResult = shops.length > 0 ? shops : flatten(recognizedShopCats);
    setShopCats(shopCategoryResult);

    const recs = getRecCategories(shopCategoryResult);
    setRecCats(recs);
    
    setRetailers(rets.length > 0 ? rets : flatten(recognizedRetailers));

    const exclude = [
      Object.keys(recognizedMaterials), 
      Object.keys(recognizedColors),
      Object.keys(recognizedShopCats),
      Object.keys(recognizedRetailers),
      spelling.propositions
    ].reduce((acc, x) => [...acc, ...x], []).filter(distinct);

    let name = words.filter(w => !exclude.includes(w));
    setNameWords(name);
  };

  return (
    <ItemSearchContext.Provider
      value ={{
        search, 
        fixedSearch,
        appliedSearch,
        keywords: {nameWords, materials, colors, retailers, recCats, shopCats},
        applySearch,
        clearSearch,
        selectedShopCat, 
        selectShopCat,
        parse,
        //updateLatestSearch,
      }}
    >
      {children}
    </ItemSearchContext.Provider>
  );
};