import styled from "styled-components";
import { breakpoints } from "../styles/responsive";

/**
 * `display: ✨ contents ✨`
 */
export const DisplayMobileOnly = styled.div`
  display: contents;
  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

/**
 * `display: ✨ contents ✨`
 */
export const DisplayWiderThanMobileOnly = styled.div`
  display: none;
  @media (min-width: ${breakpoints.mobile}) {
    display: contents;
  }
`;
