import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import NavBar from "../../shared/NavBar/NavBar";
import {
  FaqsPageContainer,
  FaqsPageWrapper,
  FaqsBG,
  EmailUsWrapper,
  EmailUsButton,
  QuestionText,
  FaqsBGWrapper,
} from "./FaqsWrapper";
import { Footer } from "../../shared/Footer/Footer";
import { BottomFooter } from "../../shared/Footer/BottomFooter";
import { Link } from "react-router-dom";
import { GroupedFaqMainPage, FaqGroup } from "../../shared/Faq";

const GROUPED_FAQ_CONTENT = [ 
  {
    name: "General",
    content: [
      {
        question: "What’s Flitch about?", 
        answer: (<>
        Flitch makes furniture shopping simple by building the entire furniture shopping journey around you. 
        Haven’t got the time or energy to spend weeks and weeks hunting for the right furniture? Well, we’ve 
        got your back - for just £25 you’ll get your own expert interior stylist who will help you buy all the 
        furniture you need! You just need to take a short style quiz and then sit back and relax. Please find 
        more information below.
        <br/><br/>
        Fancy hunting for the furniture yourself? Well, we’ve got you covered too! We have brought together loads
        of furniture shops (both well-known and up-and-coming) together so you can look through all their catalogues
        in one place. Don’t worry, you can still receive our personalised recommendations. Just take our style quiz
        to receive your free instant recommendations tailored to your taste and budget.
      </>)
      },
      {
        question: "How much does it cost to use Flitch?", 
        answer: (<>
        If you’re looking to go down the ‘Self-Serve’ route, Flitch is absolutely free! You can browse as much as you
        want to and receive instant recommendations. 
        <br/><br/>
        But sometimes we all need that helping hand so for just £25 you can get your very own expert interior stylist 
        who will take all the stress out of furniture shopping for you.
        <br/><br/>
        For more information, please see our pricing plan <Link to="/pricing">here</Link>.
      </>)
      }
    ]
  },
  {
    name: "Interior Stylist Service",
    content: [
      {
        question: "What do I get out of the styling fee?", 
        answer: (<>
        For just £25 you get your own expert stylist who will help you buy all the furniture you need - that’s a year of 
        outsourcing all your furniture discovery. You’ll get a mood board and a detailed shopping list tailored to you. 
        Note that the £25 fee gets credited towards anything you choose to buy through Flitch.
        </>)
      },
      {question: "Why get a stylist?", answer: (<>
        We’ve been there and done it the old-fashioned way so we know just how time-consuming it can be to find the right 
        pieces of furniture for your home and for the right prices. A stylist will do all the hard work on your behalf: 
        they combine your taste profile with their expertise to source the perfect items (from across the entire UK 
        furniture market) for your home at the right price, saving you an enormous amount of time and money.
      </>)},
      {question: "When will I hear from my stylist?", answer: (<>
        Your stylist will reach out to you via email within a couple of working days after you pay the styling fee and 
        complete your style quiz.
      </>)},
      {question: "How do I communicate with my stylist?", answer: (<>
        Your stylist will be in touch with their ideas for you via email, through which you’ll be able to continue to 
        converse and provide direct feedback. Your stylist will keep searching for your perfect items until you are 
        100% satisfied.
      </>)},
      {question: "What if you can’t find me the right furniture?", answer: (<>
        If you’re not satisfied, we’re not satisfied. So if our stylists can’t find the right furniture for you, we’ll 
        hand you back your styling fee - that’s a no quibble guarantee.
      </>)},
      {question: "How long does the styling fee last?", answer: (<>
        The styling fee lasts for a whole year. That means you can get as much assistance as you need from your assigned 
        stylist over the course of a year without any additional costs.
      </>)},
      {question: "What do items cost?", answer: (<>
        We have items across every price range. Easily set your price preferences for each furniture category in your style 
        quiz. You can revise your price preferences at any point when interacting with your stylist.
      </>)},
      {question: "How do you find my style?", answer: (<>
        We're style experts. We learn from your style quiz and ongoing feedback to pinpoint the best items for your taste and 
        budget. Bonus: The more we interact, the more personalised your experience becomes.
      </>)},
      {question: "Are there any limits to the Human Stylist plan?", answer: (<>
        Just to be clear, you won’t be paying for an interior design service - for starters, anyone who offers you a full 
        interior design service for £25 cannot be taken seriously! So you won’t get floor plans, 3D renderings and space 
        and lighting advice. What you will get is expert advice on finding the right pieces of furniture.
      </>)},
      {question: "Can I shop on my own without a stylist?", answer: (<>
        Of course! You can head over to our Browse section where you can look through the full product catalogues of 100+ 
        furniture retailers all in one place. But if you ever tire of that, you can choose to sit back and let our stylists 
        do the hard work for you - they won’t stop until you’re 100% satisfied.
      </>)},
    ]
  },
  {
    name: "User Accounts",
    content: [
      {question: "Do I have to register an account to use Flitch?", answer: (<>
        Nope. No registration or sign-in is needed. But we do recommend you
        set up a user profile where you can find all your previous
        recommendations and liked items to review and share with family and
        friends.
      </>)},
      {question: "How do I register a user profile?", answer: (<>
        Once you click on the user icon in the top right corner of your
        screen you will receive a pop-up, enabling you to sign-in using your
        Google or Facebook account.
      </>)},
      {question: "How do I delete my user profile?", answer: (<>
        You can delete your Flitch account in Advanced Settings, which
        can be found in Your Profile.
      </>)},
      {question: "Will my personal information be secure?", answer: (<>
        Absolutely. We fully comply with GDPR and take your privacy very
        seriously. Please read more about this in our Privacy Policy.
      </>)}
    ]
  },
    
  {
    name: "Furniture Sold By Flitch",
    subtext: "With some items, you can purchase directly from us. Here you’ll find useful information relating to such purchases:",
    content: [
      {question: "Do you manage payments?", answer: (<>
        Yes, we securely process your payment. 
      </>)},
      {question: "What happens to my payment when I place an order on Flitch?", answer: (<>
        Upon placing an order, your payment will be held whilst we confirm stock availability and 
        shipping time. Once confirmed, the payment will be processed and you will receive an email 
        confirming your order.
      </>)},
      {question: "Can I cancel an order made on Flitch?", answer: (<>
        If at any point prior to confirmation you wish to cancel part or all of your order,
        please email <a href="mailto:orders@withflitch.com">orders@withflitch.com</a>.
      </>)},
      {question: "Who is responsible for delivery?", answer: (<>
        We’ll be responsible for getting your purchase to you.
      </>)},
      {question: "How much does delivery cost?", answer: (<>
        Delivery cost will vary depending on the size of the item and whether you’re buying from 
        us or one of our partner retailers. If the latter then you will find the delivery cost info 
        on their website. If you’re buying from us then we charge a flat shipping fee of £5.95 for 
        small items, £24.95 for medium items, and £49.95 for large items (exceptions may apply). 
        In order to streamline the fulfilment process, we organise deliveries direct from suppliers 
        to you. This ensures your order will arrive faster and minimise the risk of in-transit damage. 
        As a result, our shipping fees are on a per-supplier basis. This means that if your order can be 
        fulfilled by a single supplier we will only charge you a single delivery fee (which will be the 
        highest individual unit delivery amount of the items ordered); if on the other hand your order can 
        only be fulfilled by multiple suppliers we will charge you a delivery fee for each supplier. By way 
        of illustration, if you order a small item that is fulfilled by one supplier and a large item that is 
        fulfilled by another supplier, we will charge you £55.90 for delivery (being £5.95 + £49.95).
      </>)},
      {question: "How long will delivery take?", answer: (<>
        We estimate that shipping times will take, on average, 3-7 working days for small and medium items, and 
        12-24 working days for large items; however this will vary from item to item and we will provide specific 
        timeframes in your confirmation email.
      </>)},
      {question: "Can I return an item?", answer: (<>
        Upon receiving your items you will have 14 days to inform us if you do not wish to keep any item/s from your 
        purchase. If you wish to make a return, please email returns@withflitch.com Some suppliers will allow a longer 
        period than 14 days and multiple items are to be treated on a per supplier basis and may have different return 
        periods. In our confirmation email, we will specify the return period for the item/s in your order.
      </>)},
      {question: "Who do I speak to about returns?", answer: (<>
        Please email <a href="mailto:returns@withflitch.com">returns@withflitch.com</a>.
      </>)},
    ]
  },

  {
    name: "Furniture Purchased on 3rd Party Sites",
    subtext: "In some cases you're just one click away from buying the furniture you have fallen in love with. You just need to click on “Find Out More” and we’ll take you straight through to the product page of our partner retailer who will take over from there. Here you’ll find useful information relating to such purchases:",
    content: [
      {question: "Do you manage payments?", answer: (<>
        No, the partner retailer will securely manage your payment.
      </>)},
      {question: "Who is responsible for delivery?", answer: (<>
        The retailer whom you purchase from is responsible for delivery.
      </>)},
      {question: "How long will delivery take?", answer: (<>
        Delivery time will vary depending on the retailer and the product. You can find this info on the relevant 
        product information screen.
      </>)},
      {question: "Can I return an item?", answer: (<>
        Each retailer has their own return policy. You can find this info on the relevant product information screen.
      </>)},
      {question: "Who do I speak to about returns?", answer: (<>
        We find “middle-men” only confuse matters. So for returns and customer service, you deal directly with the retailer.
      </>)}
    ]
  }
] as FaqGroup[];

export const Faqs = () => {
  return (
    <FaqsPageWrapper>
      <FlitchHelmet title="FAQs" />
      <NavBar />
      <FaqsPageContainer>
        <FaqsBGWrapper>
          <FaqsBG alt="" />
        </FaqsBGWrapper>

        <GroupedFaqMainPage faqGroups={GROUPED_FAQ_CONTENT}/>
        
        <EmailUsWrapper>
          <QuestionText style={{ margin: "auto" }}>
            Can't find what you are looking for?
          </QuestionText>
          <EmailUsButton href="mailto:support@withflitch.com" target="_blank">
            <span>Email us at support@withflitch.com</span>
          </EmailUsButton>
        </EmailUsWrapper>

      </FaqsPageContainer>
  
      <Footer />
      <BottomFooter />
    </FaqsPageWrapper>
  );
};
