import { Link } from "react-router-dom";
import styled from "styled-components";

import downArrow from "../../images/icons/downarrow.svg";
import { fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";

export const ProfilePageWrapper = styled.div`
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding-top: 9rem;
  min-height: 100vh;

  h1 {
    text-transform: uppercase;
    font-size: ${fontSizes.large};
    font-weight: bolder;
  }
`;

export const ProfilePageHR = styled.hr`
  height: 1px;
  background-color: #9e9e9e;
  width: 100vw;
  margin: 3rem 0;
`;

export const ProfilePageTopPart = styled.section`
  width: 98rem;
  max-width: 100vw;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (orientation: landscape) {
  }
  @media (orientation: portrait) {
    /* height: 10rem; */
  }
`;

export const UserTitleWrapper = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  span {
    font-size: ${fontSizes.large};
    font-weight: bolder;
    text-transform: uppercase;
  }
  p {
    font-size: ${fontSizes.small};
  }
`;

export const ViewActivityButton = styled(Link)`
  cursor: pointer;
  background-color: #535353;
  border-radius: 2rem;
  padding: 2rem 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  white-space: nowrap;
`;

export const ProfilePageBottomPart = styled.section`
  width: 100rem;
  /* margin-bottom: 4rem; */
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 9rem;
  }
  @media (orientation: landscape) {
  }
  @media (orientation: portrait) {
    grid-gap: 10vw;
  }
`;

export const ProfileInfo = styled.div`
  font-size: ${fontSizes.medium};
  font-weight: bolder;
  padding: 0 2rem;
  margin-bottom: 3rem;
`;

export const ProfileForm = styled.div`
  margin-bottom: 2rem;
  padding: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 1rem;
  max-width: 100vw;
  width: 96rem;
  align-self: center;
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 101vw;
  }
`;

export const ProfileItemForm = styled.div`
  font-size: ${fontSizes.small};
  display: flex;
  position: relative;
  /* justify-content: space-between; */
`;

export const InputStyled = styled.input`
  border-radius: 0.5rem;
  border: 0.5px solid #dcdcdc;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  height: 3.5rem;
  padding-left: 1rem;
  font-size: inherit;
  align-self: center;
  width: 30rem;
  max-width: 75vw;
  &:disabled {
    color: #aaa;
  }
`;

export const SelectStyled = styled.select`
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  appearance: none;
  /* text-transform: capitalize; */
  background: url(${downArrow}) no-repeat right transparent;
  height: 3.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #dcdcdc;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  color: #555;
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1rem;
  align-self: center;
  width: 30rem;
  max-width: 75vw;
`;

export const Label = styled.label`
  white-space: nowrap;
  font-weight: lighter;
  text-align: right;
  align-self: center;
  width: 10rem;
  max-width: 20vw;
  margin: 1.5rem;
`;

export const Optional = styled.div`
  width: 15rem;
  max-width: 25vw;
  display: none;
  margin: 1rem;
  align-self: center;
  @media only screen and (min-width: ${breakpoints.mobile}) {
    display: flex;
  }
`;

interface ISaveChanges {
  active: boolean;
}

export const SaveChangesButton = styled.div<ISaveChanges>`
  cursor: pointer;
  position: relative;
  background: #f88c09;
  background: linear-gradient(to right, #f88c09, #f08000);
  margin-top: 1rem;
  height: 3.5rem;
  width: 70vw;
  max-width: 30rem;
  display: grid;
  align-self: flex-end;
  justify-content: center;
  align-content: center;
  border-radius: 0.5rem;
  span {
    font-size: ${fontSizes.medium};
    color: white;
  }
  ${({ active }) =>
    !active &&
    `
    pointer-events:none;
    cursor: none;
    opacity: 0.7;
  `}
`;

export const AdvancedSettingsWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const AdvancedLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 12rem;
  max-width: 25vw;
  margin-right: 1.5rem;
`;

export const AdvancedRight = styled.div`
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 0.2px solid grey;
`;

export const MultiLineLabel = styled.div`
  font-weight: lighter;
  text-align: right;
  font-size: 13px;
  justify-self: center;
  &:last-child {
    margin-top: 3rem;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    &:last-child {
      margin-top: 9rem;
    }
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
  }
  label {
    font-size: 16px;
    align-self: center;
    transform: translateY(-10%);
  }
`;

export const EmailRadio = styled.input`
  margin-right: 1rem;
  align-self: flex-start;
`;

export const DeleteAccountButton = styled.div`
  cursor: pointer;
  display: flex;
  padding: 1rem;
  /* margin-top: 2rem; */
  border-radius: 0.5rem;
  border: 1px solid red;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 3rem;
  transform: translateY(-10%);
  p {
    font-size: 16px;
    color: red;
  }
  span {
    font-size: 16px;
    color: red;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    span {
      display: none;
    }
  }
`;

interface IFeedback {
  showFeedback: boolean;
}

export const FeedbackWrapper = styled.div<IFeedback>`
  position: absolute;
  background-color: #414141;
  opacity: 0;
  /* padding: 0.5rem; */
  /* top: 0; */
  width: 100%;
  height: 3.5rem;
  bottom: -6rem;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  transition: opacity 0.5s ease;
  div {
    background-color: #414141;
    position: absolute;
    height: 1.5rem;
    width: 2rem;
    top: -0.5rem;
    right: 50%;
    transform: rotate(45deg);
  }
  p {
    z-index: 1;
    color: white;
    font-size: ${fontSizes.small};
  }
  ${({ showFeedback }) =>
    showFeedback &&
    `
    opacity: 1;
  `}
`;

export const ConfirmDelete = styled.div`
  background-color: white;
  top: 50%;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr auto 3fr;
  align-content: center;
  p {
    font-size: ${fontSizes.medium};
    text-align: center;
    padding: 1rem;
  }
  div {
    border: none;
    border-radius: 5px;
    font-size: ${fontSizes.large};
    background-color: #fc5d59;
    padding: 1rem 3rem;
    width: auto;
    margin: auto;
    color: white;
    font-weight: bolder;
    cursor: pointer;
  }
`;

export const CloseModalButton = styled.img`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  align-self: center;
  justify-self: flex-end;
  margin-right: 1rem;
`;
