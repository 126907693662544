import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { ShareLinkStyled } from "../RecommendationsPageStyled";
import { css } from "@emotion/css";

interface ShareButtonProps {
  item: IFurnitureItem;
  share: (item: IFurnitureItem) => void;
}

export const ShareButton: FC<ShareButtonProps> = ({item, share}) => {
  return( 
    <ShareLinkStyled
      onClick={() => share(item)}
      target="_blank"
      rel="noopener"
      className="buynow-link"
    >
      <FontAwesomeIcon 
        icon={faShareSquare} 
        color="white" 
        size="1x" 
        className={css`
          position:relative;
          top:50%;
          left:50%;
          transform:translate(-50%,-50%)
        `}
      />
    </ShareLinkStyled>)
};
