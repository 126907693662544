import { FC } from "react";
import { 
  AboutPriceWrapper, 
  ChartMockup, 
  DescContainer } from "./AboutPriceStyles";

export const AboutPrice : FC = () => {
  return (
    <AboutPriceWrapper>
      <DescContainer>
        <h2>Get price confident</h2>
        <p>
          Track and monitor prices for thousands of furniture items. 
          And if you're holding out for a better deal, sign up for
          price alerts to get notified when the price drops
        </p>
      </DescContainer>
      <ChartMockup />
    </AboutPriceWrapper>
  )
};