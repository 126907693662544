import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 4;
`;

type ModalProps = {
  isOpen: boolean;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  onRequestClose?: VoidFunction;
  children: React.ReactNode;
};

export const Modal: FC<ModalProps> = ({
  isOpen,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  onRequestClose,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen && shouldCloseOnEsc) {
        onRequestClose?.();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => window.removeEventListener("keydown", handleEsc);
  }, [isOpen, shouldCloseOnEsc]);

  const closeClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (ref.current && event.target === ref.current) {
      onRequestClose?.();
    }
  };

  return isOpen ? (
    <ModalBackdrop
      ref={ref}
      onClick={shouldCloseOnOverlayClick ? closeClick : undefined}
    >
      {children}
    </ModalBackdrop>
  ) : null;
};
