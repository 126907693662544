import { useEffect } from "react";
import NavBar from "../../shared/NavBar/NavBar";
import { Headings } from "../../shared/Headings/Headings";
import { minSelectedItems } from "../../config";
import { SQFooter } from "./SQFooter";
import { useSqFlowContext } from "../../store/SqFlowProvider";
import { useCategory } from "../../hooks/useCategory";
import { StyleQuizPageWrapper } from "./StyleQuizPageStyled";
import { Listing } from "./Listing/Listing";
import { useNavigate } from "react-router-dom";
import { concatArray, getId, getRecsUrl } from "../../utils/helpers";
import { SQLoading } from "./SQComponents";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { MobileRoadmap } from "../../shared/NavBar/RoadMap/MobileRoadmap";

const minItemsForRefine = 10;

export const SQRefine = () => {
  const category = useCategory();
  const navigate = useNavigate();

  const {
    page3State,
    toggleSq3Item,
    fetchRefineItems,
    page1And2State,
    isRefineLoading,
    page3NeedsReload,
  } = useSqFlowContext();

  useEffect(() => {
    if (page1And2State.selectedItems.length < 1) {
      navigate("/category");
    }
  }, [concatArray(page1And2State.selectedItems)]);

  useEffect(() => {
    if (
      page3State.items &&
      page3State.items.length < minItemsForRefine &&
      page3State.loadedCategory === category &&
      !page3NeedsReload // important!
    ) {
      navigate(getRecsUrl(category));
    }
  }, [
    concatArray(page3State.items?.map(getId)),
    page3State.loadedCategory,
    category,
    page3NeedsReload,
  ]);

  useEffect(() => {
    if (!isRefineLoading && page3NeedsReload) {
      fetchRefineItems();
    }
  }, [isRefineLoading, page3NeedsReload]);

  const isMinSelected = page3State.selectedItems.length >= minSelectedItems;

  return (
    <StyleQuizPageWrapper>
      <FlitchHelmet title="Preferences — Style Quiz" />
      <NavBar />
      <MobileRoadmap position={-0.12}/>
      {page3State.items === null ? (
        <SQLoading />
      ) : (
        <>
          <Headings
            primary={`You're doing great - another few likes and we'll get you your recommendations`}
          />
          <Listing
            category={category}
            items={page3State.items}
            selected={page3State.selectedItems}
            toggleSelectItem={toggleSq3Item}
          />
          <SQFooter
            isMinSelected={isMinSelected}
            nextPage={`/style-quiz/${category}/recommendations`}
          />
        </>
      )}
    </StyleQuizPageWrapper>
  );
};
