import styled from "styled-components";
import { colors } from "../../../../../styles/globalStyled";
import { breakpoints } from "../../../../../styles/responsive";

export const MobileSearchWrapper = styled.div`
  display: relative;
  margin-right: 2px;
  width: var(--nav-button-side-mobile);
  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileSearchBox = styled.div<{visible: boolean}>`
  display: flex;
  flex-direction: column;
  display: ${({visible}) => visible ? "bloxk" : "none"};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  background-color: white;
  justify-content: center;
`;

export const MobileSearchLine = styled.div`
  display: grid;
  height: var(--navbar-height-mobile);
  grid-template-columns: 9% 1fr 9%;
`;

export const MobileSearchCloseButton = styled.button`
  color: ${colors.greyMediumDarker};
  border-style: none;
  cursor: pointer;
`;
