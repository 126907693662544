import Axios from "axios";
import { apiUrl } from "./config";

// For data that only needs to be fetched once

export let shortSqCats: CategoryUnion[] = [];
export const isShortSq = (category: CategoryUnion) => {
  return shortSqCats.includes(category);
}

// Actual data fetching
Axios.get<Metadata>(`${apiUrl}/sq/metadata`).then(
  ({ data: { shortSQs } }) => (shortSqCats = shortSQs)
);
