import { getItemPriceHistory } from "../services/api.service";

const distinctHistory = (source: PriceHistoryEntry[]) => {
  let result = [] as PriceHistoryEntry[];

  source.forEach(e => {
    if (!result.some(x => 
      x.affiliateId === e.affiliateId &&
      x.retailerLookup === e.retailerLookup &&
      x.date === e.date)) result.push(e);
  });

  return result;
}

export const sortByDateAsc = (a: PriceHistoryEntry, b: PriceHistoryEntry) => {
  if (a.date > b.date) return 1;
  if (a.date < b.date) return -1;
  return 0;
}

export const getPriceHistory = async (
  item: IFurnitureItem, 
  period: HistoryPeriodUnion,
) => 
  getItemPriceHistory(item, period)
    .then(
      result => result.sort(sortByDateAsc).filter(x => x.price !== undefined),
      err => {
        console.error("Can't fetch item history", err);
        return [] as PriceHistoryEntry[];
      });
