import { useEffect } from "react";
import { Navigate, useParams } from "react-router";
import { useUserContext } from "../store/userContext";

interface Params {
  referrer?: string;
}

export const ReferrerLandingPage = () => {
  const { referrer } = useParams<Params>();
  const { setReferrerIfNotSet } = useUserContext();

  useEffect(() => {
    if (referrer) {
      setReferrerIfNotSet(referrer);
    }
  });

  return <Navigate replace to="/" />;
};
