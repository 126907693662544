import { SearchShopCategory } from "../store/searchContext";
import { toRecord } from "./helpers";

const currency = "£";
const length = "cm";

export const Units: { currency: typeof currency; length: typeof length } = {
  currency,
  length
};

//export const Prepositions = ["in", "on", "at", "and", "or", "of", "to", "nor", "for", "with"]

export const RecCategories = [
  "armchair",
  "barstool",
  "bed",
  "bedsidetable",
  "chestofdrawers",
  "coffeetable",
  "consoletable",
  "diningchair",
  "diningtable",
  "displaycabinet",
  "shelving",
  "sideboard",
  "sidetable",
  "sofa",
  "tvstand",
  "wardrobe",
  "shop"
] as const;

export const Materials = [
"AnimalSkin",
"Bamboo",
"Canvas",
"Ceramic",
"Ceramic or Tile",
"Concrete",
"Concrete or Stone",
"Fabric",
"Fur",
"Glass",
"Jute",
"Leather",
"Leather or Suede",
"Marble",
"Metal",
"Other",
"Plastic",
"Rattan",
"Stone",
"Suede",
"Tile",
"Velvet",
"Wicker",
"Wicker or Rattan",
"Wood",
"MaterialUnknown"] as const;

export const Colors = [
"White",
"Pink",
"Grey",
"Dark Grey",
"Light Grey",
"Light Brown",
"Dark Brown",
"Red",
"Brown",
"Purple",
"Blue",
"Teal",
"Green",
"Beige",
"Yellow",
"Orange",
"Black" ] as const;

export const ShopCategories = [
"Accent Chairs",
"Armchairs",
"Chaise Longues and Day Beds",
"Corner Sofas",
"Loveseats",
"Sofabeds",
"2-Seater Sofas",
"3-Seater Sofas",
"4-Seater Sofas",
"Accent Chairs",
"Armchairs",
"Barstools",
"Beanbags and Pouffes",
"Benches",
"Dining Chairs",
"Footstools and Ottomans",
"Rocking Chair",
"Seat Pads",
"Stools",
"Swing Chairs and Hammocks",
"Bar Tables",
"Bedside Tables",
"Coffee Tables",
"Console Tables",
"Dining Tables",
"Dining Table Sets",
"Dressing Tables",
"Nest of Tables",
"Side Tables",
"Chest of Drawers",
"Coat Stands and Clothes Rails",
"Display Cabinets",
"Drinks Cabinets",
"Drink Trolleys",
"Free-Standing Shelving and Bookcases",
"Media Units and TV Stands",
"Shoes Storage",
"Sideboards",
"Wall-Mounted Shelving",
"Wardrobes",
"Wine Racks",
"Bed Frames (Single)",
"Bed Frames (Double)",
"Bed Frames (Queen)",
"Bed Frames (King)",
"Bed Frames (Super-King)",
"Divan Bases (Single)",
"Divan Bases (Double)",
"Divan Bases (Queen)",
"Divan Bases (King)",
"Divan Bases (Super-King)",
"Headboards (Single)",
"Headboards (Double)",
"Headboards (Queen)",
"Headboards (King)",
"Headboards (Super-King)",
"Mattresses (Single)",
"Mattresses (Double)",
"Mattresses (Queen)",
"Mattresses (King)",
"Mattresses (Super-King)",
"Ottomans and Storage Beds (Single)",
"Ottomans and Storage Beds (Double)",
"Ottomans and Storage Beds (Queen)",
"Ottomans and Storage Beds (King)",
"Ottomans and Storage Beds (Super-King)",
"Bed Sheets (Single)",
"Bed Sheets (Double)",
"Bed Sheets (Queen)",
"Bed Sheets (King)",
"Bed Sheets (Super-King)",
"Bedding Sets (Single)",
"Bedding Sets (Double)",
"Bedding Sets (Queen)",
"Bedding Sets (King)",
"Bedding Sets (Super-King)",
"Duvet Covers (Single)",
"Duvet Covers (Double)",
"Duvet Covers (Queen)",
"Duvet Covers (King)",
"Duvet Covers (Super-King)",
"Duvets (Single)",
"Duvets (Double)",
"Duvets (Queen)",
"Duvets (King)",
"Duvets (Super-King)",
"Mattress Protectors (Single)",
"Mattress Protectors (Double)",
"Mattress Protectors (Queen)",
"Mattress Protectors (King)",
"Mattress Protectors (Super-King)",
"Pillows",
"Pillowcases",
"Throws, Blankets and Bedspreads",
"Roller Blinds",
"Roman Blinds",
"Venetian Blinds",
"Vertical Blinds",
"Eyelet Curtains",
"Pencil-Pleat Curtains",
"Voile and Net Curtains",
"Curtain Accessories",
"Cushions",
"Cushion Covers",
"Rugs",
"Runners",
"Throws, Blankets and Bedspreads",
"Artificial Flowers and Plants",
"Artwork",
"Baskets and Boxes",
"Bookends",
"Candles and Candle Holders",
"Clocks",
"Diffusers",
"Door Stops",
"Doormats",
"Floor-Standing Mirrors",
"Table Mirrors",
"Wall Mirrors",
"Photo Frames",
"Plant Pots",
"Sculptures",
"Vases",
"Ceiling Lights",
"Chandeliers",
"Floor Lamps",
"Lamp Shades",
"Outdoor Lighting",
"Table Lamps",
"Wall Lights",
"Childrens Beds",
"Bed Sheets (Childrens)",
"Bedding Sets (Childrens)",
"Duvet Covers (Childrens)",
"Mattress Protectors (Childrens)",
"Pillowcases (Childrens)",
"Valances (Childrens)",
"Changing Tables",
"Cots, Cribs and Moses Baskets",
"Cot Mattresses",
"Childrens Curtains",
"Childrens Lighting",
"Childrens Mattresses",
"Rugs (Childrens)",
"Childrens Seating",
"Childrens Storage",
"Childrens Tables",
"Desks",
"Office Chairs",
"Office Storage",
"Garden Chairs and Benches",
"Garden Dining Sets",
"Firepits",
"Garden Loungers",
"Garden Lounge Sets",
"Parasols",
"Garden Sofas",
"Garden Storage",
"Garden Tables",
"Weathervanes" ] as const;

export const Retailers = [
  "John Lewis",
  "Made.com",
  "M&S",
  "My Furniture",
  "Maisons du Monde",
  "Barker and Stonehouse",
  "Swoon",
  "Furniture Village",
  "Habitat",
  "Houseology",
  "Amara",
  "La Redoute",
  "Dunelm",
  "Furniture123",
  "Graham & Green",
  "OKA",
  "The Cotswold Company",
  "Cox and Cox",
  "Soho Home",
  "The Conran Shop",
  "Artisanti",
  "Bombinate.com",
  "Chiltern Oak Furniture UK",
  "Cuckooland",
  "Darlings of Chelsea",
  "Knees Home & Electrical",
  "Made in Design",
  "Nkuku",
  "Nordic Nest UK",
  "Oak Furniture Superstore",
  "Olivia's",
  "Out & Out Original",
  "Pagazzi",
  "Perch and Parrow",
  "PN Home",
  "Sofa.com",
  "Teddy Beau",
  "Tom Dixon",
  "The Range",
  "IO Living",
  "Tobias Oliver",
  "Woods Furniture",
  "Downtown",
  "Heal's",
  "BHS.com",
  "The Rug Seller",
  "Noa Home",
  "Vivense",
  "Atkin & Thyme",
  "Loaf",
] as const;

export const CategoryMap = [
  ["armchair", ["Armchairs"]],
  ["barstool", ["Barstools"]],
  ["bed", ["Bed Frames (Single)", "Bed Frames (Double)", "Bed Frames (Queen)",
    "Bed Frames (King)", "Bed Frames (Super-King)", "Bed Frames (Other)",
    "Divan Bases (Single)", "Divan Bases (Double)", "Divan Bases (Queen)",
    "Divan Bases (King)", "Divan Bases (Super-King)", "Divan Bases (Other)",
    "Ottomans and Storage Beds (Single)", "Ottomans and Storage Beds (Double)",
    "Ottomans and Storage Beds (Queen)", "Ottomans and Storage Beds (King)",
    "Ottomans and Storage Beds (Super-King)", "Ottomans and Storage Beds (Other)"]],
  ["bedsidetable", ["Bedside Tables"]],
  ["chestofdrawers", ["Chest of Drawers"]],
  ["coffeetable", ["Coffee Tables"]],
  ["consoletable", ["Coffee Tables"]],
  ["diningchair", ["Dining Chairs"]],
  ["diningtable", ["Dining Tables"]],
  ["displaycabinet", ["Display Cabinets"]],
  ["shelving", ["Free-Standing Shelving and Bookcases", "Wall-Mounted Shelving"]],
  ["sideboard", ["Sideboards"]],
  ["sidetable", ["Side Tables", "Nest of Tables"]],
  ["sofa", ["2-Seater Sofas", "3-Seater Sofas", "4-Seater Sofas", "Corner Sofas",
    "Loveseats", "Sofabeds", "Sofas Other"]],
  ["tvstand", ["Media Units and TV Stands"]],
  ["wardrobe", ["Wardrobes"]]
] as [CategoryUnion, SearchShopCategory[]][];
