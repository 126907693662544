import styled from "styled-components";

import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";
import { Link } from "react-router-dom";

export const SQFooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-top: 1px solid #ececee;
  box-shadow: 0px 2px 10px rgba(10, 10, 92, 0.1);
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;

  .button.disabled {
    background: ${colors.greenDisabled};
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const NextPageButton = styled(Link)`
  background-color: ${colors.green};
  font-size: ${fontSizes.medium};
  font-weight: bolder;
  padding: 10px;
  width: 100%;
  text-transform: uppercase;
  line-height: ${fontSizes.medium};
  color: white;
  height: initial;

  &:hover,
  &:active {
    color: white;
  }

  @media (min-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.large};
    padding: 10px 75px;
    width: initial;
  }
`;
