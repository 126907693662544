import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { triggerNoteSentEvent } from "../../dataLayer";
import { addUpgradeAccountNotes } from "../../services/api.service";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import NavBar from "../../shared/NavBar/NavBar";
import {
  MessageContainer,
  NumberedLabel,
  Number,
  PostCheckoutPageWrapper,
  NotesContainer,
  NotesStripe,
  NotesElemWrapper,
  NoteSentMessage} from "./PostCheckoutStyles";

interface NumberedStepProps {
  number: number;
  label: EmotionJSX.Element
}

export const PostCheckoutPage : FC = () => {
  const navigate = useNavigate();

  const steps = [
    (<>If you haven't already done so, take a <Link to="/category">style quiz</Link> - this will help your stylist better understand your taste</>),
    (<>You'll receive an email from your stylist within the next couple of working days with some initial ideas</>),
    (<>Your stylist will continue to work with you until you're fully satisfied and have found the item you want</>)
  ];
  const placeholder = "Feel free to add some comments here to help your stylist get to better know you and your requirements...";

  const NumberedStep : FC<NumberedStepProps> = ({number, label}) => {
    return (
      <NumberedLabel>
        <Number>{number}</Number>
        <p>{label}</p>
      </NumberedLabel>
    )
  };

  const [ noteSent, setNoteSent ] = useState(false);

  const sendNote = async () => {
    let textArea = document.getElementById("notes") as HTMLTextAreaElement;
    let notes = textArea.value; 
    
    addUpgradeAccountNotes(notes)
      .then(() => {
        setNoteSent(true);
        triggerNoteSentEvent();
      })
      .catch(console.error);
  };

  return (<>
    <FlitchHelmet title="Welcome" />
    <NavBar />
    <PostCheckoutPageWrapper>
      <MessageContainer>
        <h1>
          Woohoo!
          You're now part of the Flitch family
        </h1>
        {steps.map((step, i) =>
          <NumberedStep key={i} number={i+1} label={step}/>)}
        <NotesContainer>
          <NotesStripe />
          <NotesElemWrapper visible={!noteSent}>
            <p>Notes</p>
            <textarea id="notes" placeholder={placeholder} />
            <button onClick={sendNote}>SEND</button>
          </NotesElemWrapper>
          <NoteSentMessage visible={noteSent}>
            <p>
              Your notes have been sent <br/>
              through to your stylist
            </p>
          </NoteSentMessage>
        </NotesContainer>
      </MessageContainer>
    </PostCheckoutPageWrapper>
  </>);
}