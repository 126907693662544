import styled from "styled-components";

import bg from "../../images/SQv01.jpg";
import bgStreched from "../../images/bgStretched.jpg";
import { fontSizes, colors } from "../../styles/globalStyled";
import { Link } from "react-router-dom";

export const ActivityPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  padding-top: 9rem;
  background-color: ${colors.greyLight};
  min-height: 100vh;
`;
export const ActivityPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActivityPageHR = styled.hr`
  height: 1px;
  background-color: #9e9e9e;
  width: 100vw;
  margin: 3rem 0;
`;

export const ActivityPageTopPart = styled.section`
  width: 98rem;
  max-width: 100vw;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserTitleWrapper = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  h1 {
    text-transform: uppercase;
    font-size: ${fontSizes.large};
    font-weight: bolder;
  }
`;

export const ActivityCategoriesWrapper = styled.div`
  @media (orientation: portrait) {
    span > span {
      display: none;
    }
  }
  display: flex;
  span {
    &:first-child {
      cursor: pointer;
    }
    font-size: ${fontSizes.small};
  }
`;

export const ViewProfileButton = styled(Link)`
  cursor: pointer;
  background-color: #535353;
  border-radius: 2rem;
  padding: 2rem 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  white-space: nowrap;
`;

export const LikedItemsWrapper = styled.div`
  cursor: pointer;
  background: white;
  height: 22rem;
  width: 18rem;
  @media only screen and (max-width: 767px) {
    height: 40vw;
    width: 30vw;
  }
`;

export const LikedItem = styled.img`
  background: white;
  border-radius: 0.5rem;
  @media only screen and (max-width: 767px) {
  }
`;

export const LikedItemCategory = styled.div`
  background: white;
  font-size: ${fontSizes.small};
  white-space: nowrap;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  transform: translateY(20%);
  text-align: center;
  text-transform: capitalize;
`;

export const ActivityPageBottomPart = styled.section`
  width: 96rem;
  max-width: 97vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  grid-gap: 1rem;
  grid-row-gap: 2.5rem;
  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 3.5rem;
  }
`;

export const ListingOfCategoryWrapper = styled.section`
  &:last-child {
    margin-bottom: 7rem;
  }
  margin: auto;
`;

export const YourItemsInCategory = styled.div`
  font-size: ${fontSizes.medium};
  font-weight: bold;
  margin: auto;
  margin-bottom: 2rem;
  width: 96rem;
  max-width: 97vw;
`;

export const ListingOfCategory = styled.div`
  cursor: pointer;
  width: 96rem;
  max-width: 97vw;
  display: grid;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  div {
    position: relative;
  }

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const DeleteItemMobile = styled.img`
  position: absolute;
  height: 2rem;
  top: 5px;
  right: 5px;
  transition: all 0.3s ease;
  opacity: 1;
`;

interface IDeleteItem {}
export const DeleteItemDesktop = styled.img<IDeleteItem>`
  position: absolute;
  height: 2rem;
  top: 5px;
  right: 5px;
  transition: all 0.3s ease;
  opacity: 0;
  cursor: pointer;
  @media (hover: hover) {
    &:hover,
    &:focus {
      cursor: pointer;
      pointer-events: unset;
      transform: scale(1.3);
    }
  }
`;

export const CardImageWrapper = styled.div`
  @media (hover: hover) {
    &:hover,
    &:focus {
      transition: all 0.5s;
      .showX {
        opacity: 1;
      }
    }
  }
`;
interface ICardImageStyled {}

export const CardImageStyled = styled.img<ICardImageStyled>`
  @media (hover: hover) {
    &:hover,
    &:focus {
      transition: all 0.5s;
    }
  }
  border-radius: 0.5rem;
  z-index: 1;
`;
