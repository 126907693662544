import styled, { css } from "styled-components";

import { colors } from "../../../styles/globalStyled";

interface IIsSelected {
  isSelected: boolean;
}

interface IListingWrapper {
  category: CategoryUnion;
}

const threeColumnLayoutCategories: CategoryUnion[] = [
  "diningtable",
  "coffeetable",
  "consoletable",
  "chestofdrawers",
  "displaycabinet",
  "shelving",
  "wardrobe",
  "sofa",
  "armchair",
  "bed",
];
const hasThreeColumnLayout = (cat: CategoryUnion) =>
  threeColumnLayoutCategories.includes(cat);

export const ListingWrapper = styled.div<IListingWrapper>`
  display: grid;
  grid-area: listing;
  /* grid-template-columns: 14vw 14vw 14vw 14vw 14vw;
  grid-auto-rows: 14vw; */
  grid-template-columns: 23vw 23vw 23vw;
  grid-auto-rows: 23vw;
  grid-gap: 0.6rem;
  justify-content: center;
  padding-bottom: 10rem;
  @media (max-width: 767px) {
    grid-template-columns: 48vw 48vw;
    grid-auto-rows: 48vw;
    grid-gap: 0.5rem;
  }
  .showIcon {
    transition: all 0.2s;
    opacity: 0.75;
  }
  /* ${({ category }) =>
    hasThreeColumnLayout(category) &&
    css`
      grid-template-columns: 23vw 23vw 23vw;
      grid-auto-rows: 23vw;
      @media (max-width: 767px) {
        grid-template-columns: 48vw 48vw;
        grid-auto-rows: 48vw;
        grid-gap: 0.5rem;
      }
    `} */
`;

export const Card = styled.div<IIsSelected>`
  cursor: pointer;
  position: relative;
  border-radius: 0.5rem;
  border: 0.3rem solid transparent;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 0.3rem solid ${colors.greyDark};
      border-radius: 0.5rem;
      transition: all 0.2s;
    `}
  @media (hover: hover) {
    &:hover,
    &:focus {
      .showMagnifier {
        opacity: 1;
        pointer-events: unset;
      }
    }
  }
`;

interface ICardImageStyled {
  imgUrlFront: string;
  imgUrlBack: string | null;
}

export const CardImageStyled = styled.div<ICardImageStyled & IIsSelected>`
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ imgUrlFront }) => imgUrlFront});
  transition: all 0.5s;
  ${({ imgUrlBack }) =>
    imgUrlBack &&
    css`
      @media (hover: hover) {
        &:hover,
        &:focus {
          background-image: url(${imgUrlBack});
          transition: all 0.5s;
        }
      }
    `}
  ${({ isSelected }) =>
    isSelected &&
    css`
      border-radius: 0;
      filter: brightness(75%);
    `}
`;

export const CheckMarkIcon = styled.img<IIsSelected>`
  position: absolute;
  height: 3rem;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border-radius: 0;
      opacity: 1;
      filter: brightness(85%);
    `}
`;
interface IMagnifier {
  showMagnifier: boolean;
}

const highlighted = css`
  opacity: 1;
  pointer-events: unset;
`;

export const Magnifier = styled.img<IMagnifier>`
  position: absolute;
  height: 2.5rem;
  top: 1rem;
  right: 1.1rem;
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  ${({ showMagnifier }) => showMagnifier && highlighted}
  @media (max-width:768) {
    height: 2.3rem;
  }
  @media (hover: hover) {
    &:hover,
    &:focus {
      transform: scale(1.3);
      ${highlighted}
    }
  }
`;

export const MagnifierMobile = styled.img<IMagnifier>`
  position: absolute;
  height: 2.5rem;
  top: 1rem;
  right: 1.1rem;
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  ${({ showMagnifier }) => showMagnifier && highlighted}
`;

export const ShowUpToMax = styled.div<{ maxWidth: number }>`
  @media (min-width: ${({ maxWidth }) => maxWidth + 1}px) {
    display: none;
  }
`;

export const ShowFromMin = styled.div<{ minWidth: number }>`
  display: none;

  @media (min-width: ${({ minWidth }) => minWidth}px) {
    display: initial;
  }
`;
