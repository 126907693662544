import { map } from "lodash";

import concreteImg from "../../../images/materialIcons/concrete.jpg";
import glassImg from "../../../images/materialIcons/glass.jpg";
import marbleImg from "../../../images/materialIcons/marble.jpg";
import fabricImg from "../../../images/materialIcons/fabric.jpg";
import leatherImg from "../../../images/materialIcons/leather.jpg";
import metalImg from "../../../images/materialIcons/metal.jpg";
import plasticImg from "../../../images/materialIcons/plastic.jpg";
import velvetImg from "../../../images/materialIcons/velvet.jpg";
import wickerImg from "../../../images/materialIcons/wicker.jpg";
import woodImg from "../../../images/materialIcons/wood.jpg";

interface ColourWithHex {
  colourName: ColourName;
  hex: Hex;
}

export const colourNameToHexMap: Record<ColourName, Hex> = {
  Red: "#B9141E" as Hex,
  Orange: "#DE8B15" as Hex,
  Yellow: "#E7CB1A" as Hex,
  Green: "#389521" as Hex,
  Teal: "#008080" as Hex,
  Blue: "#1E0D6B" as Hex,
  Purple: "#6F0C59" as Hex,
  Pink: "#E786CB" as Hex,
  Black: "#000000" as Hex,
  "Dark Grey": "#2A2A2A" as Hex,
  "Light Grey": "#808588" as Hex,
  White: "#FFFFFF" as Hex,
  Beige: "#E9CABA" as Hex,
  "Light Brown": "#997950" as Hex,
  "Dark Brown": "#43270F" as Hex,
  Grey: "#6E6E6E" as Hex,
  Brown: "#755B3A" as Hex,
};

export const colorsFilters: ColourWithHex[] = map(
  colourNameToHexMap,
  (hex, colourName) => ({
    hex,
    colourName: colourName as ColourName,
  })
);

export type MaterialWithImg = {
  materialName: Material;
  img: string;
};

type MaterialsMap = {
  concrete: MaterialWithImg;
  glass: MaterialWithImg;
  marble: MaterialWithImg;
  fabric: MaterialWithImg;
  leather: MaterialWithImg;
  metal: MaterialWithImg;
  plastic: MaterialWithImg;
  velvet: MaterialWithImg;
  wicker: MaterialWithImg;
  wood: MaterialWithImg;
};

export const materialsMap: MaterialsMap = {
  concrete: { img: concreteImg, materialName: "Concrete" },
  glass: { img: glassImg, materialName: "Glass" },
  marble: { img: marbleImg, materialName: "Marble" },
  fabric: { img: fabricImg, materialName: "Fabric" },
  leather: { img: leatherImg, materialName: "Leather" },
  metal: { img: metalImg, materialName: "Metal" },
  plastic: { img: plasticImg, materialName: "Plastic" },
  velvet: { img: velvetImg, materialName: "Velvet" },
  wicker: { img: wickerImg, materialName: "Wicker" },
  wood: { img: woodImg, materialName: "Wood" },
};
