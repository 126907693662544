import { FC, useEffect, useState } from "react";
import { postPriceWatch } from "../../../services/api.service";
import { useShopContext } from "../../../store/shopContext";
import { BuyNowLinkStyled } from "../RecommendationsPageStyled";

export const AddToCartButton: FC<{item: IFurnitureItem, variantId: ItemId}> = ({item, variantId}) => {
    const { cart, addToCart, increaseInCart, toggleCartOpen } = useShopContext();

    const isAdded = (id: ItemId) =>
      cart.map(x => x.item.id).includes(id)

    const addAndOpen = () => {
      if (!isAdded(variantId)) {
        addToCart(item);
        postPriceWatch(item);
      }
      else increaseInCart(item);
      
      toggleCartOpen(true);
    };

    const active = item.isRetailerOperating;

    return (
      <BuyNowLinkStyled onClick={active ? addAndOpen : undefined} isActive={active}>
        {isAdded(variantId) ? "Add Another To Basket" : "Add To Basket"}
      </BuyNowLinkStyled>
    )
}