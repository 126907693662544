import React, { FC } from "react";
import styled, { css } from "styled-components";
import Range from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

import { colors } from "../../styles/globalStyled";
import { subtitleSize } from "./sizes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { breakpoints } from "../../styles/responsive";

interface CheckProps {
  isWhite?: boolean;
}

const Check: FC<CheckProps> = ({ isWhite }) => (
  <FontAwesomeIcon
    size="lg"
    icon={faCheckCircle}
    color={isWhite ? "#fff" : colors.orange}
  />
);

const buttonContainerBasic = css<{ isSelected?: boolean }>`
  cursor: pointer;
  border: 1px solid black;
  font-size: ${subtitleSize}rem;
  padding: 10px;
  width: 300px;
  height: 38px; // 1:8 ratio 🙄
  margin: 5px 0;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.button<{ isSelected?: boolean }>`
  ${buttonContainerBasic}
  background-color: ${colors.greyLight};
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${colors.yellow};
    `}
`;

const ButtonLabel = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const ButtonSide = styled.div`
  display: flex;
  justify-content: center;
  width: 25px;
`;

type ButtonProps = { 
  isSelected?: boolean; 
  onClick?: VoidFunction;
  children?: React.ReactNode;
};

const Button: FC<ButtonProps> = ({ isSelected, onClick, children }) => {
  return (
    <ButtonContainer isSelected={isSelected} onClick={onClick}>
      <ButtonSide />
      <ButtonLabel>{children}</ButtonLabel>
      <ButtonSide>{isSelected && <Check />}</ButtonSide>
    </ButtonContainer>
  );
};

export type Option<T> = { value: T; label: string };

interface SingleSelectProps<T> {
  onSelect: (value?: T) => void;
  options: Option<T>[];
  selected?: T;
}

export function SingleSelect<T extends string | number | boolean | null>({
  options,
  onSelect,
  selected,
}: SingleSelectProps<T>) {
  return (
    <div>
      {options.map(({ label, value }) => (
        <Button
          key={`${value}`}
          isSelected={selected === value}
          onClick={() => (selected === value ? onSelect() : onSelect(value))}
        >
          {label}
        </Button>
      ))}
    </div>
  );
}

interface MultipleSelectProps<T> {
  selecteds: T[];
  onClick: (value: T) => void;
  options: Option<T>[];
}

export function MultipleSelect<T extends string | number | boolean | null>({
  selecteds,
  onClick,
  options,
}: MultipleSelectProps<T>) {
  return (
    <div>
      {options.map(({ label, value }) => (
        <Button
          key={`${value}`}
          isSelected={selecteds.includes(value)}
          onClick={() => onClick(value)}
        >
          {label}
        </Button>
      ))}
    </div>
  );
}

type ColourButtonProps = {
  isSelected?: boolean;
  bgImage: string;
  onClick?: VoidFunction;
  children?: React.ReactNode;
};

const ColourButtonButton = styled.div<ColourButtonProps>`
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  width: 300px;
  margin: 5px 0;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid black;
      backdrop-filter: greyscale(
        1
      );` // @TODO: doesn't actually work, need to find solution that does
    }
`;

const ColourButtonLabel = styled.label`
  cursor: pointer;
  display: none;

  @media (min-width: ${breakpoints.mobile}) {
    display: initial;
  }
`;

const ColourButtonContainer = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const ColourButton: FC<ColourButtonProps> = ({
  isSelected,
  bgImage,
  onClick,
  children,
}) => (
  <ColourButtonContainer onClick={onClick}>
    <ColourButtonButton isSelected={isSelected} bgImage={bgImage}>
      {isSelected && <Check isWhite />}
    </ColourButtonButton>
    <ColourButtonLabel>{children}</ColourButtonLabel>
  </ColourButtonContainer>
);

export type ColourSelectOption<T> = Option<T> & { bgImage: string };

interface MultipleColourSelectProps<T> {
  onClick: (value: T) => void;
  options: ColourSelectOption<T>[];
  selecteds: T[];
}

export function MultipleColourSelect<T extends string>({
  options,
  onClick,
  selecteds,
}: MultipleColourSelectProps<T>) {
  return (
    <div>
      {options.map(({ label, value, bgImage }) => (
        <ColourButton
          key={`${value}`}
          isSelected={selecteds.includes(value)}
          onClick={() => onClick(value)}
          bgImage={bgImage}
        >
          {label}
        </ColourButton>
      ))}
    </div>
  );
}

const RangeSliderStyled = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Goes in the parent of a Range component to overwrite `rc-slider` class styles
 */
export const rcSliderRangeStyles = css`
  --size: 26px;
  --colour: ${colors.orange};

  .rc-slider {
    height: var(--size);
  }

  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-step {
    top: 50%;
    transform: translateY(-50%);
  }

  .rc-slider-track {
    background-color: var(--colour);
  }

  .rc-slider-handle {
    height: var(--size);
    width: var(--size);
    border-color: var(--colour);
  }
`;

const RangeContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 1;

  ${rcSliderRangeStyles}
`;

const RangeIndicator = styled.div`
  background-color: ${colors.greyLight};
  border: 1px solid black;
  font-size: 1.25rem;
  padding: 10px;
  width: 75px;
  text-align: center;
`;

export interface RangeSliderProps {
  values?: NumRange;
  minValue: number;
  maxValue: number;
  step?: number;
  onChange?: (vals: NumRange) => void;
  unit?: string;
  unitIsPrefixed?: boolean;
}

export const RangeSlider: FC<RangeSliderProps> = ({
  values,
  minValue,
  maxValue,
  step,
  onChange,
  unit = "",
  unitIsPrefixed,
}) => {
  const withUnit = (n: number | string) =>
    unitIsPrefixed ? `${unit}${n}` : `${n}${unit}`;

  const [a, b] = values ?? ["", ""];
  return (
    <RangeSliderStyled>
      <RangeIndicator>{withUnit(a)}</RangeIndicator>
      <RangeContainer>
        <Range
          value={values}
          min={minValue}
          max={maxValue}
          step={step}
          onChange={onChange}
        />
      </RangeContainer>
      <RangeIndicator>{withUnit(b)}</RangeIndicator>
    </RangeSliderStyled>
  );
};
