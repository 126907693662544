import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { FC, ReactElement } from "react";
import Spacer from "react-spacer";
import { BuyNowLinkStyled } from "../../../pages/RecommendationsPage/RecommendationsPageStyled";
import { SidePanelHeader } from "../ShopCart";
import { CartContainer,  CheckoutButtonContainer, CheckoutButtonLabel } from "../ShopCartStyles";
import { FoldBlock } from "./FoldBlock";
import { CheckoutDescription, ContentTable, ContentTableCaptions, ContentTableRow, ContentTableRowHeader, TCLink } from "./InformationStepStyles";

interface InformationStepProps {
  opened: boolean;
  redirecting: boolean;
  toggleOpened: (status: boolean) => void;
  checkout: () => void;
  closeCart: () => void;
};

const ReturnsBlockContent = () => (
  <>
    Upon receiving your items you will have 14 days to inform us if you do not wish to keep any item/s from your purchase. Returns will be at your own expense, although for some items we will need to arrange this on your behalf (at a cost of between £40-£80).
    <br/><br/>
    If you wish to make a return, please email returns@withflitch.com. Some suppliers will allow a longer period than 14 days and multiple items are to be treated on a per supplier basis and may have different return periods. In our confirmation email, we will specify the return period and process for the item/s in your order.
  </>
);

//"We charge a flat shipping fee of £5.95 for small items, £24.95 for medium items, and £49.95 for large items (exceptions may apply).\nWe estimate that shipping times will take on average 3-7 working days for small items and medium items, and 12-24 working days for large items; however this will vary from item to item and we will provide specific timeframes in your confirmation email.\nIn order to streamline the fulfilment process, we organise deliveries direct from suppliers to you. This ensures your order will arrive faster and minimise the risk of in-transit damage. As a result, our shipping fees are on a per-supplier basis. This means that if your order can be fulfilled by a single supplier we will only charge you a single delivery fee (which will be the highest individual unit delivery amount of the items ordered); if on the other hand your order can only be fulfilled by multiple suppliers we will charge you a delivery fee for each supplier. By way of illustration, if you order a small item that is fulfilled by one supplier and a large item that is fulfilled by another supplier, we will charge you £55.90 for delivery (being £5.95 + £49.95)."
const DeliveryBlockContent = () => (
  <>
    <p>Our delivery rates are as follows:</p>
    <ContentTable>
      <ContentTableCaptions>
        <p/>
        <p>Cost</p>
        <p>Time</p>
      </ContentTableCaptions>
      <ContentTableRow>
        <ContentTableRowHeader>
          Small
        </ContentTableRowHeader>
        <p>&pound;5.95</p>
        <p>3-7 working days</p>
      </ContentTableRow>
      <ContentTableRow>
        <ContentTableRowHeader>
          Medium
        </ContentTableRowHeader>
        <p>&pound;24.95</p>
        <p>3-7 working days</p>
      </ContentTableRow>
      <ContentTableRow>
        <ContentTableRowHeader>
          Latge
        </ContentTableRowHeader>
        <p>&pound;49.95</p>
        <p>12-24 working days</p>
      </ContentTableRow>
    </ContentTable>
    <p>
      In order to streamline the fulfilment process, we organise deliveries 
      direct from suppliers to you. This ensures your order will arrive faster 
      and minimise the risk of in-transit damage. As a result, our shipping 
      fees are on a per-supplier basis. This means that if your order can be 
      fulfilled by a single supplier we will only charge you a single delivery 
      fee (which will be the highest individual unit delivery amount of the 
      items ordered); if on the other hand your order can only be fulfilled by 
      multiple suppliers we will charge you a delivery fee for each supplier. 
      By way of illustration, if you order a small item that is fulfilled by 
      one supplier and a large item that is fulfilled by another supplier, 
      we will charge you £55.90 for delivery (being £5.95 + £49.95).
    </p>
  </>
);

const CancellationBlockContent = () => (
  <p>
    If at any point prior to confirmation you wish to cancel part or all of your order, 
    please email <a href="mailto:orders@withflitch.com">orders@withflitch.com</a>.
  </p>
);

const Description = () => (
  <>
    We rely on a number of trusted suppliers to source and fulfill your order.
    <Spacer height="10px"/>
    After you place your order with us, we will be in touch with the relevant supplier(s) and notify you when the order has been confirmed.
    <Spacer height="10px"/>
    You can find additional information on how we manage orders below.
    <Spacer height="10px"/>
  </>
);

export const InformationStep: FC<InformationStepProps> = ({
  opened,
  redirecting,
  toggleOpened,
  checkout,
  closeCart
}) => {
  const foldBlocksContent = ([
    ["Payment", "Upon placing an order, your payment will be held whilst we confirm stock availability and shipping time. Once confirmed, the payment will be processed and you will receive an email confirming your order."],
    ["Delivery", (<DeliveryBlockContent />)],
    ["Returns", (<ReturnsBlockContent/>) ],
    ["Cancellation", (<CancellationBlockContent />)]
  ] as [string, string|EmotionJSX.Element][])
  .reduce((acc, x: [string, string|EmotionJSX.Element]) => {
    const [title, text] = x;
    return {...acc, [title]: text}
  }, {} as {[_: string]: string|EmotionJSX.Element});

  const close = () => {
    closeCart();
    toggleOpened(false);
  }
  
  return (
    <CartContainer id="info-step" isOpen={opened}>
      <SidePanelHeader closeAction={() => toggleOpened(false)} />
      <CheckoutDescription>
        <Description />
        <FoldBlock key="fb_0" title="Payment" content={foldBlocksContent["Payment"]!}/>
        <FoldBlock key="fb_1" title="Delivery" content={foldBlocksContent["Delivery"]!}/>
        <FoldBlock key="fb_2" title="Returns" content={foldBlocksContent["Returns"]!}/>
        <FoldBlock key="fb_3" title="Cancellation" content={foldBlocksContent["Cancellation"]!}/>
        Our full T&Cs can be found <u><TCLink to="/terms-of-sale" onClick={close}>here</TCLink></u>.
      </CheckoutDescription>
      <CheckoutButtonContainer id="last">
        <BuyNowLinkStyled isActive={true} onClick={checkout}>
            <CheckoutButtonLabel>
              {redirecting ? (<Icon path={mdiLoading} spin={1}/>) : "Proceed"}
            </CheckoutButtonLabel>
        </BuyNowLinkStyled>
      </CheckoutButtonContainer>
    </CartContainer>
  );
};