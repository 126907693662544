import { useCallback, useReducer } from "react";
import { useEffect } from "react";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { v4 } from "uuid";
import {
  fetchBrowseRanges,
  getShopPage,
} from "../../services/api.service";
import { NavBar } from "../../shared/NavBar/NavBar";
import {
  capitaliseTitle,
  distinct,
  getTruthyKeyValPairs,
  randomBetween,
  seoficateTag,
  toggle,
  toggleFromArray,
} from "../../utils/helpers";
import { 
  MenuContainer,
  SortListItem,
  TagGroupDropdownMenu,
  TagGroupNavItem,
} from "./BrowsePageStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { BrowseFilterPanel } from "./BrowseFilterPanel";
import { FilterPanelBackdrop } from "../RecommendationsPage/FilterPanel";
import { BrowseItem } from "./BrowseItem";
import { 
  DropDownItemCss, 
  FilterButtonCss, 
  FilterPanel, 
  HeaderCss, 
  ItemGridCss, 
  NavBarCss, 
  PageContainerCss, 
  PageContentSectionCss, 
  SortButtonCss, 
  SortSpanCss, 
  SortUl, 
  SpinnerDiv, 
  StrokeCSS } from "./BrowsePageStyles";
import { isMobile } from "react-device-detect";
import { colors } from "../../styles/globalStyled";
import { Link, useLocation } from "react-router-dom";
import { ProductCounter } from "./Counter/Counter";
import { useSearchContext } from "../../store/searchContext";
import { useShopContext } from "../../store/shopContext";
import { useUserContext } from "../../store/userContext";
import { BrowsePrompt } from "./BrowsePrompt/BrowsePrompt";
import { Blurb } from "./Blurb/Blurb";

const defaultFilters: BrowseFilterOptions = {
  coloursPreference: [],
  materialsPreference: [],
  retailers: [],
  onSale: [],

  budget: undefined,
  height: undefined,
  width: undefined,
  depth: undefined,
};

const initialState: BrowseState = {
  items: [],
  total: 0,
  allColors: [],
  allMats: [],
  allRetailers: [],
  latestNonce: "",
  filters: defaultFilters,
  tag: null,
  sortOrder: null,
  includeInStock: true,
  includeOutOfStock: false,
  soldByFlitch: true,
  soldBy3rdParty: true,
  sale: undefined
};

const reducer = (state: BrowseState, action: Action): BrowseState => {
  switch (action.type) {
    case "fetchStarted":
      return { ...state, latestNonce: action.nonce };

    case "loadItems":
      return action.nonce === state.latestNonce
        ? { 
            ...state, 
            total: action.total,
            items: action.items, 
            allColors: action.allColors, 
            allMats: action.allMats, 
            allRetailers: action.allRetailers 
        }
        : state;

    case "filterChange":
      const { keyAndVal } = action;

      return {
        ...state,
        filters: { ...state.filters, [keyAndVal[0]]: keyAndVal[1] },
      };

    case "changeSortOrder":
      const { sortOrder } = action;

      return { ...state, sortOrder };

    case "resetFilters":
      return { ...state, filters: defaultFilters };

    case "toggleRetailerFilter":
      const { retailer } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          retailers: toggleFromArray(state.filters.retailers)(retailer),
        },
      };

    case "toggleColourFilter":
      const { colour } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          coloursPreference: toggleFromArray(state.filters.coloursPreference)(
            colour
          ),
        },
      };

    case "toggleMaterialFilter":
      const { material } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          materialsPreference: toggleFromArray(
            state.filters.materialsPreference
          )(material),
        },
      };

    case "changeTag":
      return action.tag === state.tag
        ? state
        : { ...state, tag: action.tag, filters: defaultFilters };

    case "setSlider":
      return {
        ...state,
        filters: { ...state.filters, [action.label]: action.range },
      };

    case "changeIncludeIS":
      const { includeInStock } = action;
      return { ...state, includeInStock };

    case "changeIncludeOOS":
      const { includeOutOfStock } = action;
      return { ...state, includeOutOfStock };

    case "changeSoldByFlitch":
      const { soldByFlitch } = action;
      return { ...state, soldByFlitch };
  
    case "changeSoldBy3rdParty":
      const { soldBy3rdParty } = action;
      return { ...state, soldBy3rdParty };

    case "changeSale":
      const { sale } = action;
      return { ...state, sale };
  }
};

const prompts = [{
    promptType: "stylist",
    question: "Let your stylist scan the market for you",
    answer: "Leave the furniture search to us"
  }, {
    promptType: "sq",
    question: "Outsource the headache of browsing",
    answer: "Get tailored furniture shortlists"
  }, {
    promptType: "stylist",
    question: "Looking for some help?",
    answer: "Get your very own interior stylist"
  }, {
    promptType: "sq",
    question: "Only consider items that match your style",
    answer: "Get tailored furniture shortlists"
  }, {
    promptType: "stylist",
    question: "Give your search the VIP treatment",
    answer: "Get your very own interior stylist"
  }, {
    promptType: "sq",
    question: "Let's team up to find your perfect item",
    answer: "Get expert furniture suggestions"
  }] as BrowsePromptProps[];


export const BrowsePage = () => {
  const [browseMaxVals, browseMaxValsSet] = useState<BrowseMaxVals>();

  const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState);

  const [filtersOpen, filtersOpenSet] = useState(false);
  const [anyItemHovered, anyItemHoveredSet] = useState(false);

  const emptyTag = "" as Tag;
  const [hoveredCategory, hoveredCategorySet] = useState(emptyTag); 

  const [sortMenuOpened, sortMenuOpenedSet] = useState(false); 

  const emptyFilterOptions = {omit: [], add: []} as FilterOptions;
  const [filterOptions, setFilterOptions] = useState(emptyFilterOptions);

  const [customFilters, setCustomFilters] = useState([] as string[])

  const { search, fixedSearch, appliedSearch, keywords, selectedShopCat, applySearch } = useSearchContext();
  const { nameWords, materials: searchMaterials, colors: searchColors, retailers: searchRetailers, shopCats: searchCats } = keywords;

  const {browseMetadata, updateBrowseMetadata} = useShopContext();

  const [searchLabel, setSearchLabel] = useState("");

  const [filterTags, setFilterTags] = useState([] as Tag[]);

  const {customerId} = useUserContext();

  useEffect(() => {
    setSearchLabel(search);
    dispatch({ type: "resetFilters" });
  }, [appliedSearch]);

  useEffect(() => {
    fetchBrowseRanges(state.tag ?? undefined, collectTags()).then(browseMaxValsSet);
    setFilterTags([]);
  }, [state.tag]);

  const [nextPage, setNextPage] = useState(1);

  const resetItems = () => {
    dispatch({ type: "fetchStarted", nonce: "" });
    dispatch({ type: "loadItems", ...{nonce: "", items: [], allColors: [], allMats: [], allRetailers: [], total: 0} })
    setNextPage(1);
  }

  const compare = (tag : TagBase, toCompareWith : string | undefined) => {
    const result1 = tag.urlAlias?.replaceAll("-", " ") === toCompareWith?.toLowerCase()
    const result2 = tag.name.toLowerCase() === toCompareWith?.toLowerCase()
    return result1 || result2;
  }
  const getFilters = (tag?: Tag) => {
    if (!browseMetadata) return [];
    const parent = browseMetadata.tags.find(t => compare(t, tag?.toLowerCase()));
    if (parent) return [];

    const child = browseMetadata.tags
      .filter(x => hoveredCategory ? compare(x, hoveredCategory) : true)
      .map(t => t.children)
      .reduce((a, b) => [...a, ...b])
      .find(c => c.name.toLowerCase() === tag?.toLowerCase());

    return child
      ? child.filters
      : []
  };

  const location = useLocation();

  const isUrlAliasEqual = (tag: string | undefined, t : TagBase) =>
    t.urlAlias === tag || t.name.toLowerCase() === tag?.replaceAll("-", " ")

  const getPathSegments = () => {
    const arr = location.pathname.split("/");
    const index = arr.indexOf("shop");

    return [arr[index+1], arr[index+2]];
  };
  
  const getPathTags = (meta: BrowseMetadata | undefined) : [Tag | undefined, Tag | undefined] => {
    if (!meta) return [undefined, undefined]

    const [parent, child] = getPathSegments();
    //console.log(parent, child)


    if (!parent) {return [undefined, undefined]};
    const find = (tag: string | undefined) =>
      meta.tags.find(t => isUrlAliasEqual(tag, t))?.name ?? 
      meta.tags
        .map(tt => tt.children)
        .reduce((a, c) => [...a, ...c], [])
        .find(t => isUrlAliasEqual(tag, t))
        ?.name;

    return [find(parent) as Tag, find(child) as Tag];

  };

  const getSearchOptions = () => {
    return {
      coloursPreference: searchColors,
      materialsPreference: searchMaterials,
      retailers: searchRetailers,
      originalString: search,
      correctedString: fixedSearch
    } as SearchRequest;
  };

  const [activeLoadings, setActiveLoadings] = useState<string[]>([]);

  const PAGE_SIZE = 30;
  const getPage = (pageNo: number) => {
    const nonce = v4();
    dispatch({ type: "fetchStarted", nonce });
    
    setActiveLoadings(prev => [...prev, nonce]);

    return getShopPage(
      customerId,
      appliedSearch ? "all" : state.tag ?? "all", 
      collectTags(), 
      PAGE_SIZE, 
      pageNo,
      nonce,
      getSearchOptions(),
      state.filters,
      nameWords, 
      state.includeInStock, 
      state.includeOutOfStock, 
      state.soldByFlitch,
      state.soldBy3rdParty,
      state.sale,
      state.sortOrder).then(async data => {
        setActiveLoadings(prev => prev.filter(x => x !== nonce))
        return data;
      });
  };

  const randomPrompt = (except: number | undefined = undefined) => {
    const source = except
      ? prompts.filter(i => i != prompts[except])
      : prompts;

    const no = Math.floor(Math.random()*source.length);
    return source[no] as BrowsePromptProps;
  };

  const addPrompts = (items: (IFurnitureItem | BrowsePromptProps)[]) => {
    switch (items.length) {
      case 0: return [];
      
      case PAGE_SIZE:
        const skip = randomBetween(13, 17);
        const slice1 = items.slice(0, skip);
        const slice2 = items.slice(skip, items.length);

        const firstPrompt = Math.floor(Math.random()*prompts.length);
        return [
          ...slice1,
          prompts[firstPrompt],
          ...slice2,
          randomPrompt(firstPrompt)];

      default: return [...items, randomPrompt()];
    };
  }

  const isLoading = activeLoadings.length > 0;

    
  useEffect(() => {
    resetItems();

    getPage(0).then(
      (data) => {
        const newData = {
          allColors: data.allColors,
          allMats: data.allMats,
          allRetailers: data.allRetailers,
          nonce: data.nonce,
          total: data.total,
          items: addPrompts(data.items).filter(distinct) as (IFurnitureItem | BrowsePromptProps)[]
        };

        dispatch({ type: "loadItems", ...newData });
      });
  }, [
      state.filters, state.tag, state.sortOrder, 
      customFilters, browseMetadata, state.includeInStock, 
      state.includeOutOfStock,  state.soldByFlitch, appliedSearch,
      state.soldBy3rdParty, state.sale, selectedShopCat, filterTags]);
 
  useEffect(() => {
    updateBrowseMetadata().then(meta => { 
      const [parent, child] = getPathTags(meta);
      const filters = getFilters(child ?? parent);
      if (child) setHoveredCategory(!!parent, parent);
      setTag(child ?? parent ?? emptyTag, filters);
    });
  }, [location]);

  const loadNextPage = () => {
    if (isLoading) return;

    getPage(nextPage).then(
      (data) => {
        const newData = {
          allColors: data.allColors,
          allMats: data.allMats,
          allRetailers: data.allRetailers,
          nonce: data.nonce,
          total: data.total,
          items: [...state.items, ...addPrompts(data.items).filter(distinct)] as (IFurnitureItem | BrowsePromptProps)[]
        };
 
        dispatch({ type: "loadItems", ...newData });
        setNextPage(nextPage+1);
      })
  };

  const [y, setY] = useState(window.scrollY);

  const handleScroll = useCallback(
    (_: any) => {
      let bottom = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight;
      if (bottom) loadNextPage();
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);


  const setTag = (
    tag: Tag | null = null, 
    filters: FilterOptions[] | undefined = undefined
  ) => {
    dispatch({ type: "changeTag", tag });
    if(customFilters.length > 0) setCustomFilters([]);

    const flatten = filters && filters.length > 0
      ? filters[0] ?? emptyFilterOptions
      : emptyFilterOptions;
     
   setFilterOptions(flatten);
    
    if (!tag || !browseMetadata?.tags.map(t => t.displayName ?? t.name).includes(tag)){
      setHoveredCategory(false, emptyTag);
      return;
    }

    const needSet = hoveredCategory !== tag;
    setHoveredCategory(needSet, needSet && tag ? tag : emptyTag);
  }

  const setRange = (rangeLabel: keyof Ranges, range: NumRange) =>
    dispatch({ type: "setSlider", label: rangeLabel, range });

  const filtersSelected = getTruthyKeyValPairs(state.filters);

  const setHoveredCategory = (hovered: boolean, tag?: Tag) => {
    anyItemHoveredSet(hovered);
    hoveredCategorySet(hovered ? tag! : emptyTag)
  }

  const handleCategoryHover = (isMouseEntered: boolean, tag: Tag) => {
    if (isMobile) return;
    setHoveredCategory(isMouseEntered, tag);
  }

  const getFilterCatNames = (child: ChildTag) => 
    child.filters.map(f => 
      f.add.map(x => x.categories.map(cat => cat.name))
      .reduce((a, b) => [...a, ...b], []))
    .reduce((a, b) => [...a, ...b], []);

  const collectTags = () => {
    if (filterTags && filterTags.length > 0 ) return filterTags;

    if (!browseMetadata) return [];
    if (appliedSearch) return selectedShopCat ? [ selectedShopCat as Tag] : searchCats.map(x => x as Tag);
    if (!state.tag) return [];

    const parent = browseMetadata.tags.find(t => t.name.toLowerCase() === state.tag?.toLowerCase());
    if (parent)
      return [
        parent.name, 
        ...parent.children.map(c => c.name),
        ...parent.children.map(c => getFilterCatNames(c)).reduce((a, b) => [...a, ...b], [])];

    const child = browseMetadata.tags
      .filter(x => hoveredCategory ? x.name === hoveredCategory : true)
      .map(t => t.children)
      .reduce((a, b) => [...a, ...b])
      .find(c => c.name.toLowerCase() === state.tag?.toLowerCase());

    if (child)
        return [
          child.name,
          ...getFilterCatNames(child).filter(c => customFilters.length > 0 ? customFilters.includes(c) : true)
        ];

    return [];
  }

  const getDisplayName = () => {
    if (!browseMetadata) return "All Items";

    const [pUrl, cUrl] = getPathSegments();

    const normalize = (t: string | undefined) => capitaliseTitle(t?.replaceAll("-", " ") ?? "");

    if (!cUrl) {
      const parent = browseMetadata.tags.find(x => isUrlAliasEqual(pUrl, x));
      return parent 
        ? capitaliseTitle(parent.h1 ?? parent.displayName) 
        : normalize(pUrl);
    }
    
    const child = browseMetadata.tags
      .map(t => t.children)
      .reduce((a, b) => [...a, ...b], [])
      .find(x => isUrlAliasEqual(cUrl, x));

    console.log(pUrl, cUrl, child)

    return child 
      ? child.h1 ?? child.displayName ?? normalize(cUrl) 
      : normalize(cUrl);
  };

  const getTitle = (name : Tag | null) => {
    if (!browseMetadata || !name) return name ?? "shop";

    const parent = browseMetadata.tags.find(x => x.name === name);
    if (parent) return parent.title ?? "shop"

    const child = browseMetadata.tags
      .map(t => t.children)
      .reduce((a, b) => [...a, ...b], [])
      .find(x => x.name === name);

    return child?.title
      ? child.title ?? "shop"
      : name ?? "shop";
  }

  const dispatchSale = (val: SaleFilter) => dispatch({ type: "changeSale", sale: val })

  const renderItem = (x : IFurnitureItem | BrowsePromptProps) => {
    if (x.hasOwnProperty("id")) {
      const item = x as IFurnitureItem;
      return (<BrowseItem key={item.id} item={item} />);
    }

    const prompt = x as BrowsePromptProps
    return (
      <BrowsePrompt 
        promptType={prompt.promptType}
        question={prompt.question}
        answer={prompt.answer}
        key={Math.random()}
      />);
  }

  const getBlurb = () => {
    if (!browseMetadata) return undefined;

    const [parentTag, _] = getPathTags(browseMetadata);
    const parent = browseMetadata.tags.find(t => t.name === parentTag);

    return parent ? <Blurb text={parent.blurb} /> : undefined;
  }

  return (
    <>
      <FlitchHelmet title={getTitle(state.tag)}/>

      {/* Filter sidebar */}
      <FilterPanelBackdrop
        isOpen={filtersOpen}
        onClick={() => filtersOpenSet(false)}
      />
      <BrowseFilterPanel
        state={state}
        isOpen={filtersOpen}
        close={() => filtersOpenSet(toggle)}
        numberLeft={state.total}
        browseMaxVals={browseMaxVals}
        filters={state.filters}
        resetFilters={() => dispatch({ type: "resetFilters" })}
        setRange={setRange}
        dispatch={dispatch}
        options={filterOptions}
        selectedCustomFilters={customFilters}
        selectCustomFilters={setCustomFilters}
        includeIS={state.includeInStock}
        includeOOS={state.includeOutOfStock}
        setIncludeIS={val => dispatch({ type: "changeIncludeIS", includeInStock: val })}
        setIncludeOOS={val => dispatch({ type: "changeIncludeOOS", includeOutOfStock: val })}
        soldByFlitch={state.soldByFlitch}
        soldBy3rdParty={state.soldBy3rdParty}
        setSoldByFlitch={val => dispatch({ type: "changeSoldByFlitch", soldByFlitch: val })}
        setSoldBy3rdParty={val => dispatch({ type: "changeSoldBy3rdParty", soldBy3rdParty: val })}
        sale={state.sale}
        setSale={dispatchSale}
        selectedTags={getPathTags(browseMetadata)}
        filterTags={filterTags}
        setFilterTags={setFilterTags}
      />

      <NavBar />
      <div css={NavBarCss}>
        <MenuContainer>
          <TagGroupNavItem 
            key={"all"}
            label="ALL"
            onClick={() => {dispatchSale(undefined); setFilterTags([])}}
            anyItemHovered={anyItemHovered}
            thisItemHovered={false}
            onMouseEnter={() => handleCategoryHover(false, emptyTag)}
            onMouseLeave={() => handleCategoryHover(false, emptyTag)}
          />
          <TagGroupNavItem
            onClick={() => {dispatchSale("Sale"); setFilterTags([])}}
            key="sale"
            label="SALE"
            highlighted={true}
            anyItemHovered={anyItemHovered}
            thisItemHovered={false}
            onMouseEnter={() => handleCategoryHover(false, emptyTag)}
            onMouseLeave={() => handleCategoryHover(false, emptyTag)}
          />
          {browseMetadata?.tags.map((tagGroup) => (
            <TagGroupNavItem
              onClick={() => {dispatchSale(undefined); setFilterTags([])}}
              key={`${tagGroup.title ?? tagGroup.name}_menu}`}
              label={tagGroup.displayName ?? tagGroup.name}
              name={tagGroup.name}
              urlAlias={tagGroup.urlAlias}
              anyItemHovered={anyItemHovered}
              thisItemHovered={tagGroup.name === hoveredCategory}
              onMouseEnter={() => handleCategoryHover(true, tagGroup.name)}
              onMouseLeave={() => handleCategoryHover(false, emptyTag)}
            />
          ))}
        </MenuContainer>

        {browseMetadata?.tags.map((tagGroup) => (
          <TagGroupDropdownMenu 
            key={`${tagGroup.title ?? tagGroup.name}_submenu}`}
            itemCount={tagGroup.children.length} 
            visible={tagGroup.name === hoveredCategory}
            onMouseEnter={() => setHoveredCategory(true, tagGroup.name)}
            onMouseLeave={() => setHoveredCategory(false)}
          >
            {tagGroup.children.map((tag) => (
              <Link
                css={DropDownItemCss}
                key={(tagGroup.title ?? tagGroup.name)+tag.name+Math.random()}
                onClick={() => applySearch("", false, true)}
                to={`/shop/${tagGroup.urlAlias ?? seoficateTag(tagGroup.name)}/${tag.urlAlias ?? seoficateTag(tag.name)}`}
              >
                {tag.displayName}
              </Link>
            ))}
          </TagGroupDropdownMenu>
        ))}

        {/* Page container */}
        <div css={PageContainerCss}>
          {/* Page content section – everything under navbar and tag menus */}
          <div css={PageContentSectionCss}>
            <div>
              <h1 css={HeaderCss}>
                {search 
                  ? `Search Results${searchLabel ? ` for '${searchLabel}'` : undefined}`
                  : getDisplayName() ?? "All Items"}
              </h1>
            </div>
            {getBlurb()}
            <div css={StrokeCSS}/>

            <FilterPanel>
              <button
                onClick={() => filtersOpenSet(toggle)}
                css={FilterButtonCss}
              >
                Filter
                {filtersSelected.length > 0 || customFilters?.length > 0
                  ? ` (${filtersSelected.length + +(customFilters?.length > 0)})`
                  : ""}
              </button>
              <ProductCounter total={state.total} count={state.items.length} loading={isLoading}/>
              <button 
                css={SortButtonCss} 
                onClick={() => sortMenuOpenedSet(!sortMenuOpened)} 
                onBlur={() => sortMenuOpenedSet(false)}
              >
                <span css={SortSpanCss}>
                  Sort
                </span>
                <FontAwesomeIcon icon={faChevronDown} color={colors.greyDarker}/>
                <SortUl isOpen={sortMenuOpened}>
                  <SortListItem 
                    dispatch={dispatch}
                    sortOrder={null}
                    label="Default"
                  />
                  <SortListItem 
                    dispatch={dispatch}
                    sortOrder="PriceLowToHigh"
                    label="Price: Low To High"
                  />
                  <SortListItem 
                    dispatch={dispatch}
                    sortOrder="PriceHighToLow"
                    label="Price: High To Low"
                  />
                  <SortListItem 
                    dispatch={dispatch}
                    sortOrder="DiscountPercent"
                    label="Discount (%): High To Low"
                  />
                  <SortListItem 
                    dispatch={dispatch}
                    sortOrder="DiscountAbsolute"
                    label="Discount (£): High To Low"
                  />
                </SortUl>
              </button>
            </FilterPanel>

            {/* The grid of items */}
            <div css={ItemGridCss}>
              {/* @TODO: don't slice, or maybe do it more intelligently in a lazy loading, infinite scroll kind of way */}
              {/* {state.items.map((item) => <BrowseItem key={item.id} item={item} />)} */}
              {state.items.map(renderItem)}
            </div>
            {isLoading && (<SpinnerDiv>
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin={true}
                color={colors.greyDark}
                size="4x"
              />
            </SpinnerDiv>)}
          </div>
        </div>
      </div>
    </>
  );
};
