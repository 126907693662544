const prod = "production";
const dev = "development";

type nodeEnvOption = typeof prod | typeof dev | undefined;

interface Process {
  env: { NODE_ENV: nodeEnvOption };
}

declare var process: Process;

export const isProd = process.env.NODE_ENV !== dev;

export const serverUrl = "";

export const apiUrl = serverUrl + "/api";

export const minSelectedItems = 1;

/**
 * localStorage flag to prevent sending analytics
 */
export const isNonStaff = !localStorage.getItem("isStaff");
