import { FC, useState } from "react";
import { Link } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiPlus, mdiMinus } from "@mdi/js";
import { 
  FaqContainer, 
  FaqList, 
  FaqListItem,
  FaqRow,
  FaqGroupDiv} from "../pages/AboutStylistPage/AboutStylistStyles";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";


type QAPair = {
  question: string,
  answer: EmotionJSX.Element
}

interface FaqProps {
  content: QAPair[];
};

const Item: FC<{pair: QAPair}> = (
  {pair: {question, answer}}
) => {
  const [ expanded, setExpanded ] = useState(false);
  const changeFold = () => setExpanded(!expanded);

  return (
    <FaqListItem>
      <FaqRow visible={true} isQ={true}>
        <button onClick={changeFold}>
          <Icon path={expanded ? mdiMinus : mdiPlus} size={1.7}/>
          <p>{question}</p>
        </button>
      </FaqRow>
      <FaqRow visible={expanded} isQ={false}>
        <p>{answer}</p>
      </FaqRow> 
    </FaqListItem>
  );
};

export const Faq: FC<FaqProps> = ({
  content
}) => (
  <FaqContainer>
    <h2>Frequently Asked Questions</h2>
    <FaqList>
      {content.map((pair, i) => (<Item key={i} pair={pair}/>))}
    </FaqList>
  </FaqContainer>
);

export interface FaqGroup {
  name: string,
  subtext: string | undefined,
  content: QAPair[]
}

interface GroupedFaqProps {
  faqGroups: FaqGroup[]
}

export const GroupedFaq: FC<GroupedFaqProps> = ({faqGroups}) => {
  return <FaqContainer>
    <h2>Frequently Asked Questions</h2>

    {faqGroups.map(({name, content}) => 
    <FaqGroupDiv>
      <h3><b>{name}</b></h3>
      <FaqList>
        {content.map((pair, i) => (<Item key={i} pair={pair}/>))}
      </FaqList>
    </FaqGroupDiv>
    )}

    <h3>See our full FAQs <Link to="/faqs">here</Link></h3>

  </FaqContainer>
};

export const GroupedFaqMainPage: FC<GroupedFaqProps> = ({faqGroups}) => {
  return <FaqContainer>
    <h1>Frequently Asked Questions</h1>

    {faqGroups.map(({name, subtext, content}) => 
    <FaqGroupDiv>
      <h3><b>{name}</b></h3>
      {subtext ? <p> {subtext} </p> : <></>}
      <FaqList>
        {content.map((pair, i) => (<Item key={i} pair={pair}/>))}
      </FaqList>
    </FaqGroupDiv>
    )}

  </FaqContainer>
};
