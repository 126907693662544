import { css } from "@emotion/react";
import styled from "styled-components";
import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";

const blankButtonStyle = css`
  border: none;
  padding: none;
  background-color: unset;
  cursor: pointer;
`;

const buttonUnstyled = css`
  background: none;
  border: none;
  cursor: pointer;
`;

export const LargeTextCss = css`font-size: ${fontSizes.large};`
export const MediumTextCss = css`font-size: ${fontSizes.medium};`
export const LargeCapitalizedTextcss = css`
  font-size: ${fontSizes.large};
  text-transform: capitalize;
`;

export const NavBarCss = css`
  margin-top: var(--navbar-height);
  @media (max-width: ${breakpoints.mobile}) {
    margin-top: var(--navbar-height-mobile);
  }
`;

export const PageContainerCss = css`
  position: relative;
`;

export const PageContentSectionCss = css`
  padding: 20px;

  @media (max-width: ${breakpoints.bigDesktop}) {
    padding-top: 10px;
  }
`;

export const HeaderCss = css`
  font-family: "Avenir";
  font-size: ${fontSizes.veryLarge};
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  /* text-transform: capitalize; */

  @media (max-width: ${breakpoints.bigDesktop}) {
    margin: 10px 0;
    font-size: ${fontSizes.mediumLarger};
  }
`;

export const StrokeCSS = css`
  border-top: 1px ${colors.greyDark} solid;

  @media (min-width: ${breakpoints.bigDesktop}) {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
`;

export const FilterPanel = styled.div`
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  padding: 25px 0;
  top: var(--navbar-height);
  z-index: 2;

  @media (min-width: ${breakpoints.bigDesktop}) {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: grid;
    width: ${window.screen.width};
    grid-template-columns: 28% 40% 28%;
    grid-template-rows: 30px;
    margin: 0 -5%;
  }
`;

export const CounterTextCss = css`
  font-size: ${fontSizes.medium};
  text-align: center;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.smaller};
  }
`;

export const FilterButtonCss = css`
  text-transform: uppercase;
  font-size: ${fontSizes.medium};
  background-color: ${colors.greyishGreen};
  color: white;
  line-height: 1;
  border-radius: 5px;
  padding: 0 15px;
  border: none;
  cursor: pointer;

  @media (max-width: ${breakpoints.mobile}) {
    margin-left: 11%;
    font-size: ${fontSizes.small};
    padding: 0 8px;
  }
`;

export const SortButtonCss = css`
  text-transform: uppercase;
  font-size: ${fontSizes.medium};
  background-color: ${colors.greyPale};
  line-height: 1;
  border-radius: 5px;
  padding: 0 15px;
  height: 30px;
  border: none;
  cursor: pointer;
  position: relative;

  :hover {
    background-color: ${colors.greyMedium};
  }

  transition: background-color .35s;

  -webkit-tap-highlight-color: transparent;

  @media (max-width: ${breakpoints.mobile}) {
    margin-right: 11%;
    font-size: ${fontSizes.small};
    padding: 0 8px;
  }
`;

export const SortSpanCss = css`
  margin-right: 5px; 
  color:${colors.greyDarker};
`;

export const SortUl = styled.ul<{isOpen: boolean}>`
  position: absolute;
  top: 30px;
  right: 0;
  width: 260px;
  font-size: ${fontSizes.small};
  background-color: ${colors.greyPale};
  color:${colors.greyDarker};
  border-radius: 5px;

  //display: initial;
  opacity: ${p => p.isOpen ? 1 : 0};
  z-index: ${p => p.isOpen ? 5 : -1};

  transition: opacity .35s, z-index .65s;

  // button:focus &,
  // button:focus-within & {
  //   display: initial;
  // }
`;

export const ItemGridCss = css`
  --gap: 20px;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--gap);
  //margin-top: 10px;//var(--gap);

  @media (min-width: ${breakpoints.mobile}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
`

const getColNumber = (tagsNumber: number, maxCols = 2) => 
    tagsNumber > maxCols ? maxCols : tagsNumber;

export const DropDownItemCss = css`
  ${blankButtonStyle}
  text-transform: capitalize;
  text-align: left;
  font-size: ${fontSizes.small};
  color: ${colors.greyDark};
  padding: 10px;
  flex-basis: 50%;
  transition: color .65s;

  :hover {
    color: ${colors.greyDarker};
  }

  @media (min-width: ${breakpoints.mobile}) {
    flex-basis: 25%;
    text-align: center;
  }
`;

export const getDropDownMenuCss = (itemCount: number, visible: boolean) => css`
  //display : ${visible ? css`grid` : css`none`};
  display: grid;
  opacity: ${visible ? 1 : 0};
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  background-color: ${colors.greyLighter};
  padding: 20px;
  justify-content: left;
  align-items: left;

  grid-template-columns: repeat(${getColNumber(itemCount)}, 150px);
  flex-wrap: wrap;
  z-index: ${visible ? 10 : -5};
  box-shadow: 0 7px 5px 1px rgba(127,127,127,.40);

  transition: opacity .35s, z-index .35s;

  @media (min-width: ${breakpoints.mobile}) { 
    grid-template-columns: repeat(${getColNumber(itemCount, 4)}, 180px);
    justify-content: center;
    align-items: center;
  }
`;

export const MenuCintainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.greyLighter};
  position: relative;

  @media (max-width: ${breakpoints.mobile}) { //mobile
    overflow-x: auto;
    white-space: nowrap;

    align-items: left;
    justify-content: left;
  }
`;

export const TagGroupContainerCss = css`
  display: flex;

  @media (min-width: ${breakpoints.mobile}) {
    --dist: 13px;

    padding-left: var(--dist);
    padding-right: var(--dist);
  }
`;

const getTagColor = (anyItemHovered: boolean, thisItemHovered: boolean, highlighted?: boolean) => {
  if (!anyItemHovered) return highlighted ? colors.red : colors.greyMediumDarker;
  if (thisItemHovered) return highlighted ? colors.red : colors.greyDarker;
  return colors.greyPale;
}

export const getTagButtonCss = (anyItemHovered: boolean, thisItemHovered: boolean, highlighted?: boolean) => {
  const weight = highlighted ? "bold" : "normal";

  return css`
    ${blankButtonStyle};
    font-size: ${fontSizes.small};
    font-weight: ${weight};
    color: ${getTagColor(anyItemHovered, thisItemHovered, highlighted)};
    text-transform: capitalize;
    transition: color .65s;
  `};

export const FilterSectionCss = css`
  padding: 10px;
  border-bottom: 1px solid ${colors.greyLight};
`;

export const FilterOpenedCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const FilterLabelCss = css`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const RangeDivCss = css`
  display: flex;
  justify-content: space-between;
  font-size: ${fontSizes.small};
`;

export const RangeCss = css`
  --size: 26px;
  --colour: ${colors.orange};

  .rc-slider {
    height: var(--size);
    width: unset;
    margin: 0 calc(var(--size) / 2);
  }

  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-step {
    top: 50%;
    transform: translateY(-50%);
  }

  .rc-slider-track {
    background-color: var(--colour);
  }

  .rc-slider-handle {
    height: var(--size);
    width: var(--size);
    background-color: var(--colour);
    border: none;
  }
`;

export const DimensionLabelCss = css`
  margin-top: 10px;
  font-size: ${fontSizes.small};
  text-align: center;
`;

export const SortListItemCss = css`
  padding: 7px 15px;
  text-align: left;
  cursor: pointer;

  :hover {
    background-color: ${colors.greyMedium};
  }

  transition: background-color .35s;
`;

export const SpinnerDiv = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: center;
`;

export const FilterLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${fontSizes.smaller};
  color: ${colors.greyDark};

  @media (min-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.medium};
  }
`;