import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { fontSizes } from "../../../../styles/globalStyled";
import { breakpoints } from "../../../../styles/responsive";

export const ProfileIconWrapper = styled.div`
  z-index: 20;
  display: grid;
  /*justify-self: end;*/
  width: var(--nav-button-side);
  min-width: var(--nav-button-side);
  height: var(--nav-button-side);
  /*--background-color: #e4e4e4;*/
  border-radius: 50%;
  justify-self: flex-end;
  &:focus {
    outline: none;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: var(--nav-button-side-mobile);
    height: var(--nav-button-side-mobile);
    min-width: var(--nav-button-side-mobile);
    justify-content: center;
  }
`;

export const PopupWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15rem;
  padding: 2rem;
  align-items: flex-end;
`;
export const PopupItem = styled(Link)<{ active?: boolean }>`
  cursor: pointer;
  font-size: ${fontSizes.medium};
  text-align: right;
  width: 100%;
  border-radius: 0.5rem;
  &:hover {
    transform: scale(1.1);
    background: linear-gradient(to right, #ffffff, #fafafa);
  }
  ${({ active }) =>
    active &&
    css`
      font-weight: bolder;
    `}
`;

export const ProfileImage = styled.div<{ signedIn?: boolean }>`
  cursor: pointer;
`;
