import { FC, useEffect, useState } from "react";
import { FilterPanelBackdrop } from "../../pages/RecommendationsPage/FilterPanel";
import { BuyNowLinkStyled, WideLine } from "../../pages/RecommendationsPage/RecommendationsPageStyled";
import { CartEntry, useShopContext } from "../../store/shopContext";
import { distinct, normalizePriceLabel } from "../../utils/helpers";
import { ShopCartItem } from "./ShopCartItem";
import Icon from '@mdi/react'
import { mdiClose, mdiLoading, mdiAlertCircleOutline } from "@mdi/js";
import {
  CheckoutButtonContainer,
  CartContainer,
  CartHeader,
  CartItemList,
  CartTotalContainer,
  CloseCartButton,
  DiscountApplyButton,
  DiscountContainer,
  DisountInput,
  TotalLineContainer,
  TotalLineLeft,
  TotalLineRight,
  CheckoutButtonLabel,
  DiscountWarningIcon,
  DiscountInputContainer,
  CartItemQntButton} from "./ShopCartStyles";
import { getCategorySizes, generateCheckoutPage } from "../../services/api.service";
import { Reason, useUserContext } from "../../store/userContext";
import { InformationStep } from "./InformationStep/InformationStep";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

interface TotalLineProps {
  label: string;
  description?: string | EmotionJSX.Element
  value: number;
  isBold: boolean;
};

const TotalLine: FC<TotalLineProps> = ({label, description, value, isBold}) => 
  <TotalLineContainer>
    <TotalLineLeft isBold={isBold}>
      {label}
      {/* {description && <span className="description">{` (${description})`}</span>} */}
      {description && (
        <span className="description">
          {typeof description === "string"
            ? ` (${description})`
            : description}
        </span>)}
    </TotalLineLeft>
    <TotalLineRight isBold={isBold}>{value ? normalizePriceLabel(value, false) : "£0.00"}</TotalLineRight>
  </TotalLineContainer>

interface DiscountDescriptionProps {
  hasError: boolean;
  name: string;
  remove: () => void;
}

const DiscountDescription: FC<DiscountDescriptionProps> = ({
  hasError, name, remove
}) => {
  const showRemoveButton = !hasError && !!name;

  return (
    <span>
      {` (${name})`}
      {" "}
      {showRemoveButton && (
        <CartItemQntButton onClick={remove}>
          <Icon path={mdiClose} size={1}/>
        </CartItemQntButton>
      )}
    </span>
  );
};

interface HeaderProps {
  title?: string;
  closeAction: () => void;
}

export const SidePanelHeader: FC<HeaderProps> = ({title, closeAction}) => 
  <CartHeader>
    <b>{title?.toUpperCase()}</b>
    <CloseCartButton onClick={closeAction}>
      <Icon path={mdiClose} size={1.5} />
    </CloseCartButton>
  </CartHeader>

export const ShopCart: FC = () => {
  const {
    cart,
    removeFromCart,
    increaseInCart,
    decreaseInCart,
    isCartOpen,
    toggleCartOpen,
    appliedPromoCode,
    applyPromoCode } = useShopContext();

  const { user, openAuthModal } = useUserContext();

  const [enteredPromo, setEnteredPromo] = useState("");
  const [redirecting, setRedirecting] = useState(false);
  const [fetchingCoupons, setFetchingCoupons] = useState(false);
  const [shipping, setShipping] = useState<number[]>([]);

  const discountInput = document.getElementById("discountInput") as HTMLInputElement;

  const cartPriceSum =
    cart.reduce((sum, entry) => sum + (entry.item.stockData?.price ?? 0) * entry.quantity, 0);

  const cartShippingSum =
    shipping.reduce((sum, x) => sum + x, 0)
    //cart.reduce((sum, entry) => sum + (entry.item.stockData?.shippingCost ?? 0), 0);

  const checkout = () => {
    if (!user) {
      //toggleCartOpen(false);
      openAuthModal(Reason.RegisterBeforeCheckout);
      return;
    }

    setRedirecting(true);
    generateCheckoutPage(cart ?? [], cartShippingSum, appliedPromoCode?.id ?? "").then(
      url => {
        window.location.href = url;
        setRedirecting(false);
      },
      err => console.error(err)
    );
  };
  
    const [error, setError] = useState("");
    const hasError = !!error;

  const handleDiscountEnter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredPromo(event.target.value?.toUpperCase());
    setError("");
  };

  useEffect(() => {
    if (!discountInput?.value && hasError) applyPromoCode("");
  });

  const updateDiscountData = () => {
    if (!discountInput?.value) return;

    setFetchingCoupons(true);
    applyPromoCode(enteredPromo ?? "")
      .catch(console?.error)
      .finally(() => setFetchingCoupons(false))
  };

  useEffect(() => {
    if (fetchingCoupons) return;
    if (!appliedPromoCode && !enteredPromo) return;

    const minAmount = (appliedPromoCode?.restrictions.minimumAmount ?? 0) / 100;
    const codeIsInvalid = !appliedPromoCode;
    const toLowAmount = minAmount > cartPriceSum;

    if (codeIsInvalid) {
      setError("Invalid promo code.");
      return;
    }

    if (toLowAmount) {
      setError(`Mimimum basket amount for this voucher: £${minAmount}.`);
      return;
    }

    setError("");
  }, [appliedPromoCode, cart, fetchingCoupons]);

  const calculateDiscount = (coupon?: StripeCoupon) => {
    if (!coupon) return 0;

    const { amountOff, percentOff } = coupon;
    if (amountOff) return amountOff / 100;
    if (percentOff) return (cartPriceSum / 100) * percentOff;

    return 0;
  }

  const discount = hasError ? 0 : calculateDiscount(appliedPromoCode?.coupon);

  const findLargest = (sizes: CategorySizeUnion[]) => {
    //const sizes = Object.entries(source);

    if (sizes.includes("Large")) return "Large";
    if (sizes.includes("Medium")) return "Medium";
    return "Small";
  }

  type ShippingCostUnion = 0.0 | 5.95 | 24.95 | 49.95 | 120.0;

  const calculateShipping = async (
    retailer: RetailerUnion, entries: CartEntry[]
  ): Promise<ShippingCostUnion> => {
    const sizes = await getCategorySizes(entries.map(x => x.item));
    const largest = findLargest(sizes);
    
    if (largest === "Large" && retailer === "Made in Design")
      return 120;

    const sum = entries.reduce((sum, current) => sum + (current.item.stockData?.price ?? 0) * current.quantity, 0);

    if (largest === "Small" && sum >= 100) return 0;

    //return hasOversized ? 49.95 : 5.95;
    switch (largest) {
      case "Large": return 49.95;
      case "Medium": return 24.95;
      case "Small": return 5.95;
    }
  };

  const calculateEveryShipping = async () => {
    const retailers = (cart ?? []).map(x => x.item.retailer).filter(distinct).map(x => x as RetailerUnion);
    return await Promise.all(retailers.map(r => calculateShipping(r, cart.filter(x => x.item.retailer === r))));
  };

  const [isInfoStep, setIsInfoStep] = useState(false);
  const toggleInfoStep = (opened: boolean) => setIsInfoStep(opened);

  useEffect(() => { 
    calculateEveryShipping().then(
      res => setShipping(res),
      err => console.error(err));
  } , [cart]);

  const removePromocode = () => {
    setError("");
    applyPromoCode("");
    setEnteredPromo("");
    discountInput.value = "";
  };

  const discountDescription = hasError || !appliedPromoCode?.coupon.name 
    ? undefined 
    : <DiscountDescription 
        hasError={hasError} 
        name={appliedPromoCode?.coupon.name ?? ""}
        remove={removePromocode}
      />;

  return (
    <>
      <FilterPanelBackdrop
        isOpen={isCartOpen}
        onClick={() => toggleCartOpen(false)}
      />
      <CartContainer isOpen={isCartOpen}>
        <SidePanelHeader closeAction={() => toggleCartOpen(false)} title={"YOUR SHOPPING BASKET"} />
        <CartItemList>
          {cart.map(entry =>
            <ShopCartItem
              key={entry.item.id}
              entry={entry}
              removeFromCart={removeFromCart}
              increaseInCart={increaseInCart}
              decreaseInCart={decreaseInCart}
              closeCart={() => toggleCartOpen(false)}
        />)}
        </CartItemList>
        <WideLine />
        <DiscountContainer>
          <DiscountInputContainer>
            <DisountInput 
              id="discountInput"
              hasError={hasError}
              onChange={handleDiscountEnter}
              onKeyPress={e => {if (e.key === "Enter") updateDiscountData()}}
              placeholder="Enter voucher code here"
              title={error}
            />
            <DiscountWarningIcon hasError={hasError}>
              <Icon 
                path={mdiAlertCircleOutline} size={1.5}
                title={error}
              />
            </DiscountWarningIcon>
          </DiscountInputContainer>
          <span />
          <DiscountApplyButton onClick={updateDiscountData}>
              {fetchingCoupons ? <Icon path={mdiLoading} spin={1} size={1.5}/> : "Apply"}
          </DiscountApplyButton>
        </DiscountContainer> 
        <WideLine />
        <CartTotalContainer>
          <TotalLine
            label="Subtotal"
            value={cartPriceSum}
            isBold={false}/>
          <TotalLine
            label="Shipping"
            description={shipping.length > 1 ? `from ${shipping.length} suppliers` : undefined}
            value={cartShippingSum}
            isBold={false}/>
          <TotalLine
            label="Discount"
            //description={hasError ? "" : appliedPromoCode?.coupon.name}
            description={discountDescription}
            value={discount}
            isBold={false}/>
          <TotalLine
            label="Total"
            value={cartPriceSum + cartShippingSum - discount}
            isBold={true}/>
        </CartTotalContainer>
        <CheckoutButtonContainer id="last">
          <BuyNowLinkStyled  isActive={true} onClick={() => toggleInfoStep(true)}>
              <CheckoutButtonLabel>
                Checkout
              </CheckoutButtonLabel>
          </BuyNowLinkStyled>
        </CheckoutButtonContainer>
      </CartContainer>
      <InformationStep 
        redirecting={redirecting}
        opened={isInfoStep}
        toggleOpened={toggleInfoStep}
        checkout={checkout} 
        closeCart={() => toggleCartOpen(false)}
      />
    </>
  )
}