import { Dispatch, FC, SetStateAction, useState } from "react";
import { mdiMenu, mdiClose } from "@mdi/js";
import { 
  MobileHamburgerWrapper, 
  PopupClose, 
  PopupCloseWrapper, 
  PopupMenuContainer, 
  PopupMenuHeader, 
  PopupTab,
  TabContent
} from "./MobileHamburgerStyles";
import { NavButton } from "../RightSide/NavButton";
import Icon from "@mdi/react";
import { DefaultTab } from "./TabPages/DefaultTab";
import { BrowseTab } from "./TabPages/BrowseTab";
import { SqTab } from "./TabPages/SqTab";
import { AboutTab } from "./TabPages/AboutTab";

const tabs = ["style quiz", "browse", "about"] as const;
export type TabUnion = (typeof tabs)[number];

const getMode = (tab?: TabUnion): UIMode => {
  switch (tab) {
    case "style quiz": return "recs";
    case "browse": return "shop";
    default: return "none";
  }
};

export interface MenuProps {
  closeMenu: () => void;
  isOpen: boolean;
}

export const MobileHamburger: FC = () => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ selectedTab, setSelectedTab ] = useState<TabUnion | undefined>(undefined);
  
  const activeMode = getMode(selectedTab);

  const Close: FC<{onClick: () => void}> = ({onClick}) => (
    <PopupCloseWrapper>
      <PopupClose onClick={onClick} mode={activeMode}>
        <Icon path={mdiClose}/>
      </PopupClose>
    </PopupCloseWrapper>
  );

  const Tab: FC<{layout: TabUnion}> = ({layout}) => 
    <PopupTab 
      onClick={() => setSelectedTab(layout)}
      selected={selectedTab === layout}
      mode={activeMode}
    >
      {layout}
    </PopupTab>;

  const close = () => {
    setIsOpen(false);
    setSelectedTab(undefined);
  };
  
  return (
    <MobileHamburgerWrapper>
      <NavButton 
        onClick={() => setIsOpen(true)} 
        iconPath={mdiMenu} 
      />
      <PopupMenuContainer isOpen={isOpen}>
        <PopupMenuHeader mode={activeMode}>
          {tabs.map(x => (<Tab key={x} layout={x}/>))}
          <Close onClick={() => setIsOpen(false)}/>
        </PopupMenuHeader>
        {!selectedTab && <DefaultTab select={setSelectedTab}/>}
        {selectedTab === "browse" && <BrowseTab closeMenu={close} isOpen={isOpen && selectedTab === "browse"}/>}
        {selectedTab === "style quiz" && <SqTab closeMenu={close} isOpen={isOpen && selectedTab === "style quiz"}/>}
        {selectedTab === "about" && <AboutTab closeMenu={close} />}
      </PopupMenuContainer>
    </MobileHamburgerWrapper>
  );
};