import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { triggerBuyNowEvent, triggerDiscardEvent } from "../dataLayer";
import { useCategory } from "../hooks/useCategory";
import { getFilterPayload } from "../pages/FilterPage/utils/getFilterPayloads";
import { sendFullAnalytics } from "../pages/RecommendationsPage/recsUtils";
import {
  concatArray,
  getChair,
  getId,
  hashJourney,
  notEqualTo,
  splitSq1And2Selecteds,
} from "../utils/helpers";
import { useFilterCtx } from "./filterContext";
import { useSavedsContext } from "./savedsContext";
import { useSqFlowContext } from "./SqFlowProvider";
import { useUserContext } from "./userContext";

interface AnalyticsState {
  addAnalyticsSavedClick: (id: ItemId) => void;
  removeAnalyticsSavedClick: (id: ItemId) => void;
  addAnalyticsDiscardedClick: (id: ItemId) => void;
  removeAnalyticsDiscardedClick: (id: ItemId) => void;
  addAnalyticsBuyNowClick: (
    id: ItemId,
    category: CategoryUnion,
    score?: number
  ) => void;
  addDiscardAnalytics: (id: ItemId, cat: CategoryUnion) => void;
}

const AnalyticsContext = createContext<AnalyticsState>({} as AnalyticsState);

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const AnalyticsProvider: FC<{children?: React.ReactNode}> = ({ children }) => {
  const category = useCategory() ?? "shop";

  const {
    page1And2State,
    sq1Items,
    sq2Items,
    page3State,
    recsState,
    removeRec,
  } = useSqFlowContext();
  const { addDiscardId, deleteSavedId } = useSavedsContext();

  const { requestStartTime, requestEndTime } = recsState ?? {requestStartTime: +new Date() as DateNum, requestEndTime: +new Date() as DateNum};
  const filterCtx = useFilterCtx();

  const filterPayload = getFilterPayload(
    filterCtx.filterOptions[category],
    "recs",
    category === "shop"
  );
  const { customerId, user } = useUserContext();

  const [journeySavedRecs, savedRecsSet] = useState<ItemId[]>([]);
  const [journeyBuyNowClicks, buyNowClicksSet] = useState<BuyNowClick[]>([]);
  const [journeyDiscards, journeyDiscardsSet] = useState<ItemId[]>([]);

  const allDisplayeds = [
    ...(page1And2State?.items ?? []),
    ...(page3State?.items ?? []),
  ].map(getId);

  const allSelecteds = [
    ...page1And2State?.selectedItems,
    ...page3State?.selectedItems,
  ];

  const selectedsAndDisplayeds: SelectedsAndDisplayeds = {
    displayedIds: allDisplayeds,
    selectedIds: allSelecteds,
  };

  const reccedIds = (recsState?.items ?? []).map(getChair).map(getId);

  const requestSeconds = (requestEndTime - requestStartTime) / 1000;
  const journeyHash = hashJourney(
    category,
    selectedsAndDisplayeds,
    reccedIds,
    filterPayload,
    requestSeconds,
    customerId
  );

  /**
   * Clear the state for new journeys
   */
  useEffect(() => {
    savedRecsSet([]);
    buyNowClicksSet([]);
    journeyDiscardsSet([]);
  }, [journeyHash]);

  // useEffect(() => {
  //   const recommendedIds = recsState?.items?.map(getChair).map(getId) ?? [];
  //   const requestSeconds = (requestEndTime - requestStartTime) / 1000;

  //   const [sq1SelectedItems, sq2SelectedItems] = splitSq1And2Selecteds(
  //     page1And2State?.selectedItems ?? [],
  //     sq1Items,
  //     sq2Items
  //   );

  //   // Don't send before the user has actually gone through the SQ
  //   if (page1And2State?.selectedItems.length > 0 && (recsState?.items ?? null) !== null) {
  //     sendFullAnalytics({
  //       category,
  //       journeyHash,
  //       sq1SelectedIds: sq1SelectedItems.map(getId),
  //       sq2SelectedIds: sq2SelectedItems.map(getId),
  //       sqRefineSelectedIds: page3State.selectedItems,
  //       recommendedIds,
  //       filterPayload,
  //       buyNowClicks: journeyBuyNowClicks.map(getId),
  //       itemDiscards: journeyDiscards,
  //       itemSaves: journeySavedRecs,
  //       customerId,
  //       requestSeconds,
  //     });
  //   }
  // }, [
  //   /**
  //    * We purposely don't include filterOption or journeyHash here because that
  //    * makes this effect trigger and fire analytics before recsState has had a
  //    * chance to reset, meaning it fires during the illegal state after filter
  //    * change but before that change reset recsState.
  //    */
  //   category,
  //   concatArray(page1And2State.items?.map(getId)),
  //   concatArray(page1And2State.selectedItems),

  //   concatArray(page3State.items?.map(getId)),
  //   concatArray(page3State.selectedItems),

  //   concatArray(sq1Items?.map(getId)),
  //   concatArray(sq2Items?.map(getId)),

  //   concatArray(recsState.items?.map(getChair).map(getId)),

  //   concatArray(journeyBuyNowClicks.map(getId)),
  //   concatArray(journeySavedRecs),
  //   concatArray(journeyDiscards),
  //   customerId,
  //   user,
  //   requestStartTime,
  //   requestEndTime,
  // ]);

  const addAnalyticsSavedClick = (id: ItemId) =>
    savedRecsSet((state) => [...state, id]);

  const removeAnalyticsSavedClick = (id: ItemId) =>
    savedRecsSet((state) => state.filter(notEqualTo(id)));

  const addAnalyticsDiscardedClick = (id: ItemId) =>
    journeyDiscardsSet((state) => [...state, id]);

  const removeAnalyticsDiscardedClick = (id: ItemId) =>
    journeyDiscardsSet((state) => state.filter(notEqualTo(id)));

  const addAnalyticsBuyNowClick = (
    id: ItemId,
    category: CategoryUnion,
    score?: number
  ) => {
    buyNowClicksSet((state) => [...state, { id, score, category }]);
    triggerBuyNowEvent();
  };

  const addDiscardAnalytics = (id: ItemId, cat: CategoryUnion) => {
    journeyDiscardsSet((discards) => [...discards, id]);
    savedRecsSet(journeySavedRecs.filter(s => s !== id));
    //console.info(journeySavedRecs);
    removeRec(id);
    addDiscardId(id, cat, false).catch(err => console.error(err));
    deleteSavedId(id, cat, false).catch(err => console.error(err));
    triggerDiscardEvent();
  };

  return (
    <AnalyticsContext.Provider
      value={{
        addAnalyticsSavedClick,
        removeAnalyticsSavedClick,
        addAnalyticsBuyNowClick,
        addDiscardAnalytics,
        addAnalyticsDiscardedClick,
        removeAnalyticsDiscardedClick
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
