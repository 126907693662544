import delay from "delay";
import Icon from '@mdi/react';
import { mdiArrowDown } from "@mdi/js";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Arrow, CarouselWrapper, DescContainer, LeftCarouselItem, MiddleCarouselItem, RightCarouselItem } from "./CarouselStyles";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

interface CarouselProps {
  title: EmotionJSX.Element,
  subHeading: EmotionJSX.Element,
  buttonText: string
}

export const Carousel: FC<CarouselProps> = ({title, subHeading, buttonText}) => {
  const scrollDelay = 3000;

  const animate = async () => {
    const section = document.getElementById("section");
    const getOptions = (x: number) => { return{
      top: 0,
      left: x,
      behavior: 'smooth'
    } as ScrollToOptions};

    const goToFirst = () => section?.scrollTo(getOptions(0));
    const goToSecond = () => section?.scrollTo(getOptions(window.innerWidth));
    const goToThird = () => section?.scrollTo(getOptions(window.innerWidth * 2));

    await delay(scrollDelay);
    goToSecond();
    await delay(scrollDelay);
    goToThird();
    await delay(scrollDelay);
    goToFirst();

    animate();
  };

  const [started, setStarted] = useState(false);
  
  useEffect(() => { 
    if (started) return;

    animate();
    setStarted(true);
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <CarouselWrapper id="section">
        <LeftCarouselItem />
        <MiddleCarouselItem />
        <RightCarouselItem />
      </CarouselWrapper>
      <DescContainer>
        <h2>{title}</h2>
        <p>{subHeading}</p>
        <Link to="/category">
          {buttonText}
        </Link>
        <Arrow>
          <Icon path={mdiArrowDown} size={1.7}/>
        </Arrow>
      </DescContainer>
    </div>
  )
}