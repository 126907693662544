import { FC } from "react";
import { 
  mdiBriefcase, 
  mdiBriefcaseOutline } from "@mdi/js";
import { useShopContext } from "../../../store/shopContext";
import { NavButton } from "./NavButton";

export const ShopCartToggle: FC = () => {
  const { toggleCartOpen, cart } = useShopContext();
  const icon = cart.length > 0 ? mdiBriefcase : mdiBriefcaseOutline;

  return (
    <NavButton 
      onClick={() => toggleCartOpen(true)} 
      iconPath={icon}
      iconSizeMobile={26}
      iconSizeDesktop={23}
    />
);
}