import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import NavBar from "../../shared/NavBar/NavBar";
import {
  FaqsPageContainer,
  FaqsPageWrapper,
  SubOptions,
  TermsOfServiceWrapper,
} from "./TermsWrapper";
import { Footer } from "../../shared/Footer/Footer";
import { BottomFooter } from "../../shared/Footer/BottomFooter";
import { SectionHeader, Space } from "./TermPageComponents";

export const TandSPage = () => {
  return (
    <FaqsPageWrapper>
      <FlitchHelmet title="Terms & Conditions" />
      <NavBar />
      <FaqsPageContainer>
        <TermsOfServiceWrapper>
          <>
            <SectionHeader label="General Terms and Conditions" />
            <p>Last updated: 5th January 2023</p><Space />
            <p><strong>Terms of Service</strong></p><Space />
            <p>Flitch is a home styling platform, which offers users information about different items of furniture and either directs them to other third-party websites where these items can be purchased or allows users to purchase directly from Flitch.</p><Space />
            <p>Flitch is not a furniture retailer but for some items offered on the Flitch website, we act as your agent to purchase items from a third party. Flitch is not responsible for providing, or for setting or controlling the prices applicable to any of the products which you may search for or find on the Flitch platforms.</p><Space />
            <p>Flitch services are provided by Azara Limited, a private limited company incorporated and registered in England &amp; Wales with company number 11059322. Our VAT number is 372329395 and our registered office is at Suite 2 1st Floor Sutherland House, 70-78 West Hendon Broadway, London, England, NW9 7BT.</p><Space />
            <p><strong>1. Scope of Service</strong></p><Space />
            <p>1.1 These terms and conditions govern your access to and use of the Flitch services and platforms and, together with our privacy policy (which contains our cookie policy) constitute a legal agreement between you and Flitch. By using our website, you confirm that you have read, understood and agree to these terms and conditions, as well as the privacy policy, including the use of cookies.</p><Space />
            <p>1.2 These terms and conditions are subject to occasional changes and apply to all our services that are directly or indirectly provided via the internet, on mobile devices, by email or by telephone.</p><Space />
            <p><strong>2. Third Party Services</strong></p><Space />
            <p>2.1 On the Flitch website, you have the ability to compare third party services. Users have the possibility of purchasing furniture and other items through a direct connection to furniture retailer sites (third parties). Orders are made directly on the furniture retailer site and not on Flitch. Flitch is not the retailer or the contractual party for the user, but only an agent providing the technical connection to the furniture retail site of a third party. By making a purchase on the website of the third party, users agree to be bound by the terms and conditions of that retail site. These terms and conditions can be viewed on the website of the third party. Once a purchase is made on the website of a third party, a contract is concluded between the retail site and the user, and therefore no contract is concluded between the user and Flitch. Flitch is no contractual party to the order and all and any claims of the user are to be asserted against the third party and not against Flitch.</p><Space />
            <p>2.2 This agreement is not affected by any other agreements between the retail site and users.</p><Space />
            <p><strong>3. User Obligations</strong></p><Space />
            <p>3.1 You may only use the Flitch services and platforms in compliance with applicable laws and for legitimate purposes. In consideration of you agreeing to abide by these terms and conditions, we grant you a non-transferable, non-exclusive licence to download, access and use the Flitch services and platforms for your own personal, non-commercial purposes and for no other purpose. In particular, we grant such licence to you subject to you agreeing that you shall not:</p><Space />
            <SubOptions>
              <ul>
              <li>A. use the Flitch services and platforms for any purpose that is improper, unlawful, or to post, share or transmit any material that (i) is defamatory, offensive, obscene or otherwise objectionable; (ii) is in breach of confidence or privacy or of any third party&rsquo;s rights including copyright, trade mark or other intellectual property rights; (iii) is posted, shared or transmitted for the purpose of advertising or promoting yourself or any third party; or (iv) is misleading or misrepresentative as to your identity or which in any way suggests that you are sponsored, affiliated or connected with Flitch; or (v) you do not otherwise have any rights or the permissions you need to make available;</li>
              </ul><Space />
              <ul>
              <li>B. use the Flitch services and platforms for any commercial purpose or in any manner which may cause damage to Flitch or bring Flitch into disrepute;</li>
              </ul><Space />
              <ul>
              <li>C. disassemble, reverse engineer or otherwise decompile any software, applications, updates or hardware contained in or available via the Flitch services and platforms, except as permitted by law;</li>
              </ul><Space />
              <ul>
              <li>D. copy, distribute, communicate to the public, sell, rent, lend or otherwise use the Flitch services and platforms, or seek to violate or circumvent any security measures employed to prevent or limit your access to or use of the Flitch services and platforms;</li>
              </ul><Space />
              <ul>
              <li>E. use or interfere with the Flitch services and platforms in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;</li>
              </ul><Space />
              <ul>
              <li>F. introduce onto, or transmit via, the Flitch services and platforms any disruptive computer program code, virus, &lsquo;denial of service&rsquo; or &lsquo;spam&rsquo; attack, worm, Trojan horse, authorisation key, licence control utility or software lock;</li>
              </ul><Space />
              <ul>
              <li>G. remove, alter or replace any notices of authorship, trade marks, business names, logos or other designations of origin on the Flitch services and platforms or pass off or attempt to pass off the Flitch services and platforms as the product of anyone other than Flitch;</li>
              </ul><Space />
              <ul>
              <li>H. use any automated computer program or application to scan, copy, index, sort or otherwise exploit the Flitch services and platforms or any part thereof.</li>
              </ul><Space />
            </SubOptions>
            <Space />
            <p>3.2 Where you sign up to any feature of, or use, the Flitch services and platforms that requires the use of a log-in account or password, you agree that you shall be responsible for keeping those password and log-in details confidential and secure. If you become aware, or suspect for any reason, that the security of your log-in details has been compromised, please let us know as soon as you can.</p><Space />
            <p>3.3 Users have the option of creating a member account with Flitch, which allows them the option to administer and store their searches. A user cannot register multiple times. The user is responsible for the accuracy and completeness of their personal information. In order to create the account users are asked to provide personal information. For further information please check our privacy policy. By deleting the account any and all of the user&lsquo;s data will be permanently removed.</p><Space />
            <p>3.4 The Flitch services and platforms are not intended for children under 16 years of age, and no one under the age of 16 may provide any information to, on or via the Flitch services and platforms. We do not knowingly collect personal information from children under 16, and if we learn that we have collected or received personal information from a child under 16, we will delete that information in accordance with our privacy policy.</p><Space />
            <p><strong>4. Privacy, Email Advertisements</strong></p><Space />
            <p>4.1 The protection of personal data provided by the user is of the highest priority for Flitch. As such, Flitch makes every effort to ensure compliance with data protection. For more information, please read our privacy policy.</p><Space />
            <p>4.2 Flitch collects, processes and uses your personal data (hereafter &ldquo;data&rdquo;) only if we have obtained your consent or a statutory provision allows for the collection, processing or use of your data.</p><Space />
            <p>4.3 Flitch will only raise, process and use data necessary for the provision of the services rendered by Flitch, for the use and operation of the websites / apps and the services offered.</p><Space />
            <p>4.4 If the user has agreed to receive information about Flitch during registration for a user account or at another time using Flitch&rsquo;s services, the user will receive periodic product information. The consent may be revoked at any time by written communication or by email. The consent to receive emails follows after the confirmation email from Flitch is sent. By clicking on the link in the email, you will be registered to receive newsletters.</p><Space />
            <p><strong>5. Termination</strong></p><Space />
            <p>5.1 Flitch reserves the right to terminate a user&rsquo;s access and to delete their registration within a period of one week after receipt of relevant information via email about improper use of the website. The user may also terminate their own access and registration within the same period. The right to immediate termination for good cause remains unaffected.</p><Space />
            <p><strong>6. Property</strong></p><Space />
            <p>6.1 Except as set out elsewhere in these terms and conditions, all intellectual property rights including, but not limited to, copyright (including copyright in computer software), patents, trade marks or business names, design rights, database rights, know-how, trade secrets and rights of confidence in the Flitch services and platforms are owned by or licensed to Flitch. You acknowledge that by using Flitch, you will not acquire any right, title or interest in or to Flitch services and platforms except for the limited licence to use Flitch granted to you pursuant to these terms and conditions. You further acknowledge that you have no right to have access to any aspect of Flitch in source-code form.</p><Space />
            <p>6.2 We respect the intellectual property rights of others. If you have reason to believe that your copyright is being infringed by any content on Flitch, please send a written notification of such infringement to us.</p><Space />
            <p><strong>7. Liability</strong></p><Space />
            <p>7.1 Flitch is not liable for the accuracy, quality, completeness, reliability or credibility of content provided by furniture retailer sites. In particular, there is no advice or information from Flitch regarding the selection of furniture.</p><Space />
            <p>7.2 All agreements that arise through this service are between the Flitch user and the respective external furniture retail site. The terms and conditions of the respective retail site apply exclusively, especially regarding the right to cancel and to withdraw. The contact for the processing of orders and payments, as well as questions about the contract, is the respective contracting retail site. Flitch remains uninvolved in such contractual arrangements, agreements and claims between the Flitch user and the respective external retail site.</p><Space />
            <p>7.3 Flitch does not verify the accuracy of content that comes from retail sites, and Flitch has no influence on this information (in particular images, comments, reviews, etc.).</p><Space />
            <p>7.4 In particular, hyper links, advertising banners, information about items of furniture and the like do not represent recommendations or information from Flitch. For technical reasons, updating of prices and stock levels at retailer sites are not done in real time. Therefore it is possible that the price that appears on the retail site does not correspond to the price specified on the Flitch site. Flitch does not guarantee the accuracy of information, in particular prices and availability.</p><Space />
            <p>7.5 Flitch is not liable for technical malfunctions for which the cause is not within Flitch&rsquo;s sphere of responsibility or for damages caused by force majeure. Flitch does not guarantee uninterrupted availability of data and may perform technical maintenance during a freely chosen time period.</p><Space />
            <p>7.6 To the maximum extent permitted by law, we (together with our officers, directors, employees, representatives, affiliates, providers and third parties) do not accept any liability for (a) any inaccuracies or omissions in the content displayed on or via Flitch; or (b) any act of God, accident, delay or any special, exemplary, punitive, indirect, incidental or consequential loss or damage of any kind (including, without limitation, lost profits or lost savings), whether based in contract, tort (including negligence), strict liability or otherwise, incurred by you arising out of or in connection with your access to, use of, or inability to access or use, Flitch or any content contained provided therein.</p><Space />
            <p>7.7 Subject to these terms and conditions, and to the extent permitted by law, our maximum aggregate liability to you arising out of or in connection with these terms and conditions or your access to, use of, or inability to access or use, Flitch shall be limited to the sum of &pound;100 (one hundred pounds Sterling). Nothing in these terms and conditions shall limit or exclude (a) our liability in respect of death or personal injury caused by our negligence or for our fraud or fraudulent misrepresentation; (b) any other liability that cannot be excluded by law; or (c) your statutory rights.</p><Space />
            <p>7.8 You may see advertising material submitted by third parties on Flitch. Each individual advertiser is solely responsible for the content of its advertising material and Flitch accepts no responsibility for the content of advertising material, including, without limitation, any error, omission or inaccuracy therein.</p><Space />
            <p>7.9 Subject to these terms and conditions, you will be responsible and liable to us for all actions, claims, proceedings, costs, damages, losses and expenses (including, without limitation, legal fees) incurred by Flitch, and each of our officers, directors, employees and agents, arising out of, or in any way connected with, your use of Flitch or breach of these terms and conditions.</p><Space />
            <p><strong>8. General Provisions</strong></p><Space />
            <p>8.1 You acknowledge that your unauthorised use of Flitch may result in irreparable damage and injury to Flitch and/or its affiliates or licensors for which money damages would be inadequate. Consequently, in the event of such unauthorised use, we and our affiliates and/or licensors (as applicable) shall have the right, in addition to any other legal remedies available, to seek an immediate injunction against you prohibiting any further use of Flitch.</p><Space />
            <p>8.2 The invalidity or unenforceability of any provision (in whole or part) of these terms and conditions shall not affect the validity or enforceability of the remaining provisions (in whole or part). The whole or part of any provision which is held by a court of competent jurisdiction to be invalid or unenforceable shall be deemed deleted from these terms and conditions.</p><Space />
            <p>8.3 These terms and conditions are personal to you. You shall not be entitled to assign these terms and conditions in whole or in part to any third party without our prior written consent.</p><Space />
            <p>8.4 These terms and conditions represent the entire agreement between us and you and supersede and replace all and any previous terms, conditions, agreements and arrangements in respect of your use of Flitch.</p><Space />
            <p>8.5 Any failure by us to enforce any of these terms and conditions shall not be considered to be a waiver of them or the right to subsequently enforce any of these terms and conditions.</p><Space />
            <p>8.6 A person who is not a party to these terms and conditions shall have no right to enforce any provision of these terms and conditions.</p><Space />
            <p>8.7 Irrespective of the country from which you access or use Flitch, to the extent permitted by law these terms and conditions and your use of Flitch shall be governed in accordance with the laws of England and Wales and you are deemed to have submitted to the non-exclusive jurisdiction of the courts of England and Wales to resolve any disputes which may arise hereunder.</p><Space />
            <p>8.8 The current terms and conditions when using Flitch apply. Registered users receive notifications about changes by email. Users may download and print the current terms and conditions on their own computer system.</p><Space />
            <p><strong>Terms of Sale - Goods</strong></p><Space />
            <p>These terms of sale (&ldquo;Terms of Sale&rdquo;) set out the terms and conditions on which we supply any of the products (&ldquo;Product&rdquo; or &ldquo;Products&rdquo;) listed on our website www.flitch.com (&ldquo;our site&rdquo;) to you.</p><Space />
            <p>Please read these Terms of Sale carefully before ordering any Products from our site. These Terms of Sale tell you who we are, how we will provide Products to you, how you and we may end the contract, what to do if there is a problem and other important information. If you do not agree to these Terms of Sale you must not order any Product through our site.</p><Space />
            <p><strong>1. Ordering Products</strong></p><Space />
            <p>1.1 You can place orders for Products with us by following the process outlined on our site. You acknowledge that by placing an order with us you will be under an obligation to pay for the Products in that order if we accept your order.</p><Space />
            <p>1.2 Our order process allows you to check and amend any errors before submitting your order to us. Please take the time to read and check your order at each stage of the order process.</p><Space />
            <p>1.3 All orders are subject to acceptance by us. Upon receiving an order, we will send you an email acknowledging your order and this will not constitute acceptance by us. Once the necessary checks with our suppliers have been completed, we will send you a confirmation e-mail and this will constitute acceptance by us. The contract between you and us will only be made when we send you this email. We will charge your chosen payment method after we accept your order. We will send a further email when your order has been dispatched.</p><Space />
            <p>1.4 Unfortunately we can&rsquo;t stop an order once it has been dispatched. If you change your mind about your order after this point you can reject delivery or return the Products to us in accordance with the returns policy below at clause 8.</p><Space />
            <p>1.5 Before ordering from us, it is your responsibility to check and determine your full ability to receive the Products ordered. This includes ensuring that the Products ordered will pass freely into your room of choice, they fit in that room, can be transported through the door of your flat or room, stairs and doorways, and there are no other issues that could make delivery more complicated or impossible.</p><Space />
            <p>1.6 You must keep the contact details we hold for you up-to-date so that we can contact you, if necessary, about your order or the delivery of the Products.</p><Space />
            <p><strong>2. Availability</strong></p><Space />
            <p>2.1 All orders for Products are subject to the availability of those Products and the materials for making the Products. We will inform you as soon as possible after receiving your order if, for any reason, the Products you have ordered are not available or are subject to any delay.</p><Space />
            <p>2.2 If we are unable to supply you with a Product, for example, because the supplier is out of stock or because of an error in the price (see clauses 5.5 and 5.6 below) we will inform you of this by email and we will not process your order. If you have already paid for the Product we will refund you the full amount as soon as possible.</p><Space />
            <p><strong>3. Images and sizing of products</strong></p><Space />
            <p>3.1 The images of the Products on our site are for illustrative purposes only. Although we have made every effort to display the colours accurately, we cannot guarantee that your computer will display the colours accurately or reflect the colour of the Products. The Products that are delivered to you may vary slightly from those images.</p><Space />
            <p>3.2 Although we have made every effort to be as accurate as possible, the measurements indicated including weights, dimensions and capacities shown on our site are approximate only.</p><Space />
            <p><strong>4. Prices of products</strong></p><Space />
            <p>4.1 The price of any Product will be as quoted on our site, except in cases of obvious error.</p><Space />
            <p>4.2 The price of any Product may change from time to time, but changes will not affect any order we have accepted.</p><Space />
            <p>4.3 All prices on the Site are shown in GBP.</p><Space />
            <p>4.4 The price of a Product includes VAT (where it applies) but excludes any delivery costs, which where applicable will be added to the price of the Products and set out as part of the total amount due during the order process.</p><Space />
            <p>4.5 Due to the large number of Products on our site, it is always possible that some of the Products listed on our site may be incorrectly priced, despite our reasonable efforts. We will normally verify prices as part of our order-handling procedure so that, where a Product&rsquo;s correct price is less than our stated price, we will charge the lower amount when dispatching the Product to you. If the Product&rsquo;s correct price is higher than the price stated on our site, we will normally, at our discretion, either contact you for instructions before dispatching the Product, or reject your order and notify you of the rejection.</p><Space />
            <p>4.6 We are under no obligation to provide the Product to you at the incorrect (lower) price, even after we have sent you an order acceptance, if the pricing error is obvious and unmistakable and could have reasonably been recognised by you as a mis-pricing.</p><Space />
            <p><strong>5. How to pay</strong></p><Space />
            <p>5.1 We accept payment with the payment methods listed on our site. You must pay for the Products and any applicable delivery charges in advance of delivery of the Products.</p><Space />
            <p>5.2 By submitting an order to us through our site, you are confirming that the payment details provided on your order are valid and correct.</p><Space />
            <p>5.3 We are not responsible for any processing or administrative charges that may be levied by your bank or card issuer as a result of payments made to us. It is your responsibility to contact your bank or card issuer to check any conditions of payment.</p><Space />
            <p>5.4 In cooperation with Klarna Bank AB (publ), Sveav&auml;gen 46, 111 34 Stockholm, Sweden, we offer you the Pay Later payment option. Payment is to be made to Klarna. Only available to UK users. You can find further information and Klarna&rsquo;s user terms here, and you can find general information on Klarna here. Your personal data is handled in accordance with applicable data protection laws and in accordance with the information in Klarna&rsquo;s privacy statement.</p><Space />
            <p><strong>6. Delivery</strong></p><Space />
            <p>6.1 Our suppliers offer a delivery service in accordance with the timescales shown against each Product on our site. Please note that delivery timescales are estimates.</p><Space />
            <p>6.2 Please note that our suppliers only deliver to addresses in the United Kingdom, excluding Northern Ireland.</p><Space />
            <p>6.3 Your estimated dispatch date will be as set out in a dispatch confirmation sent by email. This dispatch date indicates when we expect our suppliers to send the Products to you. Although our suppliers will make every reasonable effort to ensure your Products are dispatched and delivered within the estimated timescales, unfortunately we cannot guarantee that they will not be affected by unforeseen issues affecting the suppliers of the Product or their delivery partners. If they are unable to meet the estimated dispatch or delivery date, we will contact you with a revised estimated date.</p><Space />
            <p>6.4 Your delivery will be completed when our suppliers deliver the Products to the address you gave us. If no one is available at your address to take delivery, they will leave you a note with information about how to rearrange delivery. If you have not accepted delivery after three attempts, we may treat the contract as having been cancelled by you in accordance with the terms of clause 9 below.</p><Space />
            <p><strong>7. Risk and ownership</strong></p><Space />
            <p>7.1 Ownership of the Products will pass to you on delivery, provided that we have received full payment of all sums due in respect of the Products, including delivery charges.</p><Space />
            <p>7.2 The Products will be your responsibility from the time that you (or a third party other than the carrier and indicated by you) take physical possession of the Products.</p><Space />
            <p><strong>8. Cancellation, returns and refund policy</strong></p><Space />
            <p>8.1 We hope you will be pleased with everything you have bought from us but if you are unhappy with your Products, you can return them to us either in accordance with the terms set out below or as otherwise in accordance with your legal rights.</p><Space />
            <p>8.2 You have a legal right to change your mind and cancel the contract between you and us within 14 days (unless stated otherwise) of delivery of your Products without giving a reason.</p><Space />
            <p>8.3 To return a Product it must be in a saleable condition and in its original undamaged packaging, make sure it is packed up securely with the returns form and we will refund you for the product on receipt.</p><Space />
            <p>8.4 The cancellation period will expire 14 days (unless stated otherwise) from the day on which you acquire, or a third party other than the carrier and indicated by you acquires, physical possession of the Products. Where you order Products in one order from multiple suppliers, the cancellation period will, for each individual Product, expire 14 days (unless stated otherwise) from the day on which you acquire, or a third party other than the carrier and indicated by you acquires, physical possession of that individual Product.</p><Space />
            <p>8.5 To exercise the right to cancel, you must inform us of your decision to cancel your contract with us by making a clear statement (e.g. a letter sent by post or email). The easiest way to do this is to contact our Customer Services team, their contact details can be found in clause 22</p><Space />
            <p>8.6 To meet the cancellation deadline, it is sufficient for you to send your communication concerning the exercise of your right to cancel before the cancellation period has expired</p><Space />
            <p>8.7 If you cancel your contract with us, we will reimburse you all payments received from you, which may not include the cost of delivery.</p><Space />
            <p>8.8 We will make the reimbursement without undue delay, and not later than:</p><Space />
            <p>(a) 30 days after the day our suppliers receive back from you any Products supplied, or</p><Space />
            <p>(b) if there were no goods supplied, 30 days after the day on which we are informed about your decision to cancel this contract.</p><Space />
            <p>8.9 We will make the reimbursement using the same means of payment as you used for the initial transaction unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of the reimbursement.</p><Space />
            <p>8.10 We will withhold reimbursement until our suppliers have received the Products back.</p><Space />
            <p>8.11 We may make a deduction from the reimbursement for loss in value of any Products supplied, if the loss is the result of unnecessary handling by you. You are only liable for any diminished value of the Products resulting from the unfair and/or unreasonable wear and tear of the Product other than what is necessary to establish the nature, characteristics and functioning of the Products. We may make a deduction from any reimbursement (you are entitled to from us) for such loss in value of any Products we supply.</p><Space />
            <p>8.12 As a consumer, you will always have legal rights in relation to Products that are faulty or not as described. These legal rights are not affected by the returns policy in this clause 8 or any of the other general Terms of Sale set out.</p><Space />
            <p>8.13 If you change your mind and exercise your right to cancel under clause 8.2 above after delivery, then the Products must be returned to our suppliers. In this case, this may be done by emailing us at support@withflitch.com to receive further instructions. You will incur the delivery costs to return the Products, unless otherwise stated.</p><Space />
            <p>8.14 If you exercise your right to return the Products under clause 8.1, because the Products are faulty, were not delivered as described or you otherwise have a legal right to return the Products as a result of something we have done wrong after dispatch of the Products, the Products must be returned to our suppliers. In this case, this may be done by emailing us at support@withflitch.com to receive further instructions. However, in this case, the return or collection will bear no cost to you.</p><Space />
            <p>8.15 Please contact Customer Services using the contact information below at clause 25 to arrange a collection of the Products.</p><Space />
            <p>8.16 You must take care when opening packaging that the Products were delivered in and carefully re-pack the Products in the original (or similar) packaging prior to returning the Products to our suppliers.</p><Space />
            <p><strong>9. Sales promotions</strong></p><Space />
            <p>9.1 From time to time, we may run sales promotions or offer other incentives (&ldquo;Promotions&rdquo;) to purchase particular Products from us. When we are running a Sitewide Promotion, this discount cannot be combined with other discounts, including Welcome Gift Cards or Referral Discounts.</p><Space />
            <p><strong>10. Vouchers, gift vouchers and discount codes</strong></p><Space />
            <p>10.1 Gift vouchers are not currently available for purchase, but vouchers may be offered or provided by Flitch as prizes, through promotions, or in other circumstances.</p><Space />
            <p>10.2 The voucher may be:</p><Space />
            <p>(a) emailed to the recipient; or</p><Space />
            <p>(b) printed.</p><Space />
            <p>10.3 Unless otherwise stated, vouchers are valid for one year from the date of issue and cannot be extended.</p><Space />
            <p>10.4 The value of any voucher you use will be deducted from the total cost of your order. The voucher can only be redeemed once and you will not be able to use any unused balance on a future order. There is no minimum spend required and the voucher can be redeemed against any purchase including delivery charges.</p><Space />
            <p>10.5 Vouchers may be provided in any denomination.</p><Space />
            <p>10.6 Any costs incurred on the order (including delivery, return, and collection costs) will be calculated based on the full costs of the order, before the voucher or discount code is applied.</p><Space />
            <p>10.7 Vouchers and/or discount codes must not be posted on consumer forums and we may invalidate vouchers or discount codes posted in such a way.</p><Space />
            <p>10.8 Only one discount code can be applied to any one order, this includes any promotional codes or offers (but you may also use any voucher that you have been given).</p><Space />
            <p>10.9 Unless otherwise stated, discount codes may only be used once and they cannot be used in conjunction with any other voucher codes or offers (e.g. cashback).</p><Space />
            <p>10.10 If a discount code requires a minimum spend and you return some of the Products within your order such that your order falls below the minimum spend, the discount will no longer apply to the remainder of your order.</p><Space />
            <p>10.11 When you use a voucher and/or discount code you warrant to us that you are the duly authorised recipient of the voucher or discount code (as applicable) and that you are using it in accordance with these Terms of Sale, lawfully and in good faith. If we believe that a voucher and/or discount code is being used in breach of these Terms of Sale, unlawfully or in bad faith, we may reject or cancel the voucher or discount code (as applicable).</p><Space />
            <p>10.12 If you have any difficulty redeeming your voucher or discount code or have any questions then please contact us for further assistance.</p><Space />
            <p>10.13 Any order for Products made using a voucher or discount code will be governed by these Terms of Sale.</p><Space />
            <p><strong>11. Your information</strong></p><Space />
            <p>11.1 We process information about you in accordance with our Privacy Policy.</p><Space />
            <p>11.2 In order to make purchases on Flitch you will be required to provide your personal details. You&lsquo;ll need to provide your real name, phone number, e-mail address and other requested information as well as your payment details.</p><Space />
            <p><strong>12. Our liability to you</strong></p><Space />
            <p>12.1 We are under a legal duty to supply Products that are in conformity with our contract with you.</p><Space />
            <p>12.2 Our Products are designed for residential use and we do not make any guarantees or warranties about their performance in a commercial environment. We will have no liability to you for any loss of profit, loss of business, business interruption or loss of business opportunity.</p><Space />
            <p>12.3 If we fail to comply with a contract between you and us, we will be responsible for loss or damage you suffer that is a foreseeable result of our breach of these Terms of Sale or our negligence, but we will not be responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if the loss or damage was an obvious consequence of our breach or if the loss or damage was contemplated by you and us at the time we entered into the contract.</p><Space />
            <p>12.4 The maximum loss or damage we will be responsible for under clause 12.3 is limited to the price you have paid us for the Product to which the loss or damage you suffer relates.</p><Space />
            <p>12.5 We do not in any way exclude or limit our liability for:</p><Space />
            <p>(a) death or personal injury caused by our negligence;</p><Space />
            <p>(b) fraud or fraudulent misrepresentation;</p><Space />
            <p>(c) any liability imposed under the Consumer Rights Act 2015 that is not permitted to be excluded or restricted under section 31 of the Consumer Rights Act 2015; and</p><Space />
            <p>(d) defective products under the Consumer Protection Act 1987.</p><Space />
            <p>12.6 This does not affect your statutory rights. Advice about your statutory rights is available from your local Citizens' Advice Bureau or Trading Standards office.</p><Space />
            <p><strong>13. Events outside our control</strong></p><Space />
            <p>13.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a contract that is caused by events outside our reasonable control including acts of god, fire, flood, severe weather, explosions, war (whether declared or not), acts of terrorism or acts of local or central Government or of any other competent authorities provided that:</p><Space />
            <p>(a) we will take reasonable steps to prevent or minimise the failure or delay;</p><Space />
            <p>(b) in the event of failure to perform, we will refund you all amounts paid under the affected contract; and</p><Space />
            <p>(c) in the event of substantial delay, you may cancel your order by notifying us and we will refund you all</p><Space />
            <p>amounts paid under the affected contract.</p><Space />
            <p><strong>14. Resolving disputes</strong></p><Space />
            <p>14.1 If you have a dispute with us relating to our contract with you, in the first instance please contact us at support@withflitch.com and attempt to resolve the dispute with us informally.</p><Space />
            <p>14.2 In the unlikely event that we are not able to resolve the dispute informally, please note that the Furniture &amp; Home Improvement Ombudsman operates an online dispute resolution platform for UK consumers to submit disputes arising from online purchases, which can be accessed at www.fhio.org/dispute. You will also always have the option of resolving the dispute using court action.</p><Space />
            <p><strong>15. Each of these terms operates independently</strong></p><Space />
            <p>15.1 Each of the provisions of these Terms of Sale operates separately. If any court or any other relevant authority decides that any of these paragraphs are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.</p><Space />
            <p><strong>16. Updating these terms of sale</strong></p><Space />
            <p>16.1 We may modify or update these Terms of Sale from time to time for reasons including:</p><Space />
            <p>(a) changes in how our business operates;</p><Space />
            <p>(b) changes in the legal or regulatory requirements that we must comply with; or</p><Space />
            <p>(c) changes in how we accept payment from you. However, any order of Products by you will be governed by the terms and conditions available on our site at the time you place the order.</p><Space />
            <p><strong>17. Rights of third parties</strong></p><Space />
            <p>17.1 Any Contract made between you and us is only made between you and us. No third party will have any rights to enforce any of its terms.</p><Space />
            <p><strong>18. Law and Jurisdiction</strong></p><Space />
            <p>18.1 These Terms of Sale are governed by English law. This means that a contract for the purchase of Products made through our site and any dispute or claim arising out of or in connection with it will be governed by English law.</p><Space />
            <p>18.2 You can bring legal proceedings in respect of this contract in the English courts. However, if you are a resident of Scotland, you may also bring proceedings in Scotland and if you are a resident in Northern Ireland, you may also bring proceedings in Northern Ireland.</p><Space />
            <p><strong>19. Contacting you</strong></p><Space />
            <p>19.1 If we have to contact you or give you notice in writing, we will do so by e-mail or by pre-paid post to the address you provide to us in your order.</p><Space />
            <p><strong>Terms of Sale - Services</strong></p><Space />
            <p>1. The online interior styling service for your home is tailored for private customers only and not for Business customers. For further reference private customers will be referred to as the &ldquo;Customer&rdquo;.</p><Space />
            <p>2. Services are limited to a year from the time of purchase, and happen remotely with an Online Interior Stylist (the &ldquo;Stylist&rdquo;).</p><Space />
            <p>3. During the service the Stylist will assist the Customer in finding furniture solutions within the full range of products from Flitch&rsquo;s retailer partners based on the taste of the Customer, as well as the measurements and budget provided. The solutions will be presented as a mood board, with suggested furniture products and accessories as a shopping list (the &ldquo;Proposal&rdquo;).</p><Space />
            <p>4. The customer should review all product specifications and product instructions in order to decide on the suitability of the products for the customer&rsquo;s intended use. The customer accepts that no additional warranties or consumer rights relating to the product beyond those given in the Flitch terms and conditions of sale shall apply. Any such warranties, express or implied, are hereby fully excluded.</p><Space />
            <p>5. The Service must be purchased on the website.&nbsp;&nbsp;</p><Space />
            <p>6. The Service is subject to a fee, which will be displayed on the website at time of purchase.</p><Space />
            <p>7. The customer may cancel the service at any time before the Proposal is received by the Customer by emailing, sylist@withflitch.com.&nbsp;</p><Space />
            <p>8. It cannot be guaranteed that the customer will always find suitable products, or that everything will be available at the time of purchase.&nbsp;</p><Space />
            <p>9. Flitch has no control over external suppliers or sole traders and accepts no legal responsibility for any products purchased from external suppliers or sole traders. Any reference to any external supplier or sole trader does not mean that Flitch endorses that third party&rsquo;s website, products or services. The customer&rsquo;s use of that external suppliers or sole traders website, product or service shall be governed by the terms and conditions of that external supplier or sole trader and is at the customer&rsquo;s own risk.</p><Space />
            <p>10. Stylists advising on children&rsquo;s rooms are required to use only recommended furniture approved for children, and suitable for the age of the child.</p><Space />
            <p>11. Flitch reserves the right to withdraw the Service or amend these conditions at any time without notice.</p><Space />
            <p>12. Customer data will be processed in accordance with Flitch&rsquo;s privacy policy, details of which are available on the following website www.flitch.com</p><Space />
            <p>If you require further information about Flitch or have any concerns or suggestions, please contact us at hello@withflitch.com. Alternatively, you can write to us at our registered address.</p><Space />
          </>
        </TermsOfServiceWrapper>
        <Footer />
        <BottomFooter />
      </FaqsPageContainer>
    </FaqsPageWrapper>
  );
};

export default TandSPage;
