import React from "react";
import {
  SidebarWrapper,
  SideBarItem,
  SideBarExternalLink,
  CloseSidebar,
} from "./SidebarWrapper";

interface IProps {
  toggleSidebar: () => void;
  currentlyInSqFlow?: boolean;
}

export const Sidebar = ({ toggleSidebar, currentlyInSqFlow }: IProps) => {
  return (
    <SidebarWrapper>
      <CloseSidebar onClick={toggleSidebar}>&times;</CloseSidebar>
      <SideBarItem target={currentlyInSqFlow ? "_blank" : ""} to="/">
        HOME
      </SideBarItem>
      <SideBarItem target={currentlyInSqFlow ? "_blank" : ""} to="/category">
        STYLE QUIZ
      </SideBarItem>
      <SideBarItem target={currentlyInSqFlow ? "_blank" : ""} to="/shop">
        SHOP
      </SideBarItem>
      <SideBarItem target={currentlyInSqFlow ? "_blank" : ""} to="/faqs">
        FAQs
      </SideBarItem>
      <SideBarExternalLink
        href="https://blog.flitch.com"
        target="_blank"
        rel="noopener"
      >
        BLOG
      </SideBarExternalLink>
      <SideBarItem target={currentlyInSqFlow ? "_blank" : ""} to="/about">
        ABOUT US
      </SideBarItem>
    </SidebarWrapper>
  );
};
