import { css } from "@emotion/css";
import { colors, fontSizes } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";
import { getGreyBgOrFbThumbnail } from "../../../utils/helpers";

const getBoxShadow = (size: number = 7, color: string = "silver") => `0 0 ${size}px ${color};`;
//const none = css`none;`;

export const getBrowseItemCss = (isLiked: boolean) => css`
  box-shadow: ${getBoxShadow(5, "rgb(210, 210, 210)")};
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  @media (min-width: ${breakpoints.mobile}) {
    box-shadow: ${isLiked ? getBoxShadow() : "none"};
    transition: box-shadow .65s;
  }
`

export const getItemImageCss = (item: IFurnitureItem, discarded: boolean) => css`
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${getGreyBgOrFbThumbnail(item)});
  border-radius: inherit;
  filter: grayscale(${discarded ? 1 : 0});
  opacity: ${discarded ? .1 : 1};
  transition: all .65s;
`

export const DiscountLabelCss = css`
  position: absolute;
  top: 4%;
  left: 3%;
  padding: 2%;
  padding-bottom: 1%;
  padding-top: 1%;  
  height: auto; 
  width: auto;
  color: white;
  background-color: ${colors.brown}; 
  border-radius: 5px;
  
  @supports (-webkit-touch-callout: none) {
    -webkit-padding-start: .5rem;
    -webkit-padding-end: .5rem;
    -webkit-padding-before: .3rem;
    -webkit-padding-after: .3rem;
    margin-top: 1rem;
    margin-left: 1rem;
    top: auto;
    left: auto;
    text-align: center;
    vertical-align: middle;
  }
`;

export const UnderImageSectionCss = css`
  display: flex;
  flex-grow: 1;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 5%;
  
  @media (min-width: ${breakpoints.mobile}) {
    flex-direction: row;
    align-items: center;
    padding-left: 5%;
  }
`

export const DesktopLeftSectionCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

export const BrandNameAndPriceCss = css`
  text-align: center;
  flex-grow: 1;

  @media (min-width: ${breakpoints.mobile}) {
    text-align: left;
    padding-right: 0;
  }
`

export const ItemTextCss = css`
  font-family: "Avenir";
  font-size: ${fontSizes.moreSmall};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (min-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.small};
  }
`

export const PriceLabelsCss = css`
  display: flex;
  flex-direction: horizontal;
  width: auto;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 1.5px;
    justify-content: center;
  }
`;

export const ItemTextStrokedCss = css`
  ${ItemTextCss}
  color: ${colors.greyMedium};
  text-decoration: line-through;
  margin-right: 5px;
`

export const ItemTextBoldCss = css`
  font-weight: bold;
  ${ItemTextCss};
`

export const OtherColorsLineCss = css`
  height: 20px;
  margin-top: 10px;
  font-size: ${fontSizes.tiny};
  text-align: center;
  color: ${colors.greyMedium};

  @media (min-width: ${breakpoints.mobile}) {
    text-align: left;
  }
`
export const feedbackCommonButtonStyle = css`
  --size: 30px;
  font-size: 15px;

  cursor: pointer;
  border: none;
  text-align: center;
  color: white;
  border-radius: 100%;
  width: var(--size);
  height: var(--size);
  margin-left: auto;
  margin-right: auto;
  display: block;

  &:hover {
    color: white;
  }

  @media (min-width: ${breakpoints.mobile}) {
    --size: 38px;
    font-size: 19px;
  }
`;

export const ReactionButtonsDivCss = css`
  display: flex;
  text-align: center;
  width: 50%;
  margin-top: 5px;
  margin-down: 5px;
  margin-left: auto;
  margin-right: auto;
  
  @media (min-width: ${breakpoints.mobile}) {
    text-align: right;
    width: 80px;
    gap: 5px;
    margin: 5px;
  }
`

export const getDislikeButtonCss = (isDiscarded: boolean) => css`
  ${feedbackCommonButtonStyle}
  background-color: ${isDiscarded ? colors.red : colors.greyMedium};
  transition: background-color .65s;
`

export const getLikeButtonCss = (isLiked: boolean) => css`
  ${feedbackCommonButtonStyle}
  background-color: ${isLiked ? colors.green : colors.greyMedium};
  transition: background-color .65s;
  
  @media (min-width: ${breakpoints.mobile}) {
    padding-top: 2%;
  }
`
