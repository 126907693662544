import styled from "styled-components";
import { Link } from "react-router-dom";

export const SidebarWrapper = styled.div`
  height: 100%;
  width: 30rem;
  position: fixed;
  z-index: 100000;
  top: 0;
  right: 0;
  background-color: #666666;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 5rem 5rem 5rem 5rem 5rem;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  text-align: right;
`;

export const SideBarItem = styled(Link)`
  color: white;
  font-size: large;
  justify-self: center;
`;

export const SideBarExternalLink = styled.a`
  color: white;
  font-size: large;
  justify-self: center;
`;

export const CloseSidebar = styled.div`
  color: white;
  font-size: 4rem;
  padding-right: 1rem;
  justify-self: end;
  font-weight: 600;
  opacity: 0.9;
  cursor: pointer;
`;
