import { FC } from "react";
import NavBar from "../../shared/NavBar/NavBar";

import { Footer } from "../../shared/Footer/Footer";
import { BottomFooter } from "../../shared/Footer/BottomFooter";
import {
  MethodCardDescription,
  MethodCardsContainer,
  MethodCardStyled,
  MethodCardTitle,
  MethodSection,
  PageContainer,
  RetailerBgSection,
  RetailerSection,
  RetailerSectionText,
  MethodCardVideo,
  MethodTitle,
  GuideButton,
  MobileSpacer,
  GoToSqBox,
  InfoLabel,
  RebrandingNote,
} from "./HomePageStyled";

import card1VidMp4 from "../../images/homepage-card-1.mp4";
import card1VidWebm from "../../images/homepage-card-1.webm";
import card2VidMp4 from "../../images/homepage-card-2.mp4";
import card2VidWebm from "../../images/homepage-card-2.webm";
import card3VidMp4 from "../../images/homepage-card-3.mp4";
import card3VidWebm from "../../images/homepage-card-3.webm";

import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { Carousel } from "./Carousel/Carousel";
import { ReviewSection, Review } from "./ReviewSection/ReviewSection";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { AboutPrice } from "./AboutPrice/AboutPrice";
import { AboutStylistDescription } from "../AboutStylistPage/AboutStylistPage";

const REVIEWS = [
  ["Antoniya K.",
  "Trustpilot Review",
  "My partner and I have a very specific style so Flitch worked personally with me to find a bed that was perfect and much cheaper than I expected. Brilliant service!"],

  ["Annaelle M.",
  "Trustpilot Review",
  "I had a wonderful experience using Flitch. It is incredibly user-friendly and saved me so much time when decorating my new home! They got my taste spot on. Really impressed!"],

  ["Carolyn L.",
  "Trustpilot Review",
  "My stylist Sam really helped me narrow down my search for dining furniture, capturing my taste perfectly. Such good value for money and saved me so much time!"]
] as Review[];

interface MethodCardProps {
  title: EmotionJSX.Element;
  children?: React.ReactElement;
  description: string;
}

const MethodCard: FC<MethodCardProps> = ({ title, description, children }) => {
  return (
    <MethodCardStyled>
      {children}
      <MethodCardTitle>{title}</MethodCardTitle>
      <MethodCardDescription>{description}</MethodCardDescription>
    </MethodCardStyled>
  );
};

export const NewHomePage = () => {
  return (
    <PageContainer>
      <FlitchHelmet />
      <NavBar />
      <RebrandingNote>
        We've rebranded...Furnish.app is now Flitch
      </RebrandingNote>

      <Carousel 
        title={<>Meet your online<br/>interior stylist</>}
        subHeading={<>Outsource your furniture search, or compare items across 100+ furniture retailers</>}
        buttonText="Take your style quiz"
      />
      
      <AboutStylistDescription isHomePage={true} />
      <InfoLabel>
        <h1>Flitch</h1>
        <h2>Furniture shopping made simple</h2>
        More than 100 furniture retailers in one place <g>+</g> a dedicated human stylist
        <g> =</g> a furniture shopping experience that is fast, convenient, and easy
      </InfoLabel>
      <RetailerSection>
        <RetailerSectionText>
          We'll find your perfect item from across 100+ furniture retailers
          <p>
            Or if you're type of person who wants to discover your own furniture items, we've got the tool for you: 
            a single destination with the whole UK furniture market in one place
          </p>
          <MobileSpacer height="15px" />
          <GuideButton to="/shop" $shop={true}>BROWSE THE MARKET</GuideButton>
          <MobileSpacer height="15px" />
        </RetailerSectionText>
        <RetailerBgSection />
      </RetailerSection>
      <AboutPrice />
      <MethodSection>
        <MethodTitle>
          We combine human stylists and machine learning algorithms to
          find the furniture that's a perfect match for you
        </MethodTitle>
        {/* <p>
          Search and compare items from across 100+ furniture retailers in one place…or outsource all your furniture needs to your own dedicated interior stylist - for just £25
        </p> */}
        <MethodCardsContainer>
          <MethodCard
            title={(<>We Get To Know You</>)}
            description="Taking a quick and fun style quiz enables us to get to know what you're looking for, along with your budget and other requirements"
            >
            <MethodCardVideo autoPlay={true} muted={true} loop={true} playsInline={true}>
              <source src={card2VidMp4} type="video/mp4" />
              <source src={card2VidWebm} type="video/webm" />
            </MethodCardVideo>
          </MethodCard>
          <MethodCard
            title={(<>We Understand Furniture</>)}
            description="Our technology deconstructs each furniture item you've liked into hundreds of attributes, so that we can understand what makes your taste unique"
          >
            <MethodCardVideo autoPlay={true} muted={true} loop={true} playsInline={true}>
              <source src={card1VidMp4} type="video/mp4" />
              <source src={card1VidWebm} type="video/webm" />
            </MethodCardVideo>
          </MethodCard>  
          <MethodCard
            title={(<>Handpicked Recommendations</>)}
            description="Our human stylist combine their expertise and our AI to recommend items perfect for you and your space, saving you months of endless browsing"
          >
            <MethodCardVideo autoPlay={true} muted={true} loop={true} playsInline={true}>
              <source src={card3VidMp4} type="video/mp4" />
              <source src={card3VidWebm} type="video/webm" />
            </MethodCardVideo>
          </MethodCard>
        </MethodCardsContainer>
        {/* <GuideButton to="/category">TAKE YOUR STYLE QUIZ</GuideButton>
        <MobileSpacer height="30px" /> */}
      </MethodSection>

      <ReviewSection reviews={REVIEWS}/>

      <GoToSqBox>
        <p>
          Ready to get your furniture recommendations?
        </p>
        <div>
          <GuideButton to="/category" $shop={false}>
            TAKE YOUR STYLE QUIZ
          </GuideButton>
        </div>
      </GoToSqBox>
      <Footer />
      <BottomFooter />
    </PageContainer>
  );
};
