import { FC, useEffect, useState } from "react";
import { mdiMagnify, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { 
  LineItemContainer, 
  MagnifyStyle, 
  SearchLine, 
  SearchInput,
  SerachBox,
  RoundButton,
  SearchWrapper,
  SearchBackdropWrapper,
} from "./SearchStyles";
import { FilterPanelBackdrop } from "../../../../pages/RecommendationsPage/FilterPanel";
import { SearchShopCategory, useSearchContext } from "../../../../store/searchContext";
import { useResolvedPath, useNavigate } from "react-router";
import { SearchOptions } from "./SearchOptions";

interface CancelButtonProps {
  visible: boolean;
  onClick: () => void;
}

const CancelSearchButton: FC<CancelButtonProps> = (
  { visible, onClick }
) => (
    <RoundButton 
      radius={22.5} 
      visible={visible} 
      onClick={onClick}
      title="Clear search"
    >
      <Icon path={mdiClose} size={1.2} css={MagnifyStyle}/>
    </RoundButton> );

export const Search: FC = () => {
  const { search, appliedSearch, applySearch, keywords, parse, selectShopCat } = useSearchContext();

  const [focused, setFocused] = useState(false);
  const searchInput = document.getElementById("searchInput") as HTMLInputElement;

  const navigate = useNavigate();
  const url = useResolvedPath("").pathname;
  const isOnShopPage = url.includes("/shop");

  useEffect(() => {
    setFocused(!!searchInput?.value && document.activeElement === searchInput);
  }, [searchInput?.value]);

  useEffect(() => {
    if (searchInput) searchInput.value = appliedSearch;
  }, [appliedSearch, searchInput])

  // useEffect(() => {
  //   console.log(search, keywords)
  // }, [keywords]);

  const goToShop = (catToSelect: SearchShopCategory|undefined = undefined) => 
    applySearch(searchInput?.value ?? "", false, !isOnShopPage).then(() => {
        searchInput.blur();
        selectShopCat(catToSelect);
        if (!isOnShopPage) navigate("/shop");
    });

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    parse(event.target.value);
  };

  const clearSearch = () => {
    if (searchInput)
      searchInput.value = "";

    applySearch("", false, true);
  };
 
  return (
    <>
      <SearchBackdropWrapper>
        <FilterPanelBackdrop
          isOpen={focused}
          onClick={() => setFocused(false)}
        />
      </SearchBackdropWrapper>
      <SearchWrapper>
        <SerachBox focused={focused}>
          <SearchLine>
            <LineItemContainer>
              <Icon path={mdiMagnify} size={1.5} css={MagnifyStyle}/>
            </LineItemContainer>
            <SearchInput 
              id="searchInput"
              placeholder="Search for items and retailers" 
              onChange={handleSearchInput}
              onKeyUp={e => {if (e.key === "Enter") goToShop()}}
              onBlur={() => setFocused(false)}
            />
            <LineItemContainer>
              <CancelSearchButton visible={!!searchInput?.value} onClick={clearSearch}/>
            </LineItemContainer>
          </SearchLine>
          <SearchOptions 
            searchInput={searchInput}
            visible={focused&&!!searchInput?.value}
          />
        </SerachBox>
      </SearchWrapper>
    </>
  )
};

