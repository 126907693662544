import styled, { css } from "styled-components";

import { colors, fontSizes } from "../../styles/globalStyled";
import { Link } from "react-router-dom";

export const FaqsPageWrapper = styled.div`
  width: 100vw;
  background: white;
  overflow: auto;
`;

export const FaqsPageContainer = styled.div`
  width: 100vw;
  background: white;
  overflow: auto;
`;

const termsOfServiceCss = css`
  .lst-kix_bt4ntfjj7a2-5 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-5, lower-roman) ". ";
  }
  ol.lst-kix_bt4ntfjj7a2-6.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-6 0;
  }
  .lst-kix_bt4ntfjj7a2-3 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-3, decimal) ". ";
  }
  .lst-kix_bt4ntfjj7a2-2 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-2, lower-roman) ". ";
  }
  .lst-kix_bt4ntfjj7a2-6 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-6, decimal) ". ";
  }
  ol.lst-kix_bt4ntfjj7a2-4.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-4 0;
  }
  .lst-kix_bt4ntfjj7a2-4 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-4;
  }
  .lst-kix_bt4ntfjj7a2-6 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-6;
  }
  .lst-kix_bt4ntfjj7a2-4 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-4, lower-latin) ". ";
  }
  .lst-kix_bt4ntfjj7a2-1 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-1;
  }
  .lst-kix_bt4ntfjj7a2-1 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-1, lower-latin) ". ";
  }
  ol.lst-kix_bt4ntfjj7a2-1.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-1 0;
  }
  .lst-kix_bt4ntfjj7a2-0 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-0, upper-latin) ". ";
  }
  .lst-kix_bt4ntfjj7a2-5 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-5;
  }
  ol.lst-kix_bt4ntfjj7a2-8 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-8 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-8;
  }
  ol.lst-kix_bt4ntfjj7a2-7 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-6 {
    list-style-type: none;
  }
  .lst-kix_bt4ntfjj7a2-2 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-2;
  }
  ol.lst-kix_bt4ntfjj7a2-5 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-4 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-0.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-0 0;
  }
  ol.lst-kix_bt4ntfjj7a2-3 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-2 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-1 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-0 {
    list-style-type: none;
  }
  ol.lst-kix_bt4ntfjj7a2-7.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-7 0;
  }
  ol.lst-kix_bt4ntfjj7a2-3.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-3 0;
  }
  .lst-kix_bt4ntfjj7a2-0 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-0;
  }
  ol.lst-kix_bt4ntfjj7a2-8.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-8 0;
  }
  .lst-kix_bt4ntfjj7a2-7 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-7;
  }
  ol.lst-kix_bt4ntfjj7a2-2.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-2 0;
  }
  .lst-kix_bt4ntfjj7a2-3 > li {
    counter-increment: lst-ctn-kix_bt4ntfjj7a2-3;
  }
  .lst-kix_bt4ntfjj7a2-7 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-7, lower-latin) ". ";
  }
  ol.lst-kix_bt4ntfjj7a2-5.start {
    counter-reset: lst-ctn-kix_bt4ntfjj7a2-5 0;
  }
  .lst-kix_bt4ntfjj7a2-8 > li:before {
    content: "" counter(lst-ctn-kix_bt4ntfjj7a2-8, lower-roman) ". ";
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c1 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
  }
  .c3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c4 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal;
  }
  .c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c7 {
    background-color: #ffffff;
    max-width: 451.4pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c6 {
    padding: 0;
    margin: 0;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
  }
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  a{
    color: ${colors.green};
  }
`;

export const SubOptions = styled.div`
  padding-left: 1vw;
`;

export const TermsOfServiceWrapper = styled.div`
  background: white;
  width: 95vw;
  max-width: 100rem;
  margin: auto;
  padding-top: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${termsOfServiceCss}
  /* h1 {
    font-size: ${fontSizes.large};
    font-weight: bolder;
    text-align: left;
    padding: 1rem;
  }
  h2 {
    font-size: ${fontSizes.medium};
    font-weight: bolder;
    text-align: left;
    padding: 1rem;
  }
  p {
    font-size: ${fontSizes.small};
    text-align: left;
    padding: 1rem;
  } */
`;
