import { makeNullsUndefs } from "../../../utils/helpers";
import {
  clrSchemesToColours,
  mtrlGroupsToMtrls,
} from "../../SQFilterPage/filterHelpers";

export const getFilterPayload = (
  {
    colourSchemes,
    materials,
    tone,
    preferChairArms,

    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    minDepth,
    maxDepth,

    minBudget,
    maxBudget,

    preferSofabed,
    isTableExtendable,

    ...payload
  }: Partial<AllFilterOpts>,
  /**
   * `requestType` of `"sq"` ignores some of the non-visual filters, e.g. size
   * and budget.
   */
  requestType: "sq" | "recs",
  /**
   * If true then just don't apply any filters. Used for if we want to have
   * separate filter payloads for SQ and Recs.
   */
  dontApplyFiltersToRecs = false
): AllFilterPayload => {
  if (dontApplyFiltersToRecs) {
    const filterPayload: AllFilterPayload = makeNullsUndefs({
      ...payload,
      coloursPreference: [],
      materialsPreference: [],
      tone: undefined,
      minBudget: undefined,
      maxBudget: undefined,
      minHeight: undefined,
      maxHeight: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      minDepth: undefined,
      maxDepth: undefined,
      onSale: [],
    });

    return filterPayload;
  } else {
    const isSqRequest = requestType === "sq";

    const filterPayload: AllFilterPayload = makeNullsUndefs({
      ...payload,
      coloursPreference: colourSchemes?.flatMap(clrSchemesToColours) ?? [],
      materialsPreference: materials?.flatMap(mtrlGroupsToMtrls) ?? [],
      tone: tone ?? undefined,
      preferSofabed: isSqRequest ? undefined : preferSofabed,
      isTableExtendable: isSqRequest ? undefined : isTableExtendable,
      preferChairArms: preferChairArms ?? undefined,

      minBudget: isSqRequest ? undefined : minBudget,
      maxBudget: isSqRequest ? undefined : maxBudget,

      // Just for type safety, these should never be undefined for the categories that require it
      minHeight,
      maxHeight,
      minWidth,
      maxWidth,
      minDepth,
      maxDepth
    });

    return filterPayload;
  }
};

export const getBrowseFilterPayload = ({
  budget,
  width,
  height,
  depth,
  ...browseFilterOpts
}: BrowseFilterOptions): AllFilterPayload => {
  const [minBudget, maxBudget] = budget ?? [undefined, undefined];
  const [minWidth, maxWidth] = width ?? [undefined, undefined];
  const [minHeight, maxHeight] = height ?? [undefined, undefined];
  const [minDepth, maxDepth] = depth ?? [undefined, undefined];

  return {
    ...browseFilterOpts,
    minBudget,
    maxBudget,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    minDepth,
    maxDepth,
  };
};
