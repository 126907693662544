import { Option, ColourSelectOption } from "./FilterButtons";
import redsorangesandyellowsBg from "../../images/colourfilters-redsorangesandyellows.png";
import greenstealsandbluesBg from "../../images/colourfilters-greenstealsandblues.png";
import pinksandpurplesBg from "../../images/colourfilters-pinksandpurples.png";
import blacksandgreysBg from "../../images/colourfilters-blacksandgreys.png";
import whitesandbeigesBg from "../../images/colourfilters-whitesandbeiges.png";
import brownsBg from "../../images/colourfilters-browns.png";
import nonneutralsBg from "../../images/colourfilters-nonneutrals.png";
import { makeOption } from "../../utils/helpers";

const redsoranges = "redsoranges";
const greensblues = "greensblues";
const pinkspurples = "pinkspurples";
const blacksgreys = "blacksgreys";
const whitesbeiges = "whitesbeiges";
const browns = "browns";
const nonneutrals = "nonneutrals";

export const colourSchemeDisplayMap = {
  redsoranges: "Reds, Oranges and Yellows",
  greensblues: "Greens, Teals and Blues",
  pinkspurples: "Pinks and Purples",
  blacksgreys: "Blacks and Greys",
  whitesbeiges: "Whites and Beiges",
  browns: "Browns",
  nonneutrals: "Non-Neutrals",
};

export const allColourSchemeOptions: Record<
  ColourScheme,
  ColourSelectOption<ColourScheme>
> = {
  redsoranges: {
    value: redsoranges,
    label: colourSchemeDisplayMap[redsoranges],
    bgImage: redsorangesandyellowsBg,
  },
  greensblues: {
    value: greensblues,
    label: colourSchemeDisplayMap[greensblues],
    bgImage: greenstealsandbluesBg,
  },
  pinkspurples: {
    value: pinkspurples,
    label: colourSchemeDisplayMap[pinkspurples],
    bgImage: pinksandpurplesBg,
  },
  blacksgreys: {
    value: blacksgreys,
    label: colourSchemeDisplayMap[blacksgreys],
    bgImage: blacksandgreysBg,
  },
  whitesbeiges: {
    value: whitesbeiges,
    label: colourSchemeDisplayMap[whitesbeiges],
    bgImage: whitesandbeigesBg,
  },
  browns: {
    value: browns,
    label: colourSchemeDisplayMap[browns],
    bgImage: brownsBg,
  },
  nonneutrals: {
    value: nonneutrals,
    label: colourSchemeDisplayMap[nonneutrals],
    bgImage: nonneutralsBg,
  },
};

const nonUnifiedNonNeutralClrs: ColourScheme[] = [
  redsoranges,
  greensblues,
  pinkspurples,
  blacksgreys,
  whitesbeiges,
  browns,
];
export const nonUnifiedNonNeutralSchemes = nonUnifiedNonNeutralClrs.map(
  (clr) => allColourSchemeOptions[clr]
);

const unifiedNonNeutralClrs: ColourScheme[] = [
  blacksgreys,
  whitesbeiges,
  browns,
  nonneutrals,
];
export const unifiedNonNeutralSchemes = unifiedNonNeutralClrs.map(
  (clr) => allColourSchemeOptions[clr]
);

const blackAndWhiteClrs: ColourScheme[] = [blacksgreys, whitesbeiges];

export const blackAndWhiteSchemes = blackAndWhiteClrs.map(
  (clr) => allColourSchemeOptions[clr]
);

const materialDisplayMap: Record<MaterialGroup, string> = {
  fabricvelvet: "Fabric or Velvet",
  leathersuede: "Leather or Suede",
  velvetsuede: "Velvet",
  wickerwood: "Wood",
  upholstered: "Upholstered",
  nonupholstered: "Non-Upholstered",
};

export const getMaterialOrGroupDisplayname = (
  matrl: Material | MaterialGroup
) => materialDisplayMap[matrl as MaterialGroup] ?? matrl;

const plastic = "Plastic";
const wicker = "Wicker";
const wood = "Wood";
const metal = "Metal";
const fabric = "Fabric";
const leather = "Leather";
const suede = "Suede";
const velvet = "Velvet";
const concrete = "Concrete";
const glass = "Glass";
const marble = "Marble";

const fabricvelvet = "fabricvelvet";
const leathersuede = "leathersuede";
const velvetsuede = "velvetsuede";
const wickerwood = "wickerwood";
const upholstered = "upholstered";
const nonupholstered = "nonupholstered";

const fabricvelvetOpt = makeOption(fabricvelvet, "Fabric or Velvet");
const leathersuedeOpt = makeOption(leathersuede, "Leather or Suede");
const velvetsuedeOpt = makeOption(velvetsuede, "Velvet");
const wickerwoodOpt = makeOption(wickerwood, "Wood");
const upholsteredOpt = makeOption(upholstered, "Upholstered");
const nonupholsteredOpt = makeOption(nonupholstered, "Non-Upholstered");
const plasticOpt = makeOption(plastic);
const wickerOpt = makeOption(wicker);
const woodOpt = makeOption(wood);
const metalOpt = makeOption(metal);
const fabricOpt = makeOption(fabric);
const leatherOpt = makeOption(leather);
const suedeOpt = makeOption(suede);
const velvetOpt = makeOption(velvet);
const concreteOpt = makeOption(concrete);
const glassOpt = makeOption(glass);
const marbleOpt = makeOption(marble);

export const categoryMaterialMaps: Omit<
  AllCatMap<Option<MaterialGroup | Material>[]>,
  "displaycabinet" | "tvstand" | "wardrobe"
> = {
  diningchair: [
    fabricvelvetOpt,
    leathersuedeOpt,
    wickerwoodOpt,
    plasticOpt,
    metalOpt,
  ],
  diningtable: [concreteOpt, glassOpt, marbleOpt, metalOpt, wickerwoodOpt],
  barstool: [upholsteredOpt, nonupholsteredOpt],
  coffeetable: [glassOpt, marbleOpt, metalOpt, wickerwoodOpt],
  consoletable: [glassOpt, marbleOpt, metalOpt, wickerwoodOpt],
  sideboard: [wickerwoodOpt, metalOpt],
  sidetable: [glassOpt, marbleOpt, metalOpt, wickerwoodOpt],
  bedsidetable: [glassOpt, metalOpt, wickerwoodOpt],
  chestofdrawers: [glassOpt, metalOpt, wickerwoodOpt],
  shelving: [wickerwoodOpt, metalOpt],
  sofa: [fabricOpt, leatherOpt, velvetsuedeOpt],
  armchair: [fabricOpt, leatherOpt, velvetsuedeOpt],
  bed: [fabricOpt, velvetOpt, metalOpt, wickerwoodOpt],
  shop: []
};

/**
 * @deprecated can just use `clrs` array directly now that we're no longer mapping to hex
 */
const getClrs = (...clrs: ColourName[]) => clrs;

export const clrSchemesToColours = (
  colourScheme: ColourScheme
): ColourName[] => {
  switch (colourScheme) {
    case "blacksgreys":
      return getClrs("Black", "Grey");
    case "browns":
      return getClrs("Brown");
    case "greensblues":
      return getClrs("Blue", "Green", "Teal");
    case "pinkspurples":
      return getClrs("Pink", "Purple");
    case "redsoranges":
      return getClrs("Orange", "Red", "Yellow");
    case "whitesbeiges":
      return getClrs("Beige", "White");
    case "nonneutrals":
      return [
        "Pink",
        "Red",
        "Purple",
        "Blue",
        "Teal",
        "Green",
        "Yellow",
        "Orange",
      ];
  }
};

const allMaterials: Material[] = [
  plastic,
  wicker,
  wood,
  metal,
  fabric,
  leather,
  suede,
  velvet,
  concrete,
  glass,
  marble,
];

const isMaterial = (
  mtrlOrGroup: Material | MaterialGroup
): mtrlOrGroup is Material => allMaterials.includes(mtrlOrGroup as Material);

export const mtrlGroupsToMtrls = (
  materialGroup: MaterialGroup | Material
): Material[] => {
  if (isMaterial(materialGroup)) {
    return [materialGroup];
  } else {
    switch (materialGroup) {
      case "fabricvelvet":
        return ["Fabric", "Velvet"];
      case "leathersuede":
        return ["Leather", "Suede"];
      case "velvetsuede":
        return ["Velvet", "Suede"];
      case "wickerwood":
        return ["Wood", "Wicker"];
      case "upholstered":
        return ["Fabric", "Leather", "Suede", "Velvet"];
      case "nonupholstered":
        return ["Metal", "Plastic", "Wicker", "Wood", "Glass", "Concrete"];
    }
  }
};

const coffeeTableSizes: CoffeeTableSize[] = ["Small", "Average", "Large"];

export const isCoffeeTableSize = (
  size: SofaSeatsNum | BedSize | CoffeeTableSize
): size is CoffeeTableSize =>
  coffeeTableSizes.includes(size as CoffeeTableSize);

const bedSizes: BedSize[] = ["Single", "Double", "King", "Super King"];

export const isBedSize = (
  size: SofaSeatsNum | BedSize | CoffeeTableSize
): size is BedSize => bedSizes.includes(size as BedSize);

export const getSimilarSofaSizes = (sofa: SofaSeatsNum): SofaSeatsNum[] => {
  switch (sofa) {
    case 2:
      return [2, 3];
    case 3:
      return [2, 3, 4];
    case 4:
      return [3, 4];
  }
};
