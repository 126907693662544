import styled from "styled-components";
import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";
import tada from "../../images/tada.png";

export const ReceiptContainer = styled.div`
  font-size: ${fontSizes.large};
  color: ${colors.greyDark};
  margin: auto;
  padding-top: -10%;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.medium};
  }
`;

export const Tada = styled.div`
  background-image: url(${tada});
  height: 98px;
  width: 98px;

  @media (max-width: ${breakpoints.mobile}) {
    height: 80px;
    width: 80px;
    background-size: 80px 80px;
  }
`;