import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { FC } from "react";
import { Number, NumberedStepWrapper, NumberLabel } from "./AboutStylistStyles";

interface NumberedStepProps {
  number: number;
  label: EmotionJSX.Element
}

export const NumberedStep : FC<NumberedStepProps> = ({number, label}) => {
  return (
    <NumberedStepWrapper>
      <Number>{number}</Number>
      <NumberLabel>{label}</NumberLabel>
    </NumberedStepWrapper>
  )
};