import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

export const { auth } = firebase;

const configForProd = {
  apiKey: "AIzaSyDtApcTTa7DrRlDn7H60hInA0klIIElq9M",
  authDomain: "furnish-app-347934.firebaseapp.com",
  databaseURL: "https://furnish-app-347934.firebaseio.com",
  projectId: "furnish-app-347934",
  storageBucket: "furnish-app-347934.appspot.com",
  messagingSenderId: "868115147387",
  appId: "1:868115147387:web:579e0aa94195005e",
};

firebase.initializeApp(configForProd);

export const db = firebase.database();
export const googleAuthProvider = new auth.GoogleAuthProvider();
export const facebookAuthProvider = new auth.FacebookAuthProvider();
