import React, { useState, ChangeEvent, FC } from "react";
import { faFacebookSquare, faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  facebookAuthProvider,
  googleAuthProvider,
  auth,
} from "../../../firebase/firebase";

import {
  ModalContentWrapper,
  CloseModalButton,
  IconButton,
  GappedRow,
  BackModalButton,
  GoBackLink,
} from "./AuthModalWrapper";
import {
  EmailLoginForm,
  InputField,
  SubmitButton,
  ButtonLink,
  BelowContinueText,
  LoginFailMsg,
} from "./AuthForms";
import { Reason, useUserContext } from "../../../store/userContext";
import { triggerSignupEvent } from "../../../dataLayer";
import { colors } from "../../../styles/globalStyled";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
//import { createDealInACForUser } from "../../../services/api.service";
import { GoogleAuthButton, GoogleIcon } from "./AuthModalStyles";

const updateState = (setter: StrSetter) => (e: ChangeEvent<HTMLInputElement>) =>
  setter(e.target.value);

type EmailAndErr = { email: string; loginErrorMsg: string | null };
type EmailPW = { password: string } & EmailAndErr;

const blankEmailAndErr: EmailAndErr = { email: "", loginErrorMsg: null };
const blankEmailAndPw: EmailPW = { ...blankEmailAndErr, password: "" };

// See https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
const errCodeToMsg: Record<string, string> = {
  "auth/invalid-email": "This email address is not valid",
  // Login only
  "auth/user-disabled": "The user for this email address has been disabled",
  "auth/user-not-found": "There is no user registered for this email address",
  "auth/wrong-password": "The password is incorrect",
  "auth/too-many-requests":
    "Too many unsuccessful login attempts. Please try again later.",
  // Registration only
  "auth/email-already-in-use":
    "An account with this email address already exists",
  "auth/weak-password": "This password is too weak",
};

const PersonalShopperFlowInitialScreen = "PersonalShopperFlowInitialScreen";
const InitialLogin = "InitialLogin";
const EnterEmailForSignIn = "EnterEmailForSignIn";
const FirstSignIn = "FirstSignIn";
const AccountAlreadyExists = "AccountAlreadyExists";
const ForgotPassword = "ForgotPassword";
const PasswordResetLogin = "PasswordResetLogin";
const PersonalShopperThanks = "PersonalShopperThanks";

/**
 * The states for the new modal.
 * Only for the login prompt, not the personal shopper prompt.
 */
type ModalState =
  | { type: typeof InitialLogin }
  | ({ type: typeof EnterEmailForSignIn } & EmailAndErr)
  | ({
      type: typeof FirstSignIn;
      firstName: string;
      confirmPw: string;
    } & EmailPW)
  | ({ type: typeof AccountAlreadyExists } & EmailPW)
  | ({ type: typeof ForgotPassword } & EmailPW)
  | ({ type: typeof PasswordResetLogin } & EmailPW)
  // Personal shopper screens
  | { type: typeof PersonalShopperFlowInitialScreen }
  | { type: typeof PersonalShopperThanks };

const getPrevState = (
  modalState: ModalState,
  isInPersonalShopperFlow: boolean
): ModalState | null => {
  switch (modalState.type) {
    case PersonalShopperFlowInitialScreen:
      return null;
    case InitialLogin:
      return isInPersonalShopperFlow
        ? { type: PersonalShopperFlowInitialScreen }
        : null;
    case EnterEmailForSignIn:
      return { type: InitialLogin };
    case FirstSignIn:
      return { ...modalState, type: EnterEmailForSignIn };
    case AccountAlreadyExists:
      return { ...modalState, type: EnterEmailForSignIn };
    case ForgotPassword:
      return { ...modalState, type: AccountAlreadyExists };
    case PasswordResetLogin:
      return { ...modalState, type: ForgotPassword };
    case PersonalShopperThanks:
      // Technically not null but there's no point going back from this screen; also there are multiple possible parents
      return null;
  }
};

const HasEmailLoginMethod = "HasEmailLoginMethod";
const NoEmailButHasFbMethod = "NoEmailButHasFbMethod";
const NoEmailButHasGoogleMethod = "NoEmailButHasGoogleMethod";
const NoLoginMethods = "NoLoginMethods";

const getEmailAndPwIfExist = ({
  email,
  password,
}: { email?: string; password?: string } & Partial<ModalState>): EmailPW => ({
  ...blankEmailAndPw,
  email: email ?? blankEmailAndPw.email,
  password: password ?? blankEmailAndPw.password,
});

const pwSignInMethodExistsForEmail = async (email: string) => {
  const signInMethods = await auth().fetchSignInMethodsForEmail(email);

  return signInMethods.includes(
    auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
  )
    ? HasEmailLoginMethod
    : signInMethods.includes(auth.FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD)
    ? NoEmailButHasFbMethod
    : signInMethods.includes(auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD)
    ? NoEmailButHasGoogleMethod
    : NoLoginMethods;
};

const ConditionalFailMsg: FC<{ msg: string | null }> = ({ msg }) =>
  msg ? <LoginFailMsg>{msg}</LoginFailMsg> : null;

interface AuthModalProps {
  onRequestClose?: VoidFunction;
}

export const AuthModal = ({ onRequestClose }: AuthModalProps) => {
  const {
    user,
    continueWithFirebaseSSO,
    registerWithFirebaseEmail,
    loginWithFirebaseEmail,
    authModalState,
  } = useUserContext();

  const triggeredByReason = authModalState.reason;
  const triggeredByPersonalShopperFlow =
    triggeredByReason === Reason.PersonalShopperFlow;

  const [modalState, modalStateSet] = useState<ModalState>({
    type: triggeredByPersonalShopperFlow
      ? PersonalShopperFlowInitialScreen
      : InitialLogin,
  });

  const changeEmail = (email: string) =>
    modalStateSet((s) => ({ ...s, email }));
  const changePassword = (password: string) =>
    modalStateSet((s) => ({ ...s, password }));
  const changePasswordConfirm = (confirmPw: string) =>
    modalStateSet((s) => ({ ...s, confirmPw }));

  const cancelErrorMsg = () =>
    modalStateSet((s) => ({ ...s, loginErrorMsg: null }));

  const switchToForgotPw = () =>
    modalStateSet((s) => ({
      type: ForgotPassword,
      ...getEmailAndPwIfExist(s),
    }));
  const switchToPwResetLogin = () =>
    modalStateSet((s) => ({
      type: PasswordResetLogin,
      ...getEmailAndPwIfExist(s),
      password: "",
    }));

  const switchToEmailForSignIn = () =>
    modalStateSet((s) => ({
      type: EnterEmailForSignIn,
      ...getEmailAndPwIfExist(s),
    }));

  const setPwAndCancelErr = (e: ChangeEvent<HTMLInputElement>) => {
    cancelErrorMsg();
    updateState(changePassword)(e);
  };

  const setPwConfirmAndCancelErr = (e: ChangeEvent<HTMLInputElement>) => {
    cancelErrorMsg();
    updateState(changePasswordConfirm)(e);
  };

  const setError = (errCode: string) =>
    modalStateSet((s) => ({
      ...s,
      loginErrorMsg:
        errCodeToMsg[errCode] ?? "Something went wrong trying to log you in",
    }));

  /**
   * Also fires off deal creation API request for personal shopper flow
   */
  const changeToPersonalShopperThanksOrClose = () => {
    if (triggeredByPersonalShopperFlow) {
      // If in personal shopper flow go to thanks page
      //createDealInACForUser();

      modalStateSet({ type: PersonalShopperThanks });
    } else {
      // Otherwise the user just wanted to log in, so once they're done close the modal
      onRequestClose?.();
    }
  };

  const submitEmailField = (email: string) =>
    pwSignInMethodExistsForEmail(email)
      .then((loginMethodResult) => {
        if (loginMethodResult === HasEmailLoginMethod) {
          modalStateSet({
            type: AccountAlreadyExists,
            email,
            password: "",
            loginErrorMsg: null,
          });
        } else if (loginMethodResult === NoLoginMethods) {
          modalStateSet({
            type: FirstSignIn,
            email,
            firstName: "",
            password: "",
            confirmPw: "",
            loginErrorMsg: null,
          });
        } else {
          modalStateSet((s) => ({
            ...s,
            loginErrorMsg: `This email is already registered with ${
              loginMethodResult === NoEmailButHasFbMethod
                ? "Facebook"
                : "Google"
            }`,
          }));
        }
      })
      .catch((reason: { code: string }) => setError(reason.code));

  const registerWithEmail = (
    email: string,
    password: string,
    firstName: string
  ) => {
    registerWithFirebaseEmail(email, password, firstName)
      .then(() => {
        changeToPersonalShopperThanksOrClose();
        triggerSignupEvent();
      })
      .catch((reason: { code: string }) => setError(reason.code));
  };

  const loginWithEmail = (email: string, password: string) =>
    loginWithFirebaseEmail(email, password)
      .then(changeToPersonalShopperThanksOrClose)
      .catch((reason: { code: string }) => setError(reason.code));

  const continueWithFacebook = () =>
    continueWithFirebaseSSO(facebookAuthProvider).then(
      changeToPersonalShopperThanksOrClose
    );

  const continueWithGoogle = () =>
    continueWithFirebaseSSO(googleAuthProvider).then(
      changeToPersonalShopperThanksOrClose
    );

  const handleYesPleasePersonalShopper = () => {
    if (user) {
      changeToPersonalShopperThanksOrClose();
    } else {
      modalStateSet({ type: InitialLogin });
    }
  };

  const prevState = getPrevState(modalState, triggeredByPersonalShopperFlow);

  const goBackOnClose = triggeredByReason === Reason.RegisterBeforeSQ;
  const goBack = () => {
    history.back();
    onRequestClose?.();
  }

  return (
    <ModalContentWrapper>
      {prevState && (
        <BackModalButton onClick={() => modalStateSet(prevState)} />
      )}
      {goBackOnClose && <GoBackLink onClick={goBack}>Go back</GoBackLink>}
      {!goBackOnClose && onRequestClose && <CloseModalButton onClick={onRequestClose} />}
      <GappedRow>
        {modalState.type === PersonalShopperFlowInitialScreen ? (
          <>
            <h1>
              Would you be interested in speaking with one of our personal
              shoppers?
            </h1>
            <p className="heading-text">
              You can use this opportunity to refine your recommendations, send
              us a photo of a specific item you've seen elsewhere, or just have
              a general chat!
            </p>
            <SubmitButton onClick={handleYesPleasePersonalShopper}>
              Yes Please!
            </SubmitButton>
            <SubmitButton onClick={onRequestClose} bgColor={colors.greyDark}>
              I'm Not Interested
            </SubmitButton>
          </>
        ) : modalState.type === InitialLogin ? (
          <>
            {triggeredByReason === Reason.Login ? (
              <>
                <h1>Welcome to Flitch</h1>
                <p className="heading-text">
                  Creating an account enables you to come back later and view
                  your likes and recommendations
                </p>
              </>
            ) : triggeredByReason === Reason.RecsInteraction ? (
              <>
                <h1>Hold on...</h1>
                <p className="heading-text">
                  You'll first need to create a profile in order to record your
                  feedback
                </p>
              </>
            ) : triggeredByReason === "PersonalShopperFlow" ? (
              <>
                <h1>Excellent!</h1>
                <p className="heading-text">
                  We'll first need you to register some details so that we can
                  be in touch and connect your activity with your profile
                </p>
              </>
            ) : triggeredByReason === Reason.RegisterBeforeCheckout ? (
              <>
                <h1>Hold on...</h1>
                <p className="heading-text">
                  Please create or sign into your account before placing an order
                </p>
              </>
            ) : triggeredByReason === Reason.RegisterBeforeSQ ? (
              <>
                <h1>Let's get going</h1>
                <p className="heading-text">
                  Please create (or login to) a Flitch profile so that our stylist can send you your suggestions
                </p>
              </>
            ) : triggeredByReason === Reason.RegisterBeforeUpgrade ? (
              <>
                <h1>One thing first...</h1>
                <p className="heading-text">
                Before upgrading to our human stylist account, please create (or login to) a basic Flitch profile
                </p>
              </>
            ) : (
              <>
                <h1>One last thing...</h1>
                <p className="heading-text">
                  Please create – or login to – your account in order to see
                  your recommendations
                </p>
              </>
            )}
            <>
              <IconButton
                icon={faEnvelopeSquare}
                bgColor={colors.greyDark}
                onClick={switchToEmailForSignIn}
              >
                Continue with Email
              </IconButton>

              <IconButton
                icon={faFacebookSquare}
                bgColor="#3B5C9E"
                onClick={() => {
                  continueWithFacebook()
                    .then(triggerSignupEvent)
                    .catch(console.error);
                }}
              >
                Continue with Facebook
              </IconButton>

              <GoogleAuthButton
                onClick={() => {
                  continueWithGoogle()
                    .then(triggerSignupEvent)
                    .catch(console.error);
                }}
              >
                <GoogleIcon />
                Continue with Google
              </GoogleAuthButton>

              {/* <IconButton
                icon={faGoogle}
                bgColor="#3E8AF3"
                onClick={() => {
                  continueWithGoogle()
                    .then(triggerSignupEvent)
                    .catch(console.error);
                }}
              >
                Continue with Google
              </IconButton> */}
            </>
          </>
        ) : modalState.type === EnterEmailForSignIn ? (
          <>
            <h1>Please enter your email address</h1>
            <EmailLoginForm
              onSubmit={(e) => {
                e.preventDefault();
                submitEmailField(modalState.email);
              }}
            >
              <InputField
                type="email"
                value={modalState.email}
                onChange={updateState(changeEmail)}
                placeholder="Email"
                name="email"
                id="email"
                required
              />

              <SubmitButton type="submit" disabled={modalState.email === ""}>
                Continue
              </SubmitButton>
            </EmailLoginForm>

            <ConditionalFailMsg msg={modalState.loginErrorMsg} />
          </>
        ) : modalState.type === FirstSignIn ? (
          <>
            <h1>
              And now for some final few details so that we can create your
              account
            </h1>
            <EmailLoginForm
              onSubmit={(e) => {
                e.preventDefault();
                registerWithEmail(
                  modalState.email,
                  modalState.password,
                  modalState.firstName
                );
              }}
            >
              <InputField
                type="email"
                value={modalState.email}
                onChange={updateState(changeEmail)}
                placeholder="Email"
                name="email"
                id="email"
                required
              />
              <InputField
                type="text"
                value={modalState.firstName}
                onChange={({ target }) =>
                  modalStateSet((s) => ({ ...s, firstName: target.value }))
                }
                placeholder="First Name"
                name="first-name"
                id="first-name"
                required
              />

              <InputField
                hasError={!!modalState.loginErrorMsg}
                type="password"
                value={modalState.password}
                onChange={setPwAndCancelErr}
                placeholder="Password"
                name="password"
                required
              />

              <InputField
                hasError={!!modalState.loginErrorMsg}
                type="password"
                value={modalState.confirmPw}
                onChange={setPwConfirmAndCancelErr}
                placeholder="Confirm Password"
                name="confirmPassword"
                required
              />

              <SubmitButton
                type="submit"
                disabled={
                  !(
                    modalState.email !== "" &&
                    modalState.password !== "" &&
                    modalState.firstName !== "" &&
                    modalState.password !== "" &&
                    modalState.confirmPw === modalState.password
                  )
                }
              >
                Continue
              </SubmitButton>
            </EmailLoginForm>

            <ConditionalFailMsg msg={modalState.loginErrorMsg} />
          </>
        ) : modalState.type === AccountAlreadyExists ? (
          <>
            <h1>
              It looks like you already have an account...let's log you in
              instead
            </h1>
            <EmailLoginForm
              onSubmit={(e) => {
                e.preventDefault();
                loginWithEmail(modalState.email, modalState.password);
              }}
            >
              <InputField
                type="email"
                value={modalState.email}
                onChange={updateState(changeEmail)}
                placeholder="Email"
                name="email"
                id="email"
                required
              />
              <InputField
                hasError={!!modalState.loginErrorMsg}
                type="password"
                value={modalState.password}
                onChange={setPwAndCancelErr}
                placeholder="Password"
                name="password"
                required
              />

              <SubmitButton
                type="submit"
                disabled={
                  !(modalState.email !== "" && modalState.password !== "")
                }
              >
                Continue
              </SubmitButton>
            </EmailLoginForm>

            <ConditionalFailMsg msg={modalState.loginErrorMsg} />

            <BelowContinueText>
              Please{" "}
              <ButtonLink onClick={switchToForgotPw}>click here</ButtonLink> if
              you need a password reset
            </BelowContinueText>
          </>
        ) : modalState.type === ForgotPassword ? (
          <>
            <h1>Forgotten your password?</h1>
            <p className="heading-text">
              Don't worry – this happens to all of us at some point
            </p>
            <EmailLoginForm
              onSubmit={(e) => {
                e.preventDefault();
                auth()
                  .sendPasswordResetEmail(modalState.email)
                  .then(switchToPwResetLogin);
              }}
            >
              <InputField
                type="email"
                value={modalState.email}
                onChange={updateState(changeEmail)}
                placeholder="Email"
                name="email"
                required
              />

              <SubmitButton type="submit" disabled={modalState.email === ""}>
                Continue
              </SubmitButton>
            </EmailLoginForm>

            <ConditionalFailMsg msg={modalState.loginErrorMsg} />
          </>
        ) : modalState.type === PasswordResetLogin ? (
          <>
            <h1>We've now emailed you a password reset link</h1>
            <p className="heading-text">
              Once you've reset your password come back here to continue
            </p>
            <EmailLoginForm
              onSubmit={(e) => {
                e.preventDefault();
                loginWithEmail(modalState.email, modalState.password);
              }}
            >
              <InputField
                type="email"
                value={modalState.email}
                onChange={updateState(changeEmail)}
                placeholder="Email"
                name="email"
                required
              />

              <InputField
                hasError={!!modalState.loginErrorMsg}
                type="password"
                value={modalState.password}
                onChange={setPwAndCancelErr}
                placeholder="Password"
                name="password"
                required
              />

              <SubmitButton
                type="submit"
                disabled={
                  !(modalState.email !== "" && modalState.password !== "")
                }
                onClick={() =>
                  loginWithEmail(modalState.email, modalState.password)
                }
              >
                Continue
              </SubmitButton>
            </EmailLoginForm>

            <ConditionalFailMsg msg={modalState.loginErrorMsg} />
          </>
        ) : (
          <>
            <h1>Great! We'll be in touch via email</h1>
            <p className="heading-text">
              We generally aim to do this within one business day
            </p>
          </>
        )}
      </GappedRow>
    </ModalContentWrapper>
  );
};
