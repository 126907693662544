import React, { FC } from "react";
import styled from "styled-components";
import { fontSizes, colors } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowAltCircleLeft,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";

export const ModalContentWrapper = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 1.5rem;
  color: black;
  padding: 1.5rem;
  margin: 1rem;
  position: relative;
  border-radius: 10px;

  @media (min-width: ${breakpoints.mobileS}) {
    max-width: 400px;
  }

  h1 {
    /* Margin for the header to leave space for the close icon in top right */
    margin: 1.5rem 1.5rem 0;
    font-weight: bolder;
    text-align: center;
    font-size: ${fontSizes.medium};
  }
  p.heading-text {
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px; // to visually make it look more balanced
  }
`;

export const CloseModalButton: FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => (
  <button
    css={css`
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    `}
    {...props}
  >
    <FontAwesomeIcon icon={faTimesCircle} color="#ABABAB" size="2x" />
  </button>
);

export const GoBackLink = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 15px;
  color: ${colors.greyMediumDarker};
  :hover {
    text-decoration: underline
  }
`;

export const BackModalButton: FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => (
  <button
    css={css`
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 10px;
    `}
    {...props}
  >
    <FontAwesomeIcon icon={faArrowAltCircleLeft} color="#ABABAB" size="2x" />
  </button>
);

export const IconButton: FC<
  {
    icon: IconDefinition;
    bgColor: string;
    iconColor?: string;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ icon, bgColor, iconColor = "white", children, ...rest }) => (
  <button
    css={css`
      background: ${bgColor};
      display: flex;
      align-items: center;
      cursor: pointer;
      border-width: 0;
      border-radius: 0.5rem;
    `}
    {...rest}
  >
    <div
      css={css`
        margin: 10px;
      `}
    >
      <FontAwesomeIcon icon={icon} color={iconColor} size="2x" />
    </div>

    <p
      css={css`
        flex-grow: 1;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: ${fontSizes.medium};
      `}
    >
      {children}
    </p>
  </button>
);

export const GappedRow = styled.div<{ gap?: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ gap = 10 }) => gap}px;
`;
