
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { customVerticalScrollbar } from "../../../pages/RecommendationsPage/RecommendationsPageStyled";
import { colors, fontSizes } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";
  
export const CheckoutDescription = styled.div`
  display: block;
  font-size: ${fontSizes.medium};
  color: ${colors.greyMediumDarker};
  padding: 0 2%;
  overflow-y: auto;
  scroll-behavior: auto;
  scroll-snap-type: y mandatory;
  
  @media (min-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.mediumLarger};
    scroll-snap-type: initial;
    ${customVerticalScrollbar}
  }
`;

export const TCLink = styled(Link)`
  text-decoration: underline;
  color: ${colors.greyDark};
`;

export const FoldBlockContainer = styled.div`
  margin-bottom: 20px;
`;

export const FoldBlockHeader = styled.div`
  background-color: ${colors.greyPale};
  display: grid;
  grid-template-columns: 90% 1fr;
  padding: 5px 0;
  padding-left: 10px;

  .text {
     margin-left: 7px;
  }
`;

export const FoldBlockButton = styled.button`
  border-style: none;
  cursor: pointer;
  background-color: ${colors.greyPale};
  color: ${colors.greyMediumDarker};
`;

export const FoldBlockContent = styled.div<{folded: boolean}>`
  display: ${({folded}) => folded ? "none" : "block"};
  outline-width: 1px;
  outline-style: solid;
  outline-color: ${colors.greyPale};
  background-color: ${colors.greyLighter};
  padding: 5px 10px;
  font-size: ${fontSizes.medium};
  a {
    text-decoration: underline;
  }
`;

export const ContentTableCaptions = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 2fr;
  font-size: ${fontSizes.small};
  font-weight: 600;
`;

export const ContentTableRow = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 2fr;
  border-top: 1px solid ${colors.greyMediumLighter};
  font-size: ${fontSizes.small};
`;

export const ContentTableRowHeader = styled.div`
  display: grid;
  justify-items: left;
  justify-self: left;
  margin-left: 3px;
`;


export const ContentTable = styled.div`
  margin: 5px;
  padding: 2px;
  display: grid;
  row-gap: 2px;
  
  font-size: ${fontSizes.small};

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  border: 2px solid ${colors.greyMediumLighter};
`;
