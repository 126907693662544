import {FC, useEffect, useState} from "react";
import moment from 'moment';
import { ButtonPanel, Header, PlaceholderLabel, TooltipCss, TooltipHeaderCss, UpdateButton } from './PriceChartStyles'
import { CartesianGrid, XAxis, YAxis, ComposedChart, Area, Tooltip, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import { colors } from "../../../styles/globalStyled";
import { faBell, faBellSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SaveButtonTextStyled, SaveButtonStyled } from "../RecommendationsPageStyled";
import { getDateRange, normalizePriceLabel } from "../../../utils/helpers";
import { random } from "lodash";
import { getPriceHistory, sortByDateAsc } from "../../../utils/priceHistoryUtils";
import { checkIfProductWatched, saveSingleAction } from "../../../services/api.service";
import { useUserContext } from "../../../store/userContext";

const Spacer = require('react-spacer');


interface PriceChartProps {
  item: IFurnitureItem | undefined;
  createWatch: () => Promise<void>
  deleteWatch: () => Promise<void>
}

export const PriceChart: FC<PriceChartProps> = ({
  item, 
  createWatch,
  deleteWatch
}) => {
  const { user, openAuthModal } = useUserContext();
  const [fakeData, setFakeData] = useState([] as PriceHistoryEntry[])

  const [historyActionSaved, setHistoryActionSaved] = useState(false);
  const [ history, setHistory ] = useState([] as PriceHistoryEntry[]);
  const [ historyPeriod, setHistoryPeriod ] = useState("3months" as HistoryPeriodUnion);

  const updateIsWatched = () => 
    checkIfProductWatched(item?.guid)
      .then(setIsWatched)
      .catch(console.error);

  const [ checkedItem, setCheckedItem ] = useState(item);
  const [ checkedUser, setCheckedUser ] = useState(user);

  const [isWatched, setIsWatched] = useState(false);
  useEffect(() => { 
    if (!item) return;
    if (!user) return;
    if (item === checkedItem && user === checkedUser) return;
    
    setCheckedItem(item);
    setCheckedUser(user);
    updateIsWatched();
  }, [item, user]);
  
  useEffect(() => {
    updateHistory("3months");
  }, [user]);

  const updateHistory = (period: HistoryPeriodUnion) => {
    if (!item) return;
    setHistoryPeriod(period);
    getPriceHistory(item, period)
      .then(setHistory)
      .catch(err => console.error ("Could not update price history", err))
  }

  const update = (period: HistoryPeriodUnion) => {
    if (!item) return;
    updateHistory(period);
    
    if (historyActionSaved) return;
    saveSingleAction("history", item.category ?? "all", item.id)
      .then(() => setHistoryActionSaved(true));
  }

  useEffect(() => {
    if (fakeData.length > 0 && (!history || history.length === 0))
      return;

    if (fakeData.length > 0 && history?.length > 0)
      setFakeData([]);

    setFakeData(mockData());
  }, [history])

  const formatDate = (date: Date, useYear = false) => 
    moment(date).format(`MMM DD${useYear ? " YYYY" : ""}`);

  const currentPrice = history[history.length-1]?.price;

  const tickCount = 5;

  const roundStep = (step: number) => {
    if (step === 0) return 0;

    const stepOptions = [5, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250, 350, 400, 500, 600, 700, 800, 900];

    for(let i = 0; i < stepOptions.length; i++){
      if (step <= stepOptions[i]!)
        return stepOptions[i]!;
    }
       
    return 1000;
  }

  const noData = !history || history.length == 0;

  const calculateYAxis = () => {
    const maxPrice = history.map(e => e.price).sort((a, b) => b - a)[0] ?? 0;
    const stepsAboveZero = tickCount - 1;
    const rawStep = (maxPrice * 1.05) / (stepsAboveZero);

    const step = rawStep < 1000 
      ? roundStep(rawStep) 
      : Math.ceil(rawStep / 1000) * 1000;

    const steps = [...Array(tickCount).keys()].map(x => x * step);
    return [step * stepsAboveZero, steps] as const;
  };
  const [topDomain, ticks] = noData
    ? [1000, [0, 250, 500, 750, 1000]]  
    : calculateYAxis();


  const placeholder = (
    <PlaceholderLabel>
      <p className="label-body">
        Unfortunately we don’t have pricing data<br/>for this time period;<br/>please select another time period
        {/* Unfortunately we don't have enough <br/> historic data on this product */}
      </p>
    </PlaceholderLabel>)

  const mockData = () => 
    getDateRange(10, 0).map(d => {
      return {
        affiliateId: "",
        retailerLookup: undefined,
        date: d,
        price: random(500, 1000)
      } as PriceHistoryEntry
    }).sort(sortByDateAsc);

  const saveWattchAndAction = () => 
    createWatch()
      .then(updateIsWatched)
      .then(() => saveSingleAction("drop", item?.category ?? "all", item?.id ?? ""))

  const deleteWatxhAndUpdate = () => 
  deleteWatch().then(updateIsWatched)

  const pwAction = () => {
    if (!user) return openAuthModal();

    isWatched 
      ? deleteWatxhAndUpdate().catch(console.error)
      : saveWattchAndAction().catch(console.error);
  };
  
  const chart = (
    <>
      <div>
        {!noData && (<SaveButtonStyled
          isSaved={isWatched}
          onClick={pwAction}
          padding={5}
        >
          <FontAwesomeIcon icon={isWatched ? faBellSlash : faBell} size="1x" color="white" />
          <SaveButtonTextStyled> {isWatched ? "Stop watching this item" : "Notify me when on sale"} </SaveButtonTextStyled>
        </SaveButtonStyled>)}
        <Spacer height="20px" />
      </div>
      <ResponsiveContainer width="90%" height={250}>
        <ComposedChart data={noData ? fakeData : history} margin={{left: -20, right: 20}}>
          <Area 
            type="stepAfter" 
            dataKey="price" 
            stroke={noData ? colors.greyLight : colors.orange} 
            fill={noData ? colors.greyLighter : colors.peach}
            strokeWidth={2} 
            isAnimationActive={true}
            animationDuration={400}
          />
          <ReferenceLine y={currentPrice} strokeDasharray="4 1 2" stroke={colors.orange} />
          <CartesianGrid stroke={colors.greyPale} vertical={false}/>
          {!noData && (<Tooltip 
            itemStyle={TooltipHeaderCss} 
            labelStyle={TooltipCss} 
            formatter={(val: number) => [`${normalizePriceLabel(val)}`, undefined]} 
            labelFormatter={val => formatDate(val, true)}/>)}
          <XAxis 
            dataKey="date" 
            fontSize={10} 
            strokeWidth={0} 
            tickFormatter={val => formatDate(val)} minTickGap={15} />
          <YAxis 
            dataKey="price" 
            fontSize={10} 
            strokeWidth={0} 
            tickFormatter={val => `${normalizePriceLabel(val)}`} 
            domain={[0, topDomain]} 
            ticks={ticks}
            tickCount={tickCount}/>
        </ComposedChart>
      </ResponsiveContainer>

      <ButtonPanel className={`width: 100px;`}>
        <UpdateButton isSelected={historyPeriod==="1month"} onClick={() => update("1month")}>1 month</UpdateButton>
        <UpdateButton isSelected={historyPeriod==="3months"} onClick={() => update("3months")}>3 months</UpdateButton>
        <UpdateButton isSelected={historyPeriod==="6months"} onClick={() => update("6months")}>6 months</UpdateButton>
        <UpdateButton isSelected={historyPeriod==="all"} onClick={() => update("all")}>All</UpdateButton>
      </ButtonPanel>
    </>
  )

  return(
    <>
      <Header>Price History</Header>
      {chart}
      {noData && placeholder}
      {/* {(!history || history.length == 0) ? placeholder : chart} */}
    </>

  )
}