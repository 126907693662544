import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faHeart as faSolidHeart,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useSavedState } from "../../store/savedsContext";
import { ActionButton } from "./RecommendationsPageStyled";
import { colors } from "../../styles/globalStyled";
import { deletePriceWatch } from "../../services/api.service";

interface Props {
  item: IFurnitureItem;
  affiliateId: string,
  category: CategoryUnion;
  disabled: boolean;
}

export const SaveForLaterButtonNew: FC<Props> = ({ item, affiliateId, category, disabled }) => {
  const { state, toggleSavedOrAuth } = useSavedState(
    item,
    category
  );

  const isLoading = state === "ChangingSavedState";
  const isSaved = state === "IsSaved";

  const toggleSaved = async () => {
    if (isSaved)
      await deletePriceWatch(affiliateId, category)
    toggleSavedOrAuth(true, true);
  }

  return (
    <ActionButton
      color={colors.green}
      size="sm"
      onClick={toggleSaved}
      disabled={disabled || isLoading}
    >
      <FontAwesomeIcon
        icon={isSaved ? faSolidHeart : faHeart}
        color="white"
        size="2x"
      />
    </ActionButton>
  );
};
