import styled from "styled-components";
import { colors } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";

export const StyleQuizPageWrapper = styled.div`
  padding-top: var(--navbar-height);

  /*@media (max-width: ${breakpoints.mobile}) {
    padding-top: calc(var(--navbar-height-mobile) * 2);
  }*/

  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`;

export const StyleQuizPageContainer = styled.div`
  grid-area: container;
  display: grid;
  flex-direction: column;
  background-color: ${colors.greyLight};
  justify-content: center;
  padding-top: 2rem;
  section {
    height: 7rem;
  }
`;
