import styled, { css } from "styled-components";
import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";

import backDesktop from "../../images/recsPage/stylist/Desktop.jpg";


export const PostCheckoutPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  position: relative;

  @media (min-width: ${breakpoints.mobile}) {
    background-image: url(${backDesktop});
    position: absolute;
    top: var(--navbar-height);
    left: 0;
    right: 0;
    bottom: 0;

    &::-webkit-scrollbar {
      height: 0; /*height for horizontal scrollbars!*/
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    position: absolute;
    top: var(--navbar-height-mobile);
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const MessageContainer = styled.div`
  background-color: white;
  margin: auto 4vw auto auto;
  /* width: 40vw; */
  width: 700px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  letter-spacing: -0.8px;

  @media (max-width: ${breakpoints.mobile}) {
    margin: auto 5%;
    width: 90vw;
    height: auto;
  }

  h1 {
    font-weight: 900;
    text-align: center;
    font-size: ${fontSizes.veryLarge};
    margin: 5% 20%;
    color: ${colors.greyDark};
    letter-spacing: -2px;
    line-height: 1;

    @media (max-width: ${breakpoints.mobile}) {
      color: ${colors.greyDark};
      text-align: left;
      font-size: ${fontSizes.hsDescription};
      font-weight: 600;
      margin: 5% 15% 5% 5%;
      line-height: 1.2;
    }
  }
`;

export const NumberedLabel = styled.div`
  display: grid;
  grid-template-columns: 10% 1fr;
  margin: 0 10% 5% 5%;

  @media (max-width: ${breakpoints.mobile}) {
    grid-template-columns: 15% 1fr;
  }

  p {
    color: ${colors.greyDark};
    font-size: ${fontSizes.mediumLarger};
    letter-spacing: -1px;
    line-height: 1.2;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.medium};
      line-height: 1.3;
    }
  }

  a {
    text-decoration: underline;
  }
`;

export const Number = styled.div`
  font-size: ${fontSizes.hsDescription};
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${colors.green};
  border-radius: 100%;
  /* margin: 0 auto; */
  padding: 5px;
  width: 45px;
  height: 45px;

  @media (max-width: ${breakpoints.mobile}) {
    width: 35px;
    height: 35px;
    margin-bottom: 5%;
    font-size: ${fontSizes.large};
    transform:translateY(50%);
  }
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  margin: 0 5% 5%;
  box-shadow: 0 3px 10px 2px rgba(0,0,0,.15);
`;

export const NotesElemWrapper = styled.div<{visible: boolean}>`
  display: ${({visible}) => visible ? "flex" : "none"};
  flex-direction: column;

  p {
    font-size: ${fontSizes.large};
    font-weight: 600;
    color: ${colors.greyDark};
    text-align: left;
    padding: 3% 3% 1%;
  }

  button {
    margin: 3%;
    padding: 1% 0;
    border-style: none;
    border-radius: 2%;
    cursor: pointer;
    font-weight: bold;
    font-size: ${fontSizes.larger};
    color: white;
    background-color: ${colors.green};

    @media (max-width: ${breakpoints.mobile}) {
      margin: 6.5vw;
      padding: 3%;
      font-size: ${fontSizes.large};
    }
  }

  textarea::placeholder {
    color: ${colors.greyMediumDarker};
    font-size: ${fontSizes.mediumLarger};
    font-weight: 300;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.medium};
    }
  }
  
  textarea {
    height: 7em;
    color: ${colors.greyDark};
    font-size: ${fontSizes.mediumLarger};
    font-weight: 300;
    margin: 0 3%;
    resize: none;
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    @media (max-width: ${breakpoints.mobile}) {
      height: 5em;
      font-size: ${fontSizes.medium};
    }
  }
`;

export const NotesStripe = styled.div`
  background-color: ${colors.green};
  width: 100%;
  height: 8px;
`;

export const NoteSentMessage = styled.div<{visible: boolean}>`
  display: ${({visible}) => visible ? "block" : "none"};
  color: white;
  background-color: ${colors.greenPale};
  height: 26em;

  @media (max-width: ${breakpoints.mobile}) {
    height: 30vh;
  }

  p {
    margin: auto;
    text-align: center;
    font-size: ${fontSizes.hsDescription};
    font-weight: 600;
    line-height: 1.2;
    transform:translateY(130%);

    @media (max-width: ${breakpoints.mobile}) {
      position: relative;
      transform:translateY(150%);
      font-size: ${fontSizes.large};
    }
  }
`;
