import React, { FC } from "react";
import { triggerBuyNowEvent } from "../../../dataLayer";
import { postPriceWatch, saveSingleAction } from "../../../services/api.service";
import { useAnalyticsContext } from "../../../store/analyticsContext";
import { getClickRef, getProgramByRetailer } from "../../../utils/helpers";
import { BuyNowLinkStyled } from "../RecommendationsPageStyled";

interface BuyNowButtonProps {
  item: IFurnitureItem;
  /**
   * `score` is only present on Recs page, so analytics should only be sent when
   * it is present.
   */
  score?: number;
  isOutOfStock?: boolean;
  customerId: string;
  userId: string | undefined;
  category: CategoryUnion;
}

const getRefName = (program: ProgramUnion) => {
  switch (program) {
    case "Adtraction": return "epi";
    case "Impact": return "subId1";
    case "Partnerize": return "pubref";
    case "Rakuten": return "u1";
    case "CJ": return "SID";
    case "Awin": case "Webgains": case "DirectSelling": return "clickref";
    default: throw "Program handling not implemented";
  }
};

const generateClickrefUrl = (
  item: IFurnitureItem, 
  userId: string | undefined
) => {
  const program = getProgramByRetailer(item.brand as RetailerUnion);
  const clickref = getClickRef(userId, item.id);
  
  switch (program) {
    case "Partnerize": 
      return item.referralLink.replace("destination:", `pubref:${clickref}/destination:`);
    
    case "Webgains":
      return item.referralLink.replace("productname=", `clickref=${clickref}&productname=`);
      
    default: 
      const refName = getRefName(program);
      return `${item.referralLink}&${refName}=${clickref}`;
  }
}

export const BuyNowButton: FC<BuyNowButtonProps> = ({
  item,
  score,
  isOutOfStock,
  customerId,
  userId,
  category,
}) => {
  const { addAnalyticsBuyNowClick } = useAnalyticsContext();
  
  const active = item.isRetailerOperating
  
  const url = generateClickrefUrl(item, userId);
  
  const handleBuyNowClick = () => {
    if (!active) return;

    saveSingleAction("fom", category, item.id);
    triggerBuyNowEvent();
    postPriceWatch(item);

    // addAnalyticsBuyNowClick
    //   ? () => addAnalyticsBuyNowClick(item.id, category, score)
    //   : () => {
    //     saveSingleAction("fom", category, item.id);
    //     triggerBuyNowEvent();
    //     postPriceWatch(item);
    //   }}
  }
  
  return (
    <BuyNowLinkStyled
      href={url}
      onClick={handleBuyNowClick}
      isActive={active}
      target={getProgramByRetailer(item.retailer as RetailerUnion) === "Webgains" ? "" : "_blank"}
      rel="noreferrer noopener"
    >
      {isOutOfStock ? (
        <>Find Out More </>
      ) : (
        <span className="buy-now-text">Find Out More </span>
      )}
      from {item.brand}
    </BuyNowLinkStyled>
  );
};
