import { css } from "@emotion/react";
import styled from "styled-components";
import { customVerticalScrollbar } from "../../../../pages/RecommendationsPage/RecommendationsPageStyled";
import { colors, fontSizes } from "../../../../styles/globalStyled";
import { breakpoints } from "../../../../styles/responsive";

interface SerachWrapperProps {
  focused: boolean;
}

const SearchBoxShadow = (props: SerachWrapperProps) =>
  props.focused ? `0 0 4px ${colors.greyDark}` : "none";

const SearchBoxBorderColor = (props: SerachWrapperProps) =>
  props.focused ? colors.greyMediumDarker : colors.greyMedium;

const SearchBoxZIndex = (props: SerachWrapperProps) =>
  props.focused ? 5 : 1;

export const SearchWrapper = styled.div`
  position: relative;
`;

export const SerachBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -12px;
  width: 550px;
  right: 0;
  /* border: 1px solid ${SearchBoxBorderColor}; 
  border-radius: 5px; */
  transition: all .35s;
  box-shadow: ${SearchBoxShadow};
  background-color: ${colors.greyLighter};
  z-index: ${SearchBoxZIndex};

  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const SearchLine = styled.div`
  width: 100%;
  height: 28px;
  display: grid;
  grid-template-columns: 7% 1fr 7%;
  color: ${colors.greyMediumDarker};
`;

export const LineItemContainer = styled.div`
  position: relative;
`;

export const MagnifyStyle = css`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const SearchInput = styled.input<{color?:string}>`
  border: none;
  padding-right: 2%;
  color: ${colors.greyMediumDarker};
  font-size: ${fontSizes.small};
  background-color: ${({color}) => color ?? colors.greyLighter};

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${colors.greyMedium};
  }

  ::-ms-input-placeholder {
    color: ${colors.greyMedium};
  }
`;

interface RoundButtonProps {
  radius: number;
  visible: boolean;
}

const buttonRadius = (props: RoundButtonProps) =>
  `${props.radius}px`;

const buttonOpacity = (props: RoundButtonProps) =>
  +props.visible;

export const RoundButton = styled.button<RoundButtonProps>`
  position: absolute;
  border: 1px solid ${colors.greyMediumDarker};
  color: ${colors.greyMediumDarker};
  border-radius: 100%;
  width: ${buttonRadius};
  height: ${buttonRadius};
  opacity: ${buttonOpacity};
  background-color: white;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all .35s;

  :hover {
    color: ${colors.red};
    border-color: ${colors.red};
  }
`;

export const SearchBackdropWrapper = styled.div`
  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const SearchOptionsList = styled.div<{visible: boolean}>`
  position: relative;
  display: ${({visible}) => visible ? "flex" : "none"};
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: auto;
  scroll-snap-type: y mandatory;

  @media (min-width: ${breakpoints.mobile}) {
    scroll-snap-type: initial;
    ${customVerticalScrollbar}
  }
`;

export const Separator = styled.hr`
margin: 0;
height: 1px;
background-color: ${colors.greyPale};
width: auto;
`;

export const SearchOption = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 7% 1fr;
  font-size: ${fontSizes.small};
  color: ${colors.greyDark};
  cursor: pointer;
  padding: 5px 0;

  transition: background-color .35s;

  :hover{
    background-color: ${colors.biege};
  }
`;
