import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Range from "rc-slider";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { allFilterRanges } from "../../store/categoryFilters";
import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";
import {
  RangeSliderProps,
  rcSliderRangeStyles,
} from "../SQFilterPage/FilterButtons";
import { basicButtonStyle } from "./RecommendationsPageStyled";

interface FilterPanelProps {
  isOpen: boolean;
}

export const FilterPanelBackdrop = styled.div<FilterPanelProps>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, ${({isOpen}) => isOpen ? .5 : 0});
  z-index: 3;
  transform: translateX(${({ isOpen }) => (isOpen ? 0 : 100)}%);
  backdrop-filter: brightness(90%);
  transition: background .65s;
`;

const FilterPanelStyled = styled.div<FilterPanelProps>`
  position: fixed;
  width: 75vw;
  right: 0;
  top: var(--navbar-height);
  bottom: 0;
  transform: translateX(
    ${({ isOpen }) => (isOpen ? "0" : "105%")}
  ); // 105% so its box-shadow doesn't fall on the rest of the page because of being too close if it would be exactly 100%
  background-color: white;
  box-shadow: var(--box-shadow);
  z-index: 3;
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.mobile}) {
    top: var(--navbar-height-mobile);
  }

  @media (min-width: ${breakpoints.mobile}) {
    width: 500px;
  }
`;

const FilterPanelCloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button.close-filter-panel-button {
    ${basicButtonStyle}
    margin: 15px 25px 0 0;
    background: none;
  }
`;

const FilterPanelContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
`;

const FilterPanelSectionStyled = styled.div`
  padding: 25px;
`;

const FilterPanelSectionTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .filter-panel-section-header {
    text-transform: uppercase;
    font-size: ${fontSizes.small};
  }

  .value-indicator {
    font-size: ${fontSizes.small};
    font-weight: bold;
    width: 30%; // Needs to be a fixed size so it doesn't shuffle the header around as it changes
  }

  // The 2nd indicator is the 3rd child.
  // Only necessary because it has a fixed width.
  .value-indicator:nth-child(3) {
    text-align: right;
  }
`;

const RangeContainer = styled.div`
  ${rcSliderRangeStyles}
`;

const FilterPanelSection: FC<{ label: string } & RangeSliderProps> = ({
  label,
  values,
  minValue,
  maxValue,
  step,
  onChange,
  unit = "",
  unitIsPrefixed,
}) => {
  const withUnit = (n: number | string) =>
    unitIsPrefixed ? `${unit}${n}` : `${n}${unit}`;

  const [a, b] = values ?? ["", ""];

  return (
    <FilterPanelSectionStyled>
      <FilterPanelSectionTopRow>
        <p className="value-indicator">{withUnit(a)}</p>
        <h4 className="filter-panel-section-header">{label}</h4>
        <p className="value-indicator">{withUnit(b)}</p>
      </FilterPanelSectionTopRow>
      <RangeContainer>
        <Range
          value={values}
          min={minValue}
          max={maxValue}
          step={step}
          onChange={onChange}
        />
      </RangeContainer>
    </FilterPanelSectionStyled>
  );
};

const FilterButtonRowStyled = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;

  & > .filter-panel-button {
    ${basicButtonStyle}
    flex-grow: 1;
    font-size: 2rem;
    padding: 10px;
    font-weight: bold;
    box-shadow: var(--box-shadow);
  }

  & > .filter-panel-button--apply {
    background-color: ${colors.orange};
    margin-right: 10px;
  }

  & > .filter-panel-button--cancel {
    background-color: ${colors.green};
  }
`;

const HrStyled = styled.hr`
  margin: 0 15px;
`;

interface RecsFilterProps extends FilterPanelProps {
  category: CategoryUnion;
  filterOption: AnyFilterOpts;
  closePanel: VoidFunction;
  setProp: <C extends CategoryUnion, N extends keyof CategoryToFilterOpts[C]>(
    cat: C,
    name: N
  ) => (
    setStateAction: React.SetStateAction<CategoryToFilterOpts[C][N]>
  ) => void;
}

export const FilterPanel: FC<RecsFilterProps> = ({
  isOpen,
  category,
  filterOption: filterOpt,
  closePanel,
  setProp,
}) => {
  const fltrRanges = allFilterRanges[category];
  const { budget, height, width, depth } = fltrRanges;

  const [minBudget, minBudgetSet] = useState(filterOpt.minBudget);
  const [maxBudget, maxBudgetSet] = useState(filterOpt.maxBudget);
  const [minHeight, minHeightSet] = useState(filterOpt.minHeight);
  const [maxHeight, maxHeightSet] = useState(filterOpt.maxHeight);
  const [minWidth, minWidthSet] = useState(filterOpt.minWidth);
  const [maxWidth, maxWidthSet] = useState(filterOpt.maxWidth);
  const [minDepth, minDepthSet] = useState(filterOpt.minDepth);
  const [maxDepth, maxDepthSet] = useState(filterOpt.maxDepth);

  const setBudgetRange = ([a, b]: NumRange) => {
    minBudgetSet(a);
    maxBudgetSet(b);
  };
  const setHeightRange = ([a, b]: NumRange) => {
    minHeightSet(a);
    maxHeightSet(b);
  };
  const setWidthRange = ([a, b]: NumRange) => {
    minWidthSet(a);
    maxWidthSet(b);
  };
  const setDepthRange = ([a, b]: NumRange) => {
    minDepthSet(a);
    maxDepthSet(b);
  };

  const resetToProps = () => {
    minBudgetSet(filterOpt.minBudget);
    maxBudgetSet(filterOpt.maxBudget);
    minHeightSet(filterOpt.minHeight);
    maxHeightSet(filterOpt.maxHeight);
    minWidthSet(filterOpt.minWidth);
    maxWidthSet(filterOpt.maxWidth);
    minDepthSet(filterOpt.minDepth);
    maxDepthSet(filterOpt.maxDepth);
  };

  // Reset filters when props have changed
  useEffect(resetToProps, [filterOpt]);

  const saveAllValues = () => {
    setProp(category, "minBudget")(minBudget);
    setProp(category, "maxBudget")(maxBudget);
    setProp(category, "minHeight")(minHeight);
    setProp(category, "maxHeight")(maxHeight);
    setProp(category, "minWidth")(minWidth);
    setProp(category, "maxWidth")(maxWidth);
    setProp(category, "minDepth")(minDepth);
    setProp(category, "maxDepth")(maxDepth);
  };

  return (
    <FilterPanelStyled isOpen={isOpen}>
      <FilterPanelCloseButtonContainer>
        <button className="close-filter-panel-button" onClick={closePanel}>
          <FontAwesomeIcon icon={faTimes} color={colors.greyDark} size="3x" />
        </button>
      </FilterPanelCloseButtonContainer>
      <FilterPanelContents>
        <FilterPanelSection
          label="Budget"
          onChange={setBudgetRange}
          values={[minBudget ?? budget.min, maxBudget ?? budget.max]}
          step={5}
          minValue={budget.min}
          maxValue={budget.max}
          unit="£"
          unitIsPrefixed
        />
        <HrStyled />
        <FilterPanelSection
          label="Height"
          onChange={setHeightRange}
          values={[minHeight ?? height.min, maxHeight ?? height.max]}
          step={5}
          minValue={height.min}
          maxValue={height.max}
          unit="cm"
        />
        <HrStyled />
        <FilterPanelSection
          label="Width"
          onChange={setWidthRange}
          values={[minWidth ?? width.min, maxWidth ?? width.max]}
          step={5}
          minValue={width.min}
          maxValue={width.max}
          unit="cm"
        />
        <HrStyled />
        <FilterPanelSection
          onChange={setDepthRange}
          label="Depth"
          values={[minDepth ?? depth.min, maxDepth ?? depth.max]}
          step={5}
          minValue={depth.min}
          maxValue={depth.max}
          unit="cm"
        />
      </FilterPanelContents>
      <FilterButtonRowStyled>
        <button
          className="filter-panel-button filter-panel-button--apply"
          onClick={() => {
            saveAllValues();
            closePanel();
          }}
        >
          Apply Filters
        </button>
        <button
          className="filter-panel-button filter-panel-button--cancel"
          onClick={() => {
            closePanel();
            resetToProps();
          }}
        >
          Cancel
        </button>
      </FilterButtonRowStyled>
    </FilterPanelStyled>
  );
};
