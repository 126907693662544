import {Md5} from 'ts-md5/dist/md5';
import Cookies from "cookies-ts";

const SESSION_ID = "sesion_id";

const cookies = new Cookies();
cookies.config({
  expires: "1d",
  path: "" //browser default
});

const generateId = () => {
  const sessionId = Md5.hashStr(`${new Date().toString()}${Math.random()}`);
  cookies.set(SESSION_ID, sessionId);
}

const getId = () => {
  if (!cookies.isKey(SESSION_ID))
    generateId();

  return cookies.get(SESSION_ID);
}

export const getSessionId = () => getId()!;