import { FC, useEffect, useState } from "react";
import { FilterPanelBackdrop } from "../../pages/RecommendationsPage/FilterPanel";
import { CartContainer } from "../ShopCart/ShopCartStyles";
import { ReceiptContainer, Tada } from "./ReceiptStyles";
import { SaveButtonStyled } from "../../pages/RecommendationsPage/RecommendationsPageStyled";
import { SidePanelHeader } from "../ShopCart/ShopCart";
import { useShopContext } from "../../store/shopContext";
import { useUserContext } from "../../store/userContext";
import { getOrderNumber } from "../../services/api.service";

export const Receipt: FC = () => {
  const {receiptOpen, toggleReceipt} = useShopContext();
  const { user } = useUserContext();

  const [ orderNo, setOrderNo ] = useState<number|undefined>(undefined);
  
  const close = () => toggleReceipt(false);

  useEffect(() => {
    if (!user) return;
    if (receiptOpen)
      getOrderNumber().then(
        num => setOrderNo(num),
        console.error
      );
  }, [receiptOpen, user])

  return (
      <>
        <FilterPanelBackdrop
          isOpen={receiptOpen}
          onClick={close}
        />
        <CartContainer isOpen={receiptOpen}>
          <SidePanelHeader closeAction={close} />
          <ReceiptContainer>
            <Tada />
            <br/>
            Thanks for your order! 
            <br/>
            Your reference number is <b>{orderNo}</b>
            <br/>
            <br/>
            We'll be in touch within the next 24 hours or so, once we've confirmed your item(s) with our suppliers.
            <br/>
            <br/> 
            If you've got any questions then please be in touch. You can email us at orders@withflitch.com or call us on 0208 058 0705.
          </ReceiptContainer>
          <SaveButtonStyled
            disabled={false}
            isSaved={false}
            onClick={close}
            padding={10}
          >
            Continue Exploring
          </SaveButtonStyled>
        </CartContainer>
      </>
  )
}