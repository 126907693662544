import { FC } from "react";

export const Space = () => (
    <p className="c3">
      <span className="c4" />
    </p>
  );
  
  interface HeaderProps {
    label: string;
  }
  export const SectionHeader: FC<HeaderProps> = ({label}) => (
    <p className="c5">
      <span className="c4">{label}</span>
    </p>
  );