import { FC, useState } from "react";
import { useResolvedPath } from "react-router";
import {
  NavBarWrapper,
  Logo,
  LogoWrapper,
  LogoText,
  RightSide,
  DesktopRoadmap,
  LogoImage,
} from "./NavBarStyled";
import { Sidebar } from "./Sidebar/Sidebar";
import { RoadMap, sqRoutes } from "./RoadMap/RoadMap";
import { ProfileIcon } from "./RightSide/ProfileIcon/ProfileIcon";
import { useMaybeCategory } from "../../hooks/useCategory";
import { ShopCart } from "../ShopCart/ShopCart";
import { ShopCartToggle } from "./RightSide/ShopCartToggle";
import { Receipt } from "../Receipt/Receipt";
import { Search } from "./RightSide/Search/Search";
import { MobileSearch } from "./RightSide/Search/MobileSearch/MobileSearch";
import { useSearchContext } from "../../store/searchContext";
import { MobileHamburger } from "./MobileHamburger/MobileHamburger";
import Spacer from "react-spacer";

export const NavBar: FC<{ isFixed?: boolean }> = ({ isFixed = true }) => {
  const category = useMaybeCategory();
  const url = useResolvedPath("").pathname;

  const { clearSearch } = useSearchContext();

  const toggleSidebar = () => setIsSideBarOpen(!isSidebarOpen);
  const [isSidebarOpen, setIsSideBarOpen] = useState(false);
  const currentlyInSqFlow = !!category && sqRoutes(category).includes(url);

  return (
    <>
      <NavBarWrapper isFixed={isFixed}>
        <LogoWrapper>
          <Logo to="/" onClick={clearSearch} />
          {/* <LogoText to="/" onClick={clearSearch}>Furnish.app</LogoText> */}
        </LogoWrapper>

        <DesktopRoadmap>
          {currentlyInSqFlow && <RoadMap />}
        </DesktopRoadmap>
        <ShopCart/>
        <Receipt />
        <RightSide>
          {!currentlyInSqFlow && <Search />}
          {!currentlyInSqFlow && <MobileSearch />}
          <Spacer width={8} />
          <ShopCartToggle />
          <Spacer width={5} />
          <ProfileIcon />
          <Spacer width={7} />
          <MobileHamburger />
        </RightSide>
        {isSidebarOpen && !currentlyInSqFlow && (
          <Sidebar
          currentlyInSqFlow={currentlyInSqFlow}
          toggleSidebar={toggleSidebar}
          />
          )}
      </NavBarWrapper>
    </>
  );
};

export default NavBar;
