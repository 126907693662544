import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { useSavedState } from "../../../store/savedsContext";
import { 
    getDislikeButtonCss, 
    getLikeButtonCss, 
    ReactionButtonsDivCss } from "./BrowsePageComponentStyles";

export const LikeAndDiscardButtons: FC<{
    item: IFurnitureItem
  }> = ({ item }) => {
    const { state, toggleSavedOrAuth, toggleDiscaredOrAuth } = useSavedState(item, "shop");

    const isSaved = state === "IsSaved";
    const isDiscarded = state === "IsDiscarded";

    const [isSaveDisplayed, setIsSaveDisplayed] = useState(isSaved);
    const [isDiscardDisplayed, setIsDiscardDisplayed] = useState(isDiscarded);

    const [init, setInit] = useState(false);

    useEffect(() => {
      if (!isSaved && !isDiscarded) return;
      if (init) return;

      setIsSaveDisplayed(isSaved);
      setIsDiscardDisplayed(isDiscarded);
      setInit(true);
    }, [isSaved, isDiscarded]) 

    const like = () => {
      setIsSaveDisplayed(!isSaveDisplayed);

      if (!isDiscarded)
        return toggleSavedOrAuth(true, false);
      
      setIsDiscardDisplayed(!isDiscardDisplayed);
      toggleDiscaredOrAuth(false, false);
      setTimeout(() => toggleSavedOrAuth(true, false), 150);
    }

    const dislike = () => {
      setIsDiscardDisplayed(!isDiscardDisplayed);

      if (!isSaved)
        return toggleDiscaredOrAuth(true, false);
        
      setIsSaveDisplayed(!isSaveDisplayed);
      toggleSavedOrAuth(false, false);
      setTimeout(() => toggleDiscaredOrAuth(true, false), 150);
    }
  
    return (
      <div className={ReactionButtonsDivCss}>
        <button
          className={getDislikeButtonCss(isDiscardDisplayed)}
          onClick={dislike}
        >
          {/* @TODO: wire this up and grey out item after discarding */}
          <FontAwesomeIcon icon={faTimes} color="white" size="lg" />
        </button>
    
        <button
          className={getLikeButtonCss(isSaveDisplayed)}
          onClick={like}
        >
          <FontAwesomeIcon icon={faHeart} color="white" size="lg" />
        </button>
      </div>
    );
  }