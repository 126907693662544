import React, { FC } from "react";
import {
  HeadingsWrapper,
  HeadingPrimary,
  HeadingSecondary,
} from "./HeadingsWrapper";
import MediaQuery from "react-responsive";

interface IProps {
  primary: string;
  secondary?: string;
  tertiary?: string;
  sqPageNumber?: number;
}

export const Headings: FC<IProps> = ({ primary, secondary, sqPageNumber }) => (
  <HeadingsWrapper>
    {sqPageNumber === 2 ? ( // Only for SQ2
      <>
        <MediaQuery query="(orientation: landscape)">
          <HeadingPrimary>Click {primary}</HeadingPrimary>
        </MediaQuery>
        <MediaQuery query="(orientation: portrait)">
          <HeadingPrimary>Tap {primary}</HeadingPrimary>
        </MediaQuery>
      </>
    ) : (
      <HeadingPrimary>{primary}</HeadingPrimary>
    )}
    <HeadingSecondary>{secondary}</HeadingSecondary>
  </HeadingsWrapper>
);
