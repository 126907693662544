import { useState, useEffect } from "react";

type VersionedData<T> = {
  version: string;
  data: T;
};

const validateVersion = <T>(
  obj: any,
  version: string
): obj is VersionedData<T> => {
  // To get TS to let me access the fields
  const copy = obj as Partial<VersionedData<T>>;

  return copy.version === version && copy.data !== undefined;
};

const tryParseJson = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return null;
  }
};

const saveVersionedData = <T>(key: string, data: T, version: string) => {
  const versionedData: VersionedData<T> = { version, data };
  localStorage.setItem(key, JSON.stringify(versionedData));
};

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
  version: string = ""
): [T, StateSetter<T>, VoidFunction] => {
  const initialGetter = () => {
    const lsStringValue = localStorage.getItem(key);
    const parsedOrNull = lsStringValue ? tryParseJson(lsStringValue) : null;

    if (parsedOrNull && validateVersion<T>(parsedOrNull, version)) {
      return parsedOrNull.data;
    } else {
      return defaultValue;
    }
  };

  const [value, setValue] = useState<T>(initialGetter);
  const removeItem = () => localStorage.removeItem(key);

  useEffect(() => {
    if (value === undefined || value === null) {
      removeItem();
    } else {
      saveVersionedData(key, value, version);
    }
  }, [key, value]);

  return [value, setValue, removeItem];
};
