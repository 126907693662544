import Icon from "@mdi/react";
import { FC, useEffect, useState } from "react";
import {
  mdiChevronDown, 
  mdiChevronUp } from "@mdi/js";
import { 
  BlurbWrapper, 
  Description, 
  IconStyle, 
  ShowMoreButton } from "./BlurbStyles";

interface Props {
  text: string | undefined
}

export const Blurb : FC<Props> = ({text}) => {
  const [[short, full], setDescription] = useState([undefined, undefined] as [string|undefined, string[]|undefined]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);

    if (!text) {
      setDescription([undefined, undefined]);
      return;
    }

    const parts = text.split("|");
    const rest = parts.slice(1, parts.length);

    setDescription([parts[0], rest]);
  }, [text]);

  return <BlurbWrapper>
    <p>{short}</p>
    <Description visible={expanded}>
      <br />
      {full?.map(x => <>{x}<br/><br/></>)}
    </Description>
    <div>
      <Icon path={expanded ? mdiChevronUp : mdiChevronDown} size={1.5} css={IconStyle}/>
      <ShowMoreButton onClick={() => setExpanded(!expanded)}>
        {expanded ? "Show Less" : "Show More"}
      </ShowMoreButton>
    </div>
  </BlurbWrapper>
};