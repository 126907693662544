import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";

import furnishLogo from "../../images/furnishLogo.svg";
import fLogo from "../../images/flitchLogoWhite.svg";

export const FooterContainerWrapper = styled.div`
  background-color: #535353;
  display: flex;
  justify-content: center;
  padding: 36px 10px;

  @media (min-width: ${breakpoints.mobile}) {
    padding: 36px;
  }
`;

export const WholeFooterContainer = styled.footer`
  max-width: var(--regular-content-width);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TopLogoImgContainer = styled.div`
  width: 100%;
  padding: 25px;

  @media (min-width: ${breakpoints.tablet}) {
    /**
    * Hide when side logo is displayed
    */
    display: none;
  }
`;

/**
 * Displays when top logo does not
 */
export const SideLogoImgContainer = styled.div`
  display: none;
  @media (min-width: ${breakpoints.tablet}) {
    display: initial;
  }
`;

export const LogoImg = styled.div`
  height: 45px;
  width: 125px;
  background-image: url(${fLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: top;

  @media (max-width: ${breakpoints.mobile}) {
    height: 35px;
    width: 100px;
    background-position-x: left;
    background-position-y: center;
  }
`;

export const FooterColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const FooterColumn = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  margin: 25px;

  @media (min-width: ${breakpoints.mobile}) {
    flex-grow: initial;
    flex-shrink: initial;
    flex-basis: initial;

    max-width: 200px;
  }

  & > p,
  & > a {
    display: block;
  }
`;

export const FooterHeader = styled.h3`
  font-weight: bold;
  font-size: ${fontSizes.small};
  color: white;
  text-transform: uppercase;
`;

const footerItemStyle = css`
  font-size: ${fontSizes.small};
  color: ${colors.greyLight};
  margin: 15px 0;

  &:hover {
    color: ${colors.greyLight};
  }
`;

export const FooterText = styled.p`
  ${footerItemStyle}
`;

export const FooterExternalLink = styled.a`
  ${footerItemStyle}
`;

export const FooterLink = styled(Link)`
  ${footerItemStyle}
`;
