import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateOrderDetails } from "../../services/api.service";
import { useShopContext } from "../../store/shopContext";
import { useUserContext } from "../../store/userContext";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";


export const CheckoutResultPage = () => {
  const getParamByName = (name: string, search: string[]) => 
    search.find(x => x.startsWith(name))?.split("=")[1];

  const getParams = () => {
    let search = window.location.search.replace("?", "").split("&");
    let result = getParamByName("result", search);
    let redirect = getParamByName("redirect", search);

      if (!result || !redirect)
        console.error(`Wrong checkout result query: ${window.location.search}`, search);

      return [result, redirect] as const;
  };

  const [result, redirect] = getParams();

  const navigate = useNavigate();
  const { cart, resetCart, toggleReceipt } = useShopContext();
  const { user } = useUserContext();

  const goBack = () => navigate(redirect ?? "/")
  const finalizeOrder = async () => {
    await updateOrderDetails(cart);
    resetCart();
  }

  useEffect(() => {
    if (!user) return;
    if (!cart || cart.length === 0) return;

    switch (result?.toLowerCase()) {
      case undefined: return;
      case "cancel": 
        goBack();
        return;
      case "success": 
        finalizeOrder()
          .catch(console.error)
          .finally(() => {
            toggleReceipt(true);
            goBack();
          });
        return;
      default:
        console.warn("Invalid checkout session result", result);
        goBack();
        return;
    }
  }, [result, user, cart]);
  
  return (
    <>
      <FlitchHelmet statusCode="301" />
      <>We're getting you back...</>
    </> );
}