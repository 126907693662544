import { mdiClose, mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { FC, RefObject, useState } from "react";
import { Link } from "react-router-dom";
import { RefContainer } from "../../../hooks/useRefsList";
import { upgradeAccountCheckout } from "../../../services/api.service";
import { Reason, useUserContext } from "../../../store/userContext";
import { FilterPanelBackdrop } from "../FilterPanel";
import { 
  PromptBody,
  PromptHeader,
  PropmptBox,
  PropmptContainer,
  PropmptWrapper,
  UpgradeModalWrapper,
  } from "./UpgradePageStyles";

interface Props {
  // prevId: undefined | string;
  // itemRef: RefContainer<HTMLDivElement>;
  // goToElem: (ref: RefObject<HTMLElement>, newId: string|undefined) => void;
  visible: boolean;
  close: () => void;
}

export const UpgradePage : FC<Props> = ({
  visible, close
}) => {
  const { user, openAuthModal } = useUserContext();

  //const { prev } = itemRef;
  const [ isLoading, setIsLoading ] = useState(false);

  const checkout = () => {
    if (!user) {
      openAuthModal(Reason.RegisterBeforeSQ);
      return;
    }

    setIsLoading(true);
    upgradeAccountCheckout()
      .then(url => window.location.href = url)
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <FilterPanelBackdrop
        isOpen={visible}
        onClick={() => {}}
      />
      <UpgradeModalWrapper visible={visible}>
        {/* <PropmptBox> */}
          <PropmptContainer>
            <PromptHeader>
              <button onClick={close}>
                <Icon path={mdiClose} size={2}/>
              </button>
            </PromptHeader>
            <PromptBody>
              <h1>Let's get personal</h1>
              <p>
                Get your own (human) interior stylist who will work
                closely with you to find you your perfect piece of
                furniture - for just £25. They'll use the results of
                your style quiz to really understand what makes you
                unique. Find out more <Link to="/pricing">here</Link>.
              </p>
              <button onClick={checkout} disabled={isLoading}>
                {isLoading 
                  ? (<Icon path={mdiLoading} spin={1} size={1.5}/>)
                  : "Get Your Stylist"}
              </button>
            </PromptBody>
          </PropmptContainer>
        {/* </PropmptBox> */}
      </UpgradeModalWrapper>
    </>
  );
}