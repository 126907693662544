import { FC, RefObject, useRef } from "react";
import { categoryData } from "../../store/categoryData";
import {
  MultipleColourSelect,
  SingleSelect,
  MultipleSelect,
  RangeSlider,
} from "./FilterButtons";
import {
  getRangeFromDefaults,
  getSequentialChars,
  toggleFromArray,
  toggleFromArraySetter,
} from "../../utils/helpers";
import { SQFilterPageStyled, SQFilterSection } from "./SQFilterSections";
import {
  blackAndWhiteSchemes,
  categoryMaterialMaps,
  nonUnifiedNonNeutralSchemes,
  unifiedNonNeutralSchemes,
} from "./filterHelpers";
import { useCategory } from "../../hooks/useCategory";
import { useNavigate } from "react-router-dom";
import NavBar from "../../shared/NavBar/NavBar";
import { useFilterCtx } from "../../store/filterContext";
import { useRefsList } from "../../hooks/useRefsList";
import { allFilterRanges } from "../../store/categoryFilters";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { MobileRoadmap } from "../../shared/NavBar/RoadMap/MobileRoadmap";

const iDontMind: Option<null> = { label: "I don't mind", value: null };

/**
 * @TEMPORARY
 * Until we have more data to enable more filters
 */
export const TemporarySQFilterPage: FC = () => {
  const category = useCategory();
  const navigate = useNavigate();

  const { filterOptions, setProp, resetFilters } = useFilterCtx();

  const filterOption = filterOptions[category];
  const { materials } = filterOption;

  const filterRanges = allFilterRanges[category];
  const budgetFilterRange = filterRanges.budget;

  const budgetRange = getRangeFromDefaults(
    filterOption.minBudget,
    filterOption.maxBudget,
    budgetFilterRange
  );
  const budgetStep = 5;

  const toggleSelectedColour = toggleFromArraySetter(
    setProp(category, "colourSchemes")
  );

  const toggleMaterial = (material: MaterialGroup | Material | null) =>
    setProp(
      category,
      "materials"
    )((materials) => {
      if (material === null) {
        return materials === null ? [] : material;
      } else {
        return materials === null
          ? [material]
          : toggleFromArray(materials)(material);
      }
    });

  const setBudgetRange = ([min, max]: NumRange) => {
    setProp(category, "minBudget")(min);
    setProp(category, "maxBudget")(max);
  };

  const pageRef = useRef<HTMLDivElement>(null);

  const { makeNewRef, getRefByKey } = useRefsList<HTMLElement>();

  const colourSectionKey = "colour";
  const materialSectionKey = "material";
  const sizeSectionKey = "size";
  const budgetSectionKey = "budget";
  const otherSectionKey = "other";

  const scrollTo = (ref: RefObject<HTMLElement>) =>
    pageRef.current?.scrollTo({
      top: ref.current?.offsetTop,
      behavior: "smooth",
    });

  const initialiseChars = getSequentialChars();
  const getNextChar = () => initialiseChars.next().value;

  const goToSq1 = () => navigate(`/style-quiz/${category}/1`);

  return (
    <>
      <FlitchHelmet title="Preferences — Style Quiz" />
      <NavBar />
      <MobileRoadmap position={1}/>
      <SQFilterPageStyled ref={pageRef}>
        <SQFilterSection
          sectionIndex={getNextChar()}
          title={`Do you have any specific preferences (such as colour or material) in mind for your ${categoryData[category].displayName}?`}
          isSelected={filterOption.haveSomeIdeas !== undefined}
          onPressNext={
            !filterOption.haveSomeIdeas
              ? () => {
                  resetFilters();
                  goToSq1();
                }
              : undefined
          }
          elemRef={makeNewRef()}
          selectionIsMandatory
          goToElem={scrollTo}
        >
          <SingleSelect
            onSelect={setProp(category, "haveSomeIdeas")}
            selected={filterOption.haveSomeIdeas}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </SQFilterSection>

        {filterOption.haveSomeIdeas && (
          <>
            {category !== "displaycabinet" &&
              category !== "tvstand" &&
              category !== "wardrobe" && (
                <SQFilterSection
                  sectionIndex={getNextChar()}
                  title="What materials do you prefer?"
                  subtitle="Select as many as you'd like"
                  isSelected={materials === null || materials.length > 0}
                  elemRef={makeNewRef(materialSectionKey)}
                  goToElem={scrollTo}
                >
                  <MultipleSelect
                    selecteds={materials ?? [null]}
                    onClick={toggleMaterial}
                    options={[...categoryMaterialMaps[category], iDontMind]}
                  />
                </SQFilterSection>
              )}

            {category === "displaycabinet" || category === "wardrobe" ? (
              <>
                {category === "wardrobe" && (
                  <SQFilterSection
                    sectionIndex={getNextChar()}
                    title="Are you looking for a wardrobe or free-standing armoire?"
                    isSelected={
                      filterOptions[category].preferArmoire !== undefined
                    }
                    elemRef={makeNewRef()}
                    goToElem={scrollTo}
                  >
                    <SingleSelect
                      onSelect={setProp(category, "preferArmoire")}
                      selected={filterOptions[category].preferArmoire}
                      options={[
                        { label: "A wardrobe", value: false },
                        { label: "An armoire", value: true },
                        iDontMind,
                      ]}
                    />
                  </SQFilterSection>
                )}
              </>
            ) : category === "bed" ? (
              <SQFilterSection
                sectionIndex={getNextChar()}
                title="What size bed are you looking for?"
                isSelected={filterOptions[category].bedSize !== undefined}
                elemRef={makeNewRef(sizeSectionKey)}
                goToElem={scrollTo}
              >
                <SingleSelect
                  onSelect={setProp(category, "bedSize")}
                  selected={filterOptions[category].bedSize}
                  options={[
                    { label: "Single", value: "Single" },
                    { label: "Double", value: "Double" },
                    { label: "King", value: "King" },
                    { label: "Super King", value: "Super King" },
                    iDontMind,
                  ]}
                />
              </SQFilterSection>
            ) : category === "diningtable" ? (
              <SQFilterSection
                sectionIndex={getNextChar()}
                title="Do you want an extendable dining table?"
                isSelected={
                  filterOptions[category].isTableExtendable !== undefined
                }
                elemRef={makeNewRef()}
                goToElem={scrollTo}
              >
                <SingleSelect
                  onSelect={setProp(category, "isTableExtendable")}
                  selected={filterOptions[category].isTableExtendable}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                    iDontMind,
                  ]}
                />
              </SQFilterSection>
            ) : category === "sofa" ? (
              <>
                <SQFilterSection
                  sectionIndex={getNextChar()}
                  title="Do you want a corner sofa?"
                  isSelected={
                    filterOptions[category].preferCornerSofa !== undefined
                  }
                  elemRef={makeNewRef(otherSectionKey)}
                  goToElem={scrollTo}
                >
                  <SingleSelect
                    onSelect={setProp(category, "preferCornerSofa")}
                    selected={filterOptions[category].preferCornerSofa}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                      iDontMind,
                    ]}
                  />
                </SQFilterSection>
                {filterOptions[category].preferCornerSofa !== true && (
                  <SQFilterSection
                    sectionIndex={getNextChar()}
                    title="What size sofa are you looking for?"
                    isSelected={(() => {
                      const { sofaSeatsNumber } = filterOptions[category];

                      return Array.isArray(sofaSeatsNumber)
                        ? sofaSeatsNumber.length > 0
                        : sofaSeatsNumber === null;
                    })()}
                    elemRef={makeNewRef(sizeSectionKey)}
                    goToElem={scrollTo}
                  >
                    <MultipleSelect
                      selecteds={
                        filterOptions[category].sofaSeatsNumber ?? [null]
                      }
                      options={[
                        { label: "2-seater", value: 2 },
                        { label: "3-seater", value: 3 },
                        { label: "4-seater", value: 4 },
                        iDontMind,
                      ]}
                      onClick={(sofaSeat: SofaSeatsNum | null) =>
                        setProp(
                          category,
                          "sofaSeatsNumber"
                        )((sofaSeats) => {
                          if (sofaSeat === null) {
                            return sofaSeats === null ? [] : sofaSeat;
                          } else {
                            return sofaSeats === null || sofaSeats === undefined
                              ? [sofaSeat]
                              : toggleFromArray(sofaSeats)(sofaSeat);
                          }
                        })
                      }
                    />
                  </SQFilterSection>
                )}
                <SQFilterSection
                  sectionIndex={getNextChar()}
                  title="Do you want a sofa that can transform into a bed?"
                  isSelected={
                    filterOptions[category].preferSofabed !== undefined
                  }
                  elemRef={makeNewRef()}
                  goToElem={scrollTo}
                >
                  <SingleSelect
                    onSelect={setProp(category, "preferSofabed")}
                    selected={filterOptions[category].preferSofabed}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                      iDontMind,
                    ]}
                  />
                </SQFilterSection>
              </>
            ) : null}

            {category !== "displaycabinet" &&
              !(
                materials?.includes("Glass") ||
                materials?.includes("Metal") ||
                materials?.includes("Concrete")
              ) && (
                <>
                  <SQFilterSection
                    sectionIndex={getNextChar()}
                    title="What colour scheme(s) do you prefer?"
                    subtitle="Select as many as you'd like"
                    isBlackAndWhite={true}
                    isSelected={filterOption.colourSchemes.length > 0}
                    goToElem={scrollTo}
                    elemRef={makeNewRef(colourSectionKey)}
                  >
                    <MultipleColourSelect
                      onClick={toggleSelectedColour}
                      options={
                        materials?.includes("Marble")
                          ? blackAndWhiteSchemes
                          : (category === "armchair" ||
                            category === "bed" ||
                            category === "diningchair" ||
                            category === "sofa"
                              ? nonUnifiedNonNeutralSchemes
                              : unifiedNonNeutralSchemes
                            ).filter((clr) =>
                              materials?.includes("Wood") ||
                              materials?.includes("wickerwood")
                                ? clr.value !== "nonneutrals"
                                : true
                            )
                      }
                      selecteds={filterOption.colourSchemes}
                    />
                  </SQFilterSection>
                </>
              )}

            <SQFilterSection
              sectionIndex={getNextChar()}
              title="What's your budget?"
              elemRef={makeNewRef(budgetSectionKey)}
              goToElem={scrollTo}
              isSelected
              onPressNext={goToSq1}
            >
              <RangeSlider
                minValue={budgetFilterRange.min}
                maxValue={budgetFilterRange.max}
                step={budgetStep}
                values={budgetRange}
                onChange={setBudgetRange}
                unit="£"
                unitIsPrefixed
              />
            </SQFilterSection>
          </>
        )}
      </SQFilterPageStyled>
    </>
  );
};
