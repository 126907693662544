import styled, { css } from "styled-components";
import { colors } from "../../../styles/globalStyled";

export const EmailLoginForm = styled.form`
  width: 100%;
  display: contents;
`;

const inputHeight = 40;

const inputStyle = css`
  text-align: center;
  border: 1px solid ${colors.green};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${inputHeight}px;
  width: 100%;
  font-family: "Avenir", sans-serif;
  box-shadow: none;
  font-size: 1.75rem;
  box-sizing: border-box;
`;

export const InputField = styled.input<{ hasError?: boolean }>`
  ${inputStyle}

  ${({ hasError }) =>
    hasError
      ? css`
          border: 4px solid ${colors.red};
          color: ${colors.red};
        `
      : ""}
`;

export const SubmitButton = styled.button<{ bgColor?: string }>`
  ${inputStyle}

  flex-grow: 1;
  background-color: ${({ bgColor }) => bgColor ?? colors.green};
  text-align: center;
  color: white;
  font-weight: bold;
  border: none;
  padding: 0px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

export const ButtonLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const BelowContinueText = styled.p`
  font-size: 1.2rem;
  text-align: center;
`;

export const LoginFailMsg = styled.p`
  margin: 5px 0;
  font-size: 1.8rem;
  color: ${colors.red};
  text-align: center;
`;
