import { FC, useState } from "react";
import { FoldBlockButton, FoldBlockContainer, FoldBlockContent, FoldBlockHeader } from "./InformationStepStyles";
import Icon from '@mdi/react';
import { mdiPlus, mdiMinus } from "@mdi/js";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

interface FoldBlockProps {
  title: string;
  content: string|EmotionJSX.Element;
}

export const FoldBlock: FC<FoldBlockProps> = ({title, content}) => {
  const [ folded, setFolded ] = useState(true);

  return (
    <FoldBlockContainer>
      <FoldBlockHeader>
        <text>{title}</text>
        <FoldBlockButton onClick={() => setFolded(!folded)}>
          <Icon path={folded ? mdiPlus : mdiMinus} size={1.7}/>
        </FoldBlockButton>
      </FoldBlockHeader>
      <FoldBlockContent folded={folded}>
        {content}
      </FoldBlockContent>
    </FoldBlockContainer>
  )
}