import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { isValidCategory } from "../utils/helpers";

export const useMaybeCategory = () => {
  const { category } = useParams<{ category: string }>();
  if (isValidCategory(category)) {
    return category;
  } else {
    return null;
  }
};

export const useCategory = () => {
  const maybeCategory = useMaybeCategory();
  const navigate = useNavigate();

  useEffect(() => {
    if (!maybeCategory) {
      navigate("/category");
    }
  }, [maybeCategory]);

  if (maybeCategory) {
    return maybeCategory;
  } else {
    // Only doing this to appease TS
    return (maybeCategory as unknown) as CategoryUnion;
  }
};
