export const allFilterRanges: AllCatMap<FilterRanges> = {
  armchair: {
    budget: { min: 0, max: 2000 },
    height: { min: 70, max: 110 },
    width: { min: 60, max: 140 },
    depth: { min: 60, max: 110 },
  },
  barstool: {
    budget: { min: 0, max: 800 },
    height: { min: 65, max: 110 },
    width: { min: 35, max: 60 },
    depth: { min: 35, max: 65 },
  },
  bed: {
    budget: { min: 0, max: 1500 },
    height: { min: 65, max: 160 },
    width: { min: 90, max: 230 },
    depth: { min: 140, max: 250 },
  },
  bedsidetable: {
    budget: { min: 0, max: 650 },
    height: { min: 45, max: 80 },
    width: { min: 35, max: 70 },
    depth: { min: 25, max: 50 },
  },
  chestofdrawers: {
    budget: { min: 0, max: 1400 },
    height: { min: 65, max: 130 },
    width: { min: 35, max: 165 },
    depth: { min: 35, max: 60 },
  },
  coffeetable: {
    budget: { min: 0, max: 2500 },
    height: { min: 30, max: 60 },
    width: { min: 45, max: 145 },
    depth: { min: 40, max: 110 },
  },
  consoletable: {
    budget: { min: 0, max: 1800 },
    height: { min: 70, max: 95 },
    width: { min: 55, max: 165 },
    depth: { min: 25, max: 60 },
  },
  diningchair: {
    budget: { min: 0, max: 1200 },
    height: { min: 70, max: 110 },
    width: { min: 40, max: 65 },
    depth: { min: 45, max: 70 },
  },
  diningtable: {
    budget: { min: 0, max: 2500 },
    height: { min: 70, max: 80 },
    width: { min: 65, max: 260 },
    depth: { min: 65, max: 150 },
  },
  displaycabinet: {
    budget: { min: 0, max: 2000 },
    height: { min: 110, max: 225 },
    width: { min: 45, max: 200 },
    depth: { min: 30, max: 55 },
  },
  shelving: {
    budget: { min: 0, max: 2500 },
    height: { min: 65, max: 225 },
    width: { min: 35, max: 200 },
    depth: { min: 20, max: 55 },
  },
  sideboard: {
    budget: { min: 0, max: 2500 },
    height: { min: 55, max: 120 },
    width: { min: 65, max: 220 },
    depth: { min: 30, max: 60 },
  },
  sidetable: {
    budget: { min: 0, max: 1600 },
    height: { min: 35, max: 75 },
    width: { min: 30, max: 75 },
    depth: { min: 30, max: 70 },
  },
  sofa: {
    budget: { min: 0, max: 5000 },
    height: { min: 65, max: 105 },
    width: { min: 120, max: 340 },
    depth: { min: 75, max: 245 },
  },
  tvstand: {
    budget: { min: 0, max: 1400 },
    height: { min: 30, max: 75 },
    width: { min: 80, max: 200 },
    depth: { min: 30, max: 60 },
  },
  wardrobe: {
    budget: { min: 0, max: 2500 },
    height: { min: 135, max: 230 },
    width: { min: 65, max: 310 },
    depth: { min: 45, max: 75 },
  },
};
