import styled from "styled-components";

import { fontSizes } from "../../styles/globalStyled";

import faqsbg from "../../images/faqsbg.jpg";
import { breakpoints } from "../../styles/responsive";
export const FaqsPageWrapper = styled.div``;

export const FaqsPageContainer = styled.div`
  margin-top: var(--navbar-height);
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: ${fontSizes.hsDescTitle};
    text-align: center;
  }

`;

export const QandA = styled.div`
  max-width: var(--regular-content-width);
  margin-top: 2rem;
`;

export const FaqSection = styled.div`
  max-width: var(--regular-content-width);
  margin-top: 0.2rem;

  h1 {
    font-size: ${fontSizes.mediumLarger};
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 1rem;
    line-height: 2;
  }
`;

export const QuestionText = styled.h4`
  font-size: ${fontSizes.medium};
  font-weight: bolder;
  text-align: left;
  padding: 0 1rem 0;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 2vw;
    max-width: var(--regular-content-width);
  }
`;

export const AnswerText = styled.div<{folded:boolean}>`
  font-size: ${fontSizes.small};
  text-align: left;
  padding: 1rem;
  line-height: 1.5;

  div {
    display:${({folded}) => folded ? "none" : "block"};
  }

  a {
    text-decoration: underline;
  }
`;

export const FaqsBGWrapper = styled.div`
  height: 35vh;
  width: 100vw;
`;

export const FaqsBG = styled.img`
  height: 100%;
  width: 100%;
  background-image: url(${faqsbg});
  background-position: 0 50%;
  background-size: cover;
  margin-bottom: 5rem;
`;

export const EmailUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: auto;
  /* margin: 0 5rem; */
  margin-bottom: 5rem;
  h4 {
    font-weight: bolder;
  }
`;

export const EmailUsButton = styled.a`
  cursor: pointer;
  margin-top: 2rem;
  width: auto;
  /* max-width: 70vw; */
  height: 6rem;
  border-radius: 0.3rem;
  background-color: #f88c09;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;

  span {
    font-size: ${fontSizes.medium};
    padding: 1rem;
    font-weight: bolder;
    color: white;
  }
`;
