import styled from "styled-components";
import googleLogo from "../../../images/icons/g_logo.png";
import { colors, fontSizes } from "../../../styles/globalStyled";

export const GoogleAuthButton = styled.div`
  background-color: ${colors.blueGoogle};
  color: white;
  text-align: center;
  cursor: pointer;
  border-width: 0;
  border-radius: 0.5rem;
  font-size: ${fontSizes.medium};
  font-family: "Roboto";
  height: 45px;
  display: grid;
  grid-template-columns: 25px 1fr;
  padding: 10px;
`;

export const GoogleIcon = styled.div`
  background-color: white;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${googleLogo});
  width: 25px;
  height: 25px;
  margin: auto;
  border-radius: 15%;
`;