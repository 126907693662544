import styled from "styled-components";
import { colors, fontSizes } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";

import chartDesktop from "../../../images/home/price-chart-mockup.png";
import chartMobile from "../../../images/home/mobile-price-chart.png";

export const AboutPriceWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  box-shadow: 0px -2px 7px #00000029;
  z-index: 3;
  padding: 5%;

  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

export const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.greyDark};
  text-align: center;
  line-height: 1;
  height: 80vh;
  padding: 0 10%;

  @media (max-width: ${breakpoints.mobile}) {
    height: auto;
    margin: auto;
  }

  h2 {
    font-size: ${fontSizes.hsDescTitle};
    font-weight: 450;
    margin-bottom: 5%;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.larger};
    }
  }

  p {
    font-size: ${fontSizes.hsDescription};
    line-height: 1.1;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.medium};
      margin: 5% 0;
    }
  }
`;

export const ChartMockup = styled.div`
  background: url(${chartDesktop}) no-repeat;
  background-size: contain;
  background-position: center;
  margin: 5%;

  @media (max-width: ${breakpoints.mobile}) {
    /* margin: auto; */
    background: url(${chartMobile}) no-repeat;
    background-size: content;
    background-position: center;
    height: 327px;
  }
`;
