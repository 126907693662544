import armchair1000 from "../../images/recsPage/armchair-1000px.png"
import armchair2000 from "../../images/recsPage/armchair-2000px.png"
import armchair500 from "../../images/recsPage/armchair-500px.png"
import barstool1000 from "../../images/recsPage/barstool-1000px.png"
import barstool2000 from "../../images/recsPage/barstool-2000px.png"
import barstool500 from "../../images/recsPage/barstool-500px.png"
import bed1000 from "../../images/recsPage/bed-1000px.png"
import bed2000 from "../../images/recsPage/bed-2000px.png"
import bed500 from "../../images/recsPage/bed-500px.png"
import bedsidetable1000 from "../../images/recsPage/bedsidetable-1000px.png"
import bedsidetable2000 from "../../images/recsPage/bedsidetable-2000px.png"
import bedsidetable500 from "../../images/recsPage/bedsidetable-500px.png"
import chestofdrawers1000 from "../../images/recsPage/chestofdrawers-1000px.png"
import chestofdrawers2000 from "../../images/recsPage/chestofdrawers-2000px.png"
import chestofdrawers500 from "../../images/recsPage/chestofdrawers-500px.png"
import coffeetable1000 from "../../images/recsPage/coffeetable-1000px.png"
import coffeetable2000 from "../../images/recsPage/coffeetable-2000px.png"
import coffeetable500 from "../../images/recsPage/coffeetable-500px.png"
import consoletable1000 from "../../images/recsPage/consoletable-1000px.png"
import consoletable2000 from "../../images/recsPage/consoletable-2000px.png"
import consoletable500 from "../../images/recsPage/consoletable-500px.png"
import diningchair1000 from "../../images/recsPage/diningchair-1000px.png"
import diningchair2000 from "../../images/recsPage/diningchair-2000px.png"
import diningchair500 from "../../images/recsPage/diningchair-500px.png"
import diningtable1000 from "../../images/recsPage/diningtable-1000px.png"
import diningtable2000 from "../../images/recsPage/diningtable-2000px.png"
import diningtable500 from "../../images/recsPage/diningtable-500px.png"
import displaycabinet1000 from "../../images/recsPage/displaycabinet-1000px.png"
import displaycabinet2000 from "../../images/recsPage/displaycabinet-2000px.png"
import displaycabinet500 from "../../images/recsPage/displaycabinet-500px.png"
import shelving1000 from "../../images/recsPage/shelving-1000px.png"
import shelving2000 from "../../images/recsPage/shelving-2000px.png"
import shelving500 from "../../images/recsPage/shelving-500px.png"
import sideboard1000 from "../../images/recsPage/sideboard-1000px.png"
import sideboard2000 from "../../images/recsPage/sideboard-2000px.png"
import sideboard500 from "../../images/recsPage/sideboard-500px.png"
import sidetable1000 from "../../images/recsPage/sidetable-1000px.png"
import sidetable2000 from "../../images/recsPage/sidetable-2000px.png"
import sidetable500 from "../../images/recsPage/sidetable-500px.png"
import sofa1000 from "../../images/recsPage/sofa-1000px.png"
import sofa2000 from "../../images/recsPage/sofa-2000px.png"
import sofa500 from "../../images/recsPage/sofa-500px.png"
import tvstand1000 from "../../images/recsPage/tvstand-1000px.png"
import tvstand2000 from "../../images/recsPage/tvstand-2000px.png"
import tvstand500 from "../../images/recsPage/tvstand-500px.png"
import wardrobe1000 from "../../images/recsPage/wardrobe-1000px.png"
import wardrobe2000 from "../../images/recsPage/wardrobe-2000px.png"
import wardrobe500 from "../../images/recsPage/wardrobe-500px.png"

const recsToShopImages1000: AllCatMap<string> = {
    armchair: armchair1000,
    barstool: barstool1000,
    bed: bed1000,
    bedsidetable: bedsidetable1000,
    chestofdrawers: chestofdrawers1000,
    coffeetable: coffeetable1000,
    consoletable: consoletable1000,
    diningchair: diningchair1000,
    diningtable: diningtable1000,
    displaycabinet: displaycabinet1000,
    shelving: shelving1000,
    sideboard: sideboard1000,
    sidetable: sidetable1000,
    sofa: sofa1000,
    tvstand: tvstand1000,
    wardrobe: wardrobe1000,
    shop: ""
};

const recsToShopImages2000: AllCatMap<string> = {
    armchair: armchair2000,
    barstool: barstool2000,
    bed: bed2000,
    bedsidetable: bedsidetable2000,
    chestofdrawers: chestofdrawers2000,
    coffeetable: coffeetable2000,
    consoletable: consoletable2000,
    diningchair: diningchair2000,
    diningtable: diningtable2000,
    displaycabinet: displaycabinet2000,
    shelving: shelving2000,
    sideboard: sideboard2000,
    sidetable: sidetable2000,
    sofa: sofa2000,
    tvstand: tvstand2000,
    wardrobe: wardrobe2000,
    shop: ""
};

const recsToShopImages500: AllCatMap<string> = {
    armchair: armchair500,
    barstool: barstool500,
    bed: bed500,
    bedsidetable: bedsidetable500,
    chestofdrawers: chestofdrawers500,
    coffeetable: coffeetable500,
    consoletable: consoletable500,
    diningchair: diningchair500,
    diningtable: diningtable500,
    displaycabinet: displaycabinet500,
    shelving: shelving500,
    sideboard: sideboard500,
    sidetable: sidetable500,
    sofa: sofa500,
    tvstand: tvstand500,
    wardrobe: wardrobe500,
    shop: ""
};

export enum ImageSizes {Small , Medium, Big}

export const getRecsToShopImage = (cat: CategoryUnion, size: ImageSizes) => {
    const map = size === ImageSizes.Small 
        ? recsToShopImages500
        : size === ImageSizes.Medium
            ? recsToShopImages1000
            : recsToShopImages2000;

    return map[cat];
}