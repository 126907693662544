import styled from "styled-components"
import { colors, fontSizes } from "../../../styles/globalStyled"
import { breakpoints } from "../../../styles/responsive"

export const Header = styled.h4`
  font-size: ${fontSizes.medium};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
`

export const TooltipCss = {
  fontSize: fontSizes.verySmall,
  color: colors.greyDark
}

export const TooltipHeaderCss = {
  fontSize: fontSizes.small,
  color: colors.greyDark
}

export const PlaceholderLabel=styled.div`
  position: relative;
  z-index: 2;
  background-color: ${colors.greyPale};
  text-align: center;
  top: -81px;
  margin-top: -150px;
  //margin: auto;

  padding: 34px;
  border-radius: 5px;
  box-shadow: 0 0 5px ${colors.greyMedium};

  @media (min-width: ${breakpoints.mobile}px) {
    padding: 20px;
  }

  .label-body {
    font-size: ${fontSizes.small};
    color: ${colors.greyDark};
  }
`;

export const ButtonPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const UpdateButton = styled.button<{isSelected:boolean}>`
  padding: 5% 10px;
  margin: 0 10px;
  border: none;
  background-color: ${({isSelected}) => isSelected ? colors.greyDark : colors.greyPale};
  color: ${({isSelected}) => isSelected ? colors.greyLighter : colors.greyDark};
  transition: all .3s;
  cursor: pointer;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 5px;
  }
`;