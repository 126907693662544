import React, { FC } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../styles/globalStyled";
import { useNavigate } from "react-router-dom";
import { breakpoints } from "../../../styles/responsive";

const navbarSwitchBreakpoint = breakpoints.tablet;

export const RoadmapContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface RoadmapPillProps {
  isActive: boolean;
  isExpandedOnMobile: boolean;
  number: number;
  label: string;
  startingUrl?: string; // @TODO: need to check which part of the SQ the middle button should link to
}

/**
 * Using an `a` instead of router's `Link` because we can't let `Link` have an
 * undefined `to` prop for when user isn't allowed to go to next page yet.
 */
export const RoadmapPillStyled = styled.a<RoadmapPillProps>`
  --pill-height: 2.2rem;

  border-radius: 50px;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin: 0 4px;
  padding: 12px;
  line-height: 0;
  height: var(--pill-height);
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ isExpandedOnMobile }) =>
    isExpandedOnMobile ? "initial" : "var(--pill-height)"};

  @media (min-width: ${breakpoints.mobile}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${navbarSwitchBreakpoint}) {
    width: 20rem;
  }

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${colors.beigeGrey};
          color: white;
          border: 1px solid white;
          &:hover {
            color: white;
          }
          @media (max-width: ${breakpoints.mobile}) {
            border: none;
          }
        `
      : css`
          cursor: initial;
          background-color: white;
          color: ${colors.beigeGrey};
          border: 1px solid ${colors.beigeGrey};
          &:hover {
            color: ${colors.beigeGrey};
          }

          @media (max-width: ${breakpoints.mobile}) {
            background-color: ${colors.greyLight};
          }
        `};
`;

export const PillLine = styled.div<{ isActive?: boolean }>`
  display: none;

  @media (min-width: ${navbarSwitchBreakpoint}) {
    display: initial;
    height: 1px;
    width: 10px;
    margin: 0 2px;
    background-color: ${colors.beigeGrey};
  }
`;

const LabelOrNumberButLabelOnDesktop = styled.div<{ isExpanded: boolean }>`
  .roadmap-label {
    display: ${({ isExpanded }) => (isExpanded ? "initial" : "none")};

    @media (min-width: ${navbarSwitchBreakpoint}) {
      display: initial;
    }
  }

  .roadmap-number {
    display: ${({ isExpanded }) => (isExpanded ? "none" : "initial")};

    @media (min-width: ${navbarSwitchBreakpoint}) {
      display: none;
    }
  }
`;

export const RoadmapPill: FC<RoadmapPillProps> = (props) => {
  const { isActive, isExpandedOnMobile, number, label, startingUrl } = props;
  const navigate = useNavigate();

  const onClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Don't use regular browser nav event

    if (isActive && startingUrl) {
      navigate(startingUrl);
    }
  };

  return (
    <RoadmapPillStyled
      onClick={onClickHandler}
      href={isActive ? startingUrl : undefined}
      {...props}
    >
      <LabelOrNumberButLabelOnDesktop isExpanded={isExpandedOnMobile}>
        <span className="roadmap-label">{label}</span>
        <span className="roadmap-number">{number}</span>
      </LabelOrNumberButLabelOnDesktop>
    </RoadmapPillStyled>
  );
};
