import React, { FC } from "react";
import { css } from "@emotion/react";
import { Footer } from "../shared/Footer/Footer";
import NavBar from "../shared/NavBar/NavBar";
import { fontSizes } from "../styles/globalStyled";

const RatingText: FC = ({ children }) => (
  <h1
    css={css`
      font-family: "Avenir", sans-serif;
      font-size: ${fontSizes.large};
      color: black;
      text-align: center;
      margin: 10px 25px;
      max-width: var(--regular-content-width);
    `}
  >
    {children}
  </h1>
);

export const RatingSubmissionPage = () => {
  return (
    <>
      <NavBar />
      <div
        css={css`
          margin-top: var(--navbar-height);
          height: 75vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <RatingText>
          That didn't take long to do...now if only furniture shopping would be
          as fast as that!
        </RatingText>
        <RatingText>
          If you want to elaborate on your recommendations rating, you can
          always send us a quick email with more detail.
        </RatingText>
        <RatingText>
          We can be reached at{" "}
          <a
            href="mailto:hello@withflitch.com"
            css={css`
              color: black;
              font-weight: bolder;
            `}
          >
            hello@withflitch.com
          </a>
        </RatingText>
      </div>
      <Footer />
    </>
  );
};
