import { css } from "@emotion/react";
import styled from "styled-components";
import { colors, fontSizes } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";

export const BlurbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${fontSizes.mediumSmaller};
  text-align: center;
  width: 70%;
  margin: auto;
  line-height: 1.2;
  padding-bottom: 3vh;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.smaller};
    width: 85%;
  }
`;

export const ShowMoreButton = styled.button`
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: ${fontSizes.small};
  background-color: ${colors.transparent};
  width: auto;
  height: 5vh;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.smaller};
  }
`;

export const Description = styled.p<{visible: boolean}>`
  display: ${({visible}) => visible ? "initial" : "none"};
`;

export const IconStyle = css`
  vertical-align: middle;
`;
