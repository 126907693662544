import styled, { css } from "styled-components";
import NavBar from "../../shared/NavBar/NavBar";
import { breakpoints } from "../../styles/responsive";
import { colors } from "../../styles/globalStyled";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { Footer } from "../../shared/Footer/Footer";
import { BottomFooter } from "../../shared/Footer/BottomFooter";

import fLogo from "../../images/flitchLogoWhite.svg";
import origins from "../../images/aboutus/origins.jpg";
import wearefor from "../../images/aboutus/wearefor.jpg";
import whoweare from "../../images/aboutus/whoweare.jpg";
import usinanutshell from "../../images/aboutus/usinanutshell.jpg";


const sectionPadding = 50;
const margin = 15;

const mobileSize = 600;

const PageBody = styled.section`
  background-color: white;
  font-size: 2rem;
`;

const RestofPage = styled.div`
  max-width: var(--max-content-width);
  margin: 0 auto;

  // Needs to be padding because of collapsing margins (I think. It doesn't work otherwise anyway.)
  padding-top: var(--navbar-height);
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  margin: ${margin}px 0;

  @media (min-width: ${mobileSize}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${margin}px;
    grid-auto-flow: dense;
  }
`;

const PageTitle = styled.header`
  background-color: ${colors.green};
  padding: ${sectionPadding}px;
  font-size: 4rem;
  font-weight: bold;

  p {
    font-family: BuenosAires;
    color: white;
    font-weight: 450;
  }

  @media (min-width: ${mobileSize}px) {
    font-size: 6rem;
    grid-column: 1 / span 2;
  }
`;

const PageTitleHello = styled.h3`
  color: white;
  /* font-family: Didot; */
  font-family: Avenir;
  /* font-style: italic; */
  font-weight: initial;
`;

interface SectionHeaderProps {
  backgroundColor: string;
  imageSrc: string;
  side: "left" | "right" | "center";
  gridPos: "left" | "right";
  centerOnDesktop?: boolean;
}

const SectionHeader = styled.div<SectionHeaderProps>`
  margin-top: ${margin}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ imageSrc }) => imageSrc});
  background-position-x: ${({ side }) => side};
  background-size: auto 100%;
  background-repeat: no-repeat;
  height: 100px;

  @media (min-width: ${mobileSize}px) {
    margin-top: 0;
    height: unset;
    grid-column: ${({ gridPos }) => (gridPos === "left" ? 1 : 2)} / span 1;
    ${({ centerOnDesktop }) =>
      centerOnDesktop &&
      css`
        background-position-x: center;
      `}
  }
`;

const TextSection = styled.div<{ align?: "left" | "right" }>`
  margin-top: ${margin}px;
  padding: ${sectionPadding}px;
  background-color: #e4e4e4;

  @media (min-width: ${mobileSize}px) {
    text-align: ${({ align = "left" }) => align};
    margin-top: 0;
    grid-column-end: span 1;
  }

  h3,
  p {
    margin-top: ${margin}px;
  }
`;

const TextSectionHeader = styled.h4`
  font-family: BuenosAires;
  /* font-style: italic; */
  font-size: 5rem;
`;

const Logo = styled.div`
  margin: auto 2rem;
  background-image: url(${fLogo});
  background-position-x: left;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 6rem;
  width: 50%;

  @media (min-width: ${breakpoints.mobile}) {
    font-size: 4rem;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export function AboutUsPage() {
  return (
    <>
      <FlitchHelmet title="About Us" />
      <PageBody>
        <NavBar />
        <RestofPage>
          <ItemsContainer>
            <PageTitle>
              <PageTitleHello>Hello.</PageTitleHello>
                <p>We're Flitch</p>
              </PageTitle>
            <SectionHeader
              backgroundColor={colors.orange}
              side="right"
              gridPos="right"
              imageSrc={whoweare}
              centerOnDesktop={true}
            />
            <TextSection>
              <TextSectionHeader>Who We Are</TextSectionHeader>
              <p>
                Flitch is an online interior styling platform that provides an 
                effortless furniture shopping experience built entirely around your 
                taste, budget and requirements.
              </p>
              <p>
                With our personalised service, you can sit back and relax whilst your 
                dedicated stylist builds a shopping list that captures everything 
                you’re looking for.
              </p>
              <p>
                Fancy being your own stylist? You can also search and compare furniture 
                items across 100+ UK retailers and track price history to ensure you get 
                the best deal for you.
              </p>
            </TextSection>
            <SectionHeader
              backgroundColor={colors.green}
              side="left"
              gridPos="left"
              imageSrc={wearefor}
            />
            <TextSection align="right">
              <TextSectionHeader>We Are For</TextSectionHeader>
              <p>
                We’re for the people who have embraced the modern world and who
                expect services to be simple and efficient that work for them
              </p>
              <p>
                We’re for the people who favour streaming services to receive
                personalised movie recommendations or to discover new musical
                artists
              </p>
              <p>
                Endless browsing is the past…instant personalised
                recommendations are the here and now, and that’s where we’re
                bringing furniture shopping
              </p>
            </TextSection>
            <SectionHeader
              backgroundColor={colors.orange}
              side="right"
              gridPos="right"
              imageSrc={origins}
            />
            <TextSection>
              <TextSectionHeader>Our Origins</TextSectionHeader>
              <p>
                Our two founders, Uriel and Daniel, have a long history
                together, having met when they were just eleven. Fast forward
                twenty years, and over a casual chat both griped about the
                laborious nature of furniture shopping
              </p>
              <p>
                They felt there must be a better way, and sought to speed up the
                discovery and shopping process, converting months to minutes and
                transforming desperation and exhaustion into discovery and
                excitement
              </p>
            </TextSection>
            <SectionHeader
              backgroundColor={colors.green}
              side="right"
              gridPos="left"
              imageSrc={usinanutshell}
            />
            <TextSection align="right">
              <TextSectionHeader>Us In A Nutshell</TextSectionHeader>
              <h3>OUR MISSION</h3>
              <p>
                Making furniture shopping simple
              </p>
              <h3>OUR PROMISE</h3>
              <p>
                Ease and convenience - we do the searching for you
              </p>
              <h3>OUR GROOVE</h3>
              <p>
                The world we live in matters to us, and that’s why we 
                contribute 10% of all profit to charity
              </p>
            </TextSection>
          </ItemsContainer>
        </RestofPage>
        <Footer />
        <BottomFooter />
      </PageBody>
    </>
  );
}
