import { css } from "@emotion/react";
import {
  faChevronUp,
  faChevronDown,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FC } from "react";
import styled from "styled-components";
import { seoficateTag, toggle } from "../../utils/helpers";
import { Range } from "rc-slider";
import { RangeSliderProps } from "../SQFilterPage/FilterButtons";
import { 
  FilterLabelCss, 
  FilterOpenedCss, 
  FilterSectionCss, 
  getDropDownMenuCss, 
  getTagButtonCss, 
  MenuCintainerCss, 
  RangeDivCss, 
  RangeCss, 
  TagGroupContainerCss, 
  DimensionLabelCss, 
  SortListItemCss, 
  LargeTextCss,
  LargeCapitalizedTextcss} from "./BrowsePageStyles";
import { colors } from "../../styles/globalStyled";
import { Link } from "react-router-dom";
import { useSearchContext } from "../../store/searchContext";

interface TagGroupNavItemProps {
  label: string;
  onMouseEnter: VoidFunction;
  onMouseLeave: VoidFunction;
  anyItemHovered: boolean;
  thisItemHovered: boolean;
  name?: Tag;
  urlAlias?: string;
  highlighted?: boolean;
  onClick?: () => void;
}

export const TagGroupContainer = styled.div`
  height: 50px;
  margin: 5px 10px;
  display: flex;
  text-align: center;
  align-items: center;
  color: red;
`;

type DropDownProps = {
  //children: React.ReactNode;
  itemCount: number;
  visible: boolean;
  onMouseEnter: VoidFunction;
  onMouseLeave: VoidFunction;
  children?: React.ReactNode;
}

export const TagGroupDropdownMenu: FC<DropDownProps> = ({ children, itemCount, visible, onMouseEnter, onMouseLeave}) => { 
  const getColNumber = (tagsNumber: number, maxCols = 2) => 
    tagsNumber > maxCols ? maxCols : tagsNumber;
  
  return(
    <div 
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave} 
      css={getDropDownMenuCss(itemCount, visible)}
    >
      {children}
    </div>
)};

export const MenuContainer: FC<{children?: React.ReactNode}> = ({ children }) => (
  <div css={MenuCintainerCss}>
    {children}
  </div>
);

export const TagGroupNavItem: FC<TagGroupNavItemProps> = ({
  label,
  name,
  urlAlias,
  onMouseEnter,
  onMouseLeave,
  anyItemHovered,
  thisItemHovered,
  highlighted,
  onClick
}) => {
  const to = (name ?? label).toLowerCase() === "all" 
    ? "/shop" 
    : `/shop/${urlAlias ?? seoficateTag(name ?? label)}`

  const { applySearch } = useSearchContext();

  return (
    <TagGroupContainer>
      <div css={TagGroupContainerCss}>
        <Link
          css={getTagButtonCss(anyItemHovered, thisItemHovered, highlighted)}
          to={to}
          onClick={() => {applySearch("", false, true); if (onClick) onClick()}}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {label}
        </Link>
      </div>
    </TagGroupContainer>
  )
};

interface FilterSection {
  label: string;
  filtersActive: boolean;
  children?: React.ReactNode;
}

export const FilterSection: FC<FilterSection> = ({
  children,
  label,
  filtersActive,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div css={FilterSectionCss}>
      <div
        onClick={() => setIsOpen(toggle)}
        css={FilterOpenedCss}
      >
        <div css={FilterLabelCss}>
          <p css={LargeCapitalizedTextcss}>
            {label}
          </p>
          {filtersActive && (
            <FontAwesomeIcon icon={faCheck} size="lg" color={colors.orange} />
          )}
        </div>
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          size="lg"
        />
      </div>
      <div css={css`${!isOpen &&
          css`
            display: none;
          `}
        `}
      >
        {children}
      </div>
    </div>
  );
};

export const RangeSlider: FC<RangeSliderProps> = ({
  values,
  minValue,
  maxValue,
  step,
  onChange,
  unit = "",
  unitIsPrefixed,
}) => {
  const withUnit = (n: number | string) =>
    unitIsPrefixed ? `${unit}${n}` : `${n}${unit}`;
    
  useEffect(() => {
    if (values?.[0] === minValue && values?.[1] === maxValue)
      setCurrentVals([minValue, maxValue]);
  }, [values])

  const [currentVals, setCurrentVals] = useState (values ?? [minValue, maxValue] as NumRange);
  const [a, b] = currentVals;

  const handleChange = () => {
    if (!onChange) return;
    onChange(currentVals);
  }

  return (
    <div>
      <div css={RangeDivCss}>
        <p>{withUnit(a)}</p>
        <p>{withUnit(b)}</p>
      </div>
      <div css={RangeCss}>
        {/*@ts-ignore*/}
        <Range
          onAfterChange={handleChange}
          value={currentVals}
          min={minValue}
          max={maxValue}
          step={step}
          onChange={setCurrentVals}
        />
      </div>
    </div>
  );
};

export const DimensionLabel: FC<{ label: string }> = ({ label }) => (
  <p css={DimensionLabelCss}>
    {label}
  </p>
);

interface SortItemProps {
  dispatch: (value: Action) => void;
  sortOrder: SortOrder | null;
  label: string;
};

export const SortListItem: FC<SortItemProps> = ({
  dispatch,
  sortOrder,
  label
}) => (
  <li
    onClick={() => dispatch({type: "changeSortOrder", sortOrder: sortOrder})}
    css={SortListItemCss}
  >
    {label}
  </li>
);

// export const SortListItem: FC<{ onClick?: VoidFunction }> = ({
//   children,
//   onClick,
// }) => (
//   <li
//     onClick={onClick}
//     css={SortListItemCss}
//   >
//     {children}
//   </li>
// );
