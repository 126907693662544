import React from "react";
import { createRoot } from "react-dom/client";
import { AppRouter } from "./router/AppRouter";
import smoothscroll from "smoothscroll-polyfill";
// Smooth scrolling doesn't work in iOS without this 🤦‍♂️
smoothscroll.polyfill();

import "bulma/css/bulma.css";
import "./styles/style.scss";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import posthog from 'posthog-js';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

Sentry.init({
  dsn: "https://5240542d8b9d47b9977f1e36faefac53@o756172.ingest.sentry.io/5792136",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

posthog.init(
    'phc_FQyZTjvK4USsdKfpjAon6lWDNzApeI7H2RwNAI0mQpG', { api_host: 'https://app.posthog.com' }
);

const container = document.getElementById("root");
if (!container) throw "No root element!"
const root = createRoot(container);

const App = () => <AppRouter />
root.render(<App />);
