import { FC } from "react";
import { Link } from "react-router-dom"
import NavBar from "../../shared/NavBar/NavBar";

import { Footer } from "../../shared/Footer/Footer";
import { BottomFooter } from "../../shared/Footer/BottomFooter";
import {
  MethodCardsContainer,
  MethodCardStyled,
  MethodCardTitle,
  MethodSection,
  PageContainer,
  MethodCardImage,
  MethodTitle,
  InfoLabel,
  StylistBanner,
} from "./HeadspaceStyled";

import cardLeftImage from "../../images/headspace-card-left.png"
import cardMiddleImage from "../../images/headspace-card-middle.png"
import cardRightImage from "../../images/headspace-card-right.png"

import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { Carousel } from "./Carousel/Carousel";
import { ReviewSection, Review } from "./ReviewSection/ReviewSection";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { GroupedFaq, FaqGroup} from "../../shared/Faq";

const REVIEWS = [
  ["Antoniya K.",
  "Trustpilot Review",
  "My partner and I have a very specific style so Flitch worked personally with me to find a bed that was perfect and much cheaper than I expected. Brilliant service!"
  ],

  ["Annaelle M.",
  "Trustpilot Review",
  "I had a wonderful experience using Flitch. It is incredibly user-friendly and saved me so much time when decorating my new home! They got my taste spot on. Really impressed!"
  ],

  ["Carolyn L.",
  "Trustpilot Review",
  "My stylist Sam really helped me narrow down my search for dining furniture, capturing my taste perfectly. Such good value for money and saved me so much time!"
  ],

  ["Melanie A.",
  "Trustpilot Review",
  "As easy as it gets. Savouring every moment with this new armchair. Thanks for helping me find it! I highly recommend it for any occasion where you are buying new furniture."
  ],

  ["H.",
  "Trustpilot Review",
  "Good service for new homeowners. If you’re like me and are looking to furnish a bunch of empty rooms in a new house– then Flitch is a great service that can help you with that."
  ],

  ["Andrew G.",
  "Trustpilot Review",
  "It’s a sleek site which is easy on the eyes and gets the job done. Amazingly simple, thanks for helping me find my fabulous new bed!"
  ],

] as Review[];

export const GROUP_FAQ_CONTENT = [
  {
    name: "General",
    content: [
      {
        question: "What do I get out of the styling fee?", 
        answer: <>
          You get your own expert stylist who will help you buy all the furniture you need 
          - that's a year of outsourcing all your furniture discovery. You'll get a mood 
          board and a detailed shopping list tailored to you - no more endless browsing to 
          find the right items!
        </>
      },
      {
        question: "When will I hear from my stylist?",
        answer: <>
          Your stylist will reach out to you via email within a couple of working days after you 
          pay the styling fee and complete your style quiz.
        </>
      },
      {
        question: "Are there any limits to the Human Stylist plan?",
        answer: <>
          Just to be clear, you won't be paying for a full interior design service. 
          So you won't get floor plans, 3D renderings and space and lighting advice. 
          What you will get is expert advice on finding the right pieces of furniture.
        </>
      },
      {
        question: "Can I shop on my own without a stylist?",
        answer: <>
          Of course! You can head over to our <Link to="/shop">browse section</Link> where
          you can look through the full product catalogues of 100+ furniture retailers 
          all in one place. But if you ever tire of that, you can choose to sit back and let 
          our stylists do the hard work for you - they won't stop until you're 100% satisfied.
        </>
      }
    ]
  },

  {
    name: "Pricing",
    content: [
      {
        question: "How much is the styling fee?",
        answer: <>
          For just £25 you can get your very own expert interior stylist who will take all 
          the stress out of furniture shopping for you. For more information, please 
          see <Link to="/pricing">our pricing plans here</Link>. Note that the £25 styling 
          fee gets credited towards anything you choose to buy through Flitch.
        </>
      },
      {
        question: "How long does the styling fee last?",
        answer: <>
          The styling fee lasts for a whole year. That means you can get as much assistance 
          as you need from your assigned stylist over the course of a year without any 
          additional styling fees.
        </>
      },
      {
        question: "What if I'm not satisfied?",
        answer: <>
          If you're not satisfied, we're not satisfied. So if our stylists can't find the right 
          furniture for you, we'll hand you back your styling fee - that's a no quibble guarantee.
        </>
      },
    ]
  }
] as FaqGroup[]

interface MethodCardProps {
  title: EmotionJSX.Element;
}

const MethodCard: FC<MethodCardProps> = ({ title, children }) => {
  return (
    <MethodCardStyled>
      <MethodCardTitle>{title}</MethodCardTitle>
      {children}
    </MethodCardStyled>
  );
};

export const HeadspacePage = () => {
  return (
    <PageContainer>
      <FlitchHelmet />
      <NavBar />
      <Carousel 
        title={<>No headspace for<br/>furniture shopping?</>}
        subHeading={<>Outsource your furniture search to your very own stylist</>}
        buttonText="Get your stylist"
      />
      <InfoLabel>
        <h2>We'll do the furniture shopping for you</h2>
        Moving home. School pickups. Zoom meetings.
        <hr/> 
        With all that (and more) who's got time for furniture shopping?
        <hr/>
        So leave that to us - we've got the expertise.
      </InfoLabel>
      <ReviewSection reviews={REVIEWS}/>
      <MethodSection>
        <MethodTitle>
          This is what effortless furniture shopping looks like
        </MethodTitle>
        {/* <p>
          Search and compare items from across 100+ furniture retailers in one place…or outsource all your furniture needs to your own dedicated interior stylist - for just £25
        </p> */}
        <MethodCardsContainer>

          <MethodCard 
            title={(<>Take a quick and fun <br/> <b>style quiz</b></>)}
          >
            <MethodCardImage src={cardLeftImage}/>
          </MethodCard>

          <MethodCard
            title={(<>Get a <b>tailored shortlist</b> from your interior stylist</>)}
          >
            <MethodCardImage src={cardMiddleImage}/>
          </MethodCard>  

          <MethodCard
            title={(<><b>Enjoy your hassle-free</b> furniture purchase</>)}
          >
            <MethodCardImage src={cardRightImage}/>
          </MethodCard>

        </MethodCardsContainer>
      </MethodSection>
      
      <StylistBanner>
        <h2>
          Get your stylist now.
        </h2>
        <p>
          We're so confident you'll love this new way of furniture shopping that we'll
          guarantee your styling fee back if you're not 100% satisfied
        </p>
        <Link to="/category">
          <b>GET STARTED</b>
        </Link>
      </StylistBanner>

      <GroupedFaq faqGroups={GROUP_FAQ_CONTENT}/>
      <Footer />
      <BottomFooter />
    </PageContainer>
  );
};
