import {mdiClose, mdiMagnify} from "@mdi/js"
import Icon from "@mdi/react";
import { FC, useEffect, useState } from "react";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { FilterPanelBackdrop } from "../../../../../pages/RecommendationsPage/FilterPanel";
import { SearchShopCategory, useSearchContext } from "../../../../../store/searchContext";
import { NavButton } from "../../NavButton";
import { SearchOptions } from "../SearchOptions";
import { LineItemContainer, MagnifyStyle, SearchInput } from "../SearchStyles";
import { 
  MobileSearchBox, 
  MobileSearchCloseButton, 
  MobileSearchLine, 
  MobileSearchWrapper 
} from "./MobileSearchStyles";

export const MobileSearch: FC = () => {
  const [ isOpen, setIsOpen ] = useState(false);
  const { applySearch, parse, search, appliedSearch, selectShopCat } = useSearchContext();

  const searchInput = document.getElementById("searchInputMobile") as HTMLInputElement;

  useEffect(() => {
    if (searchInput) searchInput.value = appliedSearch;
  }, [appliedSearch, searchInput])

  const close = () => {
    applySearch("", false, true);
    setIsOpen(false);

    if(searchInput)
      searchInput.value = "";
  };

  const openSearch = () => {
    setIsOpen(true);
    searchInput.focus();
  };
  const navigate = useNavigate();
  const url = useResolvedPath("").pathname;
  const isOnShopPage = url.includes("/shop");

  const goToShop = (catToSelect: SearchShopCategory|undefined = undefined) => {
    searchInput.blur();

    applySearch(searchInput?.value ?? "", false, !isOnShopPage).then(() => {
        selectShopCat(catToSelect);
        if (!isOnShopPage) navigate("/shop");
    });

    setIsOpen(false);
  };

  return (
    <MobileSearchWrapper>
      <NavButton 
        onClick={openSearch} 
        iconPath={mdiMagnify}
        iconSizeMobile={26}
      />
      <FilterPanelBackdrop isOpen={isOpen}/>
      <MobileSearchBox visible={isOpen}>
        <MobileSearchLine>
          <LineItemContainer >
            <Icon path={mdiMagnify} css={MagnifyStyle} size={1.5}/>
          </LineItemContainer>
          <SearchInput 
            id="searchInputMobile"
            onChange={e => parse(e.target.value)}
            color="white"
            placeholder="Search for items and retailers"
            onKeyPress={e => {if (e.key === "Enter") goToShop()}}
          />
          <LineItemContainer >
            <MobileSearchCloseButton onClick={close}>
              <Icon path={mdiClose} css={MagnifyStyle} size={1.5}/>
            </MobileSearchCloseButton>
          </LineItemContainer>
        </MobileSearchLine>
        <SearchOptions 
          visible={!!search}
          searchInput={searchInput}
          allCategoriesFirst={true}
          onSelect={() => setIsOpen(false)}
          // onBlur={() => setIsOpen(false)}
        />
      </MobileSearchBox>
    </MobileSearchWrapper>
  );
};