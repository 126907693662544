import { FC } from "react";
import { Link } from "react-router-dom";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import NavBar from "../../shared/NavBar/NavBar";
import { NotFoundPageWrapper } from "./NotFoundStyles";

export const NotFoundPage : FC = () => {
  return (<>
    <FlitchHelmet 
      title="404" 
      error="404 - Not Found"
      statusCode="404"/>
    <NavBar />

    <NotFoundPageWrapper>
      <h1>
        {"Oops! That page doesn't exist :("}
      </h1>

      <p>
        But Flitch is here to help you...why not <Link to="/category">take a style quiz</Link> and
        get some furniture recommendations, or <Link to="/shop">browse furniture</Link> from
        100+ retailers in one place
      </p>
    </NotFoundPageWrapper>
  </>)
}