import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import hash from "object-hash";
import { concatArray, notEqualTo } from "../utils/helpers";
import { useUserContext } from "./userContext";
//import { sendFullAnalytics } from "../pages/RecommendationsPage/recsUtils";
import { useSavedsContext } from "./savedsContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { fetchBrowseMetadata, getPartnerData, getStripePromoCodes, saveSingleAction } from "../services/api.service";

 interface ShopState {
  addAnalyticsSavedClick: (id: ItemId) => void;
  removeAnalyticsSavedClick: (id: ItemId) => void;
  addAnalyticsDiscardedClick: (id: ItemId) => void;
  removeAnalyticsDiscardedClick: (id: ItemId) => void;
  cart: CartEntry[];
  resetCart: () => void;
  addToCart: (item: IFurnitureItem) => void;
  removeFromCart: (item: IFurnitureItem) => void;
  increaseInCart: (item: IFurnitureItem) => void;
  decreaseInCart: (item: IFurnitureItem) => void;
  isCartOpen: boolean;
  toggleCartOpen: (state: boolean) => void;
  appliedPromoCode: StripePromoCode | undefined,
  applyPromoCode: (code: string) => Promise<void>;
  partnerData: PartnerData[];
  receiptOpen: boolean;
  toggleReceipt: (state: boolean) => void;
  browseMetadata: BrowseMetadata | undefined;
  updateBrowseMetadata: () => Promise<BrowseMetadata>;
 };

const ShopContext = createContext<ShopState>({} as ShopState);
export const useShopContext = () => useContext(ShopContext);

export type CartEntry = {
  item: IFurnitureItem;
  quantity: number;
};

const createCartEntry = (item: IFurnitureItem, quantity = 1) => {
  return { item, quantity } as CartEntry
};

export const ShopProvider: FC<{children?: React.ReactNode}> = ({children}) => {
  const { customerId, user } = useUserContext();
  const { savedsSets, discardsSets } = useSavedsContext();
  
  const [savedItems, savedItemsSet] = useState<ItemId[]>([]);
  const [discardItems, discardItemsSet] = useState<ItemId[]>([]);

  const [partnerData, setPartnerData] = useState<PartnerData[]>([]);

  const [appliedPromoCode, setAppliedPromoCode] = useLocalStorage<StripePromoCode|undefined>("appliedStripeCoupon", undefined);
  
  const [cart, setCart] = useLocalStorage<CartEntry[]>("shopCart", []);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const toggleCartOpen = (state: boolean) => setIsCartOpen(state);

  const [receiptOpen, setReceiptOpen] = useState(false);
  const toggleReceipt = (state: boolean) => setReceiptOpen(state);

  const [browseMetadata, browseMetadataSet] = useState<BrowseMetadata>();

  const updateBrowseMetadata = async () => {
    const data = await fetchBrowseMetadata();
    browseMetadataSet(data);
    return data;
  };

  useEffect(() => {
    updateBrowseMetadata()
      .catch(console.error);
  }, []);

  const applyPromoCode = async (code: string = "") => {
    if (!code) {
      setAppliedPromoCode(undefined);
      return;
    }

    try {
      const codes = await getStripePromoCodes();
      const toUse = codes.find(promo => promo.code === code);
      setAppliedPromoCode(toUse);
    }
    catch(err){
      console.error(err)
    }
  };
  
  const resetCart = () => {
    setCart([]);
    setAppliedPromoCode(undefined);
  };

  const addToCart = (item: IFurnitureItem) => {
    setCart([...cart, createCartEntry(item)]);
    saveSingleAction("addToCart", item.category ?? "all", item.id);
  };

  const removeFromCart = (item: IFurnitureItem) => {
    setCart(cart.filter(entry => entry.item !== item));
    saveSingleAction("removeFromCart", item.category ?? "all", item.id);
  };

  const changeQuantityInCart = (item: IFurnitureItem, step: number) => {
    const index = cart.findIndex(x => x.item.id === item.id);
    if (index < 0) return;

    const newQuantity = cart[index]!.quantity + step;
    if (newQuantity === 0) return;

    const newCart = cart.map((x, i) => i === index
      ? createCartEntry(x.item, newQuantity) : x);

    setCart(newCart);
  }

  const increaseInCart = (item: IFurnitureItem) => changeQuantityInCart(item, 1);
  const decreaseInCart = (item: IFurnitureItem) => changeQuantityInCart(item, -1);

  const shopHash = hash(
    { category: "shop", customerId },
    { unorderedArrays: true, unorderedObjects: true }
  ) as JourneyHash;

  useEffect(() => {
    getPartnerData().then(
      res => setPartnerData(res),
      err => console.error(err)
    );
  }, [shopHash]);

  useEffect(() => {
    savedItemsSet([...savedsSets["shop"]]);
    discardItemsSet([...discardsSets["shop"]]);
  }, [
    shopHash, 
    savedsSets["shop"], 
    discardsSets["shop"]
  ]);

  // useEffect(() => {
  //   sendFullAnalytics({
  //     category: "shop",
  //     journeyHash: shopHash,
  //     sq1SelectedIds: [],
  //     sq2SelectedIds: [],
  //     sqRefineSelectedIds: [],
  //     recommendedIds: [],
  //     filterPayload: {} as AllFilterPayload,
  //     buyNowClicks: [],
  //     itemDiscards: discardItems,
  //     itemSaves: savedItems,
  //     customerId,
  //     requestSeconds: 0
  //   })}, [
  //     concatArray(discardItems), 
  //     concatArray(savedItems),
  //     customerId,
  //     user
  //   ]);

  const addAnalyticsSavedClick = (id: ItemId) =>
    savedItemsSet((state) => [...state, id]);

  const removeAnalyticsSavedClick = (id: ItemId) =>
    savedItemsSet((state) => state.filter(notEqualTo(id)));

  const addAnalyticsDiscardedClick = (id: ItemId) =>
    discardItemsSet((state) => [...state, id]);

  const removeAnalyticsDiscardedClick = (id: ItemId) =>
    discardItemsSet((state) => state.filter(notEqualTo(id)));

  return (
    <ShopContext.Provider
      value={{
        addAnalyticsSavedClick,
        removeAnalyticsSavedClick,
        addAnalyticsDiscardedClick,
        removeAnalyticsDiscardedClick,
        cart: cart ?? [],
        resetCart,
        addToCart,
        removeFromCart,
        increaseInCart,
        decreaseInCart,
        isCartOpen,
        toggleCartOpen,
        appliedPromoCode,
        applyPromoCode,
        partnerData,
        receiptOpen,
        toggleReceipt,
        browseMetadata,
        updateBrowseMetadata
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};
