import React from "react";
import {
  StatusRow,
  StatusContainer,
  StatusImg,
  StatusLabel,
} from "./ItemStatusWrapper";

import ExclamationMark from "../../../images/exclamationMark.png";
import QuestionMark from "../../../images/questionMark.png";
import CheckMark from "../../../images/checkMark.png";
import { useFilterCtx } from "../../../store/filterContext";
import { useCategory } from "../../../hooks/useCategory";
import { isNotFalsy } from "../../../utils/helpers";

const getIconImg = (status: boolean | null) =>
  status === null
    ? QuestionMark
    : status === true
    ? CheckMark
    : ExclamationMark;

interface IProps {
  item: IFurnitureItem;
}

export const ItemStatus = ({ item }: IProps) => {
  const { filterOptions } = useFilterCtx();
  const category = useCategory();
  const filterOpts = filterOptions[category];

  return (
    <StatusRow>
      <StatusContainer>
        <StatusImg img={getIconImg(true)} />
        <StatusLabel>Style Match</StatusLabel>
      </StatusContainer>
      <StatusContainer>
        <StatusImg
          img={getIconImg(
            isNotFalsy(filterOpts.minBudget ?? filterOpts.maxBudget) || null
          )}
        />
        <StatusLabel>Budget Match</StatusLabel>
      </StatusContainer>
      <StatusContainer>
        <StatusImg
          img={getIconImg(
            isNotFalsy(
              filterOpts.minWidth ??
                filterOpts.maxWidth ??
                filterOpts.minHeight ??
                filterOpts.maxHeight ??
                filterOpts.minDepth ??
                filterOpts.maxDepth ??
                (category === "coffeetable"
                  ? filterOptions[category].size
                  : category === "chestofdrawers" || category === "shelving"
                  ? filterOptions[category].height ??
                    filterOptions[category].width
                  : null)
            ) || null
          )}
        />
        <StatusLabel>Perfect Fit</StatusLabel>
      </StatusContainer>
      <StatusContainer>
        <StatusImg img={getIconImg(item.isChildFriendly)} />
        <StatusLabel>Easy Clean</StatusLabel>
      </StatusContainer>
      <StatusContainer>
        <StatusImg
          img={getIconImg(item.stockData ? item.stockData.isInStock : false)}
        />
        <StatusLabel>In Stock</StatusLabel>
      </StatusContainer>
      <StatusContainer>
        <StatusImg img={getIconImg(item.returnsAllowed)} />
        <StatusLabel>Free returns</StatusLabel>
      </StatusContainer>
    </StatusRow>
  );
};

export default ItemStatus;
