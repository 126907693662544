export type RefContainer<T = unknown, K extends string | number = string> = {
  key: K | null;
  current: T | null;
  prev: RefContainer<T, K> | null;
  next: RefContainer<T, K> | null;
};

const makeNewRefContainer = <T, K extends string | number>({
  key = null,
  current = null,
  prev = null,
}: {
  key?: K | null;
  current?: T | null;
  prev?: RefContainer<T, K> | null;
}): RefContainer<T, K> => ({ key, current, prev, next: null });

/**
 * Uses a doubly linked list implementation to reference both the previous and
 * next created nodes.
 */
export const useRefsList = <T, K extends string | number = string>() => {
  const refsList: RefContainer<T, K>[] = [];

  const makeNewRef = (key?: K) => {
    const len = refsList.length;
    const prev = refsList[len - 1] ?? null;

    const newRef = makeNewRefContainer<T, K>({ key, prev });

    if (prev) {
      prev.next = newRef;
    }

    refsList.push(newRef);

    return newRef;
  };

  const getRefByKey = (searchKey: K) =>
    refsList.find(({ key }) => searchKey === key) ?? null;

  return { makeNewRef, getRefByKey };
};
