import React, { useState, useEffect, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { map, mapValues } from "lodash";
import debounce from "lodash/debounce";
import MediaQuery from "react-responsive";
import axios from "axios";

import {
  ActivityPageWrapper,
  ActivityPageTopPart,
  UserTitleWrapper,
  ActivityPageBottomPart,
  ViewProfileButton,
  LikedItemsWrapper,
  LikedItem,
  LikedItemCategory,
  ActivityCategoriesWrapper,
  YourItemsInCategory,
  ListingOfCategory,
  ListingOfCategoryWrapper,
  ActivityPageHR,
  ActivityPageContainer,
  DeleteItemMobile,
  DeleteItemDesktop,
  CardImageStyled,
  CardImageWrapper,
} from "./ActivityPageWrapper";
import NavBar from "../../shared/NavBar/NavBar";
import { apiUrl } from "../../config";
import crossGrey from "../../images/icons/cross_grey.svg";
import { useUserContext } from "../../store/userContext";
import { categoryData } from "../../store/categoryData";
import { getLikedAndRecs } from "../../store/savedsContext";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";

function getFirstOfFirstNonEmpty<T>(...arrays: T[][]): T | null {
  return arrays.reduce(
    (last: T | null, thisArray) => last ?? thisArray[0] ?? null,
    null
  );
}

type LikesRecsSaveds = {
  likes: IFurnitureItem[];
  recommendations: IFurnitureItem[];
  saveds: IFurnitureItem[];
};

type LikedType = keyof LikesRecsSaveds;

type LikedApiResponse = Record<CategoryUnion, LikesRecsSaveds>;

/**
 * @TODO need to unify a lot of this stuff  with the similar/duplicate
 * functionality in `savedsContext`.
 * Also probably need to clean up the debounced logic which I suspect doesn't
 * correctly handle successive calls with different itemIDs.
 */

const deleteLikesDebounced = debounce(async (data: ItemId[]) => {
  await axios.delete(`${apiUrl}/likes/liked`, { data });
  return data;
}, 1000);

const deleteRecsDebounced = debounce(async (data: ItemId[]) => {
  await axios.delete(`${apiUrl}/likes/recommended`, { data });
  return data;
}, 1000);

const deleteSavedsDebounced = debounce(async (data: ItemId[]) => {
  await axios.delete(`${apiUrl}/likes/saved`, { data });
  return data;
}, 1000);

const ActivityPage = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [data, setData] = useState<LikedApiResponse | null>(null);

  const [selectedCategory, selectedCategorySet] =
    useState<CategoryUnion | null>(null);
  const [likesToDelete, setLikesToDelete] = useState<ItemId[]>([]);
  const [recsToDelete, setRecsToDelete] = useState<ItemId[]>([]);
  const [savedsToDelete, setSavedsToDelete] = useState<ItemId[]>([]);

  const likesOfUser =
    data && selectedCategory ? data[selectedCategory].likes : [];
  const recommendationsOfUser =
    data && selectedCategory ? data[selectedCategory].recommendations : [];
  const savedsOfUser =
    data && selectedCategory ? data[selectedCategory].saveds : [];

  const clearCategory = () => selectedCategorySet(null);

  useEffect(() => {
    if (user) {
      getLikedAndRecs().then(setData);
    }
  }, [selectedCategory, user]);

  const handleDeleteItem = (id: ItemId, likedType: LikedType) => {
    if (data) {
      const filteredData: LikedApiResponse = mapValues(
        data,
        (likesAndRecs) => ({
          ...likesAndRecs,
          [likedType]: likesAndRecs[likedType].filter((item) => item.id !== id),
        })
      );

      setData(filteredData);

      switch (likedType) {
        case "likes":
          return setLikesToDelete((vals) => [...vals, id]);
        case "recommendations":
          return setRecsToDelete((vals) => [...vals, id]);
        case "saveds":
          return setSavedsToDelete((vals) => [...vals, id]);
      }
    }
  };

  useEffect(() => {
    if (likesToDelete.length > 0) {
      deleteLikesDebounced(likesToDelete);
    }
  }, [likesToDelete]);

  useEffect(() => {
    if (recsToDelete.length > 0) {
      deleteRecsDebounced(recsToDelete);
    }
  }, [recsToDelete]);

  useEffect(() => {
    if (savedsToDelete.length > 0) {
      deleteSavedsDebounced(savedsToDelete);
    }
  }, [savedsToDelete]);

  return (
    <ActivityPageWrapper>
      <FlitchHelmet title="Your Activity"/>
      <NavBar />
      <ActivityPageContainer>
        <ActivityPageTopPart>
          <UserTitleWrapper>
            <h1>{user?.displayName ?? "You"}</h1>
            <ActivityCategoriesWrapper>
              <p>
                <span onClick={clearCategory}>Your Activity </span>
                <span style={{ textTransform: "capitalize" }}>
                  {selectedCategory &&
                    `˃ ${categoryData[selectedCategory].plural}`}
                </span>
              </p>
            </ActivityCategoriesWrapper>
          </UserTitleWrapper>
          <ViewProfileButton to="/profile">
            <span>VIEW PROFILE</span>
          </ViewProfileButton>
        </ActivityPageTopPart>
        <ActivityPageHR />
        {selectedCategory ? (
          <>
            <ListingOfCategoryWrapper>
              <YourItemsInCategory>YOUR SAVED ITEMS</YourItemsInCategory>
              <ListingOfCategory>
                {savedsOfUser.map((item, k) => (
                  <CardImage
                    key={item.id + k}
                    isInLikes={false}
                    item={item}
                    handleDeleteItem={() => handleDeleteItem(item.id, "saveds")}
                    category={selectedCategory}
                  />
                ))}
              </ListingOfCategory>
            </ListingOfCategoryWrapper>
            <ListingOfCategoryWrapper>
              <ActivityPageHR />
              <YourItemsInCategory>YOUR RECOMMENDATIONS</YourItemsInCategory>
              <ListingOfCategory>
                {recommendationsOfUser.map((item, k) => (
                  <CardImage
                    key={item.id + k}
                    isInLikes={false}
                    item={item}
                    handleDeleteItem={() =>
                      handleDeleteItem(item.id, "recommendations")
                    }
                    category={selectedCategory}
                  />
                ))}
              </ListingOfCategory>
            </ListingOfCategoryWrapper>
            <ListingOfCategoryWrapper>
              <ActivityPageHR />
              <YourItemsInCategory>YOUR LIKES</YourItemsInCategory>
              <ListingOfCategory>
                {likesOfUser.map((item, k) => (
                  <CardImage
                    key={item.id + k}
                    isInLikes={true}
                    item={item}
                    handleDeleteItem={() => handleDeleteItem(item.id, "likes")}
                    category={selectedCategory}
                  />
                ))}
              </ListingOfCategory>
            </ListingOfCategoryWrapper>
          </>
        ) : (
          <ActivityPageBottomPart>
            {data &&
              map(data, (items, categoryLabel: CategoryUnion) => {
                if (
                  items.saveds.length > 0 ||
                  items.recommendations.length > 0 ||
                  items.likes.length > 0
                ) {
                  return (
                    <LikedItemsWrapper
                      onClick={() => selectedCategorySet(categoryLabel)}
                      key={
                        getFirstOfFirstNonEmpty(
                          items.saveds,
                          items.recommendations,
                          items.likes
                        )?.id
                      }
                    >
                      <LikedItem
                        src={
                          getFirstOfFirstNonEmpty(
                            items.saveds,
                            items.recommendations,
                            items.likes
                          )?.images.thumbnail.mainImage
                        }
                      />
                      <div>
                        <LikedItemCategory>
                          {categoryData[categoryLabel].plural}
                        </LikedItemCategory>
                      </div>
                    </LikedItemsWrapper>
                  );
                } else {
                  return null;
                }
              })}
          </ActivityPageBottomPart>
        )}
      </ActivityPageContainer>
    </ActivityPageWrapper>
  );
};

interface ICardImage {
  item: IFurnitureItem;
  category: CategoryUnion;
  isInLikes: boolean;
  handleDeleteItem: (id: ItemId) => void;
}

const handleImageClick =
  (id: ItemId, category: string, mouseOnX: boolean) => (e: MouseEvent) => {
    e.preventDefault();

    if (!mouseOnX) {
      const win = window.open(`/product/${id}`, "_blank");
      win?.focus();
    }
  };

const CardImage = ({ category, item, handleDeleteItem }: ICardImage) => {
  const [mouseOnX, setShowX] = React.useState(false);

  return (
    <CardImageWrapper>
      <MediaQuery maxDeviceWidth={768}>
        <DeleteItemMobile
          onClick={() => handleDeleteItem(item.id)}
          src={crossGrey}
        />
      </MediaQuery>
      <MediaQuery minDeviceWidth={769}>
        <DeleteItemDesktop
          className="showX"
          onClick={() => handleDeleteItem(item.id)}
          src={crossGrey}
          onMouseEnter={() => setShowX(true)}
          onMouseLeave={() => setShowX(false)}
        />
      </MediaQuery>
      <CardImageStyled
        onClick={handleImageClick(item.id, category, mouseOnX)}
        src={item.images.thumbnail.mainImage}
      />
    </CardImageWrapper>
  );
};

export default ActivityPage;
