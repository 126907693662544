import checkMark from "../../../images/icons/checkmark.svg";
import magnifier from "../../../images/magnifier.svg";
import { getProductUrl, seoficateTag } from "../../../utils/helpers";
import {
  ListingWrapper,
  Card,
  CheckMarkIcon,
  CardImageStyled,
  Magnifier,
  MagnifierMobile,
  ShowUpToMax,
  ShowFromMin,
} from "./ListingWrapper";

interface ListingProps {
  items: IFurnitureItem[];
  selected: ItemId[];
  toggleSelectItem(id: ItemId): void;
  category: CategoryUnion;
}

export const Listing = ({
  selected,
  items,
  toggleSelectItem,
  category,
}: ListingProps) => {
  const handleMagnifierClick = (item: IFurnitureItem) => () => {
    const win = window.open(getProductUrl(item), "_blank");
    win?.focus();
    toggleSelectItem(item.id);
  };

  const handleSelectItem = (id: ItemId) => () => toggleSelectItem(id);

  return (
    <>
      <ListingWrapper category={category}>
        {items.map((item) => {
          const isSelected = selected.includes(item.id);
          const { mainImageGb, backImageGb, mainImage, backImage } =
            item.images.thumbnail;

          return (
            <CardImage
              isSelected={isSelected}
              main={mainImageGb ?? mainImage} // Grey BG versions should always be non-null for dynamic SQ, at least after imgs have loaded
              back={backImageGb ?? backImage} // Grey BG versions should always be non-null for dynamic SQ, at least after imgs have loaded
              key={item.id}
              handleSelectItem={handleSelectItem(item.id)}
              handleMagnifierClick={handleMagnifierClick(item)}
            />
          );
        })}
      </ListingWrapper>
    </>
  );
};

interface CardImageProps {
  main: string;
  back: string | null;
  isSelected: boolean;
  handleSelectItem: VoidFunction;
  handleMagnifierClick: VoidFunction;
}

const CardImage = ({
  back,
  main,
  isSelected,
  handleMagnifierClick,
  handleSelectItem,
}: CardImageProps) => {
  return (
    <Card
      isSelected={isSelected}
      onClick={handleSelectItem}
      className="click-stylequizitem"
    >
      <CardImageStyled
        isSelected={isSelected}
        imgUrlFront={main}
        imgUrlBack={back}
      />
      <CheckMarkIcon isSelected={isSelected} src={checkMark} />
      <ShowUpToMax maxWidth={768}>
        <MagnifierMobile
          showMagnifier={isSelected}
          onClick={handleMagnifierClick}
          src={magnifier}
        />
      </ShowUpToMax>
      <ShowFromMin minWidth={769}>
        <Magnifier
          showMagnifier={isSelected}
          className="showMagnifier"
          onClick={handleMagnifierClick}
          src={magnifier}
        />
      </ShowFromMin>
    </Card>
  );
};
