import React, { FC } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import desktopBg from "../../images/background-desktop-prefilters.png";
import mobileBg from "../../images/background-mobile-prefilters.png";
import { categoryData } from "../../store/categoryData";
import { colors } from "../../styles/globalStyled";
import {
  colourSchemeDisplayMap,
  getMaterialOrGroupDisplayname,
  isBedSize,
  isCoffeeTableSize,
} from "./filterHelpers";
import { breakpoints } from "../../styles/responsive";
import {
  subtitleSize,
  titleSize,
  summaryTitleSize,
  sizetitleSize,
} from "./sizes";
import { RefContainer } from "../../hooks/useRefsList";
import { makeListHumanFriendly } from "../../utils/helpers";

const BackButton = () => (
  <FontAwesomeIcon size="1x" icon={faArrowLeft} color="#fff" />
);

const SectionArrow = () => (
  <FontAwesomeIcon size="1x" icon={faArrowRight} color={colors.orange} />
);

export const SQFilterPageStyled = styled.div`
  scroll-snap-type: y mandatory;

  height: var(--height-under-navbar);
  margin-top: var(--navbar-height);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(${mobileBg});

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: var(--navbar-height-mobile);
    height: calc(var(--height-under-navbar-mobile) - var(--navbar-height-mobile));

  }

  @media (min-width: ${breakpoints.mobile}) {
    background-position: 100% center;
    background-image: url(${desktopBg});
  }

  overflow: auto;
`;

const SQFilterSectionStyled = styled.section<{ isBlackAndWhite?: boolean }>`
  height: var(--height-under-navbar);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  padding: 0 30px;

  ${({ isBlackAndWhite }) =>
    isBlackAndWhite &&
    css`
      backdrop-filter: grayscale(1);
    `}

  @media (max-width: ${breakpoints.mobile}) {
    height: calc(var(--height-under-navbar-mobile) - var(--navbar-height-mobile));
  }
`;

const InnerSQFilterContainer = styled.div`
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  flex-direction: column;

  @media (min-width: ${breakpoints.mobile}) {
    flex-direction: row;
  }
`;

const SQFilterContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.mobile}) {
    padding-left: 5px;
  }
`;

const ButtonRow = styled.div<{ hasSelected: boolean; isMandatory?: boolean }>`
  display: flex;
  & > button {
    border: none;
    text-transform: uppercase;
    color: white;
    font-size: 1.5rem;
    padding: 10px;
    cursor: pointer;
  }
  .back {
    background-color: ${colors.greyDark};
    margin-right: 10px;
  }
  .next {
    background-color: ${({ hasSelected, isMandatory }) =>
      isMandatory && !hasSelected ? colors.greenDisabled : colors.green};
  }
`;

const TitleLeftRow = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;

  .title-item {
    font-size: 1.75rem;
    margin: 0;

    @media (min-width: ${breakpoints.mobile}) {
      margin: 0 2px;
    }
  }

  .index-char {
    color: ${colors.orange};
  }

  .arrow {
    font-size: 1.3rem;
    color: ${colors.orange};

    display: none;
    @media (min-width: ${breakpoints.mobile}) {
      display: initial;
    }
  }
`;

const FilterSectionTitle = styled.p`
  font-size: ${titleSize}rem;
  font-weight: bold;
`;

export const FilterSectionSubtitle = styled.p`
  font-size: ${subtitleSize}rem;
`;

export const FilterSectionSizeTitle = styled.p`
  font-size: ${sizetitleSize}rem;
  margin: 0.7rem 0 1rem;
  font-weight: bold;
`;

const FilterContent = styled.div`
  margin: 10px 0;
  width: 100%;
`;

interface SectionProps {
  sectionIndex?: string;
  title?: string;
  subtitle?: string;
  onPressBack?: VoidFunction;
  onPressNext?: VoidFunction;
  isSelected: boolean;
  isBlackAndWhite?: boolean;
  selectionIsMandatory?: boolean;
  elemRef: RefContainer<HTMLElement>;
  goToElem: (ref: RefContainer<HTMLElement>) => void;
  children?: React.ReactNode
}

export const SQFilterSection: FC<SectionProps> = ({
  sectionIndex,
  title,
  subtitle,
  onPressBack,
  onPressNext,
  isSelected,
  isBlackAndWhite,
  selectionIsMandatory,
  elemRef,
  goToElem,
  children,
}) => {
  const { prev, next } = elemRef;

  return (
    <SQFilterSectionStyled isBlackAndWhite={isBlackAndWhite} ref={elemRef}>
      <InnerSQFilterContainer>
        {sectionIndex && (
          <TitleLeftRow>
            <div className="title-item index-char">{sectionIndex}</div>
            <div className="title-item arrow">
              <SectionArrow />
            </div>
          </TitleLeftRow>
        )}
        <SQFilterContentContainer>
          {title && <FilterSectionTitle>{title}</FilterSectionTitle>}
          {subtitle && (
            <FilterSectionSubtitle>{subtitle}</FilterSectionSubtitle>
          )}
          <FilterContent>{children}</FilterContent>
          <ButtonRow
            isMandatory={selectionIsMandatory}
            hasSelected={isSelected}
          >
            {(onPressBack || prev) && (
              <button
                onClick={
                  onPressBack ?? (prev ? () => goToElem(prev) : undefined)
                }
                className="back"
              >
                <BackButton />
              </button>
            )}
            <button
              onClick={onPressNext ?? (next ? () => goToElem(next) : undefined)}
              className="next"
              disabled={selectionIsMandatory && !isSelected}
            >
              {!selectionIsMandatory && !isSelected ? "Skip" : "Continue"}
            </button>
          </ButtonRow>
        </SQFilterContentContainer>
      </InnerSQFilterContainer>
    </SQFilterSectionStyled>
  );
};

const SummaryTitle = styled.p`
  font-size: ${summaryTitleSize}rem;
  margin-bottom: 15px;
`;

const SummaryRow = styled.div`
  margin-bottom: 25px;
`;

const SummaryText = styled.p`
  font-size: 1.5rem;
`;

const SummaryTitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const EditIconWrapped = styled.span`
  cursor: pointer;
  margin-right: 20px;
`;

const EditIcon: FC<{ onClick?: VoidFunction }> = ({ onClick }) => (
  <EditIconWrapped className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faCircle} size="2x" onClick={onClick} />
    <FontAwesomeIcon
      icon={faEdit}
      size="2x"
      onClick={onClick}
      inverse
      transform="shrink-8"
    />
  </EditIconWrapped>
);

interface SummarySectionProps extends SectionProps {
  category: CategoryUnion;

  colourSchemes: ColourScheme[];
  materials: (MaterialGroup | Material)[];
  tone?: Tone | null;
  size?: CoffeeTableSize | BedSize | SofaSeatsNum | null;
  height?: NumRange | HeightName | null;
  width?: NumRange | WidthName | null;
  budget?: NumRange | undefined | null;

  preferCornerSofa?: boolean;
  preferSofabed?: boolean;

  goToColour: VoidFunction;
  goToMaterial: VoidFunction;
  goToSize: VoidFunction;
  goToBudget: VoidFunction;
  goToOther: VoidFunction;
}

export const SummarySection: FC<SummarySectionProps> = ({
  category,

  colourSchemes,
  tone,
  materials,
  size,
  height,
  width,
  budget,

  preferCornerSofa,
  preferSofabed,

  goToColour,
  goToMaterial,
  goToSize,
  goToBudget,
  goToOther,
  ...rest
}) => {
  // @TODO: Finish up summary wordings for variations in filter fields between categories

  return (
    <SQFilterSection {...rest}>
      <SummaryTitle>Summary</SummaryTitle>
      <SummaryRow>
        <SummaryTitleRow>
          <EditIcon onClick={goToColour} />
          <FilterSectionTitle>Colour</FilterSectionTitle>
        </SummaryTitleRow>
        {colourSchemes.length > 0 ? (
          <SummaryText>
            You like{" "}
            {makeListHumanFriendly(
              colourSchemes.map((colourScheme) =>
                colourSchemeDisplayMap[colourScheme].toLocaleLowerCase()
              )
            )}{" "}
            {(tone === "Light" || tone === "Dark") &&
              `that are ${tone.toLocaleLowerCase()} in tone`}
          </SummaryText>
        ) : (
          <SummaryText>You have no colour preference</SummaryText>
        )}
      </SummaryRow>

      <SummaryRow>
        <SummaryTitleRow>
          <EditIcon onClick={goToMaterial} />
          <FilterSectionTitle>Material</FilterSectionTitle>
        </SummaryTitleRow>
        {materials.length > 0 ? (
          <SummaryText>
            You like{" "}
            {makeListHumanFriendly(
              materials.map((material) =>
                getMaterialOrGroupDisplayname(material).toLocaleLowerCase()
              )
            )}
          </SummaryText>
        ) : (
          <SummaryText>You have no material preference</SummaryText>
        )}
      </SummaryRow>

      <SummaryRow>
        <SummaryTitleRow>
          <EditIcon onClick={goToSize} />
          <FilterSectionTitle>Size</FilterSectionTitle>
        </SummaryTitleRow>
        {size || width || height ? (
          <SummaryText>
            You're looking for a {categoryData[category].displayName} that is{" "}
            {size &&
              (isCoffeeTableSize(size) || isBedSize(size)
                ? size.toLocaleLowerCase()
                : `a ${size}-seater`)}
            {makeListHumanFriendly(
              [
                height &&
                  (typeof height === "string"
                    ? height // @TODO: need to map these to the size's display names, e.g. standard -> average
                    : `between ${height[0]}cm and ${height[1]}cm tall`),

                width &&
                  (typeof width === "string"
                    ? width // @TODO: need to map these to the size's display names, e.g. standard -> average
                    : `between ${width[0]}cm and ${width[1]}cm wide`),
              ].map((s) => s?.toLocaleLowerCase())
            )}
          </SummaryText>
        ) : (
          <SummaryText>You expressed no size preference</SummaryText>
        )}
      </SummaryRow>

      <SummaryRow>
        <SummaryTitleRow>
          <EditIcon onClick={goToBudget} />
          <FilterSectionTitle>Budget</FilterSectionTitle>
        </SummaryTitleRow>
        <SummaryText>
          {budget
            ? `Your budget is between £${budget[0]} and £${budget[1]}`
            : `You have not specified a budget`}
        </SummaryText>
      </SummaryRow>

      {(preferCornerSofa !== undefined || preferSofabed !== undefined) && (
        <SummaryRow>
          <SummaryTitleRow>
            <EditIcon onClick={goToOther} />
            <FilterSectionTitle>Other</FilterSectionTitle>
          </SummaryTitleRow>
          <SummaryText>
            {`You'd like a ${
              preferCornerSofa !== undefined
                ? preferCornerSofa
                  ? "corner"
                  : "non-corner"
                : ""
            } sofa${
              preferSofabed !== undefined
                ? ` that can${preferSofabed ? "" : "not"} transform into a bed`
                : ""
            }`}
          </SummaryText>
        </SummaryRow>
      )}
    </SQFilterSection>
  );
};
