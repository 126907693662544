import styled, { css } from "styled-components";
import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";

//import wideBg from "../../images/background-desktop-home.png"
import checkMark from "../../images/aboutstylist/checkMark_white.png"
import topTriangle from "../../images/aboutstylist/Triangle_Teal_Left.png"
import bottomTriangle from "../../images/aboutstylist/Triangle_Orange_Right.png"
import mobileBack from "../../images/aboutstylist/SQ.png"

const SectionHeaderCss = css`
  font-size: ${fontSizes.hsDescTitle};
  color: ${colors.greyDark};
  font-weight: 450;
  letter-spacing: -1px;
  margin-bottom: 5%;

  @media (max-width: ${breakpoints.netbook}) {
    font-size: ${fontSizes.veryLarge};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.hsDescription};
    line-height: 1;
    margin-bottom: 10%;
  }
`;

export const AboutStylistPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  scroll-snap-type: x mandatory;
  /* padding-top: var(--navbar-height-mobile); */
  
  position: relative;

  @media (min-width: ${breakpoints.mobile}) {
    /* padding-top: var(--navbar-height); */
    position: absolute;
    top: var(--navbar-height);
    left: 0;
    right: 0;
    bottom: 0;

    &::-webkit-scrollbar {
      height: 0; /*height for horizontal scrollbars!*/
    }
  }
`;

export const TopLeftCorner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  height: 644px;
  background-image: url(${topTriangle});
  background-repeat: no-repeat;
  z-index: -1;
  background-size: 80%;

  @media (max-width: ${breakpoints.netbook}) {
    background-size: 60%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const BottomRightCorner = styled.div`
  position: absolute;
  width: 476px;
  height: 375px;
  background-image: url(${bottomTriangle});
  z-index: -1;
  
  bottom: 0;
  right: 0;

  @media (max-width: ${breakpoints.twoK}) {
    /*2k res is strange 🤷*/
    /* top: 33.27vh;  */
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

interface DescProps {
  isHome: boolean
}

const getDescHeight = (p: DescProps) =>
  p.isHome ? "auto" : "calc(1080px - var(--navbar-height))";

const descPadding = css`
  padding: 5vh 0 0;

  @media (max-width: ${breakpoints.netbook}) {
    padding: 2vh 0 0;
  }
`;

const getDescPadding = (p: DescProps) =>
  p.isHome ? descPadding : undefined;

export const DescWrapper = styled.div<DescProps>`
  /* we prefer it to have a static width and height*/
  box-shadow: 0px 0px 15px rgba(10,10,92,0.1) inset;
  width: 100%; 
  margin: auto;

  position: relative;
  display: grid;
  grid-template-columns: 1fr 485px 485px;
  grid-template-rows: max-content;
  padding-bottom: 3em;

  @media (max-width: ${breakpoints.netbook}) {
    grid-template-columns: max(calc(100vw - 660px), 290px) 330px 330px;
  }
  
  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr 1.2fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
  `;

const getMobDescHeight = (p: DescProps) =>
  p.isHome ? "3vh" : "25vh";

export const DescWrapperMobile = styled.div<DescProps>`
  display: flex;
  flex-direction: column;
  padding-top: ${getMobDescHeight};

  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const PlanHeader = styled.h2`
  text-align: center;
  color: ${colors.greyDark};
  font-weight: 450;
  margin-top: 1vh;
  font-size: ${fontSizes.larger};
`;

export const DescContainer = styled.div`
  display: grid;
  position: relative;
  top: 5%;
  grid-template-columns: 1fr 485px 485px;
  grid-template-rows: calc(910px - var(--navbar-height));
  width: 1824px;
  margin: auto;
  /* height: 100vh; */
  overflow-x: auto;

  @media (max-width: ${breakpoints.netbook}) {
    width: 95vw;
  }

  @media (max-width: ${breakpoints.netbook}) {
    grid-template-columns: 1fr 400px 400px;
  }
`;

export const GeneralDescription = styled.div`
  text-align: center;
  /* vertical-align: middle; */
  padding: 3% 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: max-content;
  margin-top: 5em;
  grid-row: 1 / -1;
  align-self: center;

  h1 { 
    ${SectionHeaderCss} 
  }

  h2 { 
    ${SectionHeaderCss} 
  }

  p {
    font-size: ${fontSizes.larger};
    font-weight: 300;
    color: ${colors.greyDarker};
  }

  @media (max-width: ${breakpoints.mobile}) {
    /* box-shadow: 0 4px 5px -2px rgba(10,10,92,0.1); */
    padding-top: 3%;
  }
`;

export const NumberedStepWrapper = styled.div`
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: 80px 1fr;
  margin-top: 5%;
  margin-bottom: 2%;

  @media (max-width: ${breakpoints.netbook}) {
    margin-bottom: 5%;
    grid-template-columns: 70px 1fr;
  }
`;

export const Number = styled.div`
  font-size: ${fontSizes.veryLarge};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${colors.greenPale};
  border-radius: 100%;
  margin: auto;
  padding: 5px;
  width: 70px;
  height: 70px;
  box-shadow: 0 0 3px 0px #3C8586;

  @media (max-width: ${breakpoints.netbook}) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 50px;
    height: 50px;
    font-size: ${fontSizes.larger};
  }
`;

export const NumberLabel = styled.div`
  margin: auto;
  position: relative;
  width: 98%;
  text-align: left;
  color: ${colors.greyMediumDarker};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1vw;
  
  a {
    text-decoration: underline;
  }
  
  p {
    font-size: ${fontSizes.larger};
    letter-spacing: -1px;
    line-height: 1.2em;

    @media (max-width: ${breakpoints.netbook}) {
      font-size: ${fontSizes.mediumLarger};
      margin-bottom: 3%;
    }

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mediumLarger};
      /* margin-bottom: 7%; */
    }
  }
`;

export const VariantContainer = styled.div`
  /*margin: auto;*/
  position: relative;
  height: max(max-content, 100% - 7em);
  width: 90%;
  color: ${colors.greyDark};
  letter-spacing: -1px;
  background-color: white;
  letter-spacing: -1px;
  box-shadow: 0 3px 10px 2px rgba(0,0,0,.15);
  margin: 5em 0 0 0;

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / -1;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin: 10% auto 0 auto;
    width: 80%;
    height: auto;
  }

  h2 {
    font-size: ${fontSizes.hsDescription};
    font-weight: 600;
    margin-bottom: 4%;

    @media (max-width: ${breakpoints.netbook}) {
      font-size: ${fontSizes.larger};
    }

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.larger};
    }
  }

  p {
    font-size: ${fontSizes.larger};
    margin-bottom: 4%;
    line-height: 1.1em;

    @media (max-width: ${breakpoints.netbook}) {
      font-size: ${fontSizes.large};
    }

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mediumLarger};
    }
  }
`;

interface VariantDetails {
  isFree: boolean
};

const getColour = (details: VariantDetails) =>
  details.isFree ? colors.orange : colors.green;

export const VariantStripe = styled.div<{isFree: boolean}>`
  background-color: ${getColour};
  width: 100%;
  height: 7px;
`;

export const VariantWrapper = styled.div`
  padding: 5%;
`;

export const CostContainer = styled.span`
  display: flex;
  flex-direction: row;
`

interface CostLabelProps {
  striked?: boolean;
}

const getCostLabelColor = (p: CostLabelProps) =>
  p.striked ? colors.greyMedium : colors.greyDark;

const getCostLabelTestStyle = (p: CostLabelProps) =>
  p.striked ? "line-through" : "none";

  const getCostLabelTMargin = (p: CostLabelProps) =>
  p.striked ? "2%" : "0";

export const CostLabel = styled.div<CostLabelProps>`
  font-weight: 600;
  font-size: ${fontSizes.hsDescTitle};
  text-transform: uppercase;
  color: ${getCostLabelColor};
  text-decoration: ${getCostLabelTestStyle};
  margin-right: ${getCostLabelTMargin};

  @media (max-width: ${breakpoints.netbook}) {
    font-size: ${fontSizes.veryLarge};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.larger};
  }
`;

export const OffLabel = styled.div`
  background-color: ${colors.brown};
  padding: 0.5% 4%;
  color: white;
  font-size: ${fontSizes.medium};
  margin-left: 2%;
  margin-top: 4%;
  border-radius: 5px;
  bottom: 0;
  height: 25%;

  @media (max-width: ${breakpoints.netbook}) {
    font-size: ${fontSizes.medium};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.small};
    margin-top: 2%;
  }
`;

export const CostLabelYear = styled.div`
  position: relative;
  bottom: 0;
  font-size: ${fontSizes.small};
`;

export const FeatureTitle = styled.div`
  text-transform: uppercase;
  font-size: ${fontSizes.large};
  margin: 3% 0;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.mediumLarger};
  }
`;

export const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: 10% 90%;
  margin: 5% 0;

  div { margin: auto; }

  p {
    font-size: ${fontSizes.large};
    padding-left: 5%;

    @media (max-width: ${breakpoints.netbook}) {
      font-size: ${fontSizes.medium};
    }

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mediumLarger};
    }
  }
`;

export const CheckMark = styled.div<VariantDetails>`
  background-image: url(${checkMark});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: ${getColour};
  border-radius: 100%;
  width: 30px;
  height: 30px; 

  @media (max-width: ${breakpoints.mobile}) {
    width: 25px;
    height: 25px; 
    background-size: 40%;
    margin: auto;
  }
`;

export const VariantButton = styled.button<VariantDetails>`
  color: white;
  font-size: ${fontSizes.hsDescription};
  font-weight: 600;
  text-transform: uppercase;
  border-style: none;
  border-radius: 3px;
  background-color: ${getColour};
  cursor: pointer;
  width: 100%;
  padding: 2% 0;
  margin-top: 13%;
  box-shadow: 0 0 6px 1px rgba(0,0,0,.2);

  @media (max-width: ${breakpoints.netbook}) {
    font-size: ${fontSizes.larger};
    margin-top: 5%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.mediumLarger};
    margin-top: 5%;
  }
`;

export const VariantButtonDisabled = styled.button`
  background-color: white;
  font-size: ${fontSizes.hsDescription};
  font-weight: 600;
  text-transform: uppercase;
  border: solid 1px ${colors.green};
  /* border-radius: 3px; */
  color: ${colors.green};
  width: 100%;
  padding: 2% 0;
  margin-top: 13%;

  @media (max-width: ${breakpoints.netbook}) {
    font-size: ${fontSizes.larger};
    margin-top: 5%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.mediumLarger};
    margin-top: 5%;
  }
`;

export const ButtonIcon = css`
  transform: translateY(15%);
  margin-left: 2%;
`;

export const FaqContainer = styled.div`
  padding: 2em 15vw;
  font-weight: 450;
  letter-spacing: -1px;
  margin-bottom: 2em;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 5% 5% 5%;
  }

  h2 {
    font-size: ${fontSizes.hsDescTitle};
    font-weight: 450;
    color: ${colors.greyDark};
    text-align: center;
    margin-bottom: 0.8em;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.larger};
    }
  }

  h3 {
    font-size: ${fontSizes.mediumLarger};
    font-weight: 450;
    color: ${colors.greyDark};
    text-align: center;
    margin-bottom: 0.3em;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.medium};
    }
  }

  a {
    text-decoration: underline;
  }

`;

export const FaqGroupDiv = styled.div`
  background-color: ${colors.greyLighter};
  padding: 20px;
  margin: 20px;

  h3 {
    font-size: ${fontSizes.larger};
    font-weight: 700;
    color: ${colors.greyDark};
    text-align: left;
    margin-bottom: 0.3em;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mediumLarger};
    }
  }

  p {
    font-size: ${fontSizes.mediumLarger};
    color: ${colors.greyDark};
    font-weight: 500;
    padding-bottom: 0.6em;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.small};
      font-weight: 400;
    }

  }

`;

const FaqColumnCss = css`
  display: flex;
  flex-direction: column;
`;

export const FaqList = styled.div`
  ${FaqColumnCss}
`;

export const FaqListItem = styled.div`
  ${FaqColumnCss}

  font-size: ${fontSizes.medium};
  color: ${colors.greyDark};
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.2em;

  @media (max-width: ${breakpoints.mobile}) {
    
    padding-bottom: 0.4em;
  }
`;

export const FaqRow = styled.div<{visible: boolean, isQ: boolean}>`
  display: ${({visible}) => visible ? "grid" : "none"};
  grid-template-columns: 2em 1fr;
  margin: 0px;
  padding-bottom: ${({isQ}) => isQ ? "0.2em" : "0.8em"};

  button {
    border: none;
    background-color: ${colors.transparent};
    cursor: pointer;
    color: ${colors.greyDark};
    grid-column-start: 1;
    grid-column-end: -1;
    display: grid;
    grid: inherit;
  }

  button:hover {
    color: ${colors.greyDarker};
  }

  p {
    font-weight: ${({isQ}) => isQ ? 700 : 500};
    font-size: ${fontSizes.mediumLarger};
    grid-column-start: 2;
    text-align: left;
    padding-top: 0.1em;
    padding-bottom: 0em;

    @media (max-width: ${breakpoints.mobile}) {
      font-weight: ${({isQ}) => isQ ? 700 : 400};
      font-size: ${fontSizes.small};
      padding-top: 0.25em;
    }
  }

`;

export const BackgroundMobile = styled.div`
  background-image: url(${mobileBack});
  background-repeat: no-repeat;
  z-index: -1;
  position: absolute;
  top: var(--navbar-height-mobile);
  background-size: 100vw auto;
  width: 100vw;
  height: 100vh;

  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;



/*export const */
