import React, { useEffect, FC } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, BrowserRouter } from "react-router-dom";

import { FilterProvider } from "../store/filterContext";
import { SavedsContextProvider } from "../store/savedsContext";
import { UserContextProvider, useUserContext } from "../store/userContext";
import { SqFlowProvider } from "../store/SqFlowProvider";

import ActivityPage from "../pages/ActivityPage/ActivityPage";
import { UserRoute } from "./UserRoute";
import { ProfilePage } from "../pages/ProfilePage/ProfilePage";
import { CategorySelectionPage } from "../pages/CategorySelectionPage/CategorySelectionPage";
import TermsPage from "../pages/TermsPage/TermsPage";
import PrivacyPage from "../pages/PrivacyPage/PrivacyPage";
import { Faqs } from "../pages/Faqs/Faqs";
import { ReferrerLandingPage } from "../pages/ReferrerLandingPage";
import { AboutUsPage } from "../pages/AbouUsPage/AboutUsPage";
import { SQ1 } from "../pages/StyleQuizPage/SQ1";
import { SQ2 } from "../pages/StyleQuizPage/SQ2";
import { SQRefine } from "../pages/StyleQuizPage/SQRefine";
import { NewHomePage } from "../pages/LandingPages/HomePage";
import { HeadspacePage } from "../pages/LandingPages/Headspace";
import { RecommendationsPage } from "../pages/RecommendationsPage/RecommendationsPage";
import { ProductPage } from "../pages/ProductPage/ProductPage";
import { AnalyticsProvider } from "../store/analyticsContext";
import { TemporarySQFilterPage } from "../pages/SQFilterPage/TemporarySQFilterPage";
import { RatingSubmissionPage } from "../pages/RatingSubmissionPage";
import { BrowsePage } from "../pages/BrowsePage/BrowsePage";
import { ShopProvider } from "../store/shopContext";
import { CheckoutResultPage } from "../pages/CheckoutResultPage/CheckoutResultPage";
import { CheckoutStylistResultPage } from "../pages/CheckoutStylist/CheckoutStylistResultPage";
import { SearchProvider } from "../store/searchContext";
import { AboutStylistPage } from "../pages/AboutStylistPage/AboutStylistPage";
import TosPage from "../pages/TermsPage/TosPage";
import { PostCheckoutPage } from "../pages/CheckoutStylist/PostCheckoutPage";
import posthog from 'posthog-js';
import { NotFoundPage } from "../pages/NotFoundPage/NotFoundPage";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

const ScrollToTop: FC<{children?: React.ReactNode}> = ({ children }) => {
  const location = useLocation();
  const { user, customerId } = useUserContext();

  useEffect(() => window.scrollTo(0, 0), [location]);

  useEffect(() => {
    user && user.email
      ? posthog.identify(customerId, {email: user.email})
      : posthog.identify(customerId, {name: customerId});
  }, [user]);

  useEffect(() => {
    //console.log("AAAA", location)
    posthog.capture("$pageview")
  }, [location]);

  return <>{children}</>;
};

const basic = (children: EmotionJSX.Element) => 
  <UserContextProvider>
    <SavedsContextProvider>
      <ShopProvider>
        <SearchProvider>
          <ScrollToTop>
            {children}
          </ScrollToTop>
        </SearchProvider>
      </ShopProvider>
    </SavedsContextProvider>
  </UserContextProvider>

const sq = (children: EmotionJSX.Element) => 
  <UserContextProvider>
    <SavedsContextProvider>
      <ShopProvider>
        <SearchProvider>
          <FilterProvider>
            <SqFlowProvider>
              <AnalyticsProvider> 
                <ScrollToTop>
                  {children}
                </ScrollToTop>
              </AnalyticsProvider>
            </SqFlowProvider>
          </FilterProvider>
        </SearchProvider>
      </ShopProvider>
    </SavedsContextProvider>
  </UserContextProvider>

export const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={basic(<NewHomePage />)} />
      <Route path="/headspace" element={basic(<HeadspacePage />)} />
      <Route path="/about" element={basic(<AboutUsPage />)} />
      <Route path="/pricing" element={basic(<AboutStylistPage />)} />
      <Route path="/faqs" element={basic(<Faqs />)} />
      <Route path="/terms" element={basic(<TermsPage />)} />
      <Route path="/terms-of-sale" element={basic(<TosPage />)} />
      <Route path="/privacy" element={basic(<PrivacyPage />)} />
      <Route path="/refer/:referrer" element={basic(<ReferrerLandingPage />)} />
      <Route path="/activity" element={basic(<ActivityPage />)} />
      <Route path="/profile" element={basic(<ProfilePage />)} />
      <Route path="/checkout" element={basic(<CheckoutResultPage />)} />
      <Route path="/checkout-stylist" element={basic(<CheckoutStylistResultPage />)} />
      <Route path="/got-stylist" element={basic(<PostCheckoutPage />)} />
      <Route path="/shop" element={basic(<BrowsePage />)} />
      <Route path="/shop/:parent" element={basic(<BrowsePage />)} />
      <Route path="/shop/:parent/:child" element={basic(<BrowsePage />)} />
      <Route path="/product/:name/:id" element={basic(<ProductPage />)} />
      <Route path="/rating-success" element={basic(<RatingSubmissionPage />)} />
      <Route path="/category" element={sq(<CategorySelectionPage />)} />
      <Route path="/style-quiz/:category/filter" element={sq(<TemporarySQFilterPage />)} />
      <Route path="/style-quiz/:category/1" element={sq(<SQ1 />)} />
      <Route path="/style-quiz/:category/2" element={sq(<SQ2 />)} />
      <Route path="/style-quiz/:category/3" element ={sq(<SQRefine />)} />
      <Route path="/style-quiz/:category/recommendations" element={sq(<RecommendationsPage />)} />
      <Route path="*" element={basic(<NotFoundPage />)} />
    </Routes>
  </BrowserRouter>
);
