import React, { useState, useEffect } from "react";
import Modal from "react-modal";

import NavBar from "../../shared/NavBar/NavBar";
import {
  ProfilePageWrapper,
  ProfilePageTopPart,
  ProfilePageBottomPart,
  ViewActivityButton,
  ProfileInfo,
  ProfileForm,
  Label,
  SelectStyled,
  InputStyled,
  ProfileItemForm,
  Optional,
  MultiLineLabel,
  EmailRadio,
  RadioWrapper,
  AdvancedLeft,
  AdvancedRight,
  AdvancedSettingsWrapper,
  DeleteAccountButton,
  SaveChangesButton,
  UserTitleWrapper,
  ProfilePageHR,
  FeedbackWrapper,
  ConfirmDelete,
  CloseModalButton,
} from "./ProfilePageWrapper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../config";
import crossGrey from "../../images/icons/cross_grey.svg";
import { useUserContext } from "../../store/userContext";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";

const genderValues = ["", "male", "female", "other", "unspecified"];
const ageGroupValues = [
  "",
  "<20",
  "<30",
  "<40",
  "<50",
  "<60",
  ">=60",
  "undefined",
];

const useGet = (url: string) => {
  const [data, setData] = useState<IFormValues | null>(null);

  async function fetchData() {
    try {
      if (axios.defaults.headers.common.Authorization) {
        const response = await axios.get<IFormValues>(url);
        const { data } = response;
        setData(data);
      } else {
        setTimeout(() => {
          fetchData();
        }, 100);
      }
    } catch (e) {}
  }

  useEffect(() => {
    fetchData();
  }, [url]);

  return data;
};

interface IFormValues {
  name: string;
  email: string;
  gender: string;
  ageGroup: string;
  postCode: string;
  promoUnsubscribed: boolean;
}

function useForm(initialState: IFormValues) {
  const [values, setValues] = useState(initialState);

  function handleChange(event: any) {
    if (event.target.name === "promoUnsubscribed") {
      setValues({
        ...values,
        [event.target.name]: event.target.value === "true" ? true : false,
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  }

  function handleChangeFromServer(data: any) {
    setValues({
      ...values,
      ...data,
    });
  }

  return { handleChange, values, handleChangeFromServer };
}

const deleteAccountModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "1rem",
    height: "33vh",
    border: "none",
    width: "45rem",
    maxWidth: "95vw",
    display: "grid",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 4,
  },
};

Modal.setAppElement("#root");

export const ProfilePage = () => {
  const navigate = useNavigate();
  const { user, signOut, deleteAccount } = useUserContext();

  const data: IFormValues | null = useGet(`${apiUrl}/account`);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { handleChange, values, handleChangeFromServer } = useForm({
    name: data === null ? (user?.displayName as string) : data!.name,
    email: data === null ? (user?.email as string) : data!.email,
    gender: data !== null ? data!.gender : "",
    ageGroup: data !== null ? data!.ageGroup : "",
    postCode: data !== null ? data!.postCode : "",
    promoUnsubscribed: data !== null ? data!.promoUnsubscribed : false,
  });
  const { name, email, gender, ageGroup, postCode, promoUnsubscribed } = values;
  useEffect(() => {
    if (data !== null) {
      handleChangeFromServer(data);
    }
  }, [data]);
  useEffect(() => {
    if (showFeedback) {
      setTimeout(() => {
        setShowFeedback(false);
      }, 3000);
    }
  }, [showFeedback]);

  const handleSaveChanges = async () => {
    setShowFeedback(true);
    await axios.put(`${apiUrl}/account`, {
      ...values,
    });
  };

  const handleDeleteAccount = async () => {
    await deleteAccount();
    navigate("/");
  };

  return (
    <ProfilePageWrapper>
      <FlitchHelmet title="Your Profile" />
      <NavBar />
      <ProfilePageTopPart>
        <UserTitleWrapper>
          {user?.displayName && <span>{user.displayName}</span>}
          <h1>Your Profile</h1>
        </UserTitleWrapper>
        <ViewActivityButton to="/activity">
          <span>VIEW ACTIVITY</span>
        </ViewActivityButton>
      </ProfilePageTopPart>
      <ProfilePageHR />
      <ProfilePageBottomPart>
        <ProfileInfo>PROFILE INFO</ProfileInfo>
        <ProfileForm>
          <ProfileItemForm>
            <Label htmlFor="First Name">Name</Label>
            <InputStyled
              name="Name"
              onChange={handleChange}
              type="text"
              value={name}
              readOnly={true}
              disabled={true}
            />
          </ProfileItemForm>
          <ProfileItemForm>
            <Label htmlFor="email">Email</Label>
            <InputStyled
              name="email"
              onChange={handleChange}
              type="text"
              value={email}
              readOnly={true}
              disabled={true}
            />
          </ProfileItemForm>
          <ProfileItemForm>
            <Label htmlFor="Gender">Gender</Label>
            <SelectStyled name="gender" onChange={handleChange} value={gender}>
              {genderValues.map((value) => (
                <option key={value} value={value}>
                  {value === "male"
                    ? "Male"
                    : value === "female"
                    ? "Female"
                    : value === "other"
                    ? "Other"
                    : value === "unspecified"
                    ? "Prefer not to say"
                    : value}
                </option>
              ))}
            </SelectStyled>
            <Optional>Optional</Optional>
          </ProfileItemForm>
          <ProfileItemForm>
            <Label htmlFor="Age">Age Group</Label>
            <SelectStyled
              name="ageGroup"
              onChange={handleChange}
              value={ageGroup}
            >
              {ageGroupValues.map((value: string) => (
                <option key={value} value={value}>
                  {value === "<20"
                    ? "19 or below"
                    : value === "<30"
                    ? "20 to 29"
                    : value === "<40"
                    ? "30 to 39"
                    : value === "<50"
                    ? "40 to 49"
                    : value === "<60"
                    ? "50 to 59"
                    : value === ">=60"
                    ? "60 and above"
                    : value === "undefined"
                    ? "Prefer not to say"
                    : ""}
                </option>
              ))}
            </SelectStyled>
            <Optional>Optional</Optional>
          </ProfileItemForm>
          <ProfileItemForm>
            <Label htmlFor="postCode">Postcode</Label>
            <InputStyled
              name="postCode"
              onChange={handleChange}
              type="text"
              value={postCode}
            />
            <Optional>Optional</Optional>
          </ProfileItemForm>
          <ProfileItemForm>
            <Label />

            <SaveChangesButton
              active={!showFeedback}
              onClick={handleSaveChanges}
            >
              <span>Save Changes</span>
              <FeedbackWrapper showFeedback={showFeedback}>
                <div />
                <p>Your changes have been saved</p>
              </FeedbackWrapper>
            </SaveChangesButton>
          </ProfileItemForm>
        </ProfileForm>
      </ProfilePageBottomPart>
      <hr />
      <ProfilePageBottomPart>
        <ProfileInfo>ADVANCED SETTINGS</ProfileInfo>
        <ProfileForm>
          <AdvancedSettingsWrapper>
            <AdvancedLeft>
              <MultiLineLabel>
                EMAIL
                <br />
                PREFERENCES
              </MultiLineLabel>
              <MultiLineLabel>
                ACCOUNT
                <br />
                DELETION
              </MultiLineLabel>
            </AdvancedLeft>
            <AdvancedRight>
              <RadioWrapper>
                <div>
                  <EmailRadio
                    type="radio"
                    name="promoUnsubscribed"
                    value="false"
                    checked={promoUnsubscribed === false}
                    onChange={handleChange}
                  />
                  <label htmlFor="optIn">
                    Opt-in to promotional and marketing emails
                  </label>
                </div>
                <div>
                  <EmailRadio
                    type="radio"
                    name="promoUnsubscribed"
                    value="true"
                    checked={promoUnsubscribed === true}
                    onChange={handleChange}
                  />
                  <label htmlFor="optOut">
                    Opt-out of promotional and marketing emails
                  </label>
                </div>
              </RadioWrapper>
              <DeleteAccountButton onClick={() => setShowConfirmDelete(true)}>
                <p>
                  Delete my <span>Flitch </span>Profile
                </p>
              </DeleteAccountButton>
              <Modal
                style={deleteAccountModalStyle}
                isOpen={showConfirmDelete}
                // isOpen={true}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                // tslint:disable-next-line: jsx-no-lambda
                onRequestClose={() => {
                  setShowConfirmDelete(false);
                }}
              >
                <ConfirmDelete>
                  <CloseModalButton
                    src={crossGrey}
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => {
                      setShowConfirmDelete(false);
                    }}
                  />
                  <section>
                    <p>
                      Are you sure you want to delete your account? We won't be
                      able to reinstate your account once this is done
                    </p>
                  </section>
                  <div onClick={handleDeleteAccount}>Delete Account</div>
                </ConfirmDelete>
              </Modal>
            </AdvancedRight>
          </AdvancedSettingsWrapper>
        </ProfileForm>
      </ProfilePageBottomPart>
    </ProfilePageWrapper>
  );
};
