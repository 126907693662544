import { FC } from "react";
import { BrowsePromptBox, BrowsePromptButton } from "./BrowsePromptStyles";

const buttonText = (type: BrowsePromptType) => {
  switch (type) {
    case "sq": return "TAKE A STYLE QUIZ";
    case "stylist": return "FIND OUT MORE";
  }
};

const url = (type: BrowsePromptType) => {
  switch (type) {
    case "sq": return "/category";
    case "stylist": return "/pricing";
  }
};

export const BrowsePrompt : FC<BrowsePromptProps> = ({
  promptType,  question, answer
}) => (
  <BrowsePromptBox type={promptType}>
    <p>{question}</p>
    <h3>{answer}</h3>
    <BrowsePromptButton type={promptType} to={url(promptType)}>
      {buttonText(promptType)}
    </BrowsePromptButton>
  </BrowsePromptBox>
);