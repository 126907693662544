import styled from "styled-components";
import { fontSizes } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";

export const StatusRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 25px 0;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`;

export const StatusImg = styled.div<{ img: string }>`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-image: url(${({ img }) => img});
  background-size: cover;
  margin-bottom: 15px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 50px;
    height: 50px;
  }
`;

export const StatusLabel = styled.p`
  font-size: ${fontSizes.small};
  text-align: center;
`;
