import React, { useState, useRef, RefObject, useEffect } from "react";
import styled, { css } from "styled-components";


import { Link } from "react-router-dom";
import NavBar from "../../shared/NavBar/NavBar";

import armchair from "../../images/furnitureIcons/armchair.jpg";
import barstool from "../../images/furnitureIcons/barstool.jpg";
import bed from "../../images/furnitureIcons/bed.jpg";
import bedside from "../../images/furnitureIcons/bedside.jpg";
import chestOfDrawers from "../../images/furnitureIcons/chestofdrawers.jpg";
import coffee from "../../images/furnitureIcons/coffee.jpg";
import consoleTable from "../../images/furnitureIcons/console.jpg";
import diningChair from "../../images/furnitureIcons/diningchair.jpg";
import diningTable from "../../images/furnitureIcons/diningtable.jpg";
import display from "../../images/furnitureIcons/display.jpg";
import shelving from "../../images/furnitureIcons/shelving.jpg";
import sideboard from "../../images/furnitureIcons/sideboard.jpg";
import sideTable from "../../images/furnitureIcons/sidetable.jpg";
import sofa from "../../images/furnitureIcons/sofa.jpg";
import tv from "../../images/furnitureIcons/tv.jpg";
import wardrobe from "../../images/furnitureIcons/wardrobe.jpg";
import {
  HeadingPrimary,
  HeadingsWrapper,
} from "../../shared/Headings/HeadingsWrapper";
import { breakpoints } from "../../styles/responsive";
import { Reason, useUserContext } from "../../store/userContext";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";

// Used for shrinking overlay text so it doesn't wrap
const smallMobileSize = 400;

// has to be a multiple of the min size or squishing of the rightmost column will happen
const mobileSize = 600;

const PageBody = styled.section`
  background-color: white;
  min-height: 100vh;
`;

const StickyHeader = styled.div`
  top: var(--navbar-height);
  position: sticky;
  text-align: center;
  z-index: 1;
  background-color: white;

  @media (max-width: ${breakpoints.mobile}) {
    top: var(--navbar-height-mobile);
  }
`;

const Img = styled.img<{ faded: boolean }>`
  display: block;
  align-self: center;
  justify-self: center;
  filter: ${({ faded }) => (faded ? "grayscale(100%)" : "none")};
`;

interface ImgLinkProps {
  /**
   * Setting this to true makes the image start a new row.
   * Was used on mobile when the menu had buttons to directly scroll to a
   * category, so there had to be a clear start to the category.
   */
  $startNewRow?: boolean;
  $transparentise?: boolean;
}

// should be the same as the image background so that when images aren't exactly 2x1 or 1x2 you don't see them being stretched
const imgBackgroundClr = "#E4E4E4";

const imgStyling = css<ImgLinkProps>`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${imgBackgroundClr};
  opacity: ${({ $transparentise }) => ($transparentise ? "0.15" : "1")};

  grid-row-end: span 1;
  grid-column-end: span 1;
`;

const SquareImg = styled(Link)<ImgLinkProps>`
  ${({ $startNewRow }) =>
    $startNewRow &&
    css`
      @media (max-width: ${mobileSize}px) {
        grid-column-start: 1;
      }
    `}

  ${imgStyling}
`;

const DoubleWidthImg = styled(Link)<ImgLinkProps>`
  ${imgStyling}

  grid-column-end: span 2;
`;

const DoubleHeightImg = styled(Link)<ImgLinkProps>`
  ${imgStyling}

  grid-row-end: span 2;
`;

interface OverlayTextProps {
  horizontal: Horizontal;
  vertical: Vertical;
}

const OverlayTextContainer = styled.div<OverlayTextProps>`
  position: absolute;
  font-size: 1.8rem;
  line-height: 1;
  display: flex;
  flex-direction: column;

  ${({ vertical }) =>
    vertical === "top"
      ? css`
          bottom: 66%;
        `
      : css`
          top: 66%;
        `};
  ${({ horizontal }) =>
    horizontal === "left"
      ? css`
          left: 0;
          align-items: flex-start;
        `
      : css`
          right: 0;
          text-align: right;
          align-items: flex-end;
        `};
`;

const overlayTextColour = "white";

const OverlayTextRow = styled.p`
  background: ${overlayTextColour};
  padding: 4px;
  white-space: nowrap;
`;

type Horizontal = "left" | "right";
type Vertical = "top" | "bottom";

type ImgProps = {
  src: string;
  category: CategoryUnion;
  mode?: "square" | "wide" | "tall";
  text?: string[];
  horizontal?: Horizontal;
  vertical?: Vertical;
  startNewRow?: boolean;
  forwardedRef?: RefObject<HTMLAnchorElement>;
  group: CategoryGroup;
  highlightedGroup: CategoryGroup | null;
};

function Image({
  src,
  category,
  mode = "square",
  text,
  horizontal = "left",
  vertical = "top",
  startNewRow = false,
  forwardedRef,
  group,
  highlightedGroup,
}: ImgProps) {
  const compMap = {
    square: SquareImg,
    tall: DoubleHeightImg,
    wide: DoubleWidthImg,
  };

  const Container = compMap[mode];

  const faded = highlightedGroup !== null && highlightedGroup !== group;

  return (
    <Container
      $transparentise={faded}
      $startNewRow={startNewRow}
      to={`/style-quiz/${category}/filter`}
      ref={forwardedRef}
    >
      {text && (
        <OverlayTextContainer horizontal={horizontal} vertical={vertical}>
          {text.map((txt) => (
            <OverlayTextRow key={txt}>{txt}</OverlayTextRow>
          ))}
        </OverlayTextContainer>
      )}
      <Img faded={faded} src={src} />
    </Container>
  );
}

const CategoryGrid = styled.main`
  position: relative;
  top: var(--navbar-height);
  /* Above styling because of navbar (and therefore also sticky) */
  margin: 0 10px 10px;
  display: grid;
  grid-gap: 10px;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr;

  // should should be moved to larger than mobile only if we want to reinstate clear separations between categories
  grid-auto-flow: dense;

  @media (min-width: ${mobileSize}px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

const MobileOnlyDiv = styled.div`
  @media (min-width: ${mobileSize}px) {
    display: none;
  }
`;

const DesktopOnlyDiv = styled.div`
  display: none;
  @media (min-width: ${mobileSize}px) {
    display: block;
  }
`;

type HighlightState = "notHighlighted" | "highlighted" | "otherHighlighted";

const GroupLink = styled.a<{ highlight?: HighlightState }>`
  color: black;
  text-transform: uppercase;
  ${({ highlight }) =>
    highlight === "highlighted"
      ? css`
          font-weight: bold;
        `
      : highlight === "otherHighlighted"
      ? css`
          color: grey;
        `
      : ""}
`;

type CategoryGroup = "storage" | "seating" | "tables" | "bedding";

export function CategorySelectionPage() {
  const { user, openAuthModal, closeAuthModal } = useUserContext();
  // useEffect(() => {
  //   user 
  //     ? closeAuthModal()
  //     : openAuthModal(Reason.RegisterBeforeSQ);
  // }, [user]);

  const [highlightedGroup, setHighlightedGroup] =
    useState<CategoryGroup | null>(null);

  const storageRef = useRef<HTMLAnchorElement>(null);
  const seatingRef = useRef<HTMLAnchorElement>(null);
  const tablesRef = useRef<HTMLAnchorElement>(null);
  const beddingRef = useRef<HTMLAnchorElement>(null);

  const scrollToRef = (ref: RefObject<HTMLAnchorElement>) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const MobileBar = () => {
    return (
      <>
        <GroupLink onClick={() => scrollToRef(storageRef)}>Storage</GroupLink> |{" "}
        <GroupLink onClick={() => scrollToRef(seatingRef)}>Seating</GroupLink> |{" "}
        <GroupLink onClick={() => scrollToRef(tablesRef)}>Tables</GroupLink> |{" "}
        <GroupLink onClick={() => scrollToRef(beddingRef)}>Beds</GroupLink>
      </>
    );
  };

  const DesktopBar = () => {
    const getHighlightState = (group: CategoryGroup): HighlightState => {
      if (highlightedGroup === null) {
        return "notHighlighted";
      } else if (group === highlightedGroup) {
        return "highlighted";
      } else {
        return "otherHighlighted";
      }
    };

    return (
      <>
        <GroupLink
          highlight={
            highlightedGroup === null ? "notHighlighted" : "otherHighlighted"
          }
          onClick={() => setHighlightedGroup(null)}
        >
          All
        </GroupLink>{" "}
        |{" "}
        <GroupLink
          highlight={getHighlightState("storage")}
          onClick={() => setHighlightedGroup("storage")}
        >
          Storage
        </GroupLink>{" "}
        |{" "}
        <GroupLink
          highlight={getHighlightState("seating")}
          onClick={() => setHighlightedGroup("seating")}
        >
          Seating
        </GroupLink>{" "}
        |{" "}
        <GroupLink
          highlight={getHighlightState("tables")}
          onClick={() => setHighlightedGroup("tables")}
        >
          Tables
        </GroupLink>{" "}
        |{" "}
        <GroupLink
          highlight={getHighlightState("bedding")}
          onClick={() => setHighlightedGroup("bedding")}
        >
          Beds
        </GroupLink>
      </>
    );
  };

  /**
   * Not used atm, but functionality is there for when needed
   */
  const HighlightOrScrollToHeaders = () => (
    <>
      <MobileOnlyDiv>
        <MobileBar />
      </MobileOnlyDiv>
      <DesktopOnlyDiv>
        <DesktopBar />
      </DesktopOnlyDiv>
    </>
  );

  return (
    <PageBody>
      <FlitchHelmet title="Category Selection — Style Quiz" />
      <NavBar />
      <StickyHeader>
        <HeadingsWrapper>
          <HeadingPrimary>
          Taking a style quiz will help your stylist understand your taste...please select the 
          item of furniture you're looking for
          </HeadingPrimary>
        </HeadingsWrapper>
        {/* <HighlightOrScrollToHeaders /> */}
      </StickyHeader>
      <CategoryGrid>
        <Image
          src={sideboard}
          category="sideboard"
          text={["Cabinets and", "Sideboards"]}
          vertical="bottom"
          forwardedRef={storageRef}
          group={"storage"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={chestOfDrawers}
          category="chestofdrawers"
          text={["Chest of", "Drawers"]}
          vertical="bottom"
          horizontal="right"
          group={"storage"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={shelving}
          category="shelving"
          text={["Shelving and", "Bookcases"]}
          group={"storage"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={wardrobe}
          category="wardrobe"
          mode="tall"
          horizontal="right"
          text={["Wardrobes"]}
          group={"storage"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={tv}
          category="tvstand"
          text={["TV Stands"]}
          group={"storage"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={display}
          category="displaycabinet"
          text={["Display", "Cabinets"]}
          vertical="bottom"
          group={"storage"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          category="diningchair"
          src={diningChair}
          text={["Dining", "Chairs"]}
          vertical="bottom"
          forwardedRef={seatingRef}
          group={"seating"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={barstool}
          category="barstool"
          text={["Barstools"]}
          vertical="bottom"
          horizontal="right"
          group={"seating"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          mode="wide"
          src={sofa}
          category="sofa"
          text={["Sofas"]}
          vertical="bottom"
          group={"seating"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={armchair}
          category="armchair"
          text={["Armchairs"]}
          vertical="bottom"
          group={"seating"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={diningTable}
          category="diningtable"
          text={["Dining", "Tables"]}
          vertical="bottom"
          forwardedRef={tablesRef}
          group={"tables"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={coffee}
          category="coffeetable"
          text={["Coffee", "Tables"]}
          vertical="bottom"
          horizontal="right"
          group={"tables"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={consoleTable}
          category="consoletable"
          text={["Console", "Tables"]}
          vertical="bottom"
          group={"tables"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={bedside}
          category="bedsidetable"
          text={["Bedside", "Tables"]}
          horizontal="right"
          group={"tables"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          src={sideTable}
          category="sidetable"
          text={["Side", "Tables"]}
          group={"tables"}
          highlightedGroup={highlightedGroup}
        />
        <Image
          category="bed"
          src={bed}
          text={["Beds"]}
          forwardedRef={beddingRef}
          group={"bedding"}
          highlightedGroup={highlightedGroup}
        />
      </CategoryGrid>
    </PageBody>
  );
}
