import { FC } from "react";
import { CouponBarSpan, CouponCodeText } from "./CouponBarStyles";

interface CouponProps {
  code: string;
  discount: number;
  isUpTo: boolean;
  type: CouponTypeUnion;
}

export const CouponBar: FC<CouponProps> = ({code, discount, isUpTo, type}) => {
  const getDiscountInfo = () => {
    switch (type) {
      case "Amount": return `${isUpTo ? "up to " : ""}£${discount} off`;
      case "Percentage": return `${isUpTo ? "up to " : ""}${discount}% off`;
      case "Free Delivery": return "free delivery";
    }
  }

  const valueWithAmount = (
    <>
      {`Use code `}
      <CouponCodeText className="code">{code}</CouponCodeText>
      {` for ${getDiscountInfo()}`}
    </>
  )

  return (
    <CouponBarSpan>
        {
          code === "auto-applied"
            ? 'Free delivery will be applied at checkout'
            : valueWithAmount
        }
    </CouponBarSpan>
  );
}