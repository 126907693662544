import { Dispatch, FC, SetStateAction } from "react"
import { TabUnion } from "../MobileHamburger"
import { TabContent } from "../MobileHamburgerStyles";
import { MenuOption } from "./MenuOption";

interface Props {
  select: Dispatch<SetStateAction<TabUnion|undefined>>;
}

export const DefaultTab: FC<Props> = (
  {select}
) => {
  return (
    <TabContent>
      <MenuOption label="Take a Style Quiz" onClick={() => select("style quiz")}/>
      <MenuOption label="Browse All Items" onClick={() => select("browse")}/>
      <MenuOption label="About Flitch" onClick={() => select("about")}/>
    </TabContent>
  )
}