import styled, { css } from "styled-components";
import { colors, fontSizes } from "../../styles/globalStyled";
import { Link } from "react-router-dom";
import { breakpoints } from "../../styles/responsive";

import fLogo from "../../images/flitchLogo.svg";


export const NavBarWrapper = styled.div<{ isFixed?: boolean }>`
  position: ${({ isFixed = true }) => (isFixed ? "fixed" : "initial")};
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececee;
  box-shadow: 0px 2px 10px rgba(10, 10, 92, 0.1);
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  z-index: 12;
  
  @media (max-width: ${breakpoints.mobile}) {
    height: var(--navbar-height-mobile);
  }
`;

export const NavButtonWrapper = styled.div`
  margin: 0 10px;
  display: flex;
  justify-content: space-around;
`;

interface NavButtonProps {
  $orange?: boolean;
  /**Hide the button if screen is narrow.*/
  $hideWhenNarrow?: boolean;
}


export const NavButton = styled(Link)<NavButtonProps>`
  margin: 0 5px;
  padding: 3px 10px;
  text-transform: uppercase;
  font-weight: bolder;
  color: white;
  border: solid transparent;
  border-radius: 5px;
  background-color: ${({ $orange }) => $orange ? colors.orange : colors.greyDark};
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color .35s;

  :hover{
    color: white;
    background-color: ${({ $orange }) => $orange ? colors.orangeDark : colors.greyDarker};
  }

  :active{
    color: white;
    background-color: ${({ $orange }) => $orange ? colors.orangeDark : colors.greyDarker};
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: ${({ $hideWhenNarrow }) => $hideWhenNarrow ? "none" : "block"};
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoImage=styled.object`
  color: red;
`;

export const Logo = styled(Link)`
  background: url(${fLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 5vw;/*var(--nav-button-side);*/
  height: var(--nav-button-side);
  margin-right: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    width: 13vw;
    /* width: var(--nav-button-side-mobile); */
    /* height: var(--nav-button-side-mobile); */
  }
`;

export const LogoText = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  color: #666666;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 3px;
    font-size: ${fontSizes.mediumSmaller};
  }
`;

export const DesktopRoadmap = styled.div`
  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const BurguerWrapper = styled.div`
  //display: none;
  //@media (max-width: ${breakpoints.mobile}) {
    display: grid;
  //}
  justify-self: end;
  width: 4rem;
  height: 4rem;
  margin-left: 1rem;
  align-content: center;
  justify-content: center;
  background-color: #e4e4e4;
  border-radius: 50%;
`;

export const Burguer = styled.div`
  cursor: pointer;
  display: grid;
  align-content: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  grid-gap: 3px;

  span {
    width: 2.4rem;
    height: 3px;
    background: #707070;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;

  @media only screen and (max-width: 768px) {
    width: 40%;
    justify-content: right;
  }
`;

interface INavBarLink {
  selected?: boolean;
}

export const NavBarLinksWrapper = styled.div<INavBarLink>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBarHR = styled.hr<INavBarLink>`
  height: 3px;
  width: 100%;
  background: orange;
  opacity: 0;
  margin: 0;
  ${({ selected }) =>
    selected &&
    css`
      height: 3px;
      opacity: 1;
    `}
`;

export const NavBarText = styled.span<INavBarLink>`
  font-size: ${fontSizes.medium};
  white-space: nowrap;
  text-transform: uppercase;
  ${({ selected }) =>
    selected &&
    css`
      font-weight: bold;
    `}
`;

export const NavBarLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
`;

export const NavBarExternalLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
`;
