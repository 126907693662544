export const colors = {
  blue:"#1177BB",
  blueGoogle: "#4285F4",
  green: "#008C8C",
  greenDisabled: "#A2D4D4",
  greenPale: "#7FC4C5",
  greyishGreen: "#0F7B79",
  greyLighter: "#F7F7F7",
  greyLight: "#EEEEEE",
  greyPale: "#D8D8D8",
  greyMediumLighter: "#BFBFBF",
  greyMedium: "#AAAAAA",
  greyMediumDarker: "#6D6D6D",
  greyDark: "#666666",
  greyDarker: "#404040",
  beigeGrey: "#919191",
  orange: "#FB9E02",
  orangeDark: "#DB941D",
  peach: "#FEECCC",
  yellow: "#FBC27F",
  red: "#EC4242",
  brown: "#993300",
  biege: "#FAEACD",
  biegeLight: "#F9F4F0",
  transparent: "#FFFFFF00",
} as const;

export const borders = {
  borderThin: "1px solid #999",
  thinner: `1px solid ${colors.greyPale}`,
} as const;

export const fontSizes = {
  huge: "5.8rem",
  veryLarge: "3.6rem",
  larger: "2.7rem",
  large: "2.2rem",
  mediumLarger: "2.0rem",
  medium: "1.8rem",
  mediumSmaller: "1.7rem",
  small: "1.6rem",
  smaller: "1.5rem",
  moreSmall: "1.4rem",
  verySmall: "1.3rem",
  tiny: "1.1em",
  hsDescTitle: "4rem",
  hsDescription: "3rem"
} as const;

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
