import React, { FC } from "react";
import { useResolvedPath } from "react-router";
import { RoadmapContainer, RoadmapPill, PillLine } from "./RoadMapWrapper";
import { useCategory } from "../../../hooks/useCategory";

export const sqRoutes = (category: CategoryUnion) => [
  `/style-quiz/${category}/filter`,
  `/style-quiz/${category}/1`,
  `/style-quiz/${category}/2`,
  `/style-quiz/${category}/3`,
  `/style-quiz/${category}/recommendations`,
];

export const RoadMap: FC = () => {
  const url = useResolvedPath("").pathname;
  const category = useCategory();

  const thisCategoryRoutes = sqRoutes(category);
  const currentRouteIndex = thisCategoryRoutes.indexOf(url);

  const activeItem = currentRouteIndex < 1 ? 0 : currentRouteIndex < 4 ? 1 : 2;

  return (
    <RoadmapContainer>
      <RoadmapPill
        isActive={activeItem >= 0}
        isExpandedOnMobile={activeItem === 0}
        number={1}
        label="Filter"
        startingUrl={thisCategoryRoutes[0]}
      />
      <PillLine />
      <RoadmapPill
        isActive={activeItem >= 1}
        isExpandedOnMobile={activeItem === 1}
        number={2}
        label="Refine"
        startingUrl={thisCategoryRoutes[1]}
      />
      <PillLine />
      <RoadmapPill
        isActive={activeItem >= 2}
        isExpandedOnMobile={activeItem === 2}
        number={3}
        label="Recommendations"
        startingUrl={thisCategoryRoutes[4]}
      />
    </RoadmapContainer>
  );
};
