import React, { FC, useEffect, useState } from "react";
import {
  LoadingPageWrapper,
  LoadingPageContainer,
  LoadingText,
  AfterLoadingWrapper,
} from "./LoadingPageStyled";
import NavBar from "../../shared/NavBar/NavBar";
import { NoResultsFoundPage } from "../StyleQuizPage/SQComponents";
import { getPrefiltersUrl } from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";
import { MobileRoadmap } from "../../shared/NavBar/RoadMap/MobileRoadmap";
import { categoryReadableNames } from "../../utils/helpers";

export type LoadingPageState = Exclude<RecsPageState, "Loaded">;

interface LoadingPageProps {
  // loadingPageState: LoadingPageState;
  category: CategoryUnion;
  // resetFilters: VoidFunction;
}
export const InitialLoadingPage: FC<LoadingPageProps> = ({category}) => {
  const [isLongLoading, setIsLongLoading] = useState(false);

  useEffect(() => {
    // Change message after 6 seconds
    const timeout = setTimeout(() => setIsLongLoading(true), 6000);
    return () => clearTimeout(timeout);
  }, []);

  const displayCategory = categoryReadableNames[category]?.toLowerCase().replace("tv", "TV");

  return (
    <LoadingPageWrapper>
      <NavBar />
      <MobileRoadmap position={0}/>
      <LoadingPageContainer>
        <AfterLoadingWrapper>
          <LoadingText>
            {isLongLoading
              ? `Your recommendations are nearly ready...just a few more moments!`
              : `We're now generating some instant recommendations for you; by choosing the Human Stylist plan, your stylist will build on your feedback to find you your perfect ${displayCategory}`}
          </LoadingText>
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            `}
          >
            <FontAwesomeIcon
              icon={faCircleNotch}
              spin={true}
              color="white"
              size="5x"
            />
          </div>
        </AfterLoadingWrapper>
      </LoadingPageContainer>
    </LoadingPageWrapper>
  );
};

interface RecsNoItemsFoundProps {
  showResetFiltersBtn: boolean;
  resetFilters: VoidFunction;
  category: CategoryUnion;
  effectOnMount?: VoidFunction;
}

export const RecsNoItemsFound: FC<RecsNoItemsFoundProps> = ({
  showResetFiltersBtn,
  resetFilters,
  category,
  effectOnMount,
}) => (
  <NoResultsFoundPage
    headerText={showResetFiltersBtn ? "Oops!" : "That's embarrassing!"}
    bodyText={
      showResetFiltersBtn
        ? "Unfortunately none of our recommendations meet these budget or size specifications. You can either reset these filters, or go back to the start to try afresh."
        : "It seems even with filters removed, we still haven't found anything suitable to recommend. We suggest you go back to the start to try afresh."
    }
    firstButtonProps={
      showResetFiltersBtn
        ? { label: "Reset filters", onClick: resetFilters }
        : undefined
    }
    sndButtonProps={{
      label: "Start afresh",
      path: getPrefiltersUrl(category),
    }}
    effectOnMount={effectOnMount}
  />
);

interface SQNoItemsFoundProps {
  makeFiltersNotApplyToRecs: VoidFunction;
  category: CategoryUnion;
  effectOnMount?: VoidFunction;
}

export const SQNoItemsFound: FC<SQNoItemsFoundProps> = ({
  makeFiltersNotApplyToRecs,
  category,
  effectOnMount,
}) => (
  <NoResultsFoundPage
    headerText="Oops!"
    bodyText="Unfortunately none of our recommendations meet these budget or size specifications. You can either go back and amend these filters, or otherwise continue with your filters removed."
    firstButtonProps={{
      label: "Amend filters",
      path: getPrefiltersUrl(category),
    }}
    sndButtonProps={{
      label: "Continue with filters removed",
      onClick: makeFiltersNotApplyToRecs,
    }}
    effectOnMount={effectOnMount}
  />
);
