import styled from "styled-components";
import { css } from "@emotion/css";
import { colors, fontSizes } from "../../styles/globalStyled";
import { breakpoints } from "../../styles/responsive";
import { customVerticalScrollbar } from "../../pages/RecommendationsPage/RecommendationsPageStyled";

export const CartContainer = styled.div<{isOpen: boolean}>`
  position: fixed;
  background-color: white;
  width: 500px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 21;
  transform: translateX(${({isOpen}) => isOpen ? "0" : "105%"});
  box-shadow: var(--box-shadow);
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow: auto;

  #last {
	  margin-top: auto;
  }


  @media (max-width: ${breakpoints.mobile}) {
    max-width:100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-right: 5%;
  }
`;

export const CartHeader = styled.span`
  display: grid;
  grid-template-columns: 90% 1fr;

  text-align: left;
  vertical-align: top;
  font-size: ${fontSizes.large};
  color: ${colors.greyDark};

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.medium};
  }
`;

export const CloseCartButton = styled.button`
  position: relative;

  top: -25%;
  right: -35%;
  text-align: right;

  width: 25px;
  height: 25px;

  margin-right: 0;

  color: ${colors.greyDark};
  background-color: white;
  border: 1px solid;
  border-color: ${colors.greyDark};
  border-radius: 100%;
  transition: all .35s;

  cursor: pointer;

  :hover {
    color: ${colors.greyMedium};
    border-color: ${colors.greyMedium};
  }
`;

export const CartItemList = styled.div`
  padding-top: 4%;
  margin-top: 3%;
  height: 63%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: auto;
  scroll-snap-type: y mandatory;

  @media (min-width: ${breakpoints.mobile}) {
    scroll-snap-type: initial;
    ${customVerticalScrollbar}
  }
`;

export const CartItemContainer = styled.div`
  display: grid;
  grid-template-columns: 140px 3% 1fr;
  padding-bottom: 3%;

  @media (max-width: ${breakpoints.mobile}) {
    scroll-snap-type: initial;
    ${customVerticalScrollbar}
  }
`;

export const CartItemLeftArea = styled.div<{image: string}>`
  height: 140px;
  width:  140px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({image}) => image});
`;

export const RemoveFromCartButtonContainer = styled.div`
  position: relative;
  left: 85%;
  top: -60px;
  width: 30px;
  height: 30px;

  -webkit-padding-start: .5rem;
  -webkit-padding-end: .5rem;
  -webkit-padding-before: .3rem;
  -webkit-padding-after: .3rem;
`;

export const RemoveFromCartButton = styled.button`
  width: 25px;
  height: 25px;
  border-style: none;
  border-radius: 100%;
  background-color: ${colors.greyPale};
  color: white;
  cursor: pointer;
  transition: background-color .35s;

  :hover{
    background-color: ${colors.red};
  }
`;

export const CartItemRightArea = styled.div`
  margin-left: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${fontSizes.small};
  color: ${colors.greyDark};
`;

export const DiscountContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 7px 1fr;
  height: 30px;
`;

interface DisplayInputProps {
  hasError: boolean;
}

const getInputBorder = (props: DisplayInputProps) =>
  `1px solid ${props.hasError ? colors.red : colors.greyPale}`;

export const DiscountInputContainer=styled.div`
  position: relative;
  height: 30px;
`;

export const DisountInput = styled.input<DisplayInputProps>`
  background-color: white;
  border: ${getInputBorder};
  padding: 0 10px;
  text-transform: uppercase;
  color: ${colors.greyMediumDarker};
  transition: all .35s;
  height: 100%;
  width: 100%;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${colors.greyPale};
  }

  ::-ms-input-placeholder {
    color: ${colors.greyPale};
  }
`;

const getIconColor = (props: DisplayInputProps) =>
  props.hasError ? colors.red : "transparent";

export const DiscountWarningIcon = styled.div<DisplayInputProps>`
  position: relative;
  height: 22.5px;
  width: 22.5px;
  color: ${getIconColor};
  right: -90%;
  bottom: 85%;

  transition: color .35s;

  @media (max-width: ${breakpoints.mobile}) {
    right: -85%;
  }
`;

export const DiscountApplyButton = styled.button `
  border-style: none;
  background-color: ${colors.orange};
  color: white;
  cursor: pointer;
  transition: background-color .35s;

  :hover {
    background-color: ${colors.orangeDark};
  }
`;

export const CartItemSubresultCss = css`
color: ${colors.green};
`;

export const CartItemPriceCss = css`
  color: ${colors.green};
  font-weight: 600;
`;

export const CartItemRetailerCss = css`
  font-size: ${fontSizes.moreSmall};
`;

export const CartItemQntContainer = styled.div`
  display: grid;
  width: 85px;
  grid-template-columns: 1fr 1fr 1fr;
  border: solid ${colors.greyMedium};
  /* border-color: ${colors.greyLight};*/
  border-width: 0.1em;
`;

export const CartItemQntCss = css`
  background-color: white;
  font-size: ${fontSizes.small};
  color: ${colors.greyDark};
  text-align: center;
`;

export const CartItemQntButton = styled.button`
  background-color: white;
  font-size: ${fontSizes.small};
  color: ${colors.greyDark};
  border: none;
  cursor: pointer;
`;

export const CartTotalContainer = styled.div`
  font-size: ${fontSizes.medium};
  background-color: white;
`;

export const TotalLineContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const TotalLineLeft = styled.span<{isBold: boolean}>`
  color: ${colors.greyMediumDarker};
  text-align: left;
  font-weight: ${({isBold}) => isBold ? 600 : 300};

  .description{
    color: ${colors.greyMedium};
    font-size: ${fontSizes.medium};
  }
`;

export const TotalLineRight = styled.span<{isBold: boolean}>`
  color: ${colors.green};
  text-align: right;
  font-weight: ${({isBold}) => isBold ? 600 : 300};
`;

export const CheckoutButtonContainer = styled.div`
  display: flex;
  height: 40px;

  @media (min-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

export const CheckoutButtonLabel = styled.text`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-weight: 600;
`;
