import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { customVerticalScrollbar } from "../../../pages/RecommendationsPage/RecommendationsPageStyled";
import { colors, fontSizes } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";

interface UIProps {
  mode: UIMode;
};

const backgroundColor = (p: UIProps) => {
  switch (p.mode) {
    case "none": return colors.greyPale;
    case "recs": return colors.green;
    case "shop": return colors.orange;
  };
};

export const MobileHamburgerWrapper = styled.div`
  position: relative;
  display: grid;
  justify-self: end;
  width: var(--nav-button-side-mobile);
  height: var(--nav-button-side-mobile);
  min-width: var(--nav-button-side-mobile);
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

interface MenuProps {
  isOpen: boolean
}

const menuDisplay = (p: MenuProps) => p.isOpen ? "block" : "none";
const menuPosition = (p: MenuProps) => p.isOpen ? "0" : "105%";

export const PopupMenuContainer = styled.div<MenuProps>`
  /*display: ${menuDisplay};*/
  background-color: white;
  position: fixed;
  top: 0;
  left: ${menuPosition};
  bottom: 0;
  right: ${p => "-" + menuPosition(p)}; 
  z-index: 20;

  transition: all .35s;
`;

export const PopupMenuHeader = styled.div<UIProps>`
  background-color: ${backgroundColor};
  display: grid;
  grid-template-columns: auto auto auto 20%;
  height: var(--navbar-height);
  padding: 5px 10px;
  transition: background-color .35s;
`;

interface TabProps extends UIProps {
  selected: boolean;
}
const tabFontWeight = (p: TabProps) => p.selected ? "bold" : "normal";
const tabFontColor = (p: TabProps) => {
  const selectColor = (active: string, inactive?: string) =>
    p.selected ? active : inactive ?? active;

  switch(p.mode) {
    case "recs": return selectColor("white", colors.greenDisabled);
    case "shop": return selectColor("white", "rgba(255,255,255,0.6)");
    default: return selectColor(colors.greyMediumDarker);
  };
};

export const PopupTab = styled.button<TabProps>`
  border-style: none;
  font-size: ${fontSizes.mediumSmaller};
  font-weight: ${tabFontWeight};
  color: ${tabFontColor};
  background-color: rgba(0,0,0,0);
  text-transform: uppercase;
  cursor: pointer;
  transition: color .35s;
`;

export const PopupCloseWrapper = styled.div`
  text-align: right;
`;

const closeColor = (p: UIProps) => 
  p.mode === "none"
    ? colors.greyMediumDarker
    : "white";

export const PopupClose = styled.button<UIProps>`
  border-style: none;
  font-size: ${fontSizes.mediumSmaller};
  color: ${closeColor};
  background-color: rgba(0,0,0,0);
  cursor: pointer;
  width: var(--nav-button-side-mobile);
  height: var(--nav-button-side-mobile);
  transform: translate(0, 25%);
`;

export const TabContent = styled.div`
  padding-top: 5px;
  position: relative;
  height: var(--height-under-navbar);
  width: 100%;
  overflow-y: auto;

  ${customVerticalScrollbar}
`;

export const TabContentLineWrapper = styled.div`
  cursor: pointer;
  height: var(--navbar-height-mobile);
  background-color: white;
`;

const TabContentLineCss = css`
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  display: grid;
`;

export const TabContentLine = styled.div`
  ${TabContentLineCss}
  grid-template-columns: 1fr 30px;
  color: ${colors.greyPale};
`;

export const TabContentCloseLine = styled.div`
  ${TabContentLineCss}
  margin-left: 10px;
  grid-template-columns: 30px 1fr;
  color: ${colors.greyMediumDarker};
`;

const TabContentCss = css`
  margin-top: 3px;
  font-size: ${fontSizes.mediumSmaller};
  color: ${colors.greyMediumDarker};

  @supports (-webkit-touch-callout: none) {
    -webkit-padding-start: .1rem;
  }
`;

export const TabContentLineText = styled(Link)`
  ${TabContentCss}
`;

export const TabContentLineP = styled.p`
  ${TabContentCss}
`;

export const SubmenuContainer = styled.div<MenuProps>`
  /*display: ${({isOpen}) => isOpen ? "block" : "none" };*/
  position: fixed;
  top: var(--navbar-height);
  left: ${menuPosition};
  bottom: 0;
  right: ${p => "-" + menuPosition(p)}; 
  background-color: white;
  transition: all .35s;
`;
