import { FC } from "react";
import { useSearchContext } from "../../../store/searchContext";
import { pluralise } from "../../../utils/helpers";
import { CounterTextCss } from "../BrowsePageStyles";

export const ProductCounter: FC<{
  //category: string
  total: number | undefined,
  count: number
  loading: boolean
}> = ({total, count, loading}) => {

  const {appliedSearch, search} = useSearchContext();

  const getZeroLabel = () => 
    loading 
      ? ""
      : appliedSearch ? `Sorry, no results for "${search}"` : "";
  
  return (
    <p css={CounterTextCss}>
      {total && count > 0 ? (
        <>
          {total.toLocaleString()} product
          {pluralise(total)}
        </>
      ) : getZeroLabel()}
    </p>)
}