import styled, { keyframes } from "styled-components";
import { colors, fontSizes } from "../../styles/globalStyled";

import bgDesktop from "../../images/homebg-desktop.png";
import bgMobileL from "../../images/homebg-mobile-left.png";
import bgMobileR from "../../images/homebg-mobile-right.png";

import logosMobile from "../../images/logos-mobile.jpg";
import logosDesktop from "../../images/logos-desktop.jpg";
import { breakpoints } from "../../styles/responsive";
import { Link } from "react-router-dom";
import Spacer from "react-spacer";

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: var(--navbar-height);

  @media (max-width: ${breakpoints.mobile}) {
    top: var(--navbar-height-mobile);
  }
`;

export const GuideSection = styled.div`
  height: var(--height-under-navbar);
  position: relative; /* for its back layer with the background video*/

  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const DesktopGuide = styled.div`
  background-image: url(${bgDesktop});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const DesktopGuideHalf = styled.div`
  box-shadow: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GuideText = styled.h1`
  font-weight: 600;
  font-size: ${fontSizes.veryLarge};
  line-height: 1em;
  color: white;
  text-align: center;
  white-space: nowrap; // Because we're setting a <wbr>
  letter-spacing: -0.05em; // @TODO: reevaluate this
  margin-bottom: 15px;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    /* Because otherwise it just overflows the bounding box */
    white-space: normal;
  }
`;

export const GuideSubtitle = styled(GuideText)`
  font-weight: normal;
  font-size: ${fontSizes.larger};
  margin-bottom: 0;
`;

export const GuideButton = styled(Link)<{$shop?: boolean}>`
  font-size: ${fontSizes.larger};
  font-weight: 600;
  line-height: 1;
  text-align: center;
  padding: 10px 55px;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px;
  border-width: 0;
  margin-top: 20px;
  background-color: ${({$shop}) => $shop ? colors.orange : colors.green};
  color: white;

  :hover{
    color: white;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding-top: 12px;
    font-size: ${fontSizes.medium};
  }
`;


export const MobileGuideSection = styled.div`
  height: var(--height-under-navbar-mobile);
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-x: auto;
  scroll-behavior: smooth;

  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const getMobileGuide = (image: string, align: string) => styled.div`
  background-image: url(${image});
  background-position: top ${align};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MobileGuideRecs = getMobileGuide(bgMobileR, "left");
export const MobileGuideShop = getMobileGuide(bgMobileL, "right");

export const MobileGuideWrapper = styled.div`
  position: relative;
  height: var( --height-under-navbar-mobile);

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const ScrollDotBox = styled.div`
  position: absolute;
  bottom: 15%;
  transform: translateX(45vw);
  width: 15%;
  height: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

interface ScrollDotProps {
  isShopVisible: boolean;
  isActive: boolean;
  isShop: boolean;
};

const dotColor = (p: ScrollDotProps) => {
  return p.isActive 
    ? p.isShop 
      ? colors.orange 
      : colors.green 
    : "rgba(0,0,0,0)";
};

const dotBorderColor = (p: ScrollDotProps) => 
  p.isShopVisible ? colors.orange : colors.green;


export const ScrollDot = styled.button<ScrollDotProps>`
  margin: auto;
  border-radius: 100%;
  border: 1px solid ${dotBorderColor};
  width: 1.5rem;
  height: 1.5rem;

  background-color: ${dotColor};
  transition: all .35s;
`;

export const MobileGuideText = styled.h1<{$shop?: boolean}>`
  font-weight: 450;
  font-size: ${fontSizes.large};
  line-height: 1em;
  color: ${({$shop}) => $shop ? colors.orange : colors.green};
  text-align: center;
  white-space: nowrap; // Because we're setting a <wbr>
  letter-spacing: -0.05em; // @TODO: reevaluate this
  margin-bottom: 15px;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    /* Because otherwise it just overflows the bounding box */
    white-space: normal;
  }
`;

export const MobileGuideSubtitle = styled(MobileGuideText)`
  font-weight: normal;
  font-size: ${fontSizes.mediumLarger};
  margin-bottom: 0;
  color: white;
`;

export const MethodTitle = styled.h2`
  font-weight: 450;
  font-size: ${fontSizes.hsDescTitle};
  margin-bottom: 0;
  word-wrap: break-word;
  color: ${colors.greyDark};
  margin: 0 20vw 3%;
  text-align: center;
  line-height: 1.2;
  
  @media (max-width: ${breakpoints.mobile}) {
    /*height: var(--height-under-navbar-mobile);*/
    font-size: ${fontSizes.larger};
    letter-spacing: -0.1rem;
    line-height: 1em;
    margin: 20px 1% 0;
    padding: 0 4%;
  }
`;

export const RetailerSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: var(--height-under-navbar);

  @media (max-width: ${breakpoints.tablet}) {
    height: 100vh;/*var(--height-under-navbar-mobile);*/
  }
`;

export const RetailerBgSection = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url(${logosMobile});
  width: 100%;
  height: 100%;
  flex-grow: 1;

  @media (min-width: ${breakpoints.tablet}) {
    width: initial;
    padding-top: 0;

    height: var(--height-under-navbar);
    background-image: url(${logosDesktop});
    background-position: right;
    position: absolute;
    top: 0;
    left: 0;
    right: 45%;
  }
`;

export const RetailerSectionText = styled.div`
  font-weight: 450;
  font-size: ${fontSizes.hsDescTitle};
  line-height: 1em;
  margin: 40px 20px 20px;
  text-align: center;
  color: ${colors.greyDark};
  font-family: "BuenosAires";

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.larger};
  }

  p {
    font-family: "Avenir";
    font-weight: 300;
    font-size: ${fontSizes.hsDescription};
    line-height: 1.2;
    margin: 5% 2%;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.medium};
      line-height: 1.1;
      letter-spacing: -0.1rem;

    }
  }
 
  @media (min-width: ${breakpoints.tablet}) {
    max-width: 620px;
    position: absolute;
    margin: 0;
    z-index: 1;
    transform: translateX(23vw) translateY(0);
  }
`;

export const MobileSpacer = styled(Spacer)`
  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const MethodSection = styled.div`
  background: white;
  position: relative; // For box shadow to work https://stackoverflow.com/a/31276836/3229534
  /* box-shadow: var(--box-shadow); */
  box-shadow: 0px 2px 7px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 4% 0;
  z-index: 2;

  @media (max-width: ${breakpoints.mobile}) {
    justify-content: center;
  }
`;

export const MethodCardsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: minmax(100px, 350px);
  justify-items: space-around;
  padding: 10px 0;

  @media (min-width: ${breakpoints.mobile}) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, minmax(100px, 350px));
    width: var(--max-content-width);
    justify-content: space-evenly;
  }
`;

export const MethodCardStyled = styled.div`
  margin: 10px 0; // bigger here so that margin around card and between cards is the same
  padding: var(--card-padding);
  
  @media (min-width: ${breakpoints.mobile}) {
    margin: 0 10px;
    box-shadow: var(--box-shadow);
  }
`;

export const MethodCardTitle = styled.h3`
  font-weight: 450;
  font-size: ${fontSizes.mediumLarger};
  text-align: center;
  color: ${colors.greyDark};
  line-height: 1;
  margin: 3% 0;
  
  @media (min-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.medium};
  }
`;

export const MethodCardDescription = styled.p`
  font-size: ${fontSizes.medium};
  text-align: center;
  color: ${colors.greyDark};

  @media (max-width: ${breakpoints.mobile}) {
    line-height: 1.1;
    padding: 1%;
  }
  
  @media (min-width: ${breakpoints.mobile}) {
    margin: 5% 5% 7%;
    font-size: ${fontSizes.mediumSmaller};
  }
`;

export const MethodCardVideo = styled.video`
  width: 100%;
  margin-bottom: var(--card-padding);

  &:focus {
    outline: none;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 70%;
    margin-left: 15%;
    margin-bottom: 2%;
  }
`;

export const GoToSqBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  outline: 7px solid ${colors.green};
  outline-offset: -0.7em; 
  background-color: ${colors.greenPale};
  text-align: center;
  font-size: ${fontSizes.hsDescTitle};
  font-weight: 450;
  padding: 6%;

  p {
    margin-bottom: 0.5%;
    
    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom: 4%;
    }
  }
  
  @media (max-width: ${breakpoints.mobile}) {
    font-weight: 600;
    outline: 4px solid ${colors.green};
    outline-offset: -0.5em;
    font-size: ${fontSizes.large};
    line-height: 1.2;
    padding: 15% 0;
  }
`;

export const InfoLabel = styled.div`
  background-color: ${colors.greyLighter};
  font-size: ${fontSizes.larger};
  text-align: center;
  color: ${colors.greyDark};
  padding: 8vh 15vw;
  box-shadow: 0px 2px 7px #00000029;
  z-index: 3;
  line-height: 1.2;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSizes.medium};
    padding: 4vh 5vw;
    line-height: 1;
    margin-top: 3vh;
  }

  h1 {
    font-size: ${fontSizes.hsDescTitle};
    font-weight: 600;
  }

  h2 {
    font-size: ${fontSizes.hsDescTitle};
    font-weight: 450;
    margin-bottom: 2vh;
    
    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.larger};
    }
  }

  g {
    color: ${colors.green};
  }
`;

export const RebrandingNote = styled.div`
  text-align: center;
  font-size: ${fontSizes.medium};
  color: white;
  font-weight: bold;
  background-color: ${colors.greyDark};
  padding: 0.5vh 3vw;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0.7vh 3vw;
    font-size: ${fontSizes.smaller};
  }
`;
