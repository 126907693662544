import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getCoupons, getPriceWatches, saveSingleAction } from "../../services/api.service";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import NavBar from "../../shared/NavBar/NavBar";
import { useUserContext } from "../../store/userContext";
import { breakpoints } from "../../styles/responsive";
import { elipsizeAfter, findBestCoupon, getChair, seoficateTag, shareItem } from "../../utils/helpers";
import { RecommendationItem } from "../RecommendationsPage/RecommendationItem";
import {
  fetchSimilarItems,
  fetchSingleItem,
} from "../RecommendationsPage/recsUtils";

const ProductPageWrapper = styled.div`
  height: var(--height-under-navbar);
  margin-top: var(--navbar-height);
  position: relative;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: var(--navbar-height-mobile);
  }
`;

// 60 is recommended page title length
const maxTitleLen = 60 - " | Flitch".length;

export const ProductPage: FC = () => {
  const { name, id } = useParams<{ name: string; id: ItemId; }>();

  const [item, itemSet] = useState<IFurnitureItem | null>(null);
  const [similarItems, similarItemsSet] = useState<IFurnitureItem[]>([]);
  const [priceWatches, setPriceWatches] = useState([] as string[]);
  const [coupon, setCoupon] = useState<Coupon|undefined>(undefined)

  const {user} = useUserContext();

  useEffect(() => {
    itemSet(null);
    similarItemsSet([]);

    const fetchData = async () => {
      const data = await fetchSingleItem(id as ItemId);
      itemSet(data);

      if (data) {
        fetchSimilarItems(id as ItemId).then(({ items }) =>
          similarItemsSet(items?.map(getChair) ?? [])
        );
      } 
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (item?.retailer)
      getCoupons().then (
        coupons => setCoupon(findBestCoupon(item, coupons)),
        err => console.error(err)
      );
  }, [item?.retailer]);

  const [itemOpened, setItemOpened] = useState<string>("");

  useEffect(() => {
    if (user && item?.id !== itemOpened) {
      setItemOpened(item?.id ?? "");
      saveSingleAction("product", item?.category ?? "all", item?.id ?? "");
    }
  }, [user]);

  // const triggerWatchChange = () => 
  //   getPriceWatches().then(
      // res => setPriceWatches(res),
      // err => console.error(err)
  //  );

  // if (!priceWatches || priceWatches.length === 0)
  //   triggerWatchChange();

  const share = async (item: IFurnitureItem) => {
    shareItem(item);
    await saveSingleAction("shared", item.category ?? "all", item.id);
  };

  const getCanonical = () => {
    if (!item) return undefined;

    const origin = window.location.origin;
    const name = seoficateTag(item.name);
    return `${origin}/${name}/${item.id}`;
  };

  return (
    <ProductPageWrapper>
      <FlitchHelmet 
        title={item ? elipsizeAfter(item.name, maxTitleLen) : elipsizeAfter(name, maxTitleLen)}
        image={item?.images?.thumbnail?.mainImageGb ?? item?.images?.thumbnail?.mainImage}
        description={item?.description ?? undefined}
        canonical={getCanonical()}
      />
      <NavBar />
      {item && (
        <RecommendationItem
          mode="ProductPage"
          share={share}
          category={item.category ?? "shop"}
          item={item}
          similarItems={similarItems}
          // isWatched={priceWatches.includes(item.id ?? "")}
          // triggerWatchChange={triggerWatchChange}
          coupon={coupon}
        />
      )}
    </ProductPageWrapper>
  );
};
