import { FC } from "react";
import { 
  ReviewBottomLine, 
  ReviewContainer, 
  ReviewWrapper,
  StaticReviewBottomLine,
  StaticReviewContainer,
  StaticReviewWrapper
} from "./ReviewSectionStyles";
//★

export type Review = [string, string, string]

interface ReviewProps {
  content: Review;
  isStatic: boolean
}

const Review : FC<ReviewProps> = ({content, isStatic}) => {
  const [name, media, text] = content;

  const [Container, BottomLine] = isStatic ? [
    StaticReviewContainer, 
    StaticReviewBottomLine 
  ] : [
    ReviewContainer,
    ReviewBottomLine
  ]

  return (
    <Container>
      <h3>★★★★★</h3>
      <p>{text}</p>
      <BottomLine>
        <p><b>{name}</b></p>
        <p>{media}</p>
      </BottomLine>
    </Container>
  );
}

interface ReviewSectionProps {
  reviews: Review[]
}

export const ReviewSection: FC<ReviewSectionProps> = ({reviews}) => {

  const isStatic = reviews.length <= 3
  const Wrapper = isStatic ? StaticReviewWrapper : ReviewWrapper

  return (
    <Wrapper>
      {reviews.map((review, i) => (
        <Review key={i} content={review} isStatic={isStatic}/>
      ))}
    </Wrapper>
  )
}