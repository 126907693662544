import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";

import NavBar from "../../shared/NavBar/NavBar";
import {
  PrivacyPolicyPageWrapper,
  PrivacyPolicyPageContainer,
  PrivacyPolicyTextWrapper,
  PrivacyPageTitle,
  PrivacySectionHeader,
  PrivacySectionBody,
} from "./PrivacyWrapper";
import { Footer } from "../../shared/Footer/Footer";
import { BottomFooter } from "../../shared/Footer/BottomFooter";

export const PrivacyPolicyPage = () => {
  return (
    <>
      <FlitchHelmet title="Privacy Policy" />
      <NavBar />
      <PrivacyPolicyPageWrapper>
        <PrivacyPolicyPageContainer>
          <PrivacyPolicyTextWrapper>
            <PrivacyPageTitle>Privacy Policy</PrivacyPageTitle>
            <PrivacySectionBody>Last updated: 29th May 2020</PrivacySectionBody>

            <PrivacySectionBody>
              In this Privacy Notice we provide information about the processing
              of personal data while using our Services. Personal data is any
              data that can be used to identify you. The protection of your
              personal data is very important to us. If you have any questions
              or would like more information about privacy at Flitch,
              please contact info@withflitch.com with the subject ‘Privacy Query’.
            </PrivacySectionBody>

            <PrivacySectionBody>
              Continuous technological development, changes to our services,
              changes to laws, or other reasons may require us to amend our
              Privacy Notice. We will make changes to this Privacy Notice
              regularly and we ask that you keep yourself informed of its
              contents.
            </PrivacySectionBody>

            <PrivacySectionHeader>
              1. Party Responsible for Data Processing
            </PrivacySectionHeader>
            <PrivacySectionBody>
              Azara Limited (registered in England with company number 11059322
              and whose registered office is at 3rd Floor Sutherland House,
              70-78 West Hendon Broadway, London NW9 7BT) and trading as
              Flitch is the controller and responsible for your personal
              data (collectively referred to as “Flitch”, "we", "us" or
              "our" in this privacy policy).
            </PrivacySectionBody>

            <PrivacySectionHeader>
              2. Data Protection Officer Point of Contact
            </PrivacySectionHeader>
            <PrivacySectionBody>
              You can reach our data protection officer at info@withflitch.com with
              the subject ‘Privacy Query’.
            </PrivacySectionBody>

            <PrivacySectionHeader>3. Your Rights</PrivacySectionHeader>
            <PrivacySectionBody>
              You have the following rights with respect to your personal data:
            </PrivacySectionBody>

            <PrivacySectionBody>3.1 General rights</PrivacySectionBody>
            <PrivacySectionBody>
              You have the right to information, access, correction, deletion,
              restriction of processing, objection to processing, and data
              portability. If processing is based on your consent, you have the
              right to revoke it at any time.
            </PrivacySectionBody>

            <PrivacySectionBody>
              3.2 Rights to object to processing of data based on legitimate
              interests
            </PrivacySectionBody>
            <PrivacySectionBody>
              Article 21(1) EU General Data Protection Regulation (EU) 2016/679
              (“GDPR”) gives you the right to object at any time for reasons
              arising out of your particular situation against the processing of
              personal data relating to you when your data is processed under
              Article 6(1)(e) or Article 6 (1)(f) GDPR. This also applies to
              profiling. If you object, we will no longer process your personal
              data unless we can establish compelling and legitimate grounds for
              processing that outweigh your interests, rights and freedoms, or
              if the processing aids the enforcing, exercising or defending of
              legal claims.
            </PrivacySectionBody>

            <PrivacySectionBody>
              3.3 Rights to object to direct marketing
            </PrivacySectionBody>
            <PrivacySectionBody>
              If we process your personal data for the purpose of direct
              marketing, Article 21(2) of EU GDPR gives you the right to object
              at any time to the processing of your personal data for the
              purpose of direct marketing; this also applies to profiling,
              insofar as it is associated with direct marketing.
            </PrivacySectionBody>

            <PrivacySectionBody>
              If you object to processing for the purpose of direct marketing,
              we will no longer process your personal data for this purpose.
            </PrivacySectionBody>

            <PrivacySectionBody>
              3.4 Right to complain to a supervisory authority
            </PrivacySectionBody>
            <PrivacySectionBody>
              You also have the right to complain to a relevant data protection
              supervisory authority about our processing of your personal data.
            </PrivacySectionBody>

            <PrivacySectionHeader>
              4. The Processing of Personal Data when Using the Services
            </PrivacySectionHeader>
            <PrivacySectionBody>
              We process your personal data using the legitimate interests legal
              basis, except in specific circumstances where you provide consent.
              We apply appropriate safeguards to protect your privacy and we
              process your personal data for the following purposes:
            </PrivacySectionBody>

            <PrivacySectionBody>
              Helping you find your ideal piece of furniture.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Providing you with customised and more relevant furniture search
              results.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Providing, securing, maintaining and improving our Services for
              our users.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Developing new products and features related to furniture search.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Understanding how users use our Services so that we can improve
              the performance and provide better services to our users.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Direct marketing to users of our services about our Services or
              related services.
            </PrivacySectionBody>
            <PrivacySectionBody>
              User research that improves users’ experience of our Services and
              improves our Services.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Complying with legal obligations.
            </PrivacySectionBody>
            <PrivacySectionBody>
              When you use our Services, we process these types of personal
              data:
            </PrivacySectionBody>

            <PrivacySectionBody>
              Personal identifying information such as your name, postcode or
              email addresses.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Personal details such as age, sex, date of birth.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Electronic identification data such as IP addresses, cookies,
              connection moments, device ID’s mobile advertising identifiers,
              date and time of the inquiry, time, request contents, (concrete
              page), access status/HTTP status code, amount of data transferred,
              website receiving the request, browser software and version,
              operating system and its interface, and language.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Data that your browser or device makes available.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Electronic localization data such as GPS data and locational data.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Data about your interaction with our services.
            </PrivacySectionBody>
            <PrivacySectionBody>
              Security details such as passwords related to our Services.
            </PrivacySectionBody>
            <PrivacySectionHeader>
              5. Contact by E-mail or Contact Form
            </PrivacySectionHeader>
            <PrivacySectionBody>
              When you contact us by e-mail or through a contact form, we will
              store the data you provide (your e-mail address, possibly your
              name and telephone number) so we can answer your questions.
              Insofar as we use our contact form to request entries that are not
              required for contacting you, we have always marked these as
              optional. This information serves to substantiate your inquiry and
              improve the handling of your request. Your message may be linked
              to various actions taken by you on the Flitch website.
              Information collected will be solely used to provide you with
              support relating to your experience and better understand your
              feedback. A statement of this information is expressly provided on
              a voluntary basis and with your consent, art. 6 par. 1a GDPR. As
              far as this concerns information about communication channels
              (such as your e-mail address or telephone number), you also agree
              that we may also, where appropriate, contact you via this
              communication channel to answer your request. You may of course
              revoke this consent for the future at any time.
            </PrivacySectionBody>

            <PrivacySectionBody>
              We delete the data that arises in this context after saving is no
              longer required, or limit processing if there are statutory
              retention requirements.
            </PrivacySectionBody>

            <PrivacySectionHeader>
              6. Flitch User Account
            </PrivacySectionHeader>
            <PrivacySectionBody>
              You can create a Flitch user account. If you create a
              Flitch user account you will receive personal,
              password-protected access and can view and manage the data you
              have stored in your account. Flitch user account creation is
              voluntary but may be required to fully use the functionality of
              some of our Services.
            </PrivacySectionBody>

            <PrivacySectionBody>
              If you create a Flitch user account, we may send you our
              direct marketing. You can delete your Flitch user account at
              any time.
            </PrivacySectionBody>

            <PrivacySectionBody>
              You can manage, modify and delete all information in your
              Flitch user account. The legal basis for this processing is
              Article 6(1)(a), (b), and (f) GDPR.
            </PrivacySectionBody>

            <PrivacySectionHeader>
              7. Use of Social Plug-ins
            </PrivacySectionHeader>
            <PrivacySectionBody>
              This website uses social plug-ins including
            </PrivacySectionBody>

            <PrivacySectionBody>
              Facebook, Instagram, Whatsapp (operator: Facebook Inc., 1601 S.
              California Ave, Palo Alto, CA 94304, USA)
            </PrivacySectionBody>
            <PrivacySectionBody>
              YouTube (operator: YouTube LLC 901 Cherry Avenue, San Bruno, CA
              94066, USA)
            </PrivacySectionBody>
            <PrivacySectionBody>
              Twitter (operator: Twitter Inc., 795 Folsom St., Suite 600, San
              Francisco, CA 94107, USA)
            </PrivacySectionBody>
            <PrivacySectionBody>
              LinkedIn (operator: LinkedIn Corporation1000 W. Maude Avenue,
              Sunnyvale, CA 94085, USA)
            </PrivacySectionBody>
            <PrivacySectionBody>
              Pinterest (operator: Pinterest Inc., 635 High Street, Palo Alto,
              CA, 94301, USA)
            </PrivacySectionBody>
            <PrivacySectionBody>
              These plug-ins typically collect data from you as standard, and
              transmit it to the respective provider’s server. We have taken
              technical measures to ensure the protection of your privacy, which
              guarantee that your data cannot be collected by the providers of
              the respective plug-ins without your consent. These will initially
              be deactivated when you visit a site connected to the plug-ins.
              The plug-ins will not be activated until you click on the
              respective symbol, and by doing so, you give your consent to have
              your data transmitted to the relevant provider. The legal basis
              for plug-in use is article 6, par. 1 a and f of the GDPR.
            </PrivacySectionBody>

            <PrivacySectionBody>
              Once activated, the plug-ins also collect personally identifiable
              information, such as your IP address, and send it to the
              respective provider’s server, where it is saved. Activated social
              plug-ins also set a cookie with a unique identifier when you visit
              the respective website. This allows the provider to generate
              profiles of your user behaviour as well. This occurs even if you
              are not a member of the provider’s social network. If you are a
              member of the provider’s social network and you are logged into
              the website during your visit, your data and information about
              your visit to the website can be linked with your profile on the
              social network. We do not have any influence over the exact extent
              to which your data is collected by the relevant provider. For more
              information about the extent, nature, and purpose of data
              processing and about the rights and setting options for protecting
              your privacy, please see the data protection notices for the
              respective social network provider. These can be found at the
              following addresses:
            </PrivacySectionBody>

            <PrivacySectionBody>
              Facebook: https://www.facebook.com/policy.php
            </PrivacySectionBody>
            <PrivacySectionBody>
              Twitter: https://twitter.com/en/privacy
            </PrivacySectionBody>
            <PrivacySectionBody>
              Pinterest: https://about.pinterest.com/en/privacy-policy
            </PrivacySectionBody>
            <PrivacySectionBody>
              Instagram:
              https://help.instagram.com/519522125107875?helpref=page_content
            </PrivacySectionBody>
            <PrivacySectionBody>
              Whatsapp: https://www.whatsapp.com/legal?eea=1#privacy-policy
            </PrivacySectionBody>
            <PrivacySectionBody>
              Youtube:
              https://support.google.com/youtube/topic/2803240?hl=en&ref_topic=6151248
            </PrivacySectionBody>
            <PrivacySectionBody>
              LinkedIn: https://www.linkedin.com/legal/privacy-policy
            </PrivacySectionBody>
            <PrivacySectionHeader>8. Facebook Connect</PrivacySectionHeader>
            <PrivacySectionBody>
              We offer you the option of enrolling and signing in through your
              Facebook account. If you enroll via Facebook, Facebook will ask
              you for your permission to release certain data in your Facebook
              account to us. This may include your first name, last name, and
              e-mail address so your identity and gender can be verified, as
              well as general location, a link to your Facebook profile, your
              time zone, your date of birth, your profile picture, your “Like”
              information, and your friends list.
            </PrivacySectionBody>

            <PrivacySectionBody>
              This data will be collected by Facebook and transmitted to us in
              compliance with the policies in the Facebook privacy policy. You
              can control the information that we receive from Facebook through
              the privacy settings in your Facebook account.
            </PrivacySectionBody>

            <PrivacySectionBody>
              This data will be used to establish, provide, and personalize your
              account. The legal basis is article 6, par. 1 a, b, and f of the
              GDPR.
            </PrivacySectionBody>

            <PrivacySectionBody>
              If you enroll with us through Facebook, your account will
              automatically be connected to your Facebook account and
              information about your activities on our website, if applicable,
              may be shared on Facebook and published on your timeline and news
              feed.
            </PrivacySectionBody>

            <PrivacySectionHeader>9. Use of Cookies</PrivacySectionHeader>
            <PrivacySectionBody>
              We use cookies for a variety of reasons detailed below.
              Unfortunately in most cases there are no industry standard options
              for disabling cookies without completely disabling the
              functionality and features they add to this site. It is
              recommended that you leave on all cookies if you are not sure
              whether you need them or not in case they are used to provide a
              service that you use.
            </PrivacySectionBody>

            <PrivacySectionBody>
              You can prevent the setting of cookies by adjusting the settings
              on your browser (see your browser help section for how to do
              this). Be aware that disabling cookies will affect the
              functionality of this and many other websites that you visit.
              Disabling cookies will usually result in also disabling certain
              functionality and features of this site. Therefore it is
              recommended that you do not disable cookies.
            </PrivacySectionBody>

            <PrivacySectionBody>Account related cookies:</PrivacySectionBody>
            <PrivacySectionBody>
              If you create an account with us then we will use cookies for the
              management of the signup process and general administration. These
              cookies will usually be deleted when you log out however in some
              cases they may remain afterwards to remember your site preferences
              when logged out.
            </PrivacySectionBody>

            <PrivacySectionBody>Login related cookies:</PrivacySectionBody>
            <PrivacySectionBody>
              We use cookies when you are logged in so that we can remember this
              fact. This prevents you from having to log in every single time
              you visit a new page. These cookies are typically removed or
              cleared when you log out to ensure that you can only access
              restricted features and areas when logged in.
            </PrivacySectionBody>

            <PrivacySectionBody>
              Advertising and website traffic to third-party related cookies:
            </PrivacySectionBody>
            <PrivacySectionBody>
              Our website is supported by advertising revenue, and we receive
              commision for ads you see and links you click on. To help us keep
              track of referrals we’ve made and ads we’ve shown, we (and our
              service providers) use cookies and similar technology on your
              device. These cookies do not reveal your identity, web use or
              other behaviour.
            </PrivacySectionBody>

            <PrivacySectionBody>
              In some special cases we also use cookies provided by trusted
              third parties. The following section details which third party
              cookies you might encounter through this site.
            </PrivacySectionBody>

            <PrivacySectionBody>
              This site uses Google Analytics which is one of the most
              widespread and trusted analytics solution on the web for helping
              us to understand how you use the site and ways that we can improve
              your experience. These cookies may track things such as how long
              you spend on the site and the pages that you visit so we can
              continue to produce engaging content.
            </PrivacySectionBody>

            <PrivacySectionBody>
              For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </PrivacySectionBody>

            <PrivacySectionBody>
              Third party analytics are used to track and measure usage of this
              site so that we can continue to produce engaging content. These
              cookies may track things such as how long you spend on the site or
              pages you visit which helps us to understand how we can improve
              the site for you.
            </PrivacySectionBody>

            <PrivacySectionBody>
              Cookies are placed by third party advertising platforms or
              networks in order to enable advertising networks to deliver ads
              that may be relevant to you based upon your activities (this is
              sometimes called “behavioral” or “targeted” advertising) on other
              websites. They may subsequently use information about your visit
              to target you with advertising that you may be interested in on
              other websites.
            </PrivacySectionBody>

            <PrivacySectionHeader>12. Advertising</PrivacySectionHeader>
            <PrivacySectionBody>
              We use cookies for marketing purposes to approach our users with
              advertising that is more tailored to their interests. We also use
              cookies to reduce the likelihood of ads playing and to measure the
              effectiveness of our advertising measures. This information may
              also be shared with third parties, such as ad networks. The legal
              basis for this is article 6, par. 1 a and f of the GDPR. The goals
              intended in data processing serve the legitimate interest of
              direct marketing. You are entitled to file an objection to the
              processing of your data for the purposes of such advertising at
              any time. We provide the following opt-out options for the
              respective services for this purpose. Alternatively, you can
              prevent cookies from being set in your browser and App settings.
            </PrivacySectionBody>

            <PrivacySectionBody>
              We use Google AdSense, a service for integrating ads. Google
              AdSense uses cookies and web beacons (invisible graphics). These
              web beacons allow the analysis of information such as visitor
              traffic on the pages of this website. The information generated by
              cookies and web beacons via the use of this website (including
              user IP addresses) and the distribution of ad formats is
              transferred to a Google server in the USA and stored there. Google
              can pass this information on to their affiliates. However, Google
              will not conflate your IP address with your other stored data.
              Users can prevent cookies from being installed through the
              relevant setting in their browser software and App settings;
              however, please note that if this is done, not all functions of
              the website may be able to be used to their full extent. By using
              this website, the user agrees to the processing of their data
              collected by Google in the manner and for the purpose described
              above.
            </PrivacySectionBody>

            <PrivacySectionBody>
              12.1 Google AdWords and Conversion Tracking
            </PrivacySectionBody>
            <PrivacySectionBody>
              To draw attention to our services, we place Google AdWords display
              ads and, within this context, use Google conversion tracking for
              the purposes of personalized online ads based on interests and
              location. The option to anonymize IP addresses is controlled
              through Google Tag Manager, via an internal setting that is not
              visible in the source of this page. This internal setting is set
              so that the anonymization required by privacy laws covers IP
              addresses.
            </PrivacySectionBody>

            <PrivacySectionBody>
              Ads are displayed based on search requests on websites in the
              Google ad network. We have the ability to combine our ads with
              certain search terms. With the use of cookies, we are able to
              place ads based on previous user visits to our website.
            </PrivacySectionBody>

            <PrivacySectionBody>
              When a user clicks on an ad, Google places a cookie on the user’s
              device. For more information on the cookie technology used, please
              see Google’s statements on website statistics and their data
              privacy policy.
            </PrivacySectionBody>

            <PrivacySectionBody>
              With the use of this technology, Google, and we as their customer,
              receive the information that a user has clicked on an ad and was
              redirected to our websites. The information acquired this way is
              solely used for statistical analysis related to ad optimization.
              We do not receive any information that would allow us to
              personally identify a visitor. The statistics provided to us by
              Google include the total number of users who have clicked on one
              of our ads and, where applicable, whether they were redirected to
              a page on our website that has a conversation tag. These
              statistics allow us to track which search terms most often lead to
              our ads receiving clicks, and which ads lead to the user
              contacting us via the contact form.
            </PrivacySectionBody>

            <PrivacySectionBody>
              If you do not want this, you can prevent the storage of the
              cookies required for this technology by, for example, using the
              settings in your browser or your App. Should you do so, your visit
              will not be incorporated into user statistics.
            </PrivacySectionBody>

            <PrivacySectionBody>
              You also have the option to choose the types of Google ads or
              deactivate interest-based ads on Google through ad settings.
              Alternatively, you can deactivate third-party use of cookies by
              using the Network Advertising Initiative’s opt-out tool.
            </PrivacySectionBody>

            <PrivacySectionBody>
              However, we and Google will still receive statistical information
              about how many users visit this site and when. If you do not want
              to be included in these statistics either, you can prevent this by
              using additional programs for your browser (such as the Ghostery
              add-on).
            </PrivacySectionBody>

            <PrivacySectionBody>12.2 Google DoubleClick</PrivacySectionBody>
            <PrivacySectionBody>
              We use DoubleClick, a service of Google Inc. DoubleClick uses
              cookies to place user-based web ads. The cookies detect which ads
              have already appeared in your browser and whether you visited a
              website via an ad placed. In doing so, the cookies do not collect
              any personally identifiable information, nor are they able to link
              to any.
            </PrivacySectionBody>

            <PrivacySectionBody>
              If you do not want to receive any user-based advertising, you can
              disable the placement of ads by using Google’s ad settings.
            </PrivacySectionBody>

            <PrivacySectionBody>
              For more information about how Google cookies are used, please
              refer to Google’s privacy statement.
            </PrivacySectionBody>

            <PrivacySectionBody>
              12.3 Google Dynamic Remarketing
            </PrivacySectionBody>
            <PrivacySectionBody>
              We use the dynamic remarketing function of Google AdWords on our
              website. This technology allows us to place automatically
              generated ads oriented towards target groups after you visit our
              website. Ads are oriented towards products and services that you
              clicked on during your last visit to our website.
            </PrivacySectionBody>

            <PrivacySectionBody>
              Google uses cookies to generate interest-based ads. Cookies are
              small text files that are stored in your browser when you visit
              our website. In this process, Google typically stores information
              such as your web request, IP address, browser type, browser
              language, and the date and time of your request. This information
              only serves the purpose of mapping the web browser to a specific
              device. It cannot be used to identify an individual.
            </PrivacySectionBody>

            <PrivacySectionBody>
              If you do not want to receive user-based advertising from Google,
              you can disable the placement of ads by using Google’s ad
              settings.
            </PrivacySectionBody>

            <PrivacySectionBody>
              For more information about how Google cookies are used, please
              refer to Google’s privacy statement.
            </PrivacySectionBody>

            <PrivacySectionBody>
              12.4 Facebook Family Custom Audiences
            </PrivacySectionBody>
            <PrivacySectionBody>
              The product Facebook Custom Audiences (Facebook Inc. 1601 S.
              California Avenue, Palo Alto, CA, 94304) for Facebook and
              Instagram is also used as part of usage-based online advertising.
              An irreversible and non-personally identifiable checksum (hash
              total) is essentially generated by your usage data, which can be
              transmitted to Facebook for analysis and marketing purposes. A
              Facebook cookie is set in this process. In doing so, information
              about your activities on the website (such as surfing behavior,
              subpages visited, etc.) is collected. Your IP address is stored
              and used for geographical modulation.
            </PrivacySectionBody>
            <PrivacySectionBody>
              For more information about the purpose and extent of data
              collection and further processing and use of data, as well as
              privacy settings, please refer to the privacy policy for Facebook
              (https://www.facebook.com/privacy/explanation) and Instagram
              (https://help.instagram.com/519522125107875?helpref=page_content).
            </PrivacySectionBody>
            <PrivacySectionHeader>13. Data Transmission</PrivacySectionHeader>

            <PrivacySectionBody>
              Your data will not be transmitted to third parties as a general
              rule unless we are legally obligated to do so or the transfer of
              data is necessary for implementing the contractual relationship or
              you have given prior express consent to have your data
              transferred.
            </PrivacySectionBody>

            <PrivacySectionBody>
              External service providers and affiliated companies, such as
              online payment vendors or communication agents, will only receive
              your data to the extent necessary to process your request. In
              these cases, however, the extent of data transmitted is kept to
              the necessary minimum. If our service providers come into contact
              with your personally identifiable information, we will make sure
              that this complies with the regulations of data protection laws in
              the same way through the course of processing the order in
              accordance with article 28 of the GDPR. Please note the vendor’s
              respective data privacy policy as well. The respective vendor is
              responsible for the content of third-party services, although we
              review services for compliance with legal requirements to a
              reasonable extent.
            </PrivacySectionBody>

            <PrivacySectionBody>
              We emphasize processing your data within the EU/EEA. However, it
              may happen that we use service providers who process data outside
              the EU/EEA. In these cases, we make sure that a reasonable level
              of data protection is established with the recipient before
              transmitting your personally identifiable information. This means
              that a level of data protection is reached through EU standard
              contracts or an adequacy decision that is comparable to the
              standard within the EU.
            </PrivacySectionBody>

            <PrivacySectionHeader>14. Data Security</PrivacySectionHeader>
            <PrivacySectionBody>
              We have taken extensive technical and operational security
              precautions to protect your data from being accidentally or
              intentionally manipulated, lost, destroyed, or accessed by
              unauthorized persons. Our security measures are reviewed regularly
              and updated in keeping with technological advances.
            </PrivacySectionBody>
          </PrivacyPolicyTextWrapper>
          <Footer />
          <BottomFooter />
        </PrivacyPolicyPageContainer>
      </PrivacyPolicyPageWrapper>
    </>
  );
};

export default PrivacyPolicyPage;
