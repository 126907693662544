import { useEffect } from "react";
import NavBar from "../../shared/NavBar/NavBar";
import { categoryData } from "../../store/categoryData";
import { Headings } from "../../shared/Headings/Headings";
import { minSelectedItems } from "../../config";
import { SQFooter } from "./SQFooter";
import { useSqFlowContext } from "../../store/SqFlowProvider";
import { useCategory } from "../../hooks/useCategory";
import { StyleQuizPageWrapper } from "./StyleQuizPageStyled";
import { Listing } from "./Listing/Listing";
import { useNavigate } from "react-router-dom";
import { SQLoading } from "./SQComponents";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { concatArray, getId, getSingleSqSelecteds } from "../../utils/helpers";
import { MobileRoadmap } from "../../shared/NavBar/RoadMap/MobileRoadmap";

export const SQ2 = () => {
  const category = useCategory();
  const navigate = useNavigate();

  const sq1Url = `/style-quiz/${category}/1`;

  const { page1And2State, toggleSq1Sq2SelectedItem, sq1Items, sq2Items } =
    useSqFlowContext();
  const { selectedItems, loadedCategory, items } = page1And2State;

  useEffect(() => {
    if (
      selectedItems.length < 1 ||
      sq1Items === null ||
      sq1Items.length < 1 ||
      loadedCategory !== category
    ) {
      // Because some items from SQ1 should've been picked
      navigate(sq1Url);
    }
  }, [
    concatArray(selectedItems),
    concatArray(sq1Items?.map(getId)),
    loadedCategory,
    category,
  ]);

  const selectedsFromThisPage = getSingleSqSelecteds(selectedItems, sq2Items);

  const isMinSelected = selectedsFromThisPage.length >= minSelectedItems;

  return (
    <>
      <FlitchHelmet title="Preferences — Style Quiz" />
      <NavBar />
      <MobileRoadmap />
      <StyleQuizPageWrapper>
        {sq2Items === null ? (
          <SQLoading />
        ) : (
          <>
            <Headings
              primary={`away – another few likes will bring you closer to your perfect ${categoryData[category].displayName}`}
              sqPageNumber={2}
            />
            <Listing
              category={category}
              items={sq2Items}
              selected={selectedItems}
              toggleSelectItem={toggleSq1Sq2SelectedItem}
            />
            <SQFooter
              isMinSelected={isMinSelected}
              nextPage={`/style-quiz/${category}/3`}
            />
          </>
        )}
      </StyleQuizPageWrapper>
    </>
  );
};
