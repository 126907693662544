import React, {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
} from "react";
import Axios from "axios";
import { Qew } from "qew";
import { makeCatMap, mapOverCatMap } from "../utils/helpers";
import { apiUrl } from "../config";
import { Reason, useUserContext } from "./userContext";
import { useAnalyticsContext } from "./analyticsContext";
import { triggerDiscardEvent, triggerSaveItemEvent } from "../dataLayer";
import { RecCategories } from "../utils/constants";
import { getLatestItemAction } from "../services/api.service";

type LikesRecsSaveds = {
  likes: IFurnitureItem[];
  recommendations: IFurnitureItem[];
  saveds: IFurnitureItem[];
  discards: IFurnitureItem[];
};

type LikesRecsSavedsFII= {
  likes: ItemId[];
  recommendations: ItemId[];
  saveds: ItemId[];
  discards: ItemId[];
};

type LikedApiResponse = Record<CategoryUnion, LikesRecsSaveds>;
type LikedApiResponseFII = Record<CategoryUnion, LikesRecsSavedsFII>;

const qew = new Qew(2);

export const getLikedAndRecs = async () => {
  const { data } = await Axios.get<LikedApiResponse>(`${apiUrl}/likes`);
  return data;
};

const getLikedAndRecsDirectly = async () => {
  const { data } = await Axios.get<LikedApiResponseFII>(`${apiUrl}/likes/directly`);
  //console.info(data);
  return data;
}

const addSaveds = (data: ItemId[], cat: CategoryUnion) =>
  qew.push(() =>
    Axios.post(`${apiUrl}/likes/saved/${cat}`, data)
  );

const deleteSaveds = (data: ItemId[], cat: CategoryUnion) =>
  qew.push(() =>
    Axios.post(`${apiUrl}/likes/unsaved/${cat}`, data)
  );
  //qew.push(() => Axios.delete(`${apiUrl}/likes/saved`, { data }));

const addDiscards = (data: ItemId[], cat: CategoryUnion) =>
  qew.push(() =>
    Axios.post(`${apiUrl}/likes/discards/${cat}`, data)
  );

const deleteDiscards = (data: ItemId[]) => 
  qew.push(() => Axios.delete(`${apiUrl}/likes/discards`, { data }))

type SavedsContext = {
  savedsSets: AllCatMap<Set<ItemId>>;
  discardsSets: AllCatMap<Set<ItemId>>;
  addSavedId: (id: ItemId, cat: CategoryUnion, refreshList: boolean) => Promise<void>;
  deleteSavedId: (idToDelete: ItemId, cat: CategoryUnion, refreshList: boolean) => Promise<void>;
  addDiscardId: (id: ItemId, cat: CategoryUnion, refreshList: boolean) => Promise<void>;
  deleteDiscardId: (idToDelete: ItemId, cat: CategoryUnion, refreshList: boolean) => Promise<void>;
};

const SavedsContext = createContext({} as SavedsContext);

export const useSavedsContext = () => useContext(SavedsContext);

export const SavedsContextProvider: FC<{children?: React.ReactNode}>= ({ children }) => {
  const [saveds, setSaveds] = useState<AllCatMap<ItemId[]>>(
    makeCatMap(() => [])
  );

  const [discards, setDiscards] = useState<AllCatMap<ItemId[]>>(
    makeCatMap(() => [])
  );

  const getSaveds = async () => {
    const result = await getLikedAndRecsDirectly();

    const newSaveds = mapOverCatMap(result, (cat) =>
      cat.saveds.map(( id ) => id)
    );
    setSaveds(newSaveds);

    const newDiscards = mapOverCatMap(result, (cat) =>
      cat.discards.map(( id ) => id) || []
    );
    setDiscards(newDiscards);
  };

  const addSavedId = async (id: ItemId, cat: CategoryUnion, refreshList: boolean) => {
    try {
      if (!cat) return;

      await addSaveds([id], cat);
      if (refreshList)
        await getSaveds();
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const deleteSavedId = async (id: ItemId, cat: CategoryUnion, refreshList: boolean) => {
    try {
      await deleteSaveds([id], cat);
      if (refreshList)
        await getSaveds();
    } catch (error) {
      return;
    }
  };

  const addDiscardId = async (id: ItemId, cat: CategoryUnion, refreshList: boolean) => {
    try {
      await addDiscards([id], cat);
      if (refreshList)
        await getSaveds();
    } catch (error) {
      return;
    }
  };

  const deleteDiscardId = async (id: ItemId, cat: CategoryUnion, refreshList: boolean) => {
    try {
      await deleteDiscards([id]);
      if (refreshList)
        await getSaveds();
    } catch (error) {
      return;
    }
  };

  const { user } = useUserContext();

  useEffect(() => {
    if (user) {
      getSaveds();
    }
  }, [user]);

  const savedsSets = mapOverCatMap(saveds, (items) => new Set(items));
  const discardsSets = mapOverCatMap(discards, (items) => new Set(items));

  return (
    <SavedsContext.Provider
      value={{ savedsSets, discardsSets, addSavedId, deleteSavedId, addDiscardId, deleteDiscardId }}
    >
      {children}
    </SavedsContext.Provider>
  );
};

export type ItemSavedState =
  | "IsSaved"
  | "IsDiscarded"
  | "IsNotSaved"
  | "ChangingState"
  | "ChangingSavedState"
  | "ChangingDiscardState"
  | "NotLoggedIn";

export const useSavedState = (
  item: IFurnitureItem,
  category: CategoryUnion
): {
  state: ItemSavedState;
  toggleSavedOrAuth: (refreshList: boolean, sendSQAnalytics: boolean) => Promise<void>;
  toggleDiscaredOrAuth: (refreshList: boolean, sendSQAnalytics: boolean) => void;
} => {
  const { user, openAuthModal } = useUserContext();
  const [ isAdding, isAddingSet ] = useState(false);
  const [ isDiscarding, isDiscardingSet ] = useState(false);
  const [ isSaved, setIsSaved ] = useState(false);

  const { savedsSets, discardsSets, addSavedId, deleteSavedId, addDiscardId, deleteDiscardId } =
    useSavedsContext();

  const { 
    addAnalyticsSavedClick, 
    removeAnalyticsSavedClick, 
    addAnalyticsDiscardedClick, 
    removeAnalyticsDiscardedClick } = useAnalyticsContext();

  const addSavedHandler = (id: ItemId, refreshList: boolean) => {
    isAddingSet(true);
    return addSavedId(id, category, refreshList).then(() => isAddingSet(false));
  };

  const deleteSavedHandler = (id: ItemId, refreshList: boolean) => {
    isAddingSet(true);
    return deleteSavedId(id, category, refreshList).then(() => isAddingSet(false));
  };

  const addDiscardedHandler = (id: ItemId, refreshList: boolean) => {
    isDiscardingSet(true);
    addDiscardId(id, category, refreshList).then(() => isDiscardingSet(false));
  };

  const deleteDiscardedHandler = (id: ItemId, refreshList: boolean) => {
    isDiscardingSet(true);
    deleteDiscardId(id, category, refreshList).then(() => isDiscardingSet(false));
  };

  const recsCategory = (cat: string) =>
    RecCategories.find(x => x as string === cat) ?? "shop"

  //const isSaved = !!user && savedsSets[recsCategory(category)]?.has(itemId);
  const toggleSavedOrAuth = (refreshList: boolean = true, sendSQAnalytics = true) => {
    /**
     * Ideally we'd trigger all dataLayer events in the analyticsCtx, but saving
     * an item cannot be done, because savedsCtx is outside analyticsCtx so
     * analyticsCtx wouldn't be able to hook into this event. Therefore it has
     * to be here.
     */

    triggerSaveItemEvent();
    return getLatestItemAction(item.guid).then(status => {
      const saved = status === "Saved";
      //setIsSaved(saved);

      try {
        if (sendSQAnalytics) {
          if (saved) {
            removeAnalyticsSavedClick(item.id);
          } else {
            addAnalyticsSavedClick(item.id);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        user
          ? saved
            ? deleteSavedHandler(item.id, refreshList).then(() => setIsSaved(false), console.error)
            : addSavedHandler(item.id, refreshList).then(() => setIsSaved(true), console.error)
          : openAuthModal(Reason.RecsInteraction);
      }
    }).catch(console.error);
  };

  const isDiscarded = !!user && discardsSets[category]?.has(item.id);
  //const isSaved = !!user && savedsSets[category]?.has(item.id);
  const toggleDiscaredOrAuth = (refreshList: boolean = true, sendSQAnalytics = true) => {
    triggerDiscardEvent();
    try {
      if (sendSQAnalytics){
        if (isDiscarded)
          removeAnalyticsDiscardedClick(item.id);
        else
          addAnalyticsDiscardedClick(item.id);
      }
    } catch (err) {
      console.error(err);
    } finally {
      user
        ? isDiscarded
          ? deleteDiscardedHandler(item.id, refreshList)
          : addDiscardedHandler(item.id, refreshList)
        : openAuthModal(Reason.RecsInteraction);
    }
  }

  return {
    state: getStatus(!!user, isAdding, isDiscarding, isSaved, isDiscarded),
    toggleSavedOrAuth,
    toggleDiscaredOrAuth
  };
};

const getStatus = (loggedIn: boolean, isSaving: boolean, isDiscarding: boolean, isSaved: boolean, isDiscarded: boolean): ItemSavedState => {
  if (!loggedIn)
    return "NotLoggedIn";

  if (isSaving && isDiscarding)
    return "ChangingState";

  if (isSaving && isDiscarding)
    return "ChangingState";

  if (isSaving)
    return "ChangingSavedState";

  if (isDiscarding)
    return "ChangingDiscardState";

  if (isSaved)
    return "IsSaved";

  if (isDiscarded)
    return "IsDiscarded";

  return "IsNotSaved";
}