// This file should ideally contain all the functions that send requests to the
// backend API. For visibility.
import axios from "axios";
import Axios from "axios";
import { apiUrl } from "../config";
import { getBrowseFilterPayload } from "../pages/FilterPage/utils/getFilterPayloads";
import { CartEntry } from "../store/shopContext";

const get = async <T>(postfix: string) => {
  const url = `${apiUrl}/${postfix}`;
  const result = await Axios.get<T>(url);
  return result.data;
};

const post = async <T>(postfix: string, body: any = undefined, customerId: string | undefined = undefined) => {
  const config = customerId 
    ? { headers: { customerId } }
    : undefined;

  const url = `${apiUrl}/${postfix}`;
  const result = await Axios.post<T>(url, body, config);
  return result.data;
};

const patch = async <T>(postfix: string, body: any = {}) => {
  const url = `${apiUrl}/${postfix}`;
  const result = await Axios.patch<T>(url, body);
  return result.data;
};

const deleteOne = async <T>(postfix: string) => {
  const url = `${apiUrl}/${postfix}`;
  const result = await Axios.delete<T>(url);
  return result.data;
};

//export const createDealInACForUser = () => Axios.post<void>(`${apiUrl}/deal`);

export const fetchBrowseItems = (
  filterOpts: BrowseFilterOptions,
  tag: Tag | null,
  includeInStock: boolean,
  includeOutOfStock: boolean,
  sortOrder: SortOrder | null,
  nonce: string
) => {
  const filterPayload = getBrowseFilterPayload(filterOpts);
  const data: BrowsePayload = {
    tag,
    filterPayload,
    includeInStock,
    includeOutOfStock,
    sortOrder,
    nonce,
  };

  return post<BrowseResponse>("browse", data);
};

export const fetchBrowseMetadata = () => get<BrowseMetadata>("browse/metadata");

export const fetchBrowseRanges = (tag?: Tag, additionalTags?: Tag[]) => 
  post<BrowseMaxVals>("browse/filter-ranges", { tag: tag ?? "", tags: additionalTags });

// const getPriceHistory = (retailer: string, items: string[], period: HistoryPeriodUnion, byLookup: boolean) => {
//   const concat = items.length > 1 
//     ? items.reduce((p, c) => `${p},${c}`) 
//     : `${items[0]}`;

//   const url = byLookup
//     ? `history/lookups/${retailer}/${concat}/${period}`
//     : `history/ids/${retailer}/${concat}/${period}`; 

//   return get<PriceHistoryEntry[]>(url);
// };

export const getConnectedAffiliateIds = async (item: IFurnitureItem) => {
  if (!item) return [];
  return await get<string[]>(`history/find-ids/${item.retailer}/${item.affiliateId!}`);
}

export const getItemPriceHistory = (item: IFurnitureItem, period: HistoryPeriodUnion) => 
  get<PriceHistoryEntry[]>(`history/${item.guid}/${period}`);

// export const getPriceHistoryById = (retailer: string, ids: string[], period: HistoryPeriodUnion) => 
//   getPriceHistory(retailer, ids, period, false);

// export const getPriceHistoryByLookup = (retailer: string, lookups: string[], period: HistoryPeriodUnion) => 
//   getPriceHistory(retailer,lookups, period, true);

export const postPriceWatch = (item: IFurnitureItem) => 
  post<any>(`watch/${item.guid}`);

export const deletePriceWatch = (item: IFurnitureItem) => //{
  deleteOne(`watch/${item.guid}`);
  //const url = `${apiUrl}/watch/${affiliateId}?productCategory=${category}`;
  //const result = await Axios.delete<number>(url);
  //return result.data;
//};

export const getPriceWatches = () => get<string[]>("watch");

export const getCoupons = () => get<Coupon[]>("coupon");
export const getCouponByRetailer = (retailer: RetailerUnion) => get<Coupon[]>(`coupon/${retailer}`);

export const getShopPage = (
  customerId: string,
  category: string, 
  tags: string[],
  size: number, 
  page: number, 
  nonce: string,
  searchPayload: SearchRequest,
  filterPayload: BrowseFilterOptions,
  nameWords: string[] | undefined,
  includeInStock: boolean,
  includeOutOfStock: boolean,
  soldByFlitch: boolean,
  soldBy3rdParty: boolean,
  sale: SaleFilter,
  sortOrder: SortOrder | null
) => {
  const normalizedCategory = category && category != ""
    ? category.replace(/(?:^|\s)\S/g, res=> res.toUpperCase())
    : "all";

  const body = {
    customerId,
    tags,
    searchPayload,
    filterPayload,
    includeInStock,
    includeOutOfStock,
    soldByFlitch,
    soldBy3rdParty,
    sale,
    sortOrder,
    nonce,
    nameWords
  };

  //console.log(body);

  return post<BrowseResponse>(`browse/${normalizedCategory}/${size}/${page}`, body);
};

export const countProducts = (category: string) => get<number>(`browse/count/${category.toLowerCase()}`);

export const saveSingleAction = (type: ExpActionUnion, cat: string, id: string) => 
  post<any>(`likes/action/${type}/${cat}/${id}`);

// export const saveSingleFOM = (cat: string, id: string) => 
//   saveSingleAction("fom", cat, id);
  //post<any>(`likes/fom/${cat}/${id}`);

export const fetchNextRecs = (cat: CategoryUnion, number: number) => 
  get<IScoredFurnitureItem[]>(`likes/recommended/${cat}/${number}`);

export const getPartnerData = () => get<PartnerData[]>("browse/partners");

const getDtoImageUrl = (item: IFurnitureItem) => 
  item.images.thumbnail?.mainImageGb ?? item.images.thumbnail?.mainImage;

const cartEntryToDto = (entry: CartEntry) => { return {
  quantity: entry.quantity,
  item: {
    id: entry.item.id,
    affiliateId: entry.item.affiliateId,
    retailer: entry.item.retailer,
    name: entry.item.name,
    description: entry.item.description,
    imageUrl: getDtoImageUrl(entry.item),
    price: entry.item.stockData?.price ?? 0,
    shipping: entry.item.stockData?.shippingCost
  }}};

export const generateCheckoutPage = (cart: CartEntry[], shippingTotal: number, promoCode: string) => {
  const body = {
    origin: window.location.origin,
    location: window.location.href.replace(window.location.origin, ""),
    cart: cart.map(cartEntryToDto),
    shippingTotal,
    promoCode
  };

  return post<string>("browse/checkout", body);
};

// export const generateStylistCheckoutPage = () => {
//   const body = {
//     origin: window.location.origin,
//     location: window.location.href.replace(window.location.origin, ""),
//   }
// }

export const getStripePromoCodes = () => get<StripePromoCode[]>("browse/promo");

export const getCategorySizes = async (items: IFurnitureItem[]) => {
  const ids = items.map(x => x.guid);
  const concat = ids.length > 1 
    ? ids.reduce((p, c) => `${p},${c}`) 
    : `${ids[0]}`;

  const resultIds = concat !== "undefined" 
    ? concat
    : "all"

  return await get<CategorySizeUnion[]>(`browse/category-sizes/${resultIds}`);
};

export const updateOrderDetails = (cart: CartEntry[]) => {
  const body = {
    origin: "",
    location: "",
    cart: cart.map(cartEntryToDto),
    shippingTotal: 0,
    promoCode: ""
  };

  return post<any>("browse/order-details", body);
};

export const getOrderNumber = () => get<number|undefined>("browse/latest-order");

export const postSearch = (
  originalQuery: string,
  spelledQuery: string,
  customerId: string,
  resultCount: number,
  toRecs: boolean
) => 
  post<number> (
    "exp/search", 
    {originalQuery, spelledQuery, customerId, resultCount, toRecs}
  );

export const patchSeacrh = (searchId: number, resultCount: number) => 
  patch<any>(`exp/search/${searchId}/${resultCount}`);

export const getSpellingDics = () =>
  get<{
    ignore: string[], 
    doNotCorrect: string[],
    retailerNames: string[]
  }>("spelling/dics");

export const getLatestItemAction = async (guid: string) => 
  (guid !== undefined)
    ? await get<ItemAction>(`likes/latest-action/${guid}`)
    : undefined;


export const getLatestItemActions = (ids: ItemId[]) => 
  post<ItemLatestAction[]>(`likes/latest-action`, ids);

export const checkIfProductWatched = async (guid?: string) =>
  (!guid)
    ? await false
    : get<boolean>(`watch/${guid}`);

export const upgradeAccountCheckout = (promoCode: string = "") => {
  const body = {
    origin: window.location.origin,
    location: window.location.href.replace(window.location.origin, ""),
    cart: [],
    shippingTotal: 0,
    promoCode
  };

  return post<string>("account/upgrade", body);
};

export const upgradeAccount = () => patch<any>("account/upgrade");

export const addUpgradeAccountNotes = (notes: string) =>
  patch<any>("account/upgrade/notes", {notes})

export const checkIfAccIsPaid = () => get<boolean>("account/is-paid");

export const getUserGuid = () => get<string>("account/guid");
