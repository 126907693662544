import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { upgradeAccountCheckout } from "../../services/api.service";
import { FlitchHelmet } from "../../shared/FlitchHelmet/FlitchHelmet";
import { BottomFooter } from "../../shared/Footer/BottomFooter";
import { Footer } from "../../shared/Footer/Footer";
import NavBar from "../../shared/NavBar/NavBar";
import { Reason, useUserContext } from "../../store/userContext";
import { 
  AboutStylistPageWrapper, 
  BackgroundMobile, 
  BottomRightCorner, 
  DescContainer, 
  DescWrapper, 
  DescWrapperMobile, 
  GeneralDescription,
  PlanHeader,
  TopLeftCorner} from "./AboutStylistStyles";
import { GroupedFaq } from "../../shared/Faq";
import { GROUP_FAQ_CONTENT } from "../LandingPages/Headspace"
import { NumberedStep } from "./NumberedStep";
import { Variant } from "./Variant";


const howWorksLabels = () => {
  const navigate = useNavigate();

  return [ 
    (<p>Take a quick <Link to="/category">style quiz</Link> so that our clever artificial intelligence algorithms can profile your taste</p>), 
    (<p>Your stylist will combine this profile with their expertise, and collate your own mood board and recommendations</p>),
    (<p>After perfecting your furniture recommendations, your stylist will send you your personalised shopping list</p>) ]};

const paidFeatures = [
  "A dedicated human stylist to satisfy your furniture needs",
  "Unlimited interactions with your interior stylist",
  "Track and monitor prices for thousands of furniture items",
  "Exclusive discounts on items sold by Flitch"
];

const FreeFeatures = [
  "Full product catalogues of 100+ furniture retailers in one place",
  "Narrow down your search with 3 tailored recommendations",
  "Receive price drop notifications for saved items",
  "Save with coupon codes that can be used at partner retailers"
];


const descHeader = (isMobile: boolean) => (<>
  {isMobile 
    ? <>How Flitch<br/> Works</> 
    : <>How Flitch Works</>}
</>);

const generalDesc = (isMobile: boolean, isHome: boolean) => (
  <GeneralDescription>
    {isHome
      ? <h2>{descHeader(isMobile)}</h2>
      : <h1>{descHeader(isMobile)}</h1>}
    {howWorksLabels().map((label, i) => 
      <NumberedStep key={i} number={i+1} label={label}/>)}
  </GeneralDescription> );

const paidVariant = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const { user, openAuthModal } = useUserContext();


  const goToCheckout = () => {
    console.log(user);
    if (!user) {
      openAuthModal(Reason.RegisterBeforeUpgrade);
      return;
    }

    setIsLoading(true);
    upgradeAccountCheckout()
      .then(url => window.location.href = url)
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  return (
    <Variant 
      title="Human Stylist"
      description="For people short on time who need an expert stylist to find the right furniture for them"
      cost={25}
      featuresTitle="Self-serve features, plus:"
      features={paidFeatures}
      isLoading={isLoading}
      buttonLabel="Join Now"
      buttonAction={goToCheckout}
    /> )
};

const freeVariant = (isMobile: boolean) => {
  const navigate = useNavigate();

  return (
    <Variant 
      title="Self-Serve"
      description="For people looking to do their own research and compare the furniture market in one place"
      featuresTitle="All the basics, for free"
      features={FreeFeatures}
      buttonLabel="Browse"
      buttonAction={() => navigate("/shop")}
      freeLabel={isMobile ? "£0" : undefined}
    /> )};

interface DescProps {
  isHomePage: boolean;
  children? :React.ReactNode
} 

export const AboutStylistDescription : FC<DescProps> = (
  {isHomePage}
) => (<>
  <DescWrapper isHome={isHomePage}>
    <TopLeftCorner />
    {generalDesc(false, isHomePage)}
    {paidVariant()}
    {freeVariant(false)}
    <BottomRightCorner />
  </DescWrapper>

  <BackgroundMobile />
  <DescWrapperMobile isHome={isHomePage}>
    {generalDesc(true, isHomePage)}
    <PlanHeader>
      Our Plans
    </PlanHeader>
    {paidVariant()}
    {freeVariant(true)}
  </DescWrapperMobile>
</>);

export const AboutStylistPage : FC = () => {
  return (<>
    <FlitchHelmet title="About Stylist" />
    <NavBar />
    <AboutStylistPageWrapper>
      <AboutStylistDescription isHomePage={false}>
        
      </AboutStylistDescription>
      <GroupedFaq faqGroups={GROUP_FAQ_CONTENT}/>
      <div>
        <Footer />
        <BottomFooter />
      </div>
    </AboutStylistPageWrapper>
  </>);
}