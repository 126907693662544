import styled from "styled-components";
import { colors, fontSizes } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";

export const ReviewWrapper = styled.div`
  padding: 0 2% 3% 2%;

  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: left;

  z-index: 3;

  scrollbar-width: 8px;

  ::-webkit-scrollbar {
    height: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.greyMedium};
  }

`;

export const ReviewContainer = styled.div`
  display: grid;
  grid-template-rows: 3em 1fr 2.5em;
  padding: 0.3em 0.8em 1.4em 0.8em;
  margin: 0 0.4em 0 0.4em;
  background-color: ${colors.greyLighter};
  font-size: ${fontSizes.mediumLarger};
  flex: 0 0 auto;
  width: 25em;
  line-height: 1.2;

  @media (max-width: ${breakpoints.mobile}) {
    grid-template-rows: 2.2em 1fr 2.5em;
    width: min(20em, 100vw - 2.5em);
    font-size: ${fontSizes.small};
    padding: 1em 0.7em 0.5em 0.7em;
    margin: 0 0.25em 0 0.25em;
  }

  h3 {
    font-size: 5rem;
    color: ${colors.greenPale};
    letter-spacing: 0.25rem;
    grid-column-start: 1;
    grid-row-start: 1;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: 3.2rem;
      line-height: 0.8;
    }
  }

  p {
    color: ${colors.greyDark};
    text-align: left;
    grid-row-start: 2;
    padding-bottom: 1em;
  }
`;

export const ReviewBottomLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  grid-column-start: 1;
  grid-row-start: 3;
  padding: 0;

  p {
    padding: 0;
    margin: 0;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 0.2em;
  }
`;

export const StaticReviewWrapper = styled.div`
  padding: 3% 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0px -2px 7px #00000029;
  z-index: 3;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 3% 2%;
    overflow-x: scroll;
    display: grid;
    grid-template-columns: 90vw 90vw 90vw;
    justify-content: start;
  }
`;

export const StaticReviewContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  padding: 0.2% 1.5% 1%;
  background-color: ${colors.greyLighter};
  font-size: ${fontSizes.large};
  width: 29vw;
  line-height: 1.2;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 3%;
    margin: 0 1.5vw 0 1vw;
    width: auto;
    font-size: ${fontSizes.medium};
  }

  h3 {
    font-size: 5rem;
    color: ${colors.greenPale};
    letter-spacing: 0.25rem;
    margin-top: 1%;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: 3.2rem;
      margin-bottom: 1.5%;
    }
  }

  p {
    color: ${colors.greyDark};
    text-align: left;
  }
`;

export const StaticReviewBottomLine = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 4%;
  }
`;
