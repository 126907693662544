import { FC } from "react";
import { Helmet } from "react-helmet";

import defaultImage from "../../images/preview-card.jpg"

interface HelmetProps {
  title?: string;
  image? : string;
  description? : string;
  canonical?: string;
  error? : string;
  statusCode? : string;
}

const defaultDescription = "Flitch is your online interior styling platform that provides an effortless furniture shopping experience built entirely around your taste, budget and requirements";

export const FlitchHelmet : FC<HelmetProps> = (
  {title, image, description, canonical, error, statusCode}
) => (
  /*@ts-ignore*/
  <Helmet>
    <meta charSet="utf-8" />
    <meta property="og:site_name" content="Flitch" />
    <title>{title ? `${title} | Flitch` : "Flitch"}</title>
    <meta name="description" content={description ?? defaultDescription} />
    <meta property="og:description" content={description ?? defaultDescription} />
    <meta property="twitter:description" content={description ?? defaultDescription} />
    <meta property="twitter:image" content={image ?? defaultImage} />
    <meta property="twitter:card" content="summary_large_image" />
    <link rel="canonical" href={canonical ?? window.location.href}/> 
    {error ? (<meta name='errorpage' content='true' />) : undefined}
    {error ? (<meta name='errortype' content={error} />) : undefined}
    {statusCode ? (<meta name='prerender-status-code' content={statusCode} />) : undefined}
  </Helmet>
);