import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/globalStyled";
import { breakpoints } from "../../../styles/responsive";
import { RoadMap } from "./RoadMap";

const verticalPosition = (p: {pos : number}) =>
  `calc(var(--navbar-height-mobile) * ${p.pos})`;

export const RoadMapWrapper = styled.div<{pos : number}>`
  position: relative;
  top: ${verticalPosition};
  height: var(--navbar-height-mobile);
  z-index: 2;

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const RoadMapMobile = styled.div`
  height: var(--navbar-height-mobile);
  background: ${colors.greyLight};
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  display: flex;
  justify-content: center;
`;

interface MobileRoadmapProps {
  position?: number
}

export const MobileRoadmap: FC<MobileRoadmapProps> = ({position}) => (
  <RoadMapWrapper pos={position ?? 1}>
    <RoadMapMobile>
      <RoadMap />
    </RoadMapMobile>
  </RoadMapWrapper>
);