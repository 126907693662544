import Axios from "axios";
import { apiUrl, isNonStaff } from "../../config";

export const fetchSingleItem = async (itemId: ItemId) => {
  const { data } = await Axios.get<IFurnitureItem>(
    `${apiUrl}/product/${itemId}`
  );
  return data;
};

export const fetchColourVariants = async (variantsToFetch: IdAndColours[]) => {
  const colourVariants = await Promise.all(
    variantsToFetch.map(({ id }) => fetchSingleItem(id))
  );

  return colourVariants;
};

export const fetchSimilarItems = async (itemId: ItemId) => {
  const { data } = await Axios.get<ISimilarItemsResponse>(
    `${apiUrl}/product/${itemId}/similar`
  );
  return data;
};

export const sendFullAnalytics = async (analytics: FullAnalyticsState) => {
  const url = `${apiUrl}/likes/journeys`;
  if (isNonStaff) {
    await Axios.post<void>(url, analytics);
    // await Axios.post<void>(url, analytics, {
    //   params: { productCategory: analytics.category },
    // });
  }
};
