import { SetStateAction } from "react";
import hash from "object-hash";
import moment from "moment";

export const notEqualTo =
  <T>(item: T) =>
  (thisItem: T) =>
    thisItem !== item;

export function toFixedRange(size: NumOrRange, fixed: number) {
  if (typeof size === "number") {
    return size.toFixed(fixed);
  } else {
    const [a, b] = size;
    return `${a.toFixed(fixed)}-${b.toFixed(fixed)}`;
  }
}

export const toFixedRangeIfNotInteger = (n: number) => {
  const isFixed = n === Math.round(n);
  return n.toFixed(isFixed ? 0 : 2);
};

export const toggleFromArray =
  <T extends string | number | null>(items: T[]) =>
  (value: T) => {
    if (items.includes(value)) {
      return items.filter(notEqualTo(value));
    } else {
      return [...items, value];
    }
  };

export const toggleFromArraySetter =
  <T extends string | number>(setter: StateSetter<T[]>) =>
  (value: T) => {
    setter((items) => {
      if (items.includes(value)) {
        return items.filter(notEqualTo(value));
      } else {
        return [...items, value];
      }
    });
  };

export const isNotFalsy = <T extends string | number | any[]>(
  t: T | null | undefined
): t is T => Array.isArray(t) || t === 0 || t === "" || !!t;

export const getId = ({ id }: { id: ItemId }) => id;

export const toggle = (v: boolean) => !v;

export const getChair = (scoredItem: IScoredFurnitureItem) => scoredItem.chair;

export const isOfCategory =
  (cat: CategoryUnion) => (item: { category: CategoryUnion }) =>
    item.category === cat;

export const allCatMap: AllCatMap<true> = {
  diningchair: true,
  diningtable: true,
  barstool: true,
  coffeetable: true,
  consoletable: true,
  sideboard: true,
  sidetable: true,
  bedsidetable: true,
  chestofdrawers: true,
  displaycabinet: true,
  shelving: true,
  tvstand: true,
  wardrobe: true,
  sofa: true,
  armchair: true,
  bed: true,
  shop: true
};

export const categoryReadableNames = ([
  ["diningchair", "Dining Chair"],
  ["diningtable", "Dining Table"],
  ["barstool", "Bar Stool"],
  ["coffeetable", "Coffee Table"],
  ["consoletable", "Console Table"],
  ["sideboard", "Sideboard"],
  ["sidetable", "Side Table"],
  ["bedsidetable", "Bedside Table"],
  ["chestofdrawers", "Chest Of Drawers"],
  ["displaycabinet", "Display Cabinet"],
  ["shelving", "Shelving"],
  ["tvstand", "TV Stand"],
  ["wardrobe", "Wardrobe"],
  ["sofa", "Sofa"],
  ["armchair", "Armchair"],
  ["bed", "Bed"]
] as const).reduce((acc, [category, name]) => {
  return {...acc, [category]: name}
}, {} as {[_: string]: string});

export const getProgramByRetailer = (retailer: RetailerUnion): ProgramUnion  => {
  if (["Amara", "Soho Home", "The Conran Shop", "OKA"].includes(retailer))
    return "Rakuten";

  if (["John Lewis", "Barker and Stonehouse", "Graham & Green", "Noa Home"].includes(retailer))
    return "Impact";

  if (["Made.com"].includes(retailer))
    return "Partnerize";

  if (["IO Living", "Tobias Oliver", "Nordic Nest UK"].includes(retailer))
    return "Adtraction";

  if (["Furniture123", "Woods Furniture", "Heal's", "BHS.com", "Downtown", "The Rug Seller"].includes(retailer))
    return "Webgains";

  if (["Habitat"].includes(retailer))
    return "CJ";

  if (["Vivense", "Atkin & Thyme", "Loaf"].includes(retailer))
    return "DirectSelling";

  return "Awin";
}

export const isValidCategory = (catStr?: string): catStr is CategoryUnion =>
  allCatMap[catStr as CategoryUnion] !== undefined;

export const makeCatMap = <T>(
  getter: (cat: CategoryUnion) => T
): AllCatMap<T> => {
  return {
    diningchair: getter("diningchair"),
    diningtable: getter("diningtable"),
    barstool: getter("barstool"),
    coffeetable: getter("coffeetable"),
    consoletable: getter("consoletable"),
    sideboard: getter("sideboard"),
    sidetable: getter("sidetable"),
    bedsidetable: getter("bedsidetable"),
    chestofdrawers: getter("chestofdrawers"),
    displaycabinet: getter("displaycabinet"),
    shelving: getter("shelving"),
    tvstand: getter("tvstand"),
    wardrobe: getter("wardrobe"),
    sofa: getter("sofa"),
    armchair: getter("armchair"),
    bed: getter("bed"),
    shop: getter("shop")
  };
};

export const mapOverCatMap = <T, R>(
  catMap: AllCatMap<T>,
  mapper: (item: T, cat: CategoryUnion) => R
) => makeCatMap((cat) => mapper(catMap[cat], cat));

export const catMapValues = <T>(catMap: AllCatMap<T>) => Object.values(catMap);

// @TODO: potentialy add referrerId into the clickref (if it doesn't risk making it longer than the clickref limit)
export const getClickRef = (
  //customerId: string | null,
  userId: string | undefined,
  itemId: ItemId = "logoclick" as ItemId
) => encodeURIComponent(`${userId ?? "notloggedin"}_${itemId}_w`);

// {
//   return encodeURIComponent(
//     `${customerId ?? "nocustomerid"}_${itemId}_${userId ?? "notloggedin"}`
//   );
// };

/**
 * Identity function
 */
export const id = <T>(x: T) => x;

export const indexOrFirst = <T>(list: T[], index: number): T =>
  index < list.length ? (list[index] as T) : (list[0] as T);

export function* getSequentialChars() {
  const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
  for (const char of alphabet) {
    yield char.toUpperCase();
  }
  return "";
}

export const isSetStateActionSetter = <T>(
  value: React.SetStateAction<T>
): value is (prevState: T) => T => typeof value === "function";

export const makeOption = <T extends string>(
  value: T,
  label: string = value
): Option<T> => ({ value, label });

export const makeSubStateSetter =
  <T, K extends keyof T>(setter: StateSetter<T>, selector: K) =>
  (setStateAction: SetStateAction<T[K]>) =>
    setter((state) => ({
      ...state,
      [selector]: isSetStateActionSetter(setStateAction)
        ? setStateAction(state[selector])
        : setStateAction,
    }));

export const makeNullsUndefs = <T extends object>(obj: T): RemoveNulls<T> => {
  const holder: RemoveNulls<T> = {} as RemoveNulls<T>;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      // @ts-ignore
      holder[key] = element === null ? undefined : element;
    }
  }

  return holder;
};

export const distinct = <T>(val: T, i: number, self: T[]) => self.indexOf(val) === i;

export const asc = (x: number, y: number) => x - y;
export const desc = (x: number, y: number) => y - x;

export const ascBy = <T>(f: (_: T) => number) => (x: T, y: T) => f(x) - f(y);
export const descBy = <T>(f: (_: T) => number) => (x: T, y: T) => f(y) - f(x);

export const shareItem = (item: IFurnitureItem) => {
  const productUrl = `${window.location.protocol}//${window.location.host}${getProductUrl(item)}`;

  navigator.clipboard.writeText(productUrl)
    .then(() => alert("The URL has been copied to your clipboard"))
    .catch(err => console.error("Can not copy the URL", err));

}

export const getPrefiltersUrl = (cat: CategoryUnion) =>
  `/style-quiz/${cat}/filter`;

export const getRecsUrl = (cat: CategoryUnion) =>
  `/style-quiz/${cat}/recommendations`;
 
export const getProductUrl = (item: IFurnitureItem)=> {
  const elipsis = (source: string) => {
    if (source.length <= 200) return source;
    return source
      .substring(0, 200)
      .substring(0, source.lastIndexOf(" ") + 1);
  };

  const latinMap = [
    ["o", ['ø','ô','ó','ö','ō','ò']],
    ["e", ['é','è','ë','ê']],
    ["a", ['ä','à','â','å','á','ã']],
    ["i", ['í','ï','î']],
    ["u", ['ù','ü','û']],
    ["s", ['š']],
    ["c", ['ç','¢']],
    ["oe", ['œ']],
    ["ae", ['æ']]] as [string, string[]][];

  const replaceExtended = (c: string) => {
    const res = latinMap.find(x => {
      const [_, lst] = x;
      return lst.includes(c)});
    if (!res) return c;
    const [key, _] = res;
    return key;
  }

  const getStandardLatin = (str: string) => 
    str.split("").map(c => replaceExtended(c)).join("");

  const name = 
    getStandardLatin(elipsis(item.name))
      .replaceAll("'", "")
      .toLowerCase()
      .replaceAll(/[^a-z0-9 ]/ig, " ")
      .replaceAll( /[ ]+/ig, "-");

  return `/product/${name}/${item.id}`;
};

export const makeListHumanFriendly = <T extends string>(
  items: (T | null | undefined)[]
) => {
  const filteredItems = items.filter(isNotFalsy);
  const len = filteredItems.length;

  if (len === 1) {
    return filteredItems[0];
  } else if (len === 2) {
    return `${filteredItems[0]} and ${filteredItems[1]}`;
  } else {
    const allButLast = filteredItems.slice(0, len - 1);
    const last = filteredItems[len - 1];

    return `${allButLast.join(", ")}${
      last === undefined ? "" : ` and ${last}`
    }`;
  }
};

export const arrayToObj = <T, Key extends string>(
  array: T[],
  mapper: (v: T) => Key
): Record<Key, T> => {
  const obj = array.reduce((obj, thisItem) => {
    const key = mapper(thisItem);
    obj[key] = thisItem;
    return obj;
  }, {} as Record<Key, T>);

  return obj;
};

export const getRangeFromDefaults = (
  minValOpt: number | null | undefined,
  maxValOpt: number | null | undefined,
  defaultRange: RangeProps
): NumRange => [minValOpt ?? defaultRange.min, maxValOpt ?? defaultRange.max];

export const hashRequestPayload = (
  category: CategoryUnion,
  selectedAndDisplayedIds: SelectedsAndDisplayeds[],
  filterOpts?: AnyFilterOpts,
  dontApplyFiltersToRecs?: boolean
): RequestInputHash =>
  hash(
    { category, selectedAndDisplayedIds, filterOpts, dontApplyFiltersToRecs },
    { unorderedArrays: true, unorderedObjects: true }
  ) as RequestInputHash;

export const hashJourney = (
  category: CategoryUnion,

  selectedAndDisplayedIds: SelectedsAndDisplayeds,
  recommendedIds: ItemId[],
  filterPayload: AllFilterPayload,
  requestSeconds: number | null,
  customerId: string
): JourneyHash =>
  hash(
    {
      category,
      selectedAndDisplayedIds,
      recommendedIds,
      filterPayload,
      requestSeconds,
      customerId,
    },
    { unorderedArrays: true, unorderedObjects: true }
  ) as JourneyHash;

export const elipsizeAfter = (str: string, maxStrLen: number) =>
  str.length > maxStrLen ? str.substring(0, maxStrLen - 3) + "…" : str;

type Result<T> = { success: true; result: T } | { success: false; error: any };

/**
 *
 */
export const getPromiseResult = async <T>(
  func: () => Promise<T>
): Promise<Result<T>> => {
  try {
    const result = await func();
    return { success: true, result };
  } catch (error) {
    return { success: false, error };
  }
};

/**
 * Gets the selecteds on a single page, whether SQ1 or SQ2
 */
export const getSingleSqSelecteds = (
  selectedIds: ItemId[],
  sq1Items: IFurnitureItem[] | null
) => sq1Items?.filter((item) => selectedIds.includes(item.id)) ?? [];

export const splitSq1And2Selecteds = (
  sq1And2SelectedIds: ItemId[],
  sq1Items: IFurnitureItem[] | null,
  sq2Items: IFurnitureItem[] | null
): Tuple<IFurnitureItem[]> => [
  getSingleSqSelecteds(sq1And2SelectedIds, sq1Items),
  getSingleSqSelecteds(sq1And2SelectedIds, sq2Items),
];

/**
 * Used in useEffect deps array to get value-based comparison. Works for
 * optional or null arrays too.
 */
export const concatArray = (array: string[] | null | undefined) =>
  array && array.join("-");

export const getGreyBgOrFallback = ({ mainImage, mainImageGb }: SizeImage) =>
  mainImageGb ?? mainImage;

export const getGreyBgOrFbThumbnail = ({
  images: { thumbnail },
}: IFurnitureItem) => getGreyBgOrFallback(thumbnail);

export const getGreyBgOrFbFull = ({ images: { full } }: IFurnitureItem) =>
  getGreyBgOrFallback(full);

export const getKeyValPairs = <T>(filters: T) => {
  const array: KeyAndValPair<T>[] = [];

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const filterVal = filters[key as keyof T];

      array.push([key, filterVal] as unknown as KeyAndValPair<T>);
    }
  }

  return array;
};

export const getTruthyKeyValPairs = <T>(filters: T) => {
  const array: KeyAndValPair<T>[] = [];

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const filterVal = filters[key as keyof T];

      if (
        Array.isArray(filterVal) &&
        filterVal.length > 0 &&
        filterVal !== undefined &&
        filterVal !== null
      ) {
        array.push([key, filterVal] as unknown as KeyAndValPair<T>);
      }
    }
  }

  return array;
};

export const pluralise = <T>(itemsOrLen: number | T[]) => {
  const n = typeof itemsOrLen === "number" ? itemsOrLen : itemsOrLen.length;
  return n === 1 ? "" : "s";
};

export const ceilTo10 = (n: number) => Math.ceil(n / 10) * 10;

export const capitalise = (str: string) =>
  `${str[0]?.toLocaleUpperCase() ?? ""}${str.slice(1)}`;

export const capitaliseTitle = (str: string) =>
  str.split(" ").map(capitalise).join(" ");

export const getDateRange = (
  daysToSubStart: number, 
  daysToSubEnd: number
) => {
  if (daysToSubStart <= daysToSubEnd){
    console.warn("getDateRange: daysToSubStart <= daysToSubEnd");
    return [];
  }
  const result = [];
  const now = moment();
  for (let i = daysToSubStart; i >= daysToSubEnd; i--)
    result.push(now.subtract(i, "days").toDate());

  return result;
}

export const areIntersect = <T>(first: T[], second: T[]) => 
  first.some(x => second.includes(x));

export const isBetween = (range: NumRange, num: number) => {
  const [min, max] = range;
  return num >= min && num <= max;
}

export const isDimensionMatch = (range: NumRange, dimension: NumOrRange) => {
  if (typeof dimension === "number")
    return isBetween (range, dimension)
    
  const [min, max] = dimension;
  return isBetween(range, min) || isBetween(range, max);
}

export const normalizePriceLabel = (price: number | undefined | null, round: boolean = true) => {
  const priceFormatter = new Intl.NumberFormat("en-GB", {currency: "GBP", style: "currency", maximumFractionDigits: round ? 0 : 2});
  return priceFormatter.format(price ?? 0);
};

export  const forcedPluralize = (name: string | undefined) => {
    if (!name) return ""
  
    return name.endsWith("s")
      ? name
      : name + "s";
  };

export const findBestCoupon = (item: IFurnitureItem, coupons: Coupon[]) => {
  const filtered = coupons
    .filter(c => c.retailer === item.retailer && (item.stockData?.price ?? 0) >= c.minSpend);
 
    switch (filtered.length) {
      case 0: return undefined;
      case 1: return filtered[0];
      default: return filtered.reduce((a, b) => a.minSpend > b.minSpend ? a : b);
    }   
};

export const group = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const toRecord = <TKey extends string|number|symbol, TValue>(source: [TKey, TValue][]) =>
  source.reduce((acc, current) => {
    const [key, value] = current;
    return {...acc, [key]: value}
  }, {} as Record<TKey, TValue>);

const singleIntersection = <T>(x: T[], y: T[]) =>
  x.filter(i => y.includes(i));

const intersectionRec = <T>(source: T[][], prev: T[]): T[] => {
  if (source.length === 0) return [];

  const next = source[0]!;
  const newIntersection = singleIntersection(prev.length === 0 ? next: prev, next);
  const newRec = source.filter(x => x != next);

  if (newRec.length === 0) return newIntersection;
  return intersectionRec(newRec, newIntersection);
};

export const intersect = <T>(source: T[][]) => intersectionRec(source, []);

export const flatten = <T>(source: T[][]) => 
  source.reduce((acc, x) => [...acc, ...x]);

export const seoficateTag = (tag: string) =>
  tag.toLowerCase().split(" ").join("-");

export const tryGetTagFromUrl = (url: string) => {
  const arr = url.split("/");
  const latest = arr[arr.length-1];
  if (latest === "shop") return undefined;

  return latest?.split("-").join(" ");
};

export const randomBetween = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);
