import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { deletePriceWatch, getLatestItemAction, postPriceWatch } from "../../services/api.service";
import { useSavedState } from "../../store/savedsContext";
import { Reason, useUserContext } from "../../store/userContext";
import { SaveButtonStyled } from "./RecommendationsPageStyled";

interface SaveForLaterButtonProps {
  item: IFurnitureItem;
  isActive: boolean;
  category: CategoryUnion;
}

export const SaveForLaterButton: FC<SaveForLaterButtonProps> = ({
  item,
  isActive,
  category
}) => {
  const { state, toggleSavedOrAuth } = useSavedState(
    item,
    category
  );

  const { user, openAuthModal } = useUserContext();

  //const isSaved = state === "IsSaved";
  //console.log(state)

    const updateIsSaved = () => 
      getLatestItemAction(item.guid)
        .then(a => {
          console.log(a)
          setIsSaved(a === "Saved")
        })
        .catch(console.error);
    

  const [ isSaved, setIsSaved ] = useState(false);
  useEffect (() => { updateIsSaved() }, [item])

  useEffect(() => console.log(isSaved), [isSaved])

  const toggleSaved = async () => {
    toggleSavedOrAuth(true, true).then(async () => {
      if (isSaved)
        await deletePriceWatch(item)
      else 
        await postPriceWatch(item);
      await updateIsSaved();
    });
  }

  const spinner = (<FontAwesomeIcon icon={faCircleNotch} spin={true} color="white" size="sm" />);
  const sfl = (<>Save<span className="more-text"> For Later</span></>)

  return (
    <SaveButtonStyled
      disabled={!isActive || state === "ChangingSavedState"}
      isSaved={isSaved}
      onClick={user ? toggleSaved : () => openAuthModal(Reason.Login)}
      padding={10}
    >
      {state === "ChangingSavedState" 
      ? spinner 
      : isSaved 
        ? ("Saved") 
        : sfl}
    </SaveButtonStyled>
  );
};
